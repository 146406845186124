import useClasses from 'hooks/useClasses';
import React, { useState } from 'react';
import rootStyles from 'LaneManagement/LaneDetails/LaneDetails.style';
import LocationSelector from 'shared-components/LocationSelector';
import { AddressDTO } from 'shared-components/dataTypes';
import LocationInfo from 'LaneManagement/LaneDetails/Cards/AddLocation/LocationInfo';
import { FormControl } from '@mui/material';
import { InformationCardField } from '../fieldMapper';

type Props = {
    field: InformationCardField,
    filter?: {
        [key: string]: any
    },
    font?: 'small' | 'medium',
    mandatory?: boolean,
    onChange?: any,
    showOnly?: string[],
    value: AddressDTO
}

const LocationSelectorField = ({
    field = null,
    filter,
    font = null,
    mandatory = false,
    onChange,
    showOnly = null,
    value,
}: Props) => {
    const classes = useClasses(rootStyles);
    const [open, setOpen] = useState(false);

    return (
        <div style={{ overflow: 'hidden' }}>
            <FormControl
                fullWidth
            >
                <span className={classes.label}>{field.label}</span>
                <div style={{ height: 5 }} />
                <LocationInfo
                    emptyText="Not Assigned"
                    font={font}
                    location={value}
                    mandatory={mandatory}
                    short
                    onClick={onChange && setOpen.bind(null, true)}
                />
            </FormControl>

            {
                onChange && (
                    <LocationSelector
                        addressData={value}
                        filter={filter}
                        open={open}
                        showOnly={showOnly}
                        onCloseAction={setOpen.bind(null, false)}
                        onDataChange={onChange}
                    />
                )
            }
        </div>
    );
};

export default LocationSelectorField;

import useGetCommonData from 'hooks/useGetCommonData';
import { useCallback, useMemo } from 'react';
import { Seaport, AirportExtended } from 'dataTypes/SecureBackend/apiResponse';
import { AddressDTO } from 'shared-components/dataTypes';
import { nonNullObject, nonNullKey } from 'shared-components/common';
import useCurrentUserContext from 'hooks/useCurrentUserContext';

export enum ENTITY_TYPE {
    ADDRESS='address',
    AIRPORT='airport',
    COMPANY='company',
    SEAPORT='seaport',
    SITE='site'
}
export enum ENTITY_TYPE_ID {
    ADDRESS='address',
    AIRPORT='airportCode',
    PHARMA_SITE='pharmaSiteId',
    SEAPORT='seaportCode',
    SERVICE_PROVIDER_SITE='serviceProviderSiteId'
}

export const extractId = (entity: AddressDTO) => {
    const obj = nonNullObject(entity);

    return obj?.code || obj?.id;
};
const useGetEntity: () => {
    dataLoaded: boolean,
    entityFromAddressId: (obj: Record<string, any> | null) => Record<string, any> | null
    getEntity: (id: number | string, type: ENTITY_TYPE) => AirportExtended | Seaport | { id: number } | string | null
    replaceWithAddressDTO: (obj: Record<string, any>, replaces: { [key: string]: ENTITY_TYPE }) => Record<string, any>
} = () => {
    const { company } = useCurrentUserContext();
    const {
        data: airports,
        status: airportsStatus,
    } = useGetCommonData<AirportExtended[]>('airports');
    const {
        data: seaports,
        status: seaportsStatus,
    } = useGetCommonData<Seaport[]>('seaports');
    const {
        data: sites,
        status: sitesStatus,
    } = useGetCommonData(
        `companies/${company?.id}/sites`,
        { postProcess: it => it.resultList },
    );
    const {
        data: companies,
        status: companiesStatus,
    } = useGetCommonData('companies', { postProcess: it => it.resultList });
    const dataLoaded = useMemo(() => {
        return [companiesStatus, airportsStatus, seaportsStatus, sitesStatus]
            .every(it => it === 'SUCCESS');
    }, [
        airports,
        seaports,
        sites,
        companies,
    ]);

    const getEntity = useCallback((id: number | string | object, type: ENTITY_TYPE) => {
        switch (type) {
        case ENTITY_TYPE.AIRPORT:
            return airports.find(it => it.code === id);
        case ENTITY_TYPE.SEAPORT:
            return seaports.find(it => it.code === id);
        case ENTITY_TYPE.SITE:
            return sites.find(it => it.id === id);
        case ENTITY_TYPE.ADDRESS:
            return id;
        case ENTITY_TYPE.COMPANY:
            return companies.find(it => it.id === id);
        default:
            return null;
        }
    },
    [
        airports,
        seaports,
        sites,
        companies,
    ]);
    const replaceWithAddressDTO = useCallback((obj: any, replaces: {[key:string]: ENTITY_TYPE}) => {
        const newObj = { ...obj };

        Object.keys(replaces).forEach((key) => {
            const addressType = replaces[key];

            newObj[key] = {
                [addressType]: getEntity(obj[key], addressType),
            };
        });
        return newObj;
    },
    [getEntity]);

    const entityFromAddressId = useCallback((obj: any) => {
        if (!obj) return null;
        const reverseMap = Object
            .entries(ENTITY_TYPE_ID)
            .reduce((acc, [key, value]) => ({
                ...acc,
                [value]: key,
            }), {});
        const key = nonNullKey(obj);

        if (key) {
            const entityId = obj?.[key];

            const type:ENTITY_TYPE = ENTITY_TYPE[reverseMap[key]];

            return {
                [type]: getEntity(entityId, type),
            };
        } else {
            return null;
        }
    },
    [getEntity, dataLoaded]);

    return {
        dataLoaded,
        entityFromAddressId,
        getEntity,
        replaceWithAddressDTO,
    };
};

export default useGetEntity;

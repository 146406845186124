import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme) => ({
    alignEnd: {
        '& input': {
            textAlign: 'end',
        },
        width: '75px',
    },
    classic: {
        background: theme.palette.secondary[30],
    },
    input: {
        '& *': {
            border: 'none',
            color: theme.palette.secondary[600],
        },
        '& .MuiSelect-select': {
            fontSize: '16px',
        },
        '& input': {
            padding: '4px',
            paddingLeft: '5px',
        },
    },
    invalid: {
    // @ts-ignore
        background: `${theme.palette.common.red}2e`,
    },
    maskInput: {
        border: 'none',
        color: theme.palette.secondary[600],
        fontSize: '16px',
        height: '31px',
        outline: 'none',
        padding: '4px',
    },
    required: {
        '&:hover': {
            background: theme.palette.primary[50],
        },
        background: theme.palette.primary[30],
    },
    timeInput: {
        '& input': {
            textAlign: 'center',
        },
        width: '90px',
    },

}));

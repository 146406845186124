import useAvailableHeight from 'hooks/useAvailableHeight';
import useClasses from 'hooks/useClasses';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageWithFilter } from 'Layout';
import { FacetDTO } from 'shared-components/dataTypes';
import { lyingTimeSwitcherOptions } from 'TrackAndTrace/Assets/components/AssetFilters/AssetFilterPanel';
import useGetCommonData from 'hooks/useGetCommonData';
import { AssetsDTO } from 'dataTypes/SecureBackend/apiResponse';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Table from 'shared-components/Table';
import AddAssets from 'Administration/Assets/components/AddAssets';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import StandalonePagination from 'shared-components/StandalonePagination';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import useDownloadExcel from 'hooks/useDownloadExcel';
import useFilter, { PAGES } from 'Contexts/useFilter';
import { filterFullWidth } from 'shared-components/SideFilter/SideFilter.style';
import useStatusState from 'hooks/useStatusState';
import { facetsConvert } from 'utils/stringTool';
import { Geofence } from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import { styles } from './Assets.style';
import { extractAssetData, ExtractedAssetData, initialAssetFilterOptions } from './lib';

import AssetFilterPanel from './components/AssetFilters';

import tableColumns from './tableColumns';

const PAGE_SIZE_TABLE = 40;

const FILTERS_WITH_NULL_CHECK = ['iataCodes', 'areas'];
const FILTERS_WITH_NULL_NO_CHECK = ['countries', 'cities'];
const FILTERS_WITH_NULL = [...FILTERS_WITH_NULL_CHECK, ...FILTERS_WITH_NULL_NO_CHECK];
const Assets = () => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const { getFilter, setFilter } = useFilter(PAGES.ASSETS);
    const filter = getFilter();
    const [page, setPage] = useState(filter?.page || 1);
    const [totalElements, setTotalElements] = useState(filter?.totalElements || 0);
    const [query, setQuery] = useState(filter?.query || '');
    const [areaLyingSince, setAreaLyingSinceTimestamp] = useState<number | null>(
        filter?.areaLyingSince || null);
    const [locationLyingSince, setLocationLyingSinceTimestamp] = useState<number | null>(
        filter?.locationLyingSince || null);
    const [country, setCountry] = useState(filter?.country || '');
    const [city, setCity] = useState(filter?.city || '');
    const [assets, setAssets] = useState<ExtractedAssetData[]>([]);
    const [areaLyingTime, setAreaLyingTime] = useState(filter?.areaLyingTime || 'All');
    const [locationLyingTime, setLocationLyingTime] = useState(filter?.locationLyingTime || 'All');
    const [showAirportsInfo, setShowAirportsInfo] = useState(filter?.showAirportsInfo || false);
    const [showGateways, setShowGateways] = useState(filter?.showGateways || false);
    const columns = useMemo(() => tableColumns(t), [t]);
    const [activeLyingTimeSwitchState, setActiveLyingTimeSwitchState] = useState<string>(
        filter?.activeLyingTime || lyingTimeSwitcherOptions[0]);
    const [shouldUpdate, setShouldUpdate] = useState(Date.now());

    const update = useCallback(() => {
        setShouldUpdate(Date.now());
    }, []);

    const availableHeight = useAvailableHeight();

    const {
        flushStatusState,
    } = useStatusState();
    const {
        data: rawInitialFacets,
    } = useGetCommonData<FacetDTO[]>('admin/assets/search/facets',
        {
            cache: true,
            mutateParam: shouldUpdate,
            postProcess: (data: FacetDTO[]) => facetsConvert(data, FILTERS_WITH_NULL),
            query: {
                initial: true,
            },
        });

    const [sort, setSort] = useState<CustomSort>(filter?.sort || null);

    const PAGE_SIZE = PAGE_SIZE_TABLE;
    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);
    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(filter?.filterOptions || initialAssetFilterOptions);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>('admin/assets/search/facets',
        {
            cache: true,
            mutateParam: shouldUpdate,
            postProcess: (data: FacetDTO[]) => facetsConvert(data, FILTERS_WITH_NULL),
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
                ...(areaLyingSince !== null ? { areaLyingSince } : {}),
                ...(locationLyingSince !== null ? { locationLyingSince } : {}),
            },
            queryWrap: false,
        });

    useEffect(() => {
        if (Object.values(filterOptions).some(options => options.length !== 0)) return;

        if (rawInitialFacets) {
            setFilterOptions(initialAssetFilterOptions);
        }
    }, [rawInitialFacets]);

    const {
        data: rawAssets, status: assetsStatus,
    } = useGetCommonData<AssetsDTO>('admin/assets/search', {
        cache: true,
        enabled: facetsStatus === 'SUCCESS',
        mutateParam: shouldUpdate,
        query: {
            page: page - 1,
            pageSize: PAGE_SIZE,
            ...(trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets)),
            ...(areaLyingSince !== null ? { areaLyingSince } : {}),
            ...(locationLyingSince !== null ? { locationLyingSince } : {}),
        },
        queryWrap: false,
        refetchOnMount: true,
    });

    const handleAreaLyingTimeChange = useCallback((days) => {
        if (days === null) {
            setAreaLyingSinceTimestamp(null);
        } else {
            setAreaLyingSinceTimestamp(days);
        }
    }, [setAreaLyingSinceTimestamp]);

    const handleLocationLyingTimeChange = useCallback((days) => {
        if (days === null) {
            setLocationLyingSinceTimestamp(null);
        } else {
            setLocationLyingSinceTimestamp(days);
        }
    }, [setLocationLyingSinceTimestamp]);

    const { downloadButtonLoading, downloadExcelHandler } = useDownloadExcel(
        trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets), true);

    const {
        data: geofences,
    } = useGetCommonData<Geofence[]>('admin/geofences', { query: {} });

    useEffect(() => {
        if (assetsStatus === 'SUCCESS' && rawAssets?.resultList) {
            const allAssets = extractAssetData(rawAssets?.resultList);

            setTotalElements(rawAssets.totalElements);
            setAssets(allAssets);
        }
    }, [assetsStatus, rawAssets]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE > totalElements) setPage(Math.max(Math.ceil(totalElements / PAGE_SIZE), 1));
    }, [page, totalElements]);

    useEffect(() => {
        setFilter({
            activeLyingTime: activeLyingTimeSwitchState,
            areaLyingSince,
            areaLyingTime,
            city,
            country,
            filterOptions,
            locationLyingSince,
            locationLyingTime,
            page,
            query,
            showAirportsInfo,
            sort,
            totalElements,
        });
    }, [
        page,
        totalElements,
        query,
        filterOptions,
        sort,
        country,
        city,
        showAirportsInfo,
        areaLyingTime,
        locationLyingTime,
        areaLyingSince,
        locationLyingSince,
        activeLyingTimeSwitchState,
    ]);

    useEffect(() => {
        return () => flushStatusState();
    }, []);

    return (
        <DataPaginationContext.Provider value={{
            page,
            paginationLoading: assetsStatus === 'PENDING',
            perPage: PAGE_SIZE,
            setPage,
            totalElements,
        }}
        >
            <PageWithFilter>
                <AssetFilterPanel
                    activeLyingTime={activeLyingTimeSwitchState}
                    areaLyingTime={areaLyingTime}
                    countsLoading={facetsStatus === 'PENDING'}
                    facets={rawFacets}
                    filterOptions={filterOptions}
                    geofences={geofences}
                    initialFacets={rawInitialFacets}
                    isTableView
                    locationLyingTime={locationLyingTime}
                    query={query}
                    setActiveLyingTime={setActiveLyingTimeSwitchState}
                    setAreaLyingTime={setAreaLyingTime}
                    setCity={setCity}
                    setCountry={setCountry}
                    setFilterOptions={setFilterOptions}
                    setLocationLyingTime={setLocationLyingTime}
                    setQuery={setQuery}
                    setShowAirportsInfo={setShowAirportsInfo}
                    setShowGateways={setShowGateways}
                    showAirports={showAirportsInfo}
                    showGateways={showGateways}
                    onAreaLyingTimeChange={handleAreaLyingTimeChange}
                    onLocationLyingTimeChange={handleLocationLyingTimeChange}
                />
                <div style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    maxHeight: availableHeight,
                    width: `calc(100% - ${filterFullWidth}px)`,
                }}
                >
                    <AddAssets shouldUpdate={update} />
                    <Table
                        classNames={{
                            tableContainerClassName: classes.filteredTable,
                        }}
                        columns={columns}
                        currentSort={sort}
                        data={assets}
                        downloadButtonLoading={downloadButtonLoading}
                        maskForHighlight={query}
                        rowLinkTemplate="/administration/assets/location/:assetNumber"
                        tableId="assetsTable"
                        tableMaxHeight="100%"
                        title={t('MENU_ITEMS.TRACK_AND_TRACE_ASSETS')}
                        onDownloadExcel={downloadExcelHandler}
                        onSort={updateSort}
                    />
                    <StandalonePagination detached entity="Assets" />
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default Assets;

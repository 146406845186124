import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import { TIME_IN_MS } from 'shared-components/constants';
import { DailyForecastData, PeriodicForecastData } from 'dataTypes/openWeatherMap';

export interface DailyForecastInfo {
    day: string,
    icon: string,
    tempMax: number,
    tempMin: number
}

export interface PeriodicForecastInfo {
    x: number,
    y: number
}

export const getDailyForecastDataLink = (apiKey: string, lat: number, lng: number, dayNumber = 8) => {
    return `https://api.openweathermap.org/data/2.5/forecast/daily?lat=${
        lat
    }&lon=${lng}&cnt=${dayNumber}&units=metric&appid=${apiKey}`;
};

export const get3HourForecastData = (apiKey: string, lat: number, lng: number, periodCount = 8) => {
    return `https://api.openweathermap.org/data/2.5/forecast?lat=${
        lat
    }&lon=${lng}&cnt=${periodCount}&units=metric&appid=${apiKey}`;
};

export const getProcessedDailyForecastData = (rawData: DailyForecastData): DailyForecastInfo[] => {
    const { list = [] } = rawData;

    return list.map(forecast => {
        const { dt: timeInSec, temp, weather = [] } = forecast;
        const { max, min } = temp;
        const [weatherFirstItem] = weather;
        const { icon } = weatherFirstItem;

        return {
            day: moment(timeInSec * TIME_IN_MS.second).format('ddd'),
            icon: `https://openweathermap.org/img/wn/${icon}@2x.png`,
            tempMax: Math.round(max),
            tempMin: Math.round(min),
        };
    });
};

export const getProcessedPeriodicForecastData = (rawData: PeriodicForecastData): PeriodicForecastInfo[] => {
    const { city, list = [] } = rawData;
    const { timezone: timezoneInSec } = city;
    // const clientOffset = new Date().getTimezoneOffset() * TIME_IN_MS.minute;

    return list.map(forecast => {
        const { dt: timeInSec, main } = forecast;
        const { temp } = main;

        return {
            // x: (timeInSec + timezoneInSec) * TIME_IN_MS.second + clientOffset,
            x: (timeInSec + timezoneInSec) * TIME_IN_MS.second,
            y: Math.round(temp),
        };
    });
};

export const getApexOptions = (data: PeriodicForecastInfo[] = []): ApexOptions => {
    const temps = data.map(item => item.y);
    // const timestamps = data.map(item => item.x);
    const max = Math.max(...temps);
    const min = Math.min(...temps);
    // const [firstItem = null] = data;
    // const { x: minXTick = 0 } = firstItem || {};

    return {
        chart: {
            animations: {
                enabled: false,
            },
            height: 150,

            toolbar: {
                show: false,
            },
            type: 'area',
        },
        colors: ['#FFCC00'],
        dataLabels: {
            background: {
                dropShadow: {
                    enabled: false,
                },
                enabled: false,
            },
            dropShadow: {
                enabled: false,
            },
            enabled: true,
            offsetY: -5,
            style: {
                colors: ['#B4B4B4'],
                fontSize: '10px',
            },
        },
        fill: {
            opacity: 0.5,
        },
        grid: {
            padding: {
                left: 5,
                right: 5,
            },
            show: false,
            yaxis: {
                lines: {
                },
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        tooltip: {
            fixed: {
                enabled: false,
                position: 'topRight',
            },
            x: {
                format: 'HH:mm',
                show: false,
            },
            y: {
                formatter(value: number) {
                    return `${value}°C`;
                },
            },
        },
        xaxis: {
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                offsetY: -5,
                style: {
                    colors: '#70757A',
                    fontSize: '10px',
                },
            },
            type: 'datetime',
        },
        yaxis: {
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            floating: false,
            max: max === min ? max + 1 : max,
            min: min === max ? min - 1 : min,
            show: false,
        },
    };
};

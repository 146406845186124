import { wrapStyles } from 'hooks/useClasses/useClasses';
import { sidebarClosedWidth, sidebarWidth } from 'theme/shared/variables';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = wrapStyles((theme: SkycellThemeInterface) => ({
    a: {
        textDecoration: 'none',
    },
    boxStyle: {
        backgroundColor: '#009CCF !important',
        overflow: 'visible',
        width: `${sidebarClosedWidth}px`,
    },
    boxStyleOpened: {
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.primary[600],
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary[200],
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.primary[600],
            borderRadius: '10px',
        },
        backgroundColor: '#009CCF !important',
        width: `${sidebarWidth}px`,
        zIndex: '30',
    },
    buttons: {
        '&:hover': {
            background: `${theme.palette.primary.darkBlue} 0% 0% no-repeat padding-box !important`,
            height: '42px',
            opacity: 1,
            padding: '8px',
            width: '100%',
        },
    },
    icon: {
        height: '26px',
        width: '31px',
    },
    itemText: {
        color: theme.palette.common.menuTextWhite,
        cursor: 'pointer',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        marginLeft: '15px',
        opacity: 1,
        textAlign: 'left',
        textTransform: 'uppercase',
    },

    list: {
        padding: '15px 0px 0px 0px !important',
    },
}));

export default styles;

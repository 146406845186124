import { useTheme } from '@emotion/react';
import InfoIcon from '@mui/icons-material/Info';
import { FLOAT_PANE, OverlayViewF } from '@react-google-maps/api';
import { LatLng } from 'dataTypes/common';
import useClasses from 'hooks/useClasses';
import { wrapStyles } from 'hooks/useClasses/useClasses';
import React, { MouseEventHandler } from 'react';
import AirportTooltipItem from 'TrackAndTrace/Assets/components/AirportTooltipItem';
import { MapElement } from 'TrackAndTrace/Assets/lib';

type Props = {
    mapElements: MapElement[],
    onMouseLeave: MouseEventHandler<HTMLDivElement>,
    position: LatLng,
}

const styles = wrapStyles((theme) => ({
    airportCount: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        color: 'black',
        cursor: 'drag',
        display: 'flex',
        fontSize: '26px',
        gap: '10px',
        height: '50px',
        justifyContent: 'space-between',
        padding: '4px 8px',
        userSelect: 'none',
        width: '100%',
    },
    body: {},
    division: {
        borderTop: '1px solid #dbdbdb',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        justifyContent: 'center',
        padding: '14px 14px 30px 14px',
        width: '100%',
    },
    headerText: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: '32px',
        fontWeight: 'normal',
        letterSpacing: '0.01em',
        minWidth: '100%',
        padding: '8px 30px',
        textAlign: 'left',
        textDecoration: 'underline',
        width: 'max-content',
    },
    info: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        color: 'black',
        display: 'flex',
        fontSize: '14px',
        gap: '10px',
        height: '66px',
        overflowX: 'auto',
        overflowY: 'hidden',
        padding: '4px 8px',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        width: '100%',
        zIndex: 1000,
    },
    infoIcon: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '20px',
    },
    loggerWrapper: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '100%',
        position: 'relative',
        width: 'max-content',
        zIndex: 100,
    },
    root: {
        cursor: 'drag!important',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        minWidth: '100%',
        paddingLeft: '40px',
        width: 'max-content',
        zIndex: 10000,
    },
}));

const AreaTooltip = ({
    mapElements = [],
    onMouseLeave,
    position,
}: Props) => {
    const classes = useClasses(styles);
    const theme = useTheme();

    return (
        <OverlayViewF
            getPixelPositionOffset={() => ({ x: -17.5, y: -17.5 })}
            mapPaneName={FLOAT_PANE}
            position={position}
            zIndex={100}
        >
            <div
                className={classes.root}
                style={{
                    transform: 'translateY(-74px)',
                }}
                onMouseLeave={onMouseLeave}
            >
                <div
                    className={classes.info}
                >
                    <div className={classes.infoIcon}>
                        <InfoIcon style={{
                            color: theme.palette.primary.deepBlue,
                            fontSize: '30px',
                        }}
                        />
                    </div>
                    <div style={{
                        whiteSpace: 'wrap',
                    }}
                    >
                        Showing the number of
                        <br />
                        assets per location.
                        <br />
                        View full list in table view.
                    </div>
                </div>

                {
                    mapElements.map(({ code, count, iataCode, locationName }) => (
                        <React.Fragment key={`assets_map_airport_${code}`}>
                            <AirportTooltipItem
                                classes={classes}
                                code={code}
                                count={count}
                                iataCode={iataCode}
                                locationName={locationName}
                            />
                        </React.Fragment>
                    ))
                }

            </div>
        </OverlayViewF>

    );
};

export default AreaTooltip;

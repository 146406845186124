import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import useGetEnums from 'hooks/useGetEnums';

import { Company } from 'dataTypes/SecureBackend/apiResponse';
import { EMAIL_PATTERN } from 'shared-components/constants';
import PhoneNumberTextField from 'shared-components/PhoneNumberTextField';
import CompanySelector from 'shared-components/CompanySelector';

import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import CommonTooltip from 'shared-components/CommonTooltip/CommonTooltip';
import styles from './UserInfoCard.style';

type Props = {
    admin?: boolean,
    currentBusinessPhone?: string,
    currentCompany?: Company,
    currentEmail?: string,
    currentFirstName?: string,
    currentJobCategory?: string,
    currentJobTitle?: string,
    currentLastName?: string,
    currentMobilePhone?: string,
    setCompanyId: (companyId: number) => void,
    setCompanyRoles: (roles: string[]) => void,
    setUserInfo: (filter) => void,
    type?: 'new' | 'editable' | 'readOnly' | 'newMyCompany'
}

const EditMode = ({
    admin = false,
    currentBusinessPhone = '',
    currentCompany = null,
    currentEmail = '',
    currentFirstName = '',
    currentJobCategory = '',
    currentJobTitle = '',
    currentLastName = '',
    currentMobilePhone = '',
    setCompanyId = () => {},
    setCompanyRoles = () => {},
    setUserInfo,
    type = 'editable',
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const { FlexibleRequest: isNameUnique } = useSkymindBackendEndpoints(`${admin ? 'admin/' : ''}users`).requests;
    // const enumsJobCategory = useGetEnums('JobCategory', 'codeLabelList', 'MY_COMPANY.:enum');
    const jobCategoryLabels = useGetTranslationGroup('JOB_CATEGORY');
    const enumsJobCategory = useGetEnums('JobCategory');

    const [firstName, setFirstName] = useState(currentFirstName);
    const [lastName, setLastName] = useState(currentLastName);
    const [email, setEmail] = useState(currentEmail);
    const [mobilePhone, setMobilePhone] = useState(currentMobilePhone);
    const [businessPhone, setBusinessPhone] = useState(currentBusinessPhone);
    const [company, setCompany] = useState<Company>(currentCompany);
    const [jobCategory, setJobCategory] = useState(currentJobCategory);
    const [jobTitle, setJobTitle] = useState(currentJobTitle);

    const [isUniqueEmail, setIsUniqueEmail] = useState(true);
    const [timerId, setTimerId] = useState(null);

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleContactEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleMobilePhoneNumber = (value) => {
        setMobilePhone(value);
    };

    const handleBusinessPhoneNumber = (value) => {
        setBusinessPhone(value);
    };

    const handleJobCategory = (event, selected) => {
        setJobCategory(selected);
    };

    const handleJobTitle = (event) => {
        setJobTitle(event.target.value);
    };

    useEffect(() => {
        if (company) {
            setCompanyId(company.id);
            setCompanyRoles(company.companyRoles);
        }
    }, [company]);

    const jobCategoryValue = useMemo(() => {
        if (!jobCategory || enumsJobCategory.length === 0) {
            return null;
        }

        return enumsJobCategory.find(item => item === jobCategory);
    }, [enumsJobCategory, jobCategory]);

    useEffect(() => {
        if (email && EMAIL_PATTERN.test(email) && email !== currentEmail) {
            (async () => {
                try {
                    clearTimeout(timerId);
                    const timerid = setTimeout(async () => {
                        const url = `/email-is-free?email=${email}`;
                        const { value = false } = (await isNameUnique('GET', url) || {})?.data || {};

                        setIsUniqueEmail(value);
                    }, 1500);

                    setTimerId(timerid);
                } catch (error) {
                    global.console.log(error);
                }
            })();
        } else if (!email) {
            setIsUniqueEmail(true);
        }
    }, [email]);

    useEffect(() => {
        setUserInfo(previous => {
            return {
                ...previous,
                businessPhone: businessPhone ? businessPhone.replace(/\(|\)| /g, '') : '',
                email: email === currentEmail || (email && EMAIL_PATTERN.test(email) && isUniqueEmail)
                    ? email
                    : '',
                firstName,
                jobCategory,
                jobTitle,
                lastName,
                mobilePhone: mobilePhone ? mobilePhone.replace(/\(|\)| /g, '') : '',
            };
        });
    }, [
        firstName,
        lastName,
        mobilePhone,
        businessPhone,
        jobCategory,
        jobTitle,
        email,
        isUniqueEmail,
    ]);

    return (
        <div className={classes.cardContentData}>
            <div>
                <div className={classes.fieldsRow}>
                    <div>
                        <div className={classes.fieldTitle}>{t('MY_COMPANY.FIRST_NAME')}</div>
                        <TextField
                            className={classes.mandatory}
                            fullWidth
                            inputProps={{
                                className: classes.textFieldText,
                            }}
                            size="small"
                            value={firstName}
                            variant="standard"
                            onChange={handleFirstNameChange}
                        />
                    </div>
                    <div>
                        <div className={classes.fieldTitle}>{t('MY_COMPANY.LAST_NAME')}</div>
                        <TextField
                            className={classes.mandatory}
                            fullWidth
                            inputProps={{
                                className: classes.textFieldText,
                            }}
                            size="small"
                            value={lastName}
                            variant="standard"
                            onChange={handleLastNameChange}
                        />
                    </div>
                </div>
                {
                    (type === 'new' || type === 'newMyCompany')
                        ? (
                            <CommonTooltip
                                borderColor="red"
                                description={
                                    isUniqueEmail
                                        ? t('MY_COMPANY.E_MAIL_ADDRESS_DOES_NOT_HAVE_A_VALID_FORMAT')
                                        : t('MY_COMPANY.USER_WITH_THIS_E_MAIL_ADDRESS_ALREADY_EXISTS')
                                }
                                open={Boolean(email && !EMAIL_PATTERN.test(email)) || !isUniqueEmail}
                                placement="top-end"
                            >
                                <div>
                                    <div className={classes.fieldTitle}>{t('MY_COMPANY.E_MAIL_ADDRESS')}</div>
                                    <TextField
                                        className={
                                            email && !EMAIL_PATTERN.test(email)
                                                ? classes.error
                                                : classes.mandatory
                                        }
                                        // disabled={type !== 'new'}
                                        fullWidth
                                        inputProps={{ className: classes.textFieldText }}
                                        size="small"
                                        value={email}
                                        variant="standard"
                                        onChange={handleContactEmail}
                                    />
                                </div>
                            </CommonTooltip>
                        )
                        : (
                            <div>
                                <div className={classes.fieldTitle}>{t('MY_COMPANY.E_MAIL_ADDRESS')}</div>
                                <div style={{ paddingTop: '6px' }}>{email}</div>
                            </div>
                        )
                }
                <div>
                    <PhoneNumberTextField
                        className={[classes.textField, classes.textFieldMargin].join(' ')}
                        fieldTitlePropsClassName={classes.fieldTitle}
                        handleContactPhoneNumber={handleMobilePhoneNumber}
                        inputPropsClassName={classes.textFieldText}
                        isFieldTitleShown
                        label={t('MY_COMPANY.MOBILE_PHONE')}
                        phoneNumber={mobilePhone}
                    />
                </div>
                <div>
                    <PhoneNumberTextField
                        className={[classes.textField, classes.textFieldMargin].join(' ')}
                        fieldTitlePropsClassName={classes.fieldTitle}
                        handleContactPhoneNumber={handleBusinessPhoneNumber}
                        inputPropsClassName={classes.textFieldText}
                        isFieldTitleShown
                        label={t('MY_COMPANY.BUSINESS_PHONE')}
                        phoneNumber={businessPhone}
                    />
                </div>
            </div>
            <div>
                <div className={classes.fieldTitle}>{t('COMMON.COMPANY')}</div>
                {
                    type === 'new' && admin
                        ? (
                            <CompanySelector
                                className={classes.mandatory}
                                company={company}
                                disabled={type !== 'new'}
                                setCompany={setCompany}
                            />
                        )
                        : (
                            company !== null && <div style={{ paddingTop: '5px' }}>{currentCompany.name}</div>
                        )
                }
                {/* <div className={classes.fieldTitle}>{t('MY_COMPANY.JOB_CATEGORY')}</div> */}
                <Autocomplete
                    classes={{
                        inputRoot: classes.textFieldTextAutocomplete,
                        listbox: classes.listBox,
                    }}
                    filterSelectedOptions
                    getOptionLabel={(option) => jobCategoryLabels[option]}
                    id="jobTitle"
                    isOptionEqualToValue={(option, value) => jobCategoryLabels[option] === jobCategoryLabels[value]}
                    options={enumsJobCategory}
                    popupIcon={null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={classes.textField}
                            fullWidth
                            label={t('MY_COMPANY.JOB_CATEGORY')}
                            size="small"
                            variant="standard"
                        />
                    )}
                    size="small"
                    value={jobCategoryValue}
                    onChange={handleJobCategory}
                    // className={`${classes.mandatory}`}
                    // value={jobCategoryObj}
                />
                <TextField
                    className={classes.textField}
                    inputProps={{
                        className: classes.textFieldText,
                    }}
                    label={t('MY_COMPANY.JOB_TITLE')}
                    size="small"
                    value={jobTitle}
                    variant="standard"
                    onChange={handleJobTitle}
                />
            </div>
        </div>
    );
};

export default EditMode;

import React, { useMemo, useRef, useState, ReactElement } from 'react';
import { Div, Empty } from 'utils/screenWrap';
import useClasses from 'hooks/useClasses';
import styles from './SkyTooltip.style';

type Props = {
    align?: 'top' | 'bottom' | 'center',
    children: ReactElement[],
    description?: string,
    hideLabel?: boolean,
    label?: string,
    modalMode?: boolean,
    onCloseAction?: (bool:boolean) => void,
    open?: boolean | null,
    style?: object
}

const TooltipParams = {
    margin: 10,
};
const SkyTooltip = ({
    align = null,
    children,
    description = '',
    hideLabel = false,
    label = '',
    modalMode = false,
    onCloseAction = () => {
    },
    open = null,
    style = {},
}: Props) => {
    const classes = useClasses(styles);
    const toolTipRef = useRef(null);
    const toolTipBodyRef = useRef(null);

    const [translateValue, setTranslateValue] = useState<number>(0);
    const [toggleState, setToggleState] = useState<boolean>(false);

    const ScreenWrap = useMemo(() => (align === 'center' ? Div : Empty), [align]);

    const toggleToolTip = (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
        setTranslateValue(() => {
            const [top, bottom, center] = [
                (toolTipRef.current?.getBoundingClientRect()?.top
                    - toolTipBodyRef.current?.scrollHeight - TooltipParams?.margin),
                (toolTipRef.current?.getBoundingClientRect()?.top
                    + toolTipRef.current?.scrollHeight + TooltipParams?.margin),
                'unset',
            ];

            switch (align) {
            case 'bottom':
                return bottom;
            case 'top':
                return top;
            case 'center':
                return center;
            default:
                if ('clientY' in event) {
                    return event.clientY < toolTipBodyRef.current?.scrollHeight + TooltipParams.margin * 2
                        ? bottom : top;
                }
                return top;
            }
        });
        setToggleState(prevState => !prevState);
    };

    const openState: boolean = useMemo(() => open ?? toggleState, [toggleState, open]);
    const setOpenState: (bool: boolean) => void = useMemo(() => {
        return open === null ? setToggleState : onCloseAction;
    }, [open]);

    return (
        <>
            <div className={classes.wrapper} style={style}>
                {
                    !modalMode && (
                        <div
                            ref={toolTipRef}
                            className={[
                                classes.skyTooltip,
                                (!hideLabel || openState) ? '' : classes.hideLabel,
                            ].join(' ')}
                            onClick={toggleToolTip}
                            onKeyPress={toggleToolTip}
                        >
                            <span
                                className={classes.tooltipIcon}
                            >
                                ?
                            </span>
                            <span>
                                {label}
                            </span>
                        </div>
                    )
                }
                <ScreenWrap className={classes.centered}>
                    <div
                        ref={toolTipBodyRef}
                        className={[
                            classes.body,
                            `${modalMode ? classes.modalBody : classes.tooltipBody}`,
                            `${openState ? classes.toggled : ''}`,

                        ].join(' ')}
                        style={{ top: translateValue }}
                        onClick={() => (modalMode ? {} : setOpenState(false))}
                        onKeyPress={() => (modalMode ? {} : setOpenState(false))}
                    >

                        <div
                            className={classes.tooltipTop}
                        >
                            {description || label}
                        </div>
                        <div
                            className={classes.contents}
                        >
                            {children}
                        </div>
                    </div>

                </ScreenWrap>
                <div
                    className={`${classes.tooltipBackground} ${openState ? classes.tooltipBackgroundOn : ''}`}
                    onClick={() => setOpenState(false)}
                    onKeyPress={() => setOpenState(false)}
                />

            </div>

        </>
    );
};

export default SkyTooltip;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    anim_loading: {
        '0%': {
            backgroundPositionX: '-1000px',
        },
        '100%': {
            backgroundPositionX: '100px',
        },
    },

    button: {
        padding: '12px 40px',
        textTransform: 'capitalize',
    },
    caret: {
        color: theme.palette.primary[700],
        fontSize: '30px',
        marginRight: '5px',
    },

    cell: {
        '& .MuiFormControl-root': {
            width: '100%',
        },
        padding: '5px 5px 5px 0px!important',
    },

    filledSelect: {
        background: theme.palette.primary[50],
    },

    hcell: {
        '& div': {
            paddingLeft: '0!important',
        },
        '&::after': {
            display: 'none',
        },
        color: `${theme.palette.common.black} !important`,
    },

    icon: {
        '&:hover': {
            color: theme.palette.secondary[800],
        },
        color: theme.palette.secondary[600],
    },
    loadingSkeleton: ({ classes }) => ({
        animation: `${classes.anim_loading} 10s ease infinite alternate`,
        background: `linear-gradient(90deg, ${theme.palette.secondary[200]} 0%, ${theme.palette.secondary[400]} 25%,\
        ${theme.palette.secondary[200]} 50%, ${theme.palette.secondary[400]} 75%,\
        ${theme.palette.secondary[200]} 100%)`,
        borderRadius: '4px',
        height: '35px',
        width: '100%',
    }),

    noLocations: {
        alignItems: 'center',
        color: theme.palette.secondary[600],
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'center',
    },

    table: {
        margin: '10px',
    },
}));

export default styles;

import moment from 'moment';
import { Logger } from 'dataTypes/SecureBackend/apiResponse';
import icons from 'shared-components/icons';
import { CheckboxOption } from 'shared-components/dataTypes';

export interface ExtractedLoggerData {
    batteryLevel?: number,
    batteryLevelThreshold?: number,
    lastMeasuredData?: {
        [key: string]: string;
    }
    lastMeasuredLatitude: number,
    lastMeasuredLongitude: number,
    lastMeasuredTemp?: number,
    lastMeasuredTempStr?: string,
    lastMeasuredTimestamp?: number,
    lastMeasuredTimestampUnit?: {
        unit: string,
        value: number
    },
    loggerFamily: string,
    loggerFamilyLabel?: string,
    loggerNumber?: string,
    pairingStatus?: string,
}

export interface LoggerFilter { [optionsGroupKey: string]: string[] }

export const extractLoggerData = (rawData: Logger[], loggerFamilyLabels = {}, pairingStatusLabels = {})
    : ExtractedLoggerData[] => {
    return rawData
        .map((item) => {
            const {
                lastMeasuredData = null,
                loggerNumber = '',
                loggerType = null,
                pairingStatus,
            } = item;
            const loggerFamily = loggerType || '';

            const {
                batteryLevel,
                geolocation,
                temperature = null,
                temperatureGeolocationTimestamp,
                temperatureGeolocationTimestampUnit,
            } = lastMeasuredData || {};

            const {
                latitude = null,
                longitude = null,
            } = geolocation || {};

            const lastMeasuredTempStr = temperature !== null
                ? `${temperature.toFixed(1)}°C` : 'n/a';

            return {
                batteryLevel,
                lastMeasuredLatitude: latitude ? (Math.trunc(latitude * 10000) / 10000) : null,
                lastMeasuredLongitude: longitude ? (Math.trunc(longitude * 10000) / 10000) : null,
                lastMeasuredTemp: temperature,
                lastMeasuredTempStr,
                lastMeasuredTimestamp: temperatureGeolocationTimestamp
                    ? moment(temperatureGeolocationTimestamp).utc(true).valueOf()
                    : null,
                lastMeasuredTimestampUnit: temperatureGeolocationTimestampUnit,
                loggerFamily,
                loggerFamilyLabel: loggerFamilyLabels[loggerFamily] || loggerFamily,
                loggerNumber,
                pairingStatus: pairingStatusLabels[pairingStatus] || pairingStatus,
            };
        });
};

export interface ActiveLogger {
    loggerNumber: string,
    timestamp: number,
}

export const crossMarkerIconObj = {
    anchor: { x: 10, y: 10 },
    origin: { x: 0, y: 0 },
    scaledSize: { height: 20, width: 20 },
    url: icons.hex_with_cross,
};

export const getMapDataItem = (logger: ExtractedLoggerData) => {
    return {
        geometry: {
            coordinates: [
                logger.lastMeasuredLongitude,
                logger.lastMeasuredLatitude,
            ],
            type: 'Point',
        },
        properties: {
            data: logger,
            marker: true,
        },
        type: 'Feature',
    };
};

export const getSuperclusterOptions = (mapData, bounds, zoom) => {
    return {
        bounds,
        options: {
            generateId: true,
            map: (item) => {
                return {
                    lastMeasuredTimestamp: item.data.lastMeasuredTimestamp,
                    loggerFamilyLabel: item.data.loggerFamilyLabel,
                };
            },
            maxZoom: 5,
            radius: 75,
            reduce: (data, props) => {
                const { lastMeasuredTimes = [], loggerTypes = [] } = data;

                if (!loggerTypes.includes(props.loggerFamilyLabel)) {
                    data.loggerTypes = [...loggerTypes, props.loggerFamilyLabel];
                }

                if (!lastMeasuredTimes.includes(props.lastMeasuredTimestamp)) {
                    data.lastMeasuredTimes = [...lastMeasuredTimes, props.lastMeasuredTimestamp];
                }
                return data;
            },
        },
        points: mapData,
        zoom: zoom || 10,
    };
};

export const getExistedLoggerFamilies = (loggers: ExtractedLoggerData[]): string[] => {
    return loggers.reduce((data, { loggerFamilyLabel }) => {
        return data.includes(loggerFamilyLabel)
            ? data
            : [...data, loggerFamilyLabel];
    }, []);
};

export const getLoggersFamilyCheckboxOption = (loggers: ExtractedLoggerData[]): CheckboxOption[] => {
    const existedLoggerFamilies = getExistedLoggerFamilies(loggers);

    return existedLoggerFamilies.map((currentLoggerFamily) => {
        const count = loggers
            .filter(({ loggerFamilyLabel }) => loggerFamilyLabel === currentLoggerFamily).length;

        return {
            count,
            label: currentLoggerFamily,
            value: currentLoggerFamily,
        };
    });
};

export const initialFilterOptions: { [optionsGroupKey: string]: string[] } = {
    loggerFamilyLabel: [],
    pairingStatus: [],
};

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    autocompleteClass: {
        marginTop: '20px',
    },
    listBox: {
        '& .MuiAutocomplete-option.Mui-focused': { // works
            backgroundColor: 'rgba(25, 118, 210, 0.12)!important',
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': { // works
            backgroundColor: '#FFF!important',
        },
        '& .MuiInputBase-root': { // works
            color: 'red!important',
        },
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
    },
    loggerTitle: {
        color: theme.palette.common.black,
        display: 'flex',
        fontSize: '32px !important',
        justifyContent: 'space-between',
        letterSpacing: '0.26px',
        lineHeight: 1,
    },
    mapCard: {
        marginTop: theme.spacing(1.5),
    },
    pageContent: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '8fr minmax(266px, 1fr)',
        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: '8fr minmax(166px, 1fr)',
        },
    },
    table: {
        width: '100%',
    },
    tableContainer: {
        '& > div': {
            marginLeft: 0,
        },
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        padding: '0 20px 12px 20px',
    },
    textFieldTextAutocomplete: {
        '& input': {
            padding: '3px 15px 3px 0!important',
        },
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        padding: '0',
        paddingRight: '10px!important',
        textAlign: 'left',
    },
}));

export default styles;

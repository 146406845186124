import useClasses from 'hooks/useClasses';
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { IconButton, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useScreenSize from 'hooks/useScreenSize';
import { highlight } from 'utils/stringTool';
import styles from './SelectFieldSearch.style';

type Props = {
    className?: string,
    filterName: string,
    font?: 'small'| 'medium',
    label?: string,
    mandatory?: boolean,
    onChange?: (value: string, id?: string) => void,
    readOnly?: boolean,
    requestObject: string,
    value?: number | string
}

const SelectFieldSearch = ({
    className,
    filterName,
    font = null,
    label,
    mandatory = false,
    onChange,
    readOnly = false,
    requestObject,
    value,
}: Props) => {
    const inputRef = useRef(null);
    const { width } = useScreenSize();
    const { GetAll: getData } = useSkymindBackendEndpoints(requestObject).requests;
    const [data, setData] = useState([]);
    const [filter, setSearchString] = useState('');
    const [searchVisible, setSearchVisible] = useState(false);
    const [drawerWidth, setDrawerWidth] = useState(0);
    const [listLoading, setListLoading] = useState(false);

    const classes = useClasses(styles);

    useEffect(() => {
        if (readOnly) return;
        const toggle = () => setSearchVisible(false);

        document.addEventListener('click', toggle);
        return () => {
            document.removeEventListener('click', toggle);
        };
    }, []);

    useEffect(() => {
        setDrawerWidth(inputRef?.current?.scrollWidth || 0);
    }, [width, inputRef]);

    useEffect(() => {
        (async () => {
            try {
                setListLoading(true);
                const { resultList = [] } = (await getData()).data || {};

                setListLoading(false);
                setData(resultList);
            } catch (error) {
                setListLoading(false);
                setData([]);
            }
        })();
    }, [filter]);

    useEffect(() => {
        if (value && data) {
            setSearchString(data?.find(it => it?.id === value)?.[filterName]);
        }
    }, [value, data]);

    const handleTextFieldInput = useCallback((ev) => {
        setSearchString(ev.target.value);
    }, []);

    const itemClicked = useCallback((id, object) => {
        onChange(id, object);
        setSearchVisible(false);
    }, []);

    const clearFilter = useCallback((e) => {
        e.stopPropagation();
        setSearchString('');
        setSearchVisible(false);
        onChange(null);
    }, []);

    return (
        <div className={[classes.searchContainer,
            font === 'small' ? classes.smallFont : '',
            font === 'medium' ? classes.mediumFont : '',
        ].join(' ')}
        >
            <TextField
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    className: classes.input,
                    disableUnderline: readOnly,
                    endAdornment: !readOnly && (
                        <IconButton
                            className={classes.iconButton}
                            onClick={clearFilter}
                        >
                            <Clear
                                className={classes.icon}
                            />
                        </IconButton>
                    ),
                    readOnly: !!value || readOnly,
                    ref: inputRef,
                }}
                className={className}
                label={label}
                placeholder=""
                required={mandatory}
                size="medium"
                style={{
                    pointerEvents: readOnly ? 'none' : 'unset',
                    width: '100%',
                }}
                value={filter}
                variant="standard"
                onClick={(event) => {
                    event.stopPropagation();
                    if (readOnly) {
                        return;
                    }

                    setSearchVisible(true);
                }}
                onInput={handleTextFieldInput}
            />

            {
                !readOnly && (
                    <div
                        className={classes.dummyHolder}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <ul
                            className={[
                                classes.itemsList,
                                listLoading ? classes.listLoading : '',
                                searchVisible ? classes.visible : '',
                            ].join(' ')}
                            style={{
                                width: drawerWidth || 'auto',
                            }}
                        >
                            {
                                data.map((item) => {
                                    return (
                                        <li
                                            key={`${label}_${item.id}`}
                                            className={classes.listItem}
                                        >
                                            <div
                                                onClick={() => itemClicked(item?.id, item)}
                                            >
                                                {highlight(item?.[filterName], filter)}
                                            </div>
                                        </li>
                                    );
                                })
                            }
                            {
                                data.length === 0 && (
                                    <li
                                        key={`${label}_no_results`}
                                        className={[classes.listItem, classes.appearingLi].join(' ')}
                                    >
                                        No Results
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                )
            }
        </div>

    );
};

export default SelectFieldSearch;

import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import IconList from 'shared-components/CompanyInfoComponents/IconList';
import TextArray from 'shared-components/CompanyInfoComponents/TextArray';
import icons from 'shared-components/icons';
import TableCellFacilities from 'shared-components/CompanyInfoComponents/TableCellFacilities';
import { TFunction } from 'i18next';

const facilitiesIconAdapter = {
    ACT_PLUG: icons.act_plug,
    ANIMAL_STORAGE_AREA: icons.animal_storage_area,
    C_AREA: icons.temperature.C,
    CAGE: icons.cage,
    CRT_AREA: icons.temperature.CRT,
    DANGEROUS_GOODS_STORAGE: icons.dangerous_goods,
    F_AREA: icons.temperature.F,
    FREEZER: icons.temperature.DF,
    VAULT: icons.vault,
};

const tableColumns: (t: TFunction) => ColumnsType[] = (t) => [
    {
        accessor: (row) => {
            return <div>{`${row.parentCompanyCompanyName} / ${row.companyName}`}</div>;
        },
        dataType: 'custom',
        Header: t('SERVICE_PROVIDERS.SERVICE_PROVIDER'),
    },
    {
        accessor: (row) => {
            // const certLabels = row.certificates.map((item) => labels[item] || item);

            return <TextArray textItems={row.certificates} />;
        },
        dataType: 'custom',
        Header: t('COMMON.CERTIFICATES'),
    },
    {
        accessor: (row) => {
            return <IconList iconList={row.services} />;
        },
        dataType: 'custom',
        Header: t('COMMON.SERVICES'),
    },
    {
        accessor: (row) => {
            return <IconList iconList={row.commodities} />;
        },
        dataType: 'custom',
        Header: t('COMMON.COMMODITIES'),
    },
    {
        accessor: (row) => {
            const facilities = row.facilities.map((item) => {
                return {
                    amount: item.amount || null,
                    facility: item.facility,
                    iconSrc: facilitiesIconAdapter[item.facility],
                    unit: item.unit === 'SQUARE_METERS' ? 'm\xB2' : item.unit || null,
                };
            });

            return <TableCellFacilities facilities={facilities} />;
        },
        dataType: 'custom',
        Header: t('COMMON.FACILITIES'),
    },
];

export default tableColumns;

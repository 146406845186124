import { CircularProgress, Collapse } from '@mui/material';
import useCustomTranslation from 'hooks/useCustomTranslation';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Classes } from 'jss';
import GatewayStepLocation from 'TrackAndTrace/Assets/components/AssetTooltip/GatewayStep';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import { Asset, GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import { ExtractedAssetData } from 'TrackAndTrace/Assets/lib';
import icons from 'shared-components/icons';

type Props = {
    asset: Asset | ExtractedAssetData,
    classes: Classes,
    getLatestStoredGateway: Function,
    hoveredAssetId: number,
    lastGateways: {
        gateway: GatewayInfoDTO,
        t: string,
    }[],
    sensorDataStatus: string,
    setHoveredAssetId: Function,
};

const TooltipContents = ({
    asset,
    classes,
    getLatestStoredGateway,
    hoveredAssetId,
    lastGateways,
    sensorDataStatus,
    setHoveredAssetId,
}: Props) => {
    const {
        t: trans,
    } = useCustomTranslation();
    const link = useMemo(() => `/asset-management/assets/location/${asset.assetNumber}`, [asset]);
    const {
        assetNumber,
        id,
    } = asset || {};
    const {
        userInfo,
    } = useCurrentUserContext();

    return (
        <div
            key={assetNumber}
            className={classes.loggerWrapper}
        >
            <Link to={link}>
                <div
                    className={classes.headerText}
                    onMouseEnter={() => setHoveredAssetId(id)}
                >
                    {assetNumber}
                </div>
            </Link>
            <Collapse
                in={hoveredAssetId === id}
            >
                { asset?.ownedByCompanyId !== Number(userInfo?.attributes?.companyId)
                    && (
                        <div
                            className={classes.division} style={{
                                columnGap: '10px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                padding: '10px 30px',
                            }}
                        >
                            <img alt="ownedBy" src={icons.asset_sharing} />
                            <div style={
                                {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '4px',
                                }
                            }
                            >
                                <div>{trans('TRACK_AND_TRACE.OWNED_BY')}</div>
                                <div>{asset?.ownedByCompanyName}</div>
                            </div>
                        </div>
                    )}
                <div className={classes.division}>
                    <span style={{
                        fontSize: '14px',
                        paddingBottom: '30px',
                    }}
                    >
                        <span>
                            {trans('TRACK_AND_TRACE.RECENT_LOCATIONS')}
                            <br />
                            {
                                lastGateways.length > 0 && (
                                    <span style={{
                                        fontSize: '12px',
                                    }}
                                    >
                                        (
                                        {trans('COMMON.BASED_ON_LAST_7_DAYS')}
                                        )
                                    </span>
                                )
                            }
                        </span>
                    </span>
                    {
                        !(sensorDataStatus === 'success' && lastGateways.length > 0)
                        && !(sensorDataStatus === 'error'
                            || (sensorDataStatus === 'success' && lastGateways.length === 0))
                        && <CircularProgress size={16} />
                    }
                    {
                        sensorDataStatus === 'success' && lastGateways.length > 0 && lastGateways
                            .map(({
                                gateway,
                                t: time,
                            }, i) => (
                                <GatewayStepLocation
                                    key={`${assetNumber}_${time}_${gateway?.geolocation?.latitude}`}
                                    dash={i !== 0}
                                    gateway={gateway}
                                    loading={false}
                                    time={time}
                                    tooltipType="B"
                                />
                            ))
                    }
                    {
                        (sensorDataStatus === 'error'
                            || (sensorDataStatus === 'success' && lastGateways.length === 0)) && (
                            getLatestStoredGateway(asset)
                                .map(({
                                    gateway,
                                    t: time,
                                }) => (
                                    <GatewayStepLocation
                                        key={`${gateway.iataCode}${gateway.remark}`}
                                        gateway={gateway}
                                        loading={false}
                                        time={time}
                                        tooltipType="B"
                                    />
                                ))
                        )
                    }

                </div>
            </Collapse>
        </div>
    );
};

export default TooltipContents;

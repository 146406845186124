import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';

// import NotificationsCell from './components/NotificationsCell';
import { TFunction } from 'i18next';
import RolesCell from './components/RolesCell';

const tableColumns: (t:TFunction, isAdmin: boolean) => ColumnsType[] = (
    t,
    isAdmin,
) => [
    {
        accessor: ({ fullName }) => `${fullName.firstName } ${ fullName.lastName}`,
        dataType: 'highlightedText',
        Header: t('COMMON.USER'),
        id: 'fullName',
    },
    {
        accessor: 'email',
        dataType: 'highlightedText',
        Header: t('COMMON.E_MAIL'),
    },
    ...(isAdmin ? [{
        accessor: 'companyName',
        dataType: 'text',
        Header: t('COMMON.COMPANY'),
    }] : []),
    {
        accessor: ({ userRoles }) => (<RolesCell role={userRoles.map(it => t(`USER_POLICY.${it}`))} />),
        dataType: 'text',
        disableSortBy: true,
        Header: t('COMMON.ROLES'),
    },
    // {
    //     Header: 'Open Tasks / Notifications',
    //     accessor: (row) => (
    //         <NotificationsCell
    //             notificationCount={row.notificationCount}
    //             openTaskCount={row.openTaskCount}
    //         />
    //     ),
    //     dataType: 'text',
    //     sideFilterKey: 'countryName',
    //     sideFilterType: 'enum',
    // },
];

export default tableColumns;

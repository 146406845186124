import React, {
    ReactElement,
    useContext,
    useEffect, useRef, useMemo, RefObject,
} from 'react';
import { ONBOARDING_TYPE, QuickHelpContext } from '../QuickHelpContext';

export type QuickHelpTooltipInfo = {
    backgroundOpacity?: number,
    childOffsetPercent?: number[],
    childRef?: RefObject<HTMLElement>,
    clone?: ReactElement,
    core?: ONBOARDING_TYPE,
    customBackground?: string,
    customBoundingRect?: DOMRect,
    customRectSize?: number[],
    explicitChildRef?: RefObject<HTMLElement>,
    image?: string,
    isMain?: boolean,
    lastUpdated?: object,
    link?: string,
    offsetPx?: number[],
    order?: number,
    padding?: number,
    position?: 'left' | 'right' | 'auto' | 'weighted' | 'center' | 'top' | 'bottom',
    positionDelta?: [number, number],
    radius?: number | string,
    text?: string,
    title?: string,
    uid?: string,
}
type Props = {
    children?: ReactElement,
    tooltipInfo?: QuickHelpTooltipInfo,
}

const QuickHelpTooltip = ({
    children,
    tooltipInfo,
}: Props) => {
    const { enableTooltips, setTooltipInfos } = useContext(QuickHelpContext);
    const childRef = useRef(null);
    const clone = useMemo(() => {
        return tooltipInfo?.explicitChildRef ? children : React.cloneElement(children, { ref: childRef });
    }, [tooltipInfo?.explicitChildRef, children]);

    useEffect(() => {
        if (tooltipInfo?.uid && (childRef.current || tooltipInfo?.explicitChildRef?.current)) {
            const uniqueId = `${tooltipInfo?.uid}_${tooltipInfo?.text?.replaceAll(' ', '')}`;

            setTooltipInfos(prev => ({
                ...prev,
                [uniqueId]: { ...tooltipInfo, childRef: tooltipInfo?.explicitChildRef || childRef, clone },
            }));
            return () => {
                setTooltipInfos(prev => {
                    const { ...newInfos } = prev;

                    delete newInfos[uniqueId];

                    return newInfos;
                });
            };
        }
    }, [tooltipInfo?.uid, tooltipInfo?.text, tooltipInfo?.explicitChildRef, enableTooltips, childRef]);

    return clone;
};

export default QuickHelpTooltip;

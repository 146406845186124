import React, {
    useState,
    useEffect, useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import { AddServiceProviderSiteRequestBody, Address } from 'dataTypes/SecureBackend/processedData';
import { ContactInfo as ContactInfoType } from 'dataTypes/SecureBackend/apiResponse';

import AddressCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/AddressCard';
import CommonButton from 'shared-components/CommonButton';
// eslint-disable-next-line max-len
import ServiceProviderSiteCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ServiceProviderSiteCard';
// eslint-disable-next-line max-len
import ContactInformationCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard';
import { isReadyServiceProviderSiteBody } from 'Administration/lib';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import useClasses from 'hooks/useClasses';
import styles from '../../ServiceProviderSites.style';

const AddServiceProviderSite = () => {
    const classes = useClasses(styles);
    const navigate = useNavigate();
    const [companyInfo, setCompanyInfo] = useState(null);
    const [address, setAddress] = useState<Address>(null);
    const [contactInfo, setContactInfo] = useState<ContactInfoType>(null);
    const [serviceProviderCompanyId, setServiceProviderCompanyId] = useState<number>(null);
    const [
        addServiceProviderSiteRequestBody,
        setAddServiceProviderSiteRequestBody,
    ] = useState<AddServiceProviderSiteRequestBody>(null);
    const [readyToSubmission, setReadyToSubmission] = useState(false);
    const hasAccess = useHasAccess();
    const isAdmin = useMemo(() => hasAccess(userRoles.SKYCELL_ADMIN), [hasAccess]);
    const [siteName, setSiteName] = useState('');
    const { t } = useCustomTranslation();

    const {
        FlexibleRequest: createSite,
    } = useSkymindBackendEndpoints(
        isAdmin ? 'admin/companies' : 'companies',
    ).statusNotificationRequests;

    const handleClickCreate = async () => {
        try {
            const url = `${serviceProviderCompanyId}/sites`;
            const {
                status = null,
            } = await createSite('POST', url, addServiceProviderSiteRequestBody);

            if (status === 201) {
                navigate('/administration/service-provider-sites');
            }
        } catch (error) {
            global.console.log(error);
        }
    };

    const handleClickCancel = () => {
        navigate('/administration/service-provider-sites');
    };

    const handleNameChange = (name) => {
        setSiteName(name);
    };

    useEffect(() => {
        if (address && contactInfo && companyInfo) {
            const { name = '', serviceProviderCompanyId = null } = companyInfo;

            setServiceProviderCompanyId(serviceProviderCompanyId);
            setAddServiceProviderSiteRequestBody({
                address,
                contactInfo,
                name,
            });
        } else {
            setAddServiceProviderSiteRequestBody(null);
        }
    }, [
        address,
        contactInfo,
        companyInfo,
    ]);

    useEffect(() => {
        setReadyToSubmission(isReadyServiceProviderSiteBody(addServiceProviderSiteRequestBody));
    }, [addServiceProviderSiteRequestBody, siteName]);

    return (
        <>
            <div className={classes.addCompanyPageContent}>
                <div className={classes.addEditCompanyCardContentData}>
                    <ServiceProviderSiteCard
                        setFilter={setCompanyInfo}
                        type="new"
                        onNameChange={handleNameChange}
                    />
                    <AddressCard
                        setFilter={setAddress}
                        type="new"
                    />
                    <ContactInformationCard
                        setFilter={setContactInfo}
                        type="new"
                    />
                </div>
            </div>
            <div className={classes.addCompanyPageContent}>
                <div className={classes.addEditCompanyButtonContainer}>
                    <CommonButton
                        autoFocus
                        className={classes.addEditCompanyApproveButton}
                        disabled={!readyToSubmission || siteName.trim() === ''}
                        onClick={handleClickCreate}
                    >
                        {t('MY_COMPANY.CREATE')}
                    </CommonButton>
                    <CommonButton
                        className={classes.addEditCompanyRejectButton}
                        variant="outlined"
                        onClick={handleClickCancel}
                    >
                        {t('COMMON.CANCEL')}
                    </CommonButton>
                </div>
            </div>
        </>
    );
};

export default AddServiceProviderSite;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 10px 0 0',
    },
    clearSearchButton: {
        marginRight: '16px',
        textTransform: 'none',
    },
    closed: {
        '& *': {
            pointerEvents: 'none',
        },
    },
    open: {
        '&&': {
            opacity: '1!important',
            pointerEvents: 'unset',
        },
    },

    paddedTable: {
        padding: '0 10px 10px 10px',
    },

    preview: ({ searchBoxLeft }) => ({
        '&&': {
            position: 'absolute',
            top: '79px',
        },
        marginLeft: '270px',
        marginTop: '-40px',
        maxWidth: `calc(100vw - ${searchBoxLeft + 40}px)`,
        opacity: 0,
        pointerEvents: 'none',
        transition: '100ms ease',
        width: 'auto',
        zIndex: 200,
        [`&.${open}`]: {
            pointerEvents: 'unset',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'calc( 100vw - 80px )',
        },
    }),
}));

export default styles;

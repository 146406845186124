import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import { TFunction } from 'i18next';
import { LyingDifference } from 'shared-components/LyingDiff';
import CommonTooltip, { TOOLTIP_TYPE } from 'shared-components/CommonTooltip/CommonTooltip';
import LocationHistoryTimeStep from '../LocationHistoryTimeStep';
import { DotWithDash } from './DotWithDash';

const customHeaderStyle = { paddingLeft: 13 };

const tableColumns: (t: TFunction) => ColumnsType[] = (t) => [
    {
        accessor: (row, i) => (<DotWithDash index={i} row={row} />),
        customHeaderStyle,
        customWidth: '20px',
        dataType: 'custom',
        Header: ' ',
        noBar: true,
    },
    {
        accessor: (row) => (
            <CommonTooltip
                description={row.locationName}
                enabled={row.locationName?.length >= 21}
                placement="right"
                type={TOOLTIP_TYPE.SHRINK}
            >
                <div style={{
                    color: '#000',
                    font: 'normal normal normal 12px/14px Roboto',
                    letterSpacing: '0.43px',
                    maxWidth: '100%',
                    opacity: 1,
                    overflow: 'hidden',
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
                >
                    {row.locationName?.length > 21 ? `${row.locationName.slice(0, 21)}...` : row.locationName}
                </div>
            </CommonTooltip>
        ),
        customHeaderStyle,
        dataType: 'custom',
        Header: t('TRACK_AND_TRACE.LOCATION_NAME'),
        noBar: true,
        sideFilterKey: 'locationName',
        sideFilterType: 'text',
    },
    {
        accessor: (row) => (!row.locationTimeDiffMinutes && row.locationTimeDiffMinutes !== 0 ? ' '
            : <LyingDifference isGrey={row.isHistorical || row.isNotShared} minutes={row.locationTimeDiffMinutes} />),
        customHeaderStyle,
        dataType: 'custom',
        Header: t('TRACK_AND_TRACE.LOCATION_LYING_TIME'),
        id: 'locationTimeDiffMinutes',
        noBar: true,
        sortType: (rowA, rowB) => rowB.original.locationTimeDiffMinutes - rowA.original.locationTimeDiffMinutes,
    },
    {
        accessor: (row) => (
            <CommonTooltip
                description={row.area}
                enabled={row.area?.length >= 21}
                placement="right"
                type={TOOLTIP_TYPE.SHRINK}
            >
                <div style={{
                    color: '#000',
                    font: 'normal normal normal 12px/14px Roboto',
                    letterSpacing: '0.43px',
                    maxWidth: '100%',
                    opacity: 1,
                    overflow: 'hidden',
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
                >
                    {row.area?.length > 21 ? `${row.area.slice(0, 21)}...` : row.area}
                </div>
            </CommonTooltip>
        ),
        customHeaderStyle,
        dataType: 'custom',
        Header: t('TRACK_AND_TRACE.AREA'),
        noBar: true,
        sideFilterKey: 'area',
        sideFilterType: 'text',
    },
    {
        accessor: (row) => (!row.areaTimeDiffMinutes && row.areaTimeDiffMinutes !== 0 ? ' '
            : <LyingDifference isGrey={row.isHistorical || row.isNotShared} minutes={row.areaTimeDiffMinutes} />),
        customHeaderStyle,
        dataType: 'custom',
        Header: t('TRACK_AND_TRACE.AREA_LYING_TIME'),
        id: 'areaTimeDiffMinutes',
        noBar: true,
        sortType: (rowA, rowB) => rowB.original.areaTimeDiffMinutes - rowA.original.areaTimeDiffMinutes,
    },
    {
        accessor: (row) => (!row.timeData?.gateway ? 'N/A'
            : t(`COUNTRY_NAMES.${row.timeData?.gateway?.country?.code}`)),
        customHeaderStyle,
        dataType: 'text',
        Header: t('COMMON.COUNTRY'),
        noBar: true,
        sideFilterKey: 'country',
        sideFilterType: 'text',
    },
    {
        accessor: (row) => (!row.timeData?.gateway ? 'N/A'
            : row.timeData?.gateway?.city),
        customHeaderStyle,
        dataType: 'text',
        Header: t('COMMON.CITY'),
        noBar: true,
        sideFilterKey: 'city',
        sideFilterType: 'text',
    },
    {
        accessor: (row) => (
            !row.timeData
                ? ' '
                : (
                    <LocationHistoryTimeStep
                        localTimezone={row.timeData.localTimezone}
                        time={row.timeData.time}
                    />
                )),
        customHeaderStyle,
        dataType: 'custom',
        Header: `${t('COMMON.ENTRY')} ${t('COMMON.DATE_TIME')}`,
        noBar: true,
        sideFilterKey: 'timeData',
        sideFilterType: 'text',
    },
];

export default tableColumns;

/* eslint-disable react/jsx-key */
import useClasses from 'hooks/useClasses';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@mui/material';
import { AddCircle, DeleteOutline, FileCopyTwoTone, UndoOutlined } from '@mui/icons-material';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import ParsedMessage from 'Notifications/components/ParsedMessage';
import { styles } from 'Notifications/NotificationTemplateTester/NotificationTemplateTester.style';
import { switchCase } from 'utils/stringTool';

const DEFAULT_CONTENT = {
    awb: '999-999999',
    completionDate: '09.03.2023 15:33 +11:00',
    excursion_timestamp: '09.03.2023 15:33 +11:00',
    external_id: 'CT-9999999999',
    first_name: 'Test',
    last_name: 'User',
    packaging_number: '012-99999',
    shipment_id: '#',
    user_id: '5895951',

};
const NotificationTemplateTester = () => {
    const classes = useClasses(styles);
    const [rawMessage, setRawMessage] = useState('');
    const [content, setContent] = useState<{[key: string]: string}>(DEFAULT_CONTENT);
    const examples = useGetTranslationGroup('NOTIFICATION_MESSAGE');
    const [selection, setSelection] = useState({
        end: 0,
        start: 0,
    });
    const [historyTimeout, setHistoryTimeout] = useState(new Date().getTime());
    const [history, setHistory] = useState([rawMessage]);

    useEffect(() => {
        if (new Date().getTime() - historyTimeout > 1000) {
            setHistory(prev => (prev.length <= 50
                ? [...prev, rawMessage]
                : [...prev.slice(0, prev.length - 1), rawMessage]));
            setHistoryTimeout(new Date().getTime());
        }
    }, [rawMessage, historyTimeout]);

    const revert = () => {
        if (history.length < 2) return;
        setRawMessage(history[history.length - 2]);
        setHistory(prev => [...prev.slice(0, prev.length - 2)]);
    };

    const {
        setSuccessStatus,
    } = useStatusStateProcessOptions();

    const updateSelectionStart = (ev) => setSelection({
        end: ev.target.selectionEnd,
        start: ev.target.selectionStart,
    });

    const copyToClipboard = useCallback(async () => {
        await navigator.clipboard.writeText(rawMessage.replaceAll(/\n/g, ' '));

        setSuccessStatus('Copied to clipboard!');
    }, [rawMessage]);

    const addLink = useCallback(() => {
        setRawMessage(prev => {
            const { end, start } = selection;

            return `${prev.substring(0, start)}<link to='/your/link'>${prev?.substring(start, end) || 'Link Text'}\
</link>${prev.substring(end, prev.length)}`;
        });
    }, [selection]);

    const boldSelection = useCallback(() => {
        setRawMessage(prev => {
            const { end, start } = selection;

            return `${prev.substring(0, start)}<b>${prev?.substring(start, end) || 'Bold Text'}</b>\
${prev.substring(end, prev.length)}`;
        });
    }, [selection]);

    const lineBreak = useCallback(() => {
        setRawMessage(prev => {
            const { end, start } = selection;

            return `${prev.substring(0, start)}<br/>${prev.substring(end, prev.length)}`;
        });
    }, [selection]);
    const convertBracketToHashTag = useCallback(() => {
        setRawMessage(prev => prev.replaceAll(/\$\{(.*?)\}/g, '#$1'));
    }, []);

    const message = useMemo(() => Object.keys(content || {}).reduce((acc, key) => {
        return acc.replaceAll(`#${key}`, content[key]).replaceAll(`#${switchCase(key)}`, content[key]);
    }, rawMessage || ''), [content, rawMessage]);

    return (
        <div className={classes.page}>
            <div className={classes.contentEditPane}>
                <h3>Available params:</h3>
                {
                    Object.keys(content).sort().map(key => (
                        <div
                            key={key}
                            className={classes.contentControl}
                        >
                            <TextField
                                key={`${key}_key`}
                                className={classes.key}
                                color="primary"
                                defaultValue={key}
                                label="Key"
                                size="small"
                                variant="outlined"
                                onBlur={(ev) => {
                                    setContent(prev => {
                                        const newKey = ev.target.value;
                                        const oldValue = prev[key];
                                        const oldObj = { ...prev };

                                        delete oldObj[key];
                                        return {
                                            ...oldObj, [newKey]: oldValue,
                                        };
                                    });
                                }}
                            />

                            <div
                                key={`${key}_colon`}
                                className={classes.colon}
                            >
                                :
                            </div>
                            <TextField
                                key={`${key}_value`}
                                className={classes.value}
                                color="primary"
                                defaultValue={content[key]}
                                label="Value"
                                size="small"
                                variant="outlined"
                                onBlur={(ev) => {
                                    setContent(prev => {
                                        const oldObj = { ...prev };

                                        return {
                                            ...oldObj, [key]: ev.target.value,
                                        };
                                    });
                                }}
                            />
                            <DeleteOutline
                                key={`${key}_delete`}
                                className={classes.button}
                                color="primary"
                                onClick={() => {
                                    setContent(prev => {
                                        const oldObj = { ...prev };

                                        delete oldObj[key];
                                        return oldObj;
                                    });
                                }}
                            />
                        </div>
                    ))
                }
                <div className={classes.buttonBox}>
                    <AddCircle
                        className={classes.button}
                        color="primary"
                        onClick={() => {
                            setContent(prev => ({
                                ...prev,
                                [`new_item${Object.keys(prev).filter(it => it.startsWith('new_item')).length + 1}`]: '',
                            }));
                        }}
                    />
                </div>
            </div>
            <Accordion color="primary" variant="outlined">
                <AccordionSummary>Click here for examples:</AccordionSummary>
                <AccordionDetails>
                    <div className={classes.examples}>
                        {
                            Object.keys(examples).filter(it => examples[it]).map(example => (
                                <div
                                    /* onClick={() => {
                                        const hashed = examples[example].match(/#\w+/g) || [];

                                        setContent(prev => hashed.reduce((acc, word) => {
                                            const sliced = word.slice(1);

                                            if (!acc[sliced]) {
                                                acc[sliced] = `Example ${sliced}`;
                                            }
                                            return acc;
                                        }, { ...prev }));
                                        setRawMessage(examples[example]);
                                    }} */
                                    className={classes.example}
                                    onClick={() => setRawMessage(examples[example])}
                                >
                                    {examples[example]}
                                </div>
                            ))
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
            <h2>Your template goes here</h2>
            <div className={classes.actions}>
                <div className={classes.action}>
                    <FileCopyTwoTone
                        className={classes.button}
                        color="primary"
                        onClick={copyToClipboard}
                    />
                </div>
                <div className={classes.action}>
                    <UndoOutlined
                        className={classes.button}
                        color="primary"
                        onClick={revert}
                    />
                </div>
            </div>
            <div>
                <div className={classes.textAreaWrapper}>
                    <TextField
                        className={classes.textArea}
                        color="primary"
                        focused
                        minRows={10}
                        multiline
                        value={rawMessage}
                        variant="outlined"
                        onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setRawMessage(event.target.value);
                        }}
                        onKeyDown={(event) => {
                            if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
                                revert();
                            }
                        }}
                        onSelect={updateSelectionStart}
                    />
                </div>
                <div className={classes.shortcuts}>
                    <div
                        className={classes.badge}
                        onClick={addLink}
                    >
                        Link
                    </div>
                    <div
                        className={classes.badge}
                        onClick={boldSelection}
                    >
                        Bold selection
                    </div>
                    <div
                        className={classes.badge}
                        onClick={lineBreak}
                    >
                        Line break
                    </div>
                    <div
                        className={classes.badge}
                        onClick={convertBracketToHashTag}
                    >
                        Convert brackets to hashes
                    </div>
                </div>
            </div>
            <h2>Your result goes here</h2>
            <div className={classes.parsedMessage}>
                <ParsedMessage message={message} />
            </div>
        </div>
    );
};

export default NotificationTemplateTester;

import React from 'react';
import icons from 'shared-components/icons';
import { LaneObject } from 'shared-components/dataTypes';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import TempRange from 'shared-components/TempRange';
import { TFunction } from 'i18next';

const lanesTableColumns:
    (t:TFunction) => ColumnsType[] = (t) => [
        {
            accessor: ({ laneNumber }: LaneObject) => laneNumber,
            customWidth: '20%',
            dataType: 'highlightedText',
            disableSortBy: true,
            Header: t('LANE_MANAGEMENT.LANES.LANE'),
            id: 'laneNumber',
            sideFilterKey: 'laneNumber',
            sideFilterType: 'text',
        },
        {
            accessor: ({ fourthPartyLogistics }) => fourthPartyLogistics,
            customWidth: '10%',
            dataType: 'text',
            Header: t('COMMON.4_PL'),
            id: 'fourthPartyLogisticsCompanyName',
            sideFilterKey: 'fourthPartyLogistics',
            sideFilterType: 'enum',
        },
        {
            accessor: ({
                temperatureRange,
            }: LaneObject) => <TempRange mode="reduced" tempRange={temperatureRange} />,
            customWidth: '10%',
            dataType: 'custom',
            Header: t('COMMON.RANGE_C'),
            id: 'temperatureRange',
            sideFilterKey: 'temperatureRange',
            sideFilterType: 'enum',
        },
        {
            accessor: ({ originAirportCode } :LaneObject) => originAirportCode,
            customWidth: '80px',
            dataType: 'custom',
            disableSortBy: true,
            Header: t('COMMON.FROM'),
            id: 'originAirportCode',
            sideFilterKey: 'originAirportCode',
            sideFilterType: 'airportOrigin',
        },
        {
            accessor: ({ destinationAirportCode } :LaneObject) => destinationAirportCode,
            customWidth: '100px',
            dataType: 'custom',
            disableSortBy: true,
            Header: t('COMMON.TO'),
            id: 'destinationAirportCode',
            sideFilterKey: 'destinationAirportCode',
            sideFilterType: 'airportDestination',
        },
        {
            accessor: 'loadingLocation',
            customWidth: '20%',
            dataType: 'custom',
            disableSortBy: true,
            Header: t('COMMON.ORIGIN_LOCATION'),
            id: 'loadingLocationName',
            sideFilterKey: 'loadingLocation',
            sideFilterType: 'enum',
        },
        {
            accessor: 'unloadingLocation',
            customWidth: '20%',
            dataType: 'custom',
            disableSortBy: true,
            Header: t('COMMON.DESTINATION_LOCATION'),
            id: 'unloadingLocationName',
            sideFilterKey: 'unloadingLocation',
            sideFilterType: 'enum',
        },
        {
            accessor: 'status',
            dataType: 'custom',
            Header: t('TRACK_AND_TRACE.STATUS'),
            id: 'status',
            sideFilterKey: 'status',
            sideFilterType: 'enum',
        },
        {
            accessor: 'milestones',
            customWidth: '100px',
            dataType: 'custom',
            disableSortBy: true,
            Header: t('COMMON.DURATION'),
            id: 'cell-MilestonesDuration',
        },
        {
            accessor: ({ isSkycellManaged } :LaneObject) => (isSkycellManaged === 'Yes'
                ? (<img alt="skycell_icon" src={icons.skycell_blue} style={{ height: 38, width: 38 }} />) : ''),
            customWidth: '100px',
            dataType: 'custom',
            disableSortBy: true,
            Header: t('LANE_MANAGEMENT.LANES.MANAGED_BY'),
            id: 'cell-ManagedBy',
        },
    ];

export default lanesTableColumns;

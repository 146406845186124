import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import useCachedQueryRequest from 'hooks/useCachedQueryRequest';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import { useMemo } from 'react';
import { getRequestParametersString } from 'SensorDataCommon/lib';
import { prepareRequestDataTypes } from 'shared-components/ApexTemperatureChart/lib';
import { adminApi } from 'utils/stringTool';

type Props = {
    cargoId?: number,
    currentDate: number,
    dateTimeFrom:string,
    dateTimeTo:string,
    entityId: string | number,
    isAdmin?: boolean,
    isGeneric?: boolean,
    isPredictedExcursion?: boolean,
    loggerId?: number,
    requestOptions?: SensorDataRequestBody,
    requestType: 'packagings' | 'loggers' | 'assets',
    serialNumber: string,
    shipmentId?: string,
    shipmentNumber: string,
    showMeasuredOnly?: boolean
}
const useLoadedData = ({
    cargoId,
    currentDate,
    dateTimeFrom,
    dateTimeTo,
    entityId,
    isAdmin = false,
    isGeneric = false,
    isPredictedExcursion = false,
    loggerId,
    requestOptions,
    requestType,
    serialNumber,
    shipmentId,
    shipmentNumber,
    showMeasuredOnly = false,
}: Props) => {
    const {
        FlexibleRequest: getShipmentSensorData,
    } = useSkymindBackendEndpoints(adminApi(isGeneric ? '' : 'shipment', isAdmin)).requests;
    const { FlexibleRequest: getLoggerSensorData } = useSkymindBackendEndpoints(adminApi('loggers', isAdmin)).requests;
    const { FlexibleRequest: getAssetSensorData } = useSkymindBackendEndpoints(adminApi('assets', isAdmin)).requests;

    const bakedRequestOptions = useMemo(() => {
        const {
            dataTypes: rawDataTypes = [],
            loggers = [],
            positions = [],
        } = requestOptions;

        const dataTypesForRequest = prepareRequestDataTypes(rawDataTypes);

        if (requestOptions.dataTypes.length === 0) return null;
        switch (requestType) {
        case 'assets':
            return {
                from: dateTimeFrom,
                to: dateTimeTo,
            };
        case 'loggers':
            return {
                dataTypes: [...(new Set(['TEMPERATURE', ...dataTypesForRequest]))],
                from: dateTimeFrom,
                loggers,
                positions,
                to: dateTimeTo,
                useStandardizedTimestamps: true,
            };
        case 'packagings':
            return {
                dataTypes: dataTypesForRequest,
                positions,
                timestampFormat: 'ISO',
            };
        }
    }, [requestOptions, dateTimeFrom, dateTimeTo, requestType]);

    const shouldRequest = useMemo(() => !!bakedRequestOptions && ((dateTimeFrom !== null
        && dateTimeTo !== null) || requestType === 'packagings')
        && (bakedRequestOptions?.positions?.length > 0 || requestType !== 'packagings')
&& (bakedRequestOptions?.dataTypes?.includes('TEMPERATURE') || requestType !== 'loggers'),
    [dateTimeFrom, dateTimeTo, bakedRequestOptions]);

    const {
        data: rawSensorData = null,
        isError,
        status,
    } = useCachedQueryRequest({
        key: {
            bakedRequestOptions,
            cargoId,
            currentDate,
            dateTimeFrom,
            dateTimeTo,
            entityId,
            isGeneric,
            isPredictedExcursion,
            requestType,
            serialNumber,
            shipmentId,
            shipmentNumber,
            showMeasuredOnly,
        },
        onCompleted: response => response?.data || null,
        options: {
            enabled: shouldRequest,
            retry: false,
        },
        request: () => {
            switch (requestType) {
            case 'assets':
                return getAssetSensorData(
                    'GET',
                        `${entityId}/sensor-data?${getRequestParametersString(bakedRequestOptions)}`,
                );
            case 'loggers':
                return getLoggerSensorData('GET',
                        `${loggerId}/sensor-data?${getRequestParametersString(bakedRequestOptions)}`);
            case 'packagings':
                if (isPredictedExcursion) {
                    return getShipmentSensorData('GET',
                        `v2/shipments/${shipmentId}/cargo/${cargoId}/sensor-data-with-prediction`);
                }
                if (showMeasuredOnly) {
                    return getShipmentSensorData('GET',
                            `v2/shipments/${shipmentId}/cargo/${
                            cargoId}/sensor-data?splitMeasurementsAndPredictions=true`);
                } else {
                    return getShipmentSensorData('GET',
                            `v2/shipments/${shipmentId}/cargo/${
                            cargoId}/sensor-data?splitMeasurementsAndPredictions=false`);
                }
            }
        },
    });

    return {
        isError,
        rawSensorData,
        status,
    };
};

export default useLoadedData;

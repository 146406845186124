import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    addGeolocationDescription: {
        alignItems: 'center',
        color: '#747474',
        display: 'flex',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        marginBottom: '16px',
        opacity: 1,
        textAlign: 'left',
    },
    addGeolocationDescriptionIcon: {
        marginRight: '8px',
    },
    approveButton: {
        textTransform: 'uppercase',
        width: '100px',
    },
    bottomContainer: {
        top: 'auto',
    },
    buttonContainer: {
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        marginTop: '26px',
    },
    contentData: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '1fr 1fr',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    contentDataMargin: {
        marginTop: '15px',
    },
    coordinatesFieldTitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        marginTop: '-4px',
        opacity: 1,
        textAlign: 'left',
    },
    fieldTitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        margin: '12px 0 2px 0',
        opacity: 1,
        textAlign: 'left',
    },
    flexContent: {
        alignItems: 'start',
        display: 'flex',
        justifyContent: 'space-between',
    },
    infoIcon: {
        height: '26px',
        marginRight: '7px',
        opacity: 1,
        width: '26px',
    },
    infoText: {
        color: '#747474',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    latLngTitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        opacity: 1,
        textAlign: 'left',
    },
    listBox: {
        '& .MuiAutocomplete-option.Mui-focused': { // works
            backgroundColor: 'rgba(25, 118, 210, 0.12)!important',
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': { // works
            backgroundColor: '#FFF!important',
        },
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
    },
    mandatory: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderLeft: '6px solid #8BD1E8',
        borderRadius: '3px',
        opacity: 1,
    },
    mandatoryInput: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderLeft: '6px solid #8BD1E8',
        borderRadius: '3px',
        opacity: 1,
        width: '7ch',
    },
    mapButton: {
        '& svg': {
            maxWidth: '36px',
        },
        marginRight: '19px',
        textTransform: 'uppercase',
    },
    mapButtonContainer: {
        marginRight: '20px',
    },
    modalWidth: {
        width: '75%',
    },
    rejectButton: {
        textTransform: 'uppercase',
        width: '100px',
    },
    selectStyle: {
        background: 'purple!important',
    },
    textField: {
        marginTop: '12px',
    },
    textFieldLatitude: {
        '& input': {
            color: theme.palette.secondary[600],
        },
        width: '105%',
    },
    textFieldLongitude: {
        '& input': {
            color: theme.palette.secondary[600],
        },
        width: '115%',
    },
    textFieldText: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
}));

export default styles;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles(theme => ({
    cardWithEditableContent: ({ classes }) => ({
        [`&:hover .${classes.hoverIconsRow}`]: {
            opacity: 1,
        },
    }),
    hoverIconsRow: {
        display: 'flex',
        padding: '6px 10px',
        position: 'absolute',
        right: 0,
        top: 0,
        transition: '200ms ease-in-out',
        whiteSpace: 'nowrap',
    },
    icon: {
        '&:hover': {
            color: theme.palette.secondary[800],
        },
        color: theme.palette.secondary[600],
        cursor: 'pointer',
        fontSize: '23px',
        height: '23px',
        width: '23px',
    },
    invisibleIconRow: {
        opacity: 0,
    },
    visibleIconRow: {
        opacity: 1,
    },
}));

export default styles;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    a: {
        textDecoration: 'none',
    },
    activeItem: {
        background: `${theme.palette.primary.darkBlue}!important`,
    },
    activeListItemStyle: {
        height: '42px',
        padding: '8px!important',
        position: 'relative',
    },
    block: {
        display: 'block',
    },
    homeListItem: {
        background: `${theme.palette.primary[600]} 0% 0% no-repeat padding-box`,
        height: '36px',
        opacity: 1,
        padding: '8px!important',
    },
    homeListItemText: {
        color: theme.palette.common.white,
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        marginLeft: '15px',
        opacity: 1,
        textAlign: 'left',
    },
    icon: {
        height: '26px',
        width: '31px',
    },
    listItemStyle: {
        height: '42px',
        padding: '8px!important',
    },
    listItemTextStyle: {
        // @ts-ignore
        color: theme.palette.common.menuTextWhite,
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        marginLeft: '15px',
        opacity: 1,
        textAlign: 'left',
        textTransform: 'uppercase',
    },
}));

export default styles;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useMemo, useRef } from 'react';

import {
    getNumberWidth,
} from 'shared-components/TemperatureCheck/lib';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import TemperatureCheckIcon from 'shared-components/TemperatureCheckIcon';
import { getTempMaxStyle, getTempMinStyle, getTempRulerStyle } from './lib';
import { TEMPERATURE_STATUS } from '../constants';

type Props = {
    bounds: [number, number],
    grid?: boolean,
    temperatureCheckStatus?: string,
    temperatureMax: number,
    temperatureMin: number,
    temperatureRangeMax: number,
    temperatureRangeMin: number
}

const TemperatureCheckRelative = ({
    bounds = [0, 0],
    temperatureCheckStatus = null,
    temperatureMax,
    temperatureMin,
    temperatureRangeMax,
    temperatureRangeMin,
} : Props) => {
    const theme = useTheme<SkycellThemeInterface>();
    const rectRef = useRef(null);

    const itemsCountSeparatedByLines = temperatureRangeMax - temperatureRangeMin === 6 ? 6 : 10;
    const scale = 90 / itemsCountSeparatedByLines; // Scaled down by 40%
    const temperatureMinOffset = temperatureMin === null
        ? -50
        : Math.min(((temperatureRangeMin - temperatureMin) * scale), scale * 2);
    const offsetToLeft = -108 - getNumberWidth(temperatureMin) - temperatureMinOffset; // Scaled down by 40%
    const temperatureMaxOffset = temperatureMax === null
        ? -50
        : Math.min(((temperatureMax - temperatureRangeMax) * scale), scale * 2);
    const width = 120 + temperatureMinOffset + temperatureMaxOffset;
    const [allMin, allMax] = bounds;

    const boundValues = useMemo<{
        left: number, right: number, width: number
    }>(() => {
        const rectWidth = 120;
        const minRatio = (allMax - allMin) !== 0
            ? ((temperatureMin - allMin) / (allMax - allMin)) : 0;
        const maxRatio = (allMax - allMin) !== 0
            ? ((temperatureMax - allMin) / (allMax - allMin)) : 1;
        const widthRatio = (temperatureMin && temperatureMax) ? (maxRatio - minRatio) : 1;

        return {
            left: temperatureMin ? minRatio * rectWidth : 0,
            right: temperatureMax ? maxRatio * rectWidth : rectWidth,
            width: rectWidth * widthRatio,
        };
    }, [rectRef, allMin, allMax, temperatureMin, temperatureMax]);
    const mainStyle = useMemo<CSSProperties>(() => ({
        alignItems: 'center',
        columnGap: '10px',
        display: 'grid',
        gridTemplateColumns: '60px 120px 40px',
        position: 'relative',
        rowGap: '10px',
    }), [offsetToLeft, width, allMin, allMax]);

    useMemo(() => temperatureCheckStatus === 'NO_DATA', [temperatureCheckStatus]);
    const isPotential = useMemo(() => temperatureCheckStatus === TEMPERATURE_STATUS.POTENTIAL_EXCURSION
        || temperatureCheckStatus === TEMPERATURE_STATUS.PREDICTED_EXCURSION, [temperatureCheckStatus]);

    return (
        <>
            <div
                style={mainStyle}
            >
                <TemperatureCheckIcon temperatureCheckStatus={temperatureCheckStatus || 'NO_DATA'} />
                <div
                    style={{
                        height: '20px',
                        position: 'relative',
                    }}
                >
                    <div
                        style={
                            getTempMinStyle(
                                {
                                    boundValues,
                                    isPotential,
                                    noData: temperatureCheckStatus === 'NO_DATA' || temperatureCheckStatus === null,
                                    tempMaxRange: temperatureRangeMax || null,
                                    tempMin: temperatureMin || null,
                                    tempMinRange: temperatureRangeMin || null,
                                    theme,
                                },
                            )
                        }
                    >
                        {temperatureMin === null || temperatureMin === undefined
                            ? 'n/a' : `${temperatureMin?.toFixed(1)}°C`}
                    </div>
                    <div style={
                        getTempRulerStyle(
                            {
                                boundValues,
                                isPotential,
                                noData: temperatureCheckStatus === 'NO_DATA' || temperatureCheckStatus === null,
                                tempMax: temperatureMax || null,
                                tempMaxRange: temperatureRangeMax || null,
                                tempMin: temperatureMin || null,
                                tempMinRange: temperatureRangeMin || null,
                                theme,
                            },
                        )
                    }
                    />
                    <div style={
                        getTempMaxStyle({
                            boundValues,
                            isPotential,
                            noData: temperatureCheckStatus === 'NO_DATA' || temperatureCheckStatus === null,
                            tempMax: temperatureMax || null,
                            tempMaxRange: temperatureRangeMax || null,
                            tempMinRange: temperatureRangeMin || null,
                            theme,
                        })
                    }
                    >
                        {temperatureMax === null || temperatureMax === undefined
                            ? 'n/a' : `${temperatureMax?.toFixed(1)}°C`}
                    </div>

                </div>
            </div>
        </>
    );
};

export default TemperatureCheckRelative;

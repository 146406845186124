import TextField from '@mui/material/TextField';
import useClasses from 'hooks/useClasses';
import { wrapStyles } from 'hooks/useClasses/useClasses';
import React, { useState } from 'react';
import Card from 'shared-components/Card';
import FileUpload from 'shared-components/FileUpload';

const styles = wrapStyles((theme) => ({
    cardBody: {
        color: '#747474',
        columnGap: '12px',
        display: 'grid',
        font: 'normal normal normal 16px/19px Roboto',
        gridTemplateColumns: '1fr 1fr',
        letterSpacing: '0.49px',
        marginTop: '8px',
        opacity: 1,
        rowGap: '12px',
        textAlign: 'left',
    },
    textField: {
        width: '100%',
    },
    textFieldText: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    title: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        marginBottom: '5px',
        opacity: 1,
        textAlign: 'left',
    },
}));

function AddNewPicture() {
    const classes = useClasses(styles);
    const [description, setDescription] = useState('');
    const [pictureFormData, setPictureFormData] = useState < FormData >(null);

    const handleTextInputChange = (event) => {
        setDescription(event.target.value);
    };

    return (
        <Card title="Add Picture">
            <div className={classes.cardBody}>
                <div>
                    <div className={classes.title}>Description</div>
                    <TextField
                        className={classes.textField}
                        inputProps={{
                            className: classes.textFieldText,
                        }}
                        multiline
                        rows={5}
                        value={description}
                        onChange={handleTextInputChange}
                    />
                </div>
                <div>
                    <div className={classes.title}>Picture</div>
                    <FileUpload
                        formData={pictureFormData}
                        setFormData={setPictureFormData}
                    />
                </div>
            </div>
        </Card>
    );
}

export default AddNewPicture;

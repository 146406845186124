import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    contentData: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '1fr 1fr',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    fieldTitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        margin: '12px 0 2px 0',
        opacity: 1,
        textAlign: 'left',
    },
    filedInfo: {
        color: '#747474',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    loading: {
        filter: 'blur(2px)',
        transition: '200ms ease',
    },
}));

export default styles;

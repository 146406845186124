import { useTheme } from '@emotion/react';
import { Close } from '@mui/icons-material';
import { TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import useClasses from 'hooks/useClasses';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { styles } from 'Administration/AccessRequests/DecisionModal/DecisionModal.style.';
import { AccessRequestAction, AccessRequestDTO } from 'MyCompany/AccessRequests/lib';
import CommonButton from 'shared-components/CommonButton';
import StatusChip from 'shared-components/StatusChip';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalDialog } from 'react-bootstrap';
import Map from 'shared-components/Map';

type Props = {
    accessRequests: AccessRequestDTO[],
    loading: boolean,
    onClose: () => void,
    onSend: Function,
    selected: number[]
}
const DecisionModal = ({
    accessRequests,
    loading,
    onClose,
    onSend,
    selected,
}: Props) => {
    const classes = useClasses(styles);
    const theme = useTheme();
    const isMultiple = useMemo(() => {
        return selected?.length >= 2;
    }, [selected]);
    const { t } = useCustomTranslation();
    const [comment, setComment] = useState('');
    const shouldBeOpen = useMemo(() => selected && selected.length > 0, [selected]);
    const currentSelected = useMemo(
        () => {
            if (!selected || selected.length !== 1) return null;
            return accessRequests?.find(it => it?.id === selected?.[0]);
        },
        [accessRequests, selected],
    );

    const handleApprove = useCallback(() => {
        onSend(selected, comment);
    }, [onSend, comment, selected]);

    const handleCommentInput = useCallback((event) => {
        setComment(event.target.value);
    }, []);
    const handleReject = useCallback(() => {
        onSend(selected, comment, AccessRequestAction.REJECT);
    }, [onSend, comment, selected]);

    useEffect(() => {
        setComment('');
    }, [selected]);

    const currentGeofence = useMemo(() => {
        if (!currentSelected) return null;
        return {
            coordinates: currentSelected.geofenceCoordinates?.map(it => ({
                latitude: it.latitude,
                longitude: it.longitude,
            })),
        };
    }, [currentSelected]);

    if (!selected || selected.length === 0) return <></>;
    return isMultiple ? (
        <Modal hideBackdrop open={!!shouldBeOpen}>
            <ModalDialog className={classes.modalWrapper}>
                <div className={classes.backdrop} onClick={onClose} />
                <div className={classes.modalWindow}>
                    <Close className={classes.closeIcon} onClick={onClose} />
                    <div className={classes.modalTitle}>
                        Bulk Approval Confirmation
                    </div>
                    <div className={classes.field}>
                        <span className={classes.fieldTitle}>Approval Comment</span>
                        <TextField
                            classes={{
                                root: classes.fieldInput,
                            }}
                            maxRows={4}
                            multiline
                            variant="standard"
                            onInput={handleCommentInput}
                        />
                    </div>
                    <div className={classes.buttons}>
                        <CommonButton
                            loading={loading}
                            variant="outlined"
                            onClick={onClose}
                        >
                            {t('COMMON.CANCEL')}
                        </CommonButton>
                        <CommonButton
                            loading={loading}
                            onClick={handleApprove}
                        >
                            {t('ORDER_DETAILS.APPROVE')}
                        </CommonButton>
                    </div>
                </div>
            </ModalDialog>
        </Modal>
    ) : (
        <Modal hideBackdrop open={!!shouldBeOpen}>
            <ModalDialog className={classes.modalWrapper}>
                <div className={classes.backdrop} onClick={onClose} />
                <div className={classes.modalWindow}>
                    <Close className={classes.closeIcon} onClick={onClose} />
                    <div className={classes.modalTitle}>
                        Access Request
                    </div>
                    <div className={classes.threeDivisions}>
                        <div className={classes.firstDivision}>
                            <div className={classes.fieldSingle}>
                                <span className={classes.fieldTitle}>{t('MY_COMPANY.REQUESTING_COMPANY')}</span>
                                <span className={classes.fieldValue}>{currentSelected?.requestingCompanyName}</span>
                            </div>
                            <div className={classes.fieldSingle}>
                                <span className={classes.fieldTitle}>{t('COMMON.SITE_NAME')}</span>
                                <span className={classes.fieldValue}>{currentSelected?.siteName}</span>
                            </div>
                            <div className={classes.fieldSingle}>
                                <span className={classes.fieldTitle}>{t('COMMON.GEOFENCE_NAME')}</span>
                                <span className={classes.fieldValue}>{currentSelected?.geofenceName}</span>
                            </div>
                        </div>
                        <div>
                            <div className={classes.fieldSingle}>
                                <span className={classes.fieldTitle}>Geofence Coordinates</span>
                                <div style={{
                                    height: '286px',
                                    marginBottom: '10px',
                                    width: '531px',
                                }}
                                >
                                    {
                                        currentGeofence && (
                                            <Map
                                                mapConfig={{
                                                    backgroundColor: 'transparent',
                                                    center: {
                                                        lat: currentGeofence.coordinates[0].latitude,
                                                        lng: currentGeofence.coordinates[0].longitude,
                                                    },
                                                    containerStyle: {
                                                        height: '100%',
                                                        width: '100%',
                                                    },
                                                    zoom: 10,
                                                }}
                                                separatePolygon={currentGeofence.coordinates}
                                            />
                                        )
                                    }
                                    {
                                        !currentGeofence && (
                                            <span style={{ color: theme.palette.common.red }}>
                                                Geofence was removed
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={classes.thirdDivision}>
                            <div className={classes.field}>
                                <span className={classes.fieldTitle}>Approval / Rejection Comment</span>
                                <TextField
                                    classes={{
                                        root: classes.fieldInput,
                                    }}
                                    maxRows={3}
                                    multiline
                                    variant="standard"
                                    onInput={handleCommentInput}
                                />
                            </div>
                            <div className={classes.field}>
                                <span className={classes.fieldTitle}>{t('COMMON.STATUS')}</span>
                                <StatusChip status={currentSelected.status} t={t} />
                            </div>

                            <div className={classes.buttons}>
                                <CommonButton
                                    loading={loading}
                                    variant="outlined"
                                    onClick={handleReject}
                                >
                                    {t('ORDER_DETAILS.REJECT')}
                                </CommonButton>
                                <CommonButton
                                    loading={loading}
                                    onClick={handleApprove}
                                >
                                    {t('ORDER_DETAILS.APPROVE')}
                                </CommonButton>
                            </div>

                        </div>
                    </div>
                </div>
            </ModalDialog>
        </Modal>
    );
};

export default DecisionModal;

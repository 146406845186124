import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme) => ({
    container: {
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.secondary[100],
            width: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-track': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
            backgroundColor: theme.palette.common.white,
            borderRadius: '10px',
        },
        flexDirection: 'column',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
}));

import { useMemo } from 'react';
import { css, CSSInterpolation, keyframes } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

type StyleProps<T extends Record<string, any>> = {
    [key: string]: any;
    classes?: Record<keyof T, string>;
};

type CSSProperty = CSSInterpolation | Record<string, CSSInterpolation>;

type CustomCSS = CSSProperty
    | ((props: { [key: string]: any; classes?: Record<string, string> }) => CSSProperty);

export type StylesElement<T extends Record<string, CustomCSS>> =
    T | ((theme: SkycellThemeInterface) => T);

export const wrapStyles = <
    T extends Record<string, CustomCSS
    >>(styles: (theme: SkycellThemeInterface) => T): StylesElement<T> => styles;

const useClasses = <T extends Record<string, CustomCSS>>(
    stylesElement: StylesElement<T>,
    props: StyleProps<T> = {},
): Record<keyof T, string> => {
    const theme = useTheme();

    return useMemo(() => {
        const rawClasses: T = typeof stylesElement === 'function'
            ? stylesElement(theme)
            : stylesElement;

        const prepared: Record<keyof T, string> = {} as Record<keyof T, string>;

        Object.entries(rawClasses)
            .sort(([, valueA], [, valueB]) => {
                const isFunctionA = typeof valueA === 'function';
                const isFunctionB = typeof valueB === 'function';

                if (!isFunctionA && isFunctionB) return -1;
                if (isFunctionA && !isFunctionB) return 1;
                return 0;
            })
            .forEach(([key, value]) => {
                const computedValue = typeof value === 'function'
                    ? (value as (props: StyleProps<T>) => CustomCSS)({ ...props, classes: prepared })
                    : value;

                if (key.startsWith('anim_')) {
                    prepared[key as keyof T] = keyframes({
                        label: key,
                        ...computedValue as any,
                    });
                } else {
                    prepared[key as keyof T] = css({
                        label: key,
                        ...computedValue as any,
                    });
                }
            });

        return prepared;
    }, [stylesElement, theme, props]);
};

export default useClasses;

import styles from 'Contexts/QuickHelpContext/QuickHelpElement/QuickHelpElement.style';
import useClasses from 'hooks/useClasses';
import React, { useMemo, CSSProperties, useContext, useState, useRef, useCallback, useEffect } from 'react';
import { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import { useCalculatedRectStyle } from 'Contexts/QuickHelpContext/useCalculatedRectStyle';
import { useTheme } from '@emotion/react';
import CommonTooltip, { TOOLTIP_TYPE } from 'shared-components/CommonTooltip/CommonTooltip';

type Props = {
    allTooltips: QuickHelpTooltipInfo[],
    handleNext: () => void,
    handlePrevious: () => void,
    handleSkip: () => void,
    handleStep: (step: number) => void,
    order: number,
    screenSize: {
        height: number,
        scale: number,
        width: number
    },
    steps: number,
    tooltipInfo: QuickHelpTooltipInfo
}

export const TOOLTIP_PADDING = 4;

const QuickHelpElement = ({
    allTooltips = [],
    handleNext,
    handlePrevious,
    handleSkip,
    handleStep,
    order,
    screenSize,
    steps,
    tooltipInfo,
}: Props) => {
    const {
        childOffsetPercent,
        childRef,
        customRectSize,
        image,
        offsetPx = [0, 0],
        padding,
        position = 'auto',
        positionDelta,
        radius = 0,
        text,
        title,
    } = tooltipInfo || {};
    const classes = useClasses(styles, {
        hasNext: !!handleNext,
        hasPrev: !!handlePrevious,
    });
    const { enableTooltips } = useContext(QuickHelpContext);
    const [shouldUpdate, setShouldUpdate] = useState(0);
    const update = useCallback(() => {
        setShouldUpdate(new Date().getTime());
    }, []);
    const theme = useTheme();

    useEffect(() => {
        setTimeout(() => {
            update();
        }, 10);
    }, [tooltipInfo]);
    const { height, width } = screenSize;
    const tooltipRef = useRef(null);
    const childRect = useMemo<DOMRect>(() => {
        const element:HTMLElement = childRef?.current;

        if (!element) return null;

        return element?.getBoundingClientRect();
    }, [tooltipInfo, height, width, childRef]);
    const tooltipRect = useMemo<DOMRect>(() => {
        const element:HTMLElement = tooltipRef?.current;

        if (!element) return null;

        return element?.getBoundingClientRect();
    }, [tooltipRef, shouldUpdate, screenSize, tooltipInfo]);

    const rectStyle = useCalculatedRectStyle({
        childOffsetPercent,
        childRect,
        customRectSize,
        offsetPx,
        padding,
        radius,
        screenSize,
        shouldUpdate,
    });

    useEffect(() => {
        update();
    }, []);
    const calculatedPosition = useMemo(() => {
        const rectLeft: number = parseFloat(rectStyle.left.toString());
        const rectWidth: number = parseFloat(rectStyle.width.toString());

        return width / 2 < rectLeft + rectWidth ? 'left' : 'right';
    }, [width, height, rectStyle]);

    const tooltipStyle = useMemo<CSSProperties>(() => {
        const leftOffset = positionDelta?.[0] || 0;
        const topOffset = positionDelta?.[1] || 0;

        if (position === 'weighted') {
            const tooltipCenters = allTooltips.map(tooltip => {
                const rect = (tooltip.childRef?.current || tooltip.explicitChildRef?.current)?.getBoundingClientRect();
                const rectLeft = parseFloat(rect.left.toString());
                const rectWidth = parseFloat(rect.width.toString());
                const rectTop = parseFloat(rect.top.toString());
                const rectHeight = parseFloat(rect.height.toString());

                return {
                    display: !enableTooltips || !tooltipInfo ? 'none' : 'flex',
                    left: rectLeft + rectWidth / 2,
                    position: 'fixed',
                    top: rectTop + rectHeight / 2,
                    zIndex: 10001,
                };
            });

            const totalTooltips = tooltipCenters.length;

            const averageCenter = tooltipCenters.reduce(
                (acc, tooltip) => {
                    acc.left += tooltip.left;
                    acc.top += tooltip.top;
                    return acc;
                },
                { left: 0, top: 0 },
            );

            averageCenter.left /= totalTooltips;
            averageCenter.top /= totalTooltips;

            return {
                display: !enableTooltips || !tooltipInfo ? 'none' : 'flex',
                left: averageCenter.left - tooltipRect?.width / 2,
                position: 'fixed',
                top: averageCenter.top - tooltipRect?.height / 2,
                zIndex: 10001,
            };
        }
        if (position === 'center') {
            return {
                display: !enableTooltips || !tooltipInfo ? 'none' : 'flex',
                left: width / 2 - tooltipRect?.width / 2,
                position: 'fixed',
                top: height / 2 - tooltipRect?.height / 2,
                zIndex: 10001,
            };
        }

        const rectTop: number = parseFloat(rectStyle.top.toString());
        const rectLeft: number = parseFloat(rectStyle.left.toString());
        const rectHeight: number = parseFloat(rectStyle.height.toString());
        const rectWidth: number = parseFloat(rectStyle.width.toString());

        let calculatedLeft = rectLeft;

        let calculatedTop = rectTop + rectHeight / 2 + (padding || TOOLTIP_PADDING) - tooltipRect?.height / 2;

        if (position === 'auto' || position === 'left' || position === 'right') {
            switch (calculatedPosition) {
            case 'left':
                calculatedLeft -= tooltipRect?.width - 10;
                break;
            case 'right':
                calculatedLeft += rectWidth + (padding || TOOLTIP_PADDING) + 10;
                break;
            }
        }
        if (position === 'top') {
            calculatedTop = rectTop - (tooltipRect?.height || 0) - (padding || TOOLTIP_PADDING);
            calculatedLeft = rectLeft + rectWidth / 2 - tooltipRect.width / 2;
        }
        if (position === 'bottom') {
            calculatedTop = rectTop + rectHeight + (padding || TOOLTIP_PADDING);
            calculatedLeft = rectLeft + rectWidth / 2 - tooltipRect.width / 2;
        }

        return {
            display: !enableTooltips || !tooltipInfo ? 'none' : 'flex',
            left: Math.max(calculatedLeft + leftOffset, TOOLTIP_PADDING),
            position: 'fixed',
            top: Math.max(calculatedTop + topOffset, TOOLTIP_PADDING),
            zIndex: 10001,
        };
    }, [
        childOffsetPercent,
        childOffsetPercent,
        offsetPx,
        offsetPx,
        height,
        padding,
        width,
        rectStyle,
        tooltipRect,
        calculatedPosition,
        allTooltips,
        position,
        positionDelta,
    ]);

    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleImageClick = () => {
        setIsFullscreen(prev => !prev);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Escape' && isFullscreen) {
            setIsFullscreen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isFullscreen]);

    const imageStyle: CSSProperties = useMemo(() => (isFullscreen
        ? {
            height,
            left: 0,
            opacity: 1,
            top: 0,
            width,
        }
        : {
            opacity: 0,
            pointerEvents: 'none',

        }), [isFullscreen, width, height]);

    return (
        <>
            {image && (
                <div>
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <img
                        alt="Quick Help"
                        src={image}
                        style={{
                            cursor: 'zoom-out',
                            height: tooltipRect?.height,
                            left: tooltipRect?.left,
                            objectFit: 'contain',
                            position: 'fixed',
                            top: tooltipRect?.top,
                            transition: '200ms ease',
                            width: tooltipRect?.width,
                            zIndex: 9999999,
                            ...imageStyle,
                        }}
                        onClick={handleImageClick}
                    />
                </div>
            )}

            <div
                style={rectStyle}
            />
            <div
                ref={tooltipRef}
                className={[
                    classes.quickHelp,
                    calculatedPosition === 'right' ? classes.quickHelpRight : '',
                    text ? classes.tooltipVisible : '',
                ].join(' ')}
                style={tooltipStyle}
            >
                <div className={classes.tooltipBodyWrapper}>
                    <CommonTooltip description="Close" placement="bottom" type={TOOLTIP_TYPE.SHRINK}>
                        <div className={classes.closeButton} onClick={handleSkip}>×</div>
                    </CommonTooltip>
                    <div className={classes.tooltipBody}>
                        <div className={classes.content}>
                            <span className={classes.title}>{title}</span>
                            <span style={{
                                color: theme.palette.common.black,
                                whiteSpace: 'pre-wrap',
                            }}
                            >
                                {text}
                            </span>
                            {image && (
                                <div className={classes.imageHolder}>
                                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                                    <img
                                        alt="Quick Help"
                                        src={image}
                                        onClick={handleImageClick}
                                    />
                                </div>
                            )}
                            <div className={classes.controls}>
                                <div
                                    className={classes.previous}
                                    onClick={handlePrevious || (() => {
                                    })}
                                >
                                    Previous
                                </div>
                                <div className={classes.dots}>
                                    {
                                        new Array(steps).fill(0).map((_, index) => (
                                            <div
                                                key={index}
                                                className={[
                                                    classes.dot,
                                                    order - 1 === index ? classes.active : ''].join(' ')}
                                                onClick={() => handleStep(index + 1)}
                                            />
                                        ))

                                    }
                                </div>
                                <div
                                    className={classes.next}
                                    onClick={handleNext || (() => {
                                    })}
                                >
                                    Next
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuickHelpElement;

import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme) => ({
    clearSearchBtn: {
        marginLeft: '8px',
        minWidth: '24px',
        padding: '0px',
    },
    elementDisabled: {
        '&:after': {
            content: '""',
            height: '0px!important',
            margin: '0!important',
        },
    },
    elementEnabled: {
        '&:after': {
            height: '15px!important',
        },
    },
    input: {
        '& $input': {
            paddingLeft: '8px',
        },
        '.MuiSvgIcon-root': {
            color: theme.palette.common.black,
        },
        color: theme.palette.secondary[600],
        flex: '1 1 auto',
        font: 'normal normal normal 14px/17px Roboto',
        height: '31px',
        letterSpacing: '0.43px',
        opacity: 1,
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    inputWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    keywordLimitDescription: ({ minLength }) => ({
        '&:after': {
            bottom: '0',
            color: theme.palette.secondary[500],
            content: `"Please enter at least ${minLength} characters"`,
            display: 'block',
            fontSize: '13px',
            left: '0',
            letterSpacing: '0.25px',
            margin: '12px 0 0 0',
            overflow: 'hidden',
            position: 'relative',
            transition: 'height 0.3s',
            whiteSpace: 'normal',
            width: 'max-content',
        },
    }),
    resetKeywordDescription: {
        '&:after': {
            bottom: '0',
            color: '#009CCF !important',
            content: '"Some results are filtered out, clear search to see all results"',
            display: 'flex',
            fontSize: '13px',
            left: '0',
            letterSpacing: '0.25px',
            margin: '12px 0',
            position: 'relative',
            transition: 'height 0.3s',
            whiteSpace: 'normal',
            width: '101%',
            wordBreak: 'break-word',
            wordWrap: 'break-word',
        },
    },
}));

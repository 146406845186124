import { wrapStyles } from 'hooks/useClasses/useClasses';

const zIndexValue = '100!important';
const styles = wrapStyles((theme) => ({
    chart: {
        height: '575px',
        width: '100%',
    },
    chartOptions: {
        '& .apexcharts-legend-series': {
            cursor: 'pointer!important',
        },
        '& .apexcharts-pan-icon.apexcharts-selected': {
            '&>svg': {
                stroke: `${theme.palette.primary[400]}!important`,
            },
        },
        '& .apexcharts-zoom-icon.apexcharts-selected': {
            '&>svg': {
                fill: `${theme.palette.primary[400]}!important`,
            },
        },
        position: 'relative',
    },
    chartPrinting: {
        height: '550px',
        width: '1020px',
    },
    disabledSwitchOption: {
        color: theme.palette.secondary[400],
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        opacity: 1,
        textAlign: 'left',
    },
    downloadIcon: {
        '& svg': {
            fill: '#6e8192',
        },
        '&:hover svg': {
            fill: theme.palette.common.black,
        },
        minWidth: '20px',
        width: 'unset',

    },
    fullscreen: {
        height: '85%',
        position: 'absolute',
        width: '100%',
    },
    howToZoomPic: {
        '@media (max-width: 700px)': {
            width: 'calc(100vw - 16px)',
        },
    },
    middle: {
        margin: '0px',
    },
    msg: {
        alignItems: 'center',
        display: 'flex',
    },
    noData: {
        backgroundColor: theme.palette.common.white,
    },
    overlay: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexGrow: 1,
        height: '75%',
        justifyContent: 'center',
        width: '90%',
        zIndex: zIndexValue,
    },
    refreshIcon: {
        marginTop: '5px',
        transform: 'scale(1.1)',
    },
    sizeOverrideContainer: {
        '&::after': {
            background: theme.palette.common.white,
            height: '100%',
            position: 'absolute',
            width: '100%',
        },
        maxHeight: '300px',
        maxWidth: '400px',
        position: 'relative',
        visibility: 'hidden',
    },
    switchContainer: {
        display: 'flex',
        justifyContent: 'end',
    },
    switchLabel: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        marginRight: '13px',
        opacity: 1,
        textAlign: 'left',
    },
    switchOption: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        opacity: 1,
        textAlign: 'left',
    },
    tooltip: {
        background: `${theme.palette.common.white}!important`,
        border: '1px solid #e3e3e3!important',
        borderRadius: '4px!important',
        overflow: 'hidden!important',
        transition: 'none!important',
    },
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
    },
}));

export default styles;

import useClasses from 'hooks/useClasses';
import React, { useContext, useMemo, CSSProperties } from 'react';
import rootStyles from 'LaneManagement/LaneDetails/LaneDetails.style';
import LaneCardsContext from 'Contexts/LaneCardsContext';
import Card from 'shared-components/Card';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { Milestones } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import moment from 'moment';
import { styles } from './TransportCard.style';
import FlowElement from './FlowElement';

type Props = {
    className?: string,
    offsetPadding?: [number, number],
    progress?: number,
    showNow?: boolean,
    shrink?: boolean,
    stepsRaw: Milestones[]
};

export const sumDurations: (arg: any[]) => number = (durations) => {
    return Math.floor(durations?.flat()?.map((it) => it?.duration).reduce((a, b) => a + b, 0));
};

export type ProcessedMilestone = Milestones & {
    end: number,
    start: number
}
const TransportCard = ({
    className = '',
    offsetPadding = [0, 0],
    progress = 0,
    showNow = false,
    shrink = false,
    stepsRaw,
}: Props) => {
    const rootClasses = useClasses(rootStyles);
    const classes = useClasses(styles);

    const {
        editingCard,
    } = useContext(LaneCardsContext);
    const theme = useTheme<SkycellThemeInterface>();
    const processedMilestones = useMemo<ProcessedMilestone[]>(() => stepsRaw.map(step => {
        const pickup = step.progress.actualPickup
            || step.progress.plannedPickup;
        const delivery = step.progress.actualDelivery
            || step.progress.plannedDelivery;
        const start = moment.utc(pickup, 'YYYY-MM-DDTHH:mm:ss').valueOf();
        const end = moment.utc(delivery, 'YYYY-MM-DDTHH:mm:ss').valueOf();

        return {
            ...step,
            end,
            start,
        };
    }), [stepsRaw]);

    const hasUnloadingStep = useMemo(() => !!processedMilestones?.find((it) => it.type === 'UNLOADING'),
        [processedMilestones]);
    const [
        globalStart,
        globalEnd,
    ] = useMemo(() => {
        return [
            processedMilestones?.[0]?.start,
            processedMilestones?.[processedMilestones.length - 1]?.end,
        ];
    }, [processedMilestones]);
    const now = useMemo(() => moment.utc().valueOf(), []);
    const currentProgress = useMemo(() => (progress || showNow ? (
        (now - globalStart)
        / (globalEnd - globalStart)) * 100
        : 0), [progress, globalStart, globalEnd, now]);

    const rectPosition = useMemo<CSSProperties>(() => {
        return {
            background: theme.palette.common.white,
            border: `2px solid ${theme.palette.primary[400]}`,
            borderRadius: 4,
            height: 29,
            left: `calc(${Math.min(Math.max(currentProgress, 0), 100)}% - ${6}px)`,
            position: 'absolute',
            top: -5,
            width: 12,
            zIndex: 5,
        };
    }, [currentProgress]);

    const sum = useMemo(() => (globalEnd
        - globalStart) / 1000, [globalEnd, globalStart]);

    return (
        <Card
            className={shrink ? classes.noShadow : ''}
            contentClass={className}
            disabled={!!editingCard}
        >
            {
                processedMilestones && (
                    <div
                        className={[rootClasses.cardBody, classes.transportFlow].join(' ')}
                        style={{
                            paddingLeft: offsetPadding[0],
                            paddingRight: offsetPadding[1],
                        }}
                    >
                        <div className={classes.transportFlowRelative}>
                            <div style={rectPosition} />
                            {
                                processedMilestones.map(step => {
                                    return (
                                        <React.Fragment key={`step_transport_${step.index}`}>
                                            <FlowElement
                                                active={step.progress.status === 'COMPLETED'
                                                    || (step.progress.status !== 'NOT_STARTED' && now > step.end)}
                                                shrink={shrink}
                                                step={step}
                                                sum={sum}
                                            />
                                        </React.Fragment>
                                    );
                                })
                            }
                        </div>
                        {(!hasUnloadingStep && !shrink) && (
                            <FlowElement
                                shrink={shrink}
                                sum={sum}
                                tbd
                            />
                        )}
                    </div>
                )
            }
        </Card>
    );
};

export default TransportCard;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles(theme => ({
    active: {
        background: theme.palette.primary.darkBlue,
    },
    anim_appearLinkKeyframes: {
        from: {
            height: '0px',
            opacity: 0,
        },
        to: {
            height: '32px',
            opacity: 1,
        },
    },
    anim_appearTitleKeyframes: {
        from: {
            width: '0%',
        },
        to: {
            width: '100%',
        },
    },
    floatingItem: ({ classes }) => ({
        '& span': {
            display: 'flex',
            paddingRight: '6px',
        },
        '& svg': {
            fontSize: '14px',
            rotate: '180deg',
        },
        '&:hover': {
            background: theme.palette.primary.deepBlue,
        },
        '&:last-child': {
            borderRadius: '0 0 10px 10px !important',
        },
        alignItems: 'center',
        animation: `${classes.anim_appearLinkKeyframes} 50ms ease forwards`,
        background: theme.palette.primary.darkBlue,
        color: theme.palette.common.white,
        display: 'flex',
        fontSize: '14px',
        height: '0px',
        justifyContent: 'space-between',
        marginLeft: '10px',
        opacity: 0,
        paddingLeft: '14px',
        textDecoration: 'none',
    }),
    floatingMenu: {
        left: '100%',
        minWidth: '200px',
        position: 'absolute',
        top: '0px',
    },
    title: ({ classes }) => ({
        alignItems: 'center',
        animation: `${classes.anim_appearTitleKeyframes} 100ms ease forwards`,
        background: theme.palette.primary.darkBlue,
        borderRadius: '0 10px 0 0 !important',
        color: theme.palette.common.white,
        display: 'flex',
        fontSize: '14px',
        height: '42px',
        paddingLeft: '14px',
        textTransform: 'uppercase',
        width: '0%',
    }),
}));

export default styles;

import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import Close from '@mui/icons-material/Close';
import { CheckboxOption as CheckboxOptionType } from 'shared-components/dataTypes';
import { CheckboxOption } from 'shared-components/SideFilter';
import useClasses from 'hooks/useClasses';
import CustomListbox, { CustomListboxProps } from './components/CustomListbox';
import styles from './AutoCompleteSearch.style';

interface AutoCompleteSearchProps {
    className?: string;
    filterOptions: { [optionsGroupKey: string]: string[] },
    options: CheckboxOptionType[];
    optionsGroupKey: string;
    placeholder?: string;
    setFilterOptions: Dispatch<SetStateAction<{ [optionsGroupKey: string]: string[] }>>,
}

const AutoCompleteSearch = ({
    className,
    filterOptions,
    options,
    optionsGroupKey,
    placeholder = '',
    setFilterOptions,
}: AutoCompleteSearchProps) => {
    const classes = useClasses(styles);

    const selectedOptions = useMemo(() => {
        return options.filter(option => filterOptions[optionsGroupKey]?.includes(option.value));
    }, [filterOptions, options, optionsGroupKey]);

    const handleSelectionChange = useCallback((_, newValue: CheckboxOptionType[]) => {
        setFilterOptions(prev => ({
            ...prev,
            [optionsGroupKey]: newValue.map(option => option.value),
        }));
    }, [optionsGroupKey, setFilterOptions]);

    const handleDeleteOption = useCallback((optionToDelete: CheckboxOptionType) => {
        setFilterOptions(prev => ({
            ...prev,
            [optionsGroupKey]: prev[optionsGroupKey]?.filter(value => value !== optionToDelete.value),
        }));
    }, [optionsGroupKey, setFilterOptions]);

    const handleSelectDeselectAll = useCallback(() => {
        if (!options || options.length === 0) return;

        if (selectedOptions.length > 0) {
            setFilterOptions(prev => ({
                ...prev,
                [optionsGroupKey]: [],
            }));
        } else {
            setFilterOptions(prev => ({
                ...prev,
                [optionsGroupKey]: options.map(option => option.value),
            }));
        }
    }, [options, optionsGroupKey, selectedOptions, setFilterOptions]);

    return (
        <Autocomplete
            ListboxComponent={CustomListbox}
            ListboxProps={{ onSelectDeselectAll: handleSelectDeselectAll } as CustomListboxProps}
            className={className}
            clearOnBlur={false}
            disableCloseOnSelect
            disabled={options.length === 0}
            filterOptions={createFilterOptions({
                ignoreCase: true,
            })}
            getOptionLabel={(option) => option.label}
            handleHomeEndKeys
            multiple
            options={options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    className={classes.multipleSelectionInput}
                    placeholder={selectedOptions.length === 0 && placeholder ? placeholder : ''}
                    variant="standard"
                />
            )}
            renderOption={(props, option) => {
                return (
                    <ListItem {...props} key={option.value} className={classes.optionList}>
                        <CheckboxOption
                            checked={filterOptions[optionsGroupKey]?.includes(option.value)}
                            count={option.count}
                            label={option.label}
                            optionKey={option.value}
                            optionsGroupKey={optionsGroupKey}
                            setCheckboxGroupOptions={setFilterOptions}
                        />
                    </ListItem>
                );
            }}
            renderTags={(value, getTagProps) => value.map((option, index) => {
                return (
                    <Chip
                        {...getTagProps({ index })}
                        key={option.value}
                        classes={{
                            deleteIcon: classes.deleteIcon,
                            label: classes.chipLabel,
                            root: classes.chipRoot,
                        }}
                        deleteIcon={(
                            <Close sx={{
                                color: '#707070',
                                fontSize: '14px!important',
                            }}
                            />
                        )}
                        label={option.label}
                        size="small"
                        onClick={() => handleDeleteOption(option)}
                        onDelete={() => handleDeleteOption(option)}
                    />
                );
            })}
            selectOnFocus
            size="small"
            value={selectedOptions}
            onChange={handleSelectionChange}
        />
    );
};

export default AutoCompleteSearch;

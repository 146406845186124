import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    cardContentData: {
        width: '100%',
    },
    wrapper: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: 'minmax(315px, 1fr) 1fr minmax(315px, 1fr) 3fr',
        margin: 'auto',
        padding: '12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xl')]: {
        },
        [theme.breakpoints.down('lg')]: {
        },
    },
}));

export default styles;

import React, {
    createContext,
    CSSProperties,
    Dispatch,
    SetStateAction,
    useMemo,
    useState,
    useCallback,
    useRef, RefObject, useEffect, useContext,
} from 'react';
import { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import useGetCommonData from 'hooks/useGetCommonData';
import useScreenSize from 'hooks/useScreenSize';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import { SvgWithCutouts } from './generateSvgWithCutouts';
import { QuickHelpPage } from './QuickHelpPage';
import HelloModal from './HelloModal';
import QuickHelpElement from './QuickHelpElement';

export interface QuickHelpContextInterface {
    currentStep?: number,
    enableGuide: boolean,
    enableTooltips: boolean,
    hasOnboardingTour: boolean,
    hasUserGuide: boolean,
    onBoardingRef: RefObject<HTMLDivElement> | null,
    onboardingTooltipInfo: QuickHelpTooltipInfo | null,
    setEnableGuide: Dispatch<SetStateAction<boolean>>,
    setEnableTooltips: Dispatch<SetStateAction<boolean>>,
    setHasOnboardingTour: Dispatch<SetStateAction<boolean>>,
    setHasUserGuide: Dispatch<SetStateAction<boolean>>,
    setOnboardingTooltipInfo: Dispatch<SetStateAction<QuickHelpTooltipInfo>>,
    setTooltipInfos: Dispatch<SetStateAction<{ [key: string]: QuickHelpTooltipInfo; }>>
}

export const QuickHelpContext = createContext<QuickHelpContextInterface>({
    currentStep: 0,
    enableGuide: false,
    enableTooltips: false,
    hasOnboardingTour: false,
    hasUserGuide: false,
    onBoardingRef: null,
    onboardingTooltipInfo: null,
    setEnableGuide: () => false,
    setEnableTooltips: () => false,
    setHasOnboardingTour: () => {},
    setHasUserGuide: () => false,
    setOnboardingTooltipInfo: () => {},
    setTooltipInfos: () => false,
});

const backStyle: CSSProperties = {
    background: 'transparent',
    position: 'fixed',
    transition: 'opacity 200ms ease',
    zIndex: 10000,
};

export enum ONBOARDING_TYPE {
    ASSET = 'ASSET',
    COMPANY = 'COMPANY',
    LOGGER = 'LOGGER',
    SHIPMENT = 'SHIPMENT',
    SITE = 'SITE',
    USER = 'USER'
}

// Add this hook to single pages to show onboarding tour
export const useHasOnboardingTour = () => {
    const {
        setHasOnboardingTour,
    } = useContext(QuickHelpContext);

    useEffect(() => {
        setHasOnboardingTour(true);
        return () => setHasOnboardingTour(false);
    }, []);
};

const STOP_VALUE: number = 127;
const QuickHelpContextProvider = ({ children }) => {
    const [enableTooltips, setEnableTooltips] = useState<boolean>(false);
    const [enableGuide, setEnableGuide] = useState<boolean>(false);
    const [hasUserGuide, setHasUserGuide] = useState<boolean>(false);
    const [hasOnboardingTour, setHasOnboardingTour] = useState<boolean>(false);
    const onBoardingRef = useRef(null);
    const [tooltipInfos, setTooltipInfos] = useState<{
        [key: string]: QuickHelpTooltipInfo
    }>({});
    const { t } = useCustomTranslation();
    const [order, setOrder] = useState(-1);
    const onboardingType = useMemo(() => Object
        .values(tooltipInfos)?.find(it => it.order && it.core)?.core, [tooltipInfos]);
    const [loading, setLoading] = useState<boolean>(false);
    const {
        height, scale, width,
    } = useScreenSize();
    const hasAccess = useHasAccess();
    const isAdmin = useMemo(() => hasAccess(userRoles.SKYCELL_ADMIN), [hasAccess]);
    const [onboardingTooltipInfo, setOnboardingTooltipInfo] = useState<QuickHelpTooltipInfo | null>(null);
    const onBoardingStatus = useGetCommonData<string>(`onboarding-tour/${onboardingType}`, {
        enabled: !!onboardingType,
        postProcess: data => data?.state,
        query: {},
    });

    const {
        Patch: updateOnboardingStatus,
    } = useSkymindBackendEndpoints('onboarding-tour').requests;
    const steps = useMemo(() => Array.from(
        new Set(
            Object.values(tooltipInfos || {}).map(tooltipInfo => tooltipInfo.order).filter(Boolean),
        )).length, [tooltipInfos]);

    useEffect(() => {
        if (onBoardingStatus.data === 'NON_COMPLETED') {
            setEnableTooltips(true);
        }
    }, [onBoardingStatus.data === 'NON_COMPLETED']);
    useEffect(() => {
        setOrder(enableTooltips ? 0 : -1);
    }, [enableTooltips]);
    const handleNext = useCallback(() => {
        setOrder(prev => (prev === steps ? STOP_VALUE : prev + 1));
    }, [steps]);
    const handlePrev = useCallback(() => {
        setOrder(prev => prev - 1);
    }, []);
    const handleStart = useCallback(() => {
        if (steps > 0) {
            setOrder(steps > 0 ? 1 : STOP_VALUE);
        }
    }, [steps]);
    const handleFinish = useCallback(async () => {
        setLoading(true);
        if (onBoardingStatus?.data === 'COMPLETED') {
            setLoading(false);
            setEnableTooltips(false);
        } else {
            try {
                await updateOnboardingStatus(`${onboardingType}/complete`);
            } catch (e) {
                global.console.error(e);
            } finally {
                setLoading(false);
                setEnableTooltips(false);
            }
        }
    }, [onboardingType, onBoardingStatus]);

    const onboardingRect: DOMRect | null = useMemo(() => {
        const rect = onBoardingRef.current?.getBoundingClientRect();

        if (rect) {
            return rect;
        }
        return null;
    }, [onBoardingRef.current, width, height]);
    const guideBackStyle = useMemo<CSSProperties>(() => ({
        cursor: enableTooltips ? 'pointer' : 'none',
        opacity: enableTooltips ? 1 : 0,
        pointerEvents: enableTooltips ? 'all' : 'none',
        ...backStyle,
    }), [enableTooltips]);
    const knowledgeBaseBackStyle = useMemo<CSSProperties>(() => ({
        cursor: enableGuide ? 'pointer' : 'none',
        opacity: enableGuide ? 0.3 : 0,
        pointerEvents: enableGuide ? 'all' : 'none',
        ...backStyle,
        background: 'rgba(0, 0, 0, 0.6)',
        height: '100vh',
        width: '100vw',
    }), [enableGuide]);

    const firstRect = useMemo(() => {
        return Object.values(tooltipInfos || {}).sort((a, b) => (
            a.order || 0) - (b.order || 0))
            .filter(it => it.order)?.at(0)?.childRef.current?.getBoundingClientRect();
    }, [tooltipInfos, width, height]);
    const lastRect = useMemo(() => {
        return Object.values(tooltipInfos || {}).sort((a, b) => (
            a.order || 0) - (b.order || 0))?.at(-1)?.childRef.current?.getBoundingClientRect();
    }, [tooltipInfos, width, height]);
    const handleBackgroundClick = useCallback(() => {
        setEnableGuide(false);
    }, []);
    const currentOrderTooltips = useMemo(() => (
        enableGuide ? [] : Object.values(tooltipInfos || {}).filter(tooltipInfo => tooltipInfo.order === order)
    ), [tooltipInfos, enableGuide, order]);
    const currentMainTooltip = useMemo(() => currentOrderTooltips
        .find(it => it.isMain) || currentOrderTooltips?.[0], [currentOrderTooltips]);

    return (
        <QuickHelpContext.Provider value={{
            currentStep: order,
            enableGuide,
            enableTooltips,
            hasOnboardingTour,
            hasUserGuide,
            onBoardingRef,
            onboardingTooltipInfo,
            setEnableGuide,
            setEnableTooltips,
            setHasOnboardingTour,
            setHasUserGuide,
            setOnboardingTooltipInfo,
            setTooltipInfos,
        }}
        >
            { isAdmin ? <></> : (
                <>
                    <div
                        style={knowledgeBaseBackStyle}
                        onClick={handleBackgroundClick}
                    />
                    <SvgWithCutouts
                        screenSize={{
                            height,
                            scale,
                            width,
                        }}
                        style={guideBackStyle}
                        tooltipInfos={currentOrderTooltips}
                        onClick={handleBackgroundClick}
                    />

                    <HelloModal
                        close={handleFinish}
                        open={enableTooltips && order === 0}
                        screenSize={{
                            height,
                            scale,
                            width,
                        }}
                        shrinkFrom={order === 1 ? firstRect : onboardingRect}
                        start={handleStart}
                        subTitle={t('ONBOARDING.COMMON.WELCOME_SUBTITLE')}
                        text={t('ONBOARDING.COMMON.WELCOME_DESC')}
                        title={t('ONBOARDING.COMMON.WELCOME')}
                    />
                    <HelloModal
                        close={handleFinish}
                        isSuccess
                        loading={loading}
                        open={enableTooltips && order === STOP_VALUE}
                        screenSize={{
                            height,
                            scale,
                            width,
                        }}
                        shrinkFrom={order === steps ? lastRect : onboardingRect}
                        start={handleStart}
                    />
                    <QuickHelpPage />
                </>
            )}
            {children}
            { order > 0 && order !== STOP_VALUE && (
                <QuickHelpElement
                    allTooltips={currentOrderTooltips}
                    handleNext={handleNext}
                    handlePrevious={order > 1 ? handlePrev : null}
                    handleSkip={handleFinish}
                    handleStep={(step) => setOrder(step)}
                    order={order}
                    screenSize={{
                        height,
                        scale,
                        width,
                    }}
                    steps={steps}
                    tooltipInfo={currentMainTooltip}
                />
            )}
        </QuickHelpContext.Provider>
    );
};

export default QuickHelpContextProvider;

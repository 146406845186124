import { LatLng } from 'dataTypes/common';
import useClasses from 'hooks/useClasses';
import React, { useCallback, useState } from 'react';
import { OVERLAY_MOUSE_TARGET, OverlayViewF } from '@react-google-maps/api';
import ClusterBodyItem
    from 'TrackAndTrace/GenericShipments/components/ShipmentsMap/components/TooltipCluster/ClusterBodyItem';
import { ActiveShipment } from 'TrackAndTrace/GenericShipments/components/ShipmentsMap/ShipmentsMap';
import { ClusterInfo, GenericShipmentData } from 'TrackAndTrace/GenericShipments/lib';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import styles from './TooltipCluster.style';

interface ActiveCluster {
    clusterId: number,
    shipmentNumbers: string[],
    timestamp: number
}

type Props = {
    activeCluster?: ActiveCluster,
    activeShipment?: ActiveShipment,
    closeActiveTooltip?: Function,
    cluster?: ClusterInfo,
    position?: LatLng,
    setActiveCluster?: (activeCluster: ActiveCluster | React.SetStateAction<ActiveCluster>) => void,
    setActiveShipment?: Function,
    shipments?: GenericShipmentData[]
}

export const CIRCLE_GROW = 10;
export const CIRCLE_SIZE = 35;

const getClusterPositionOffset = () => ({
    x: -(CIRCLE_GROW + CIRCLE_SIZE) / 2,
    y: -(CIRCLE_GROW + CIRCLE_SIZE) / 2,
});

const TooltipCluster = ({
    activeCluster,
    activeShipment,
    closeActiveTooltip,
    cluster,
    position = null,
    setActiveCluster,
    setActiveShipment,
    shipments = [],
} : Props) => {
    const classes = useClasses(styles);
    const hasAccess = useHasAccess();
    const [isFrontElement, setFrontElement] = useState(false);
    const showTooltip = useCallback(() => {
        setActiveCluster(() => {
            return ({
                clusterId: cluster.id,
                shipmentNumbers: cluster.externalIds,
                timestamp: Date.now(),
            });
        });
        setFrontElement(true);
    }, [cluster, activeCluster]);
    const hideTooltip = useCallback(() => {
        setFrontElement(false);
        closeActiveTooltip();
        setActiveCluster({
            clusterId: null,
            shipmentNumbers: [],
            timestamp: Date.now(),
        });
    }, [closeActiveTooltip, activeCluster]);

    const stopPropagation = useCallback((e) => e.stopPropagation(), []); // disable double click zoom on tooltip

    return (
        <>
            <OverlayViewF
                getPixelPositionOffset={getClusterPositionOffset}
                mapPaneName={OVERLAY_MOUSE_TARGET}
                position={position || cluster.position}
                zIndex={isFrontElement ? 1002 : 1000}
            >
                <div
                    className={[
                        classes.wrapper,
                    ].join(' ')}
                    onClick={showTooltip}
                    onDoubleClick={stopPropagation}
                    onMouseEnter={showTooltip}
                    onMouseLeave={hideTooltip}
                >
                    <div
                        className={[
                            classes.tooltip,
                            activeCluster?.clusterId === cluster?.id
                                ? cluster?.hasWarning
                                    ? classes.activeWarning : classes.active : '',
                            (cluster?.externalIds.includes('CT-023321') || cluster?.hasPotentialWarning)
                                && !cluster?.hasWarning
                                ? classes.tooltipPredicted : cluster?.hasWarning ? classes.tooltipWarning : '',
                            (cluster?.hasBreach && hasAccess(userRoles.INTELLIGENT_MONITORING) && !cluster?.hasWarning)
                                ? classes.tooltipPredicted : '',
                        ].join(' ')}
                        style={{ zIndex: isFrontElement ? 100 : 0 }}
                    >
                        {cluster?.externalIds?.length || 1}
                    </div>
                    {
                        activeCluster?.clusterId === cluster?.id && (
                            <div
                                className={classes.clusterBody}
                            >
                                {
                                    cluster?.externalIds?.map(externalId => (
                                        <ClusterBodyItem
                                            key={externalId}
                                            activeShipment={activeShipment}
                                            setActiveShipment={setActiveShipment}
                                            shipment={shipments.find(it => it.externalId === externalId)}
                                        />
                                    ))
                                }
                            </div>

                        )
                    }
                </div>
            </OverlayViewF>
        </>

    );
};

export default TooltipCluster;

import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme) => ({
    button: {
        margin: '9px',
        textTransform: 'uppercase',
    },
    buttonWrapper: {

    },
    card: {
        boxShadow: '0px 1px 3px #00000033',
    },
    description: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.46px',
    },
    error: {
        color: theme.palette.common.red,
    },
    message: {
        alignItems: 'center',
        color: theme.palette.primary[400],
        display: 'flex',
        font: 'normal normal 100 36px/43px Roboto',
        letterSpacing: '0.46px',
    },
    root: {
        display: 'grid',
        gridTemplateColumns: '5fr 1fr',
    },
    textWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

    },
}));

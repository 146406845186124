import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles(() => ({
    disabled: {
        '& *::after': {
            content: '""',
        },
        '& *::before': {
            content: '""',
        },
        pointerEvents: 'none',
    },
    fieldWrapper: {
        '& .flag-dropdown': {
            display: 'none',
        },
        '& .special-label': {
            display: 'none',
        },
        '& input.form-control': {
            paddingLeft: '2px',
        },
        '& input.invalid-number': {
            background: '#FFE5E5 0% 0% no-repeat padding-box',
            borderRadius: '3px',
            opacity: 1,
        },

        '&:after': {
            backgroundColor: 'rgba(0, 0, 0, 0.42)',
            bottom: 0,
            content: "''",
            height: '1px',
            left: 0,
            position: 'absolute',
            transition: 'height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            width: '100%',
        },

        '&:hover:after': {
            backgroundColor: 'rgba(0, 0, 0, 0.87)',
            height: '2px',
        },

        position: 'relative',
    },
    input: {
        border: 'none',
        outline: 'none',
        width: '100%',
    },
    noMargin: {
        margin: 0,
    },
}));

export default styles;

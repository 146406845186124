import { ColumnsType } from 'shared-components/Table/dataTypes';
import { TFunction } from 'i18next';

const tableColumns: (t: TFunction) =>ColumnsType[] = (t) => [
    {
        accessor: 'name',
        dataType: 'text',
        Header: t('COMMON.SITE'),
    },
    {
        accessor: 'city',
        dataType: 'text',
        Header: t('COMMON.CITY'),
    },
    {
        accessor: 'country',
        dataType: 'text',
        Header: t('COMMON.COUNTRY'),
    },
];

export default tableColumns;

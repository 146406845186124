import React, { forwardRef, useEffect, useRef, useState } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import useClasses, { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    selectDeselectAll: ({ selectDeselectAllHeight }) => ({
        color: theme.palette.primary.deepBlue,
        cursor: 'pointer',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: `${selectDeselectAllHeight}px`,
        padding: '0 10px',
        textAlign: 'left',
    }),
}));

export interface CustomListboxProps extends React.HTMLAttributes<HTMLUListElement> {
    onSelectDeselectAll?: () => void;
}

const VISIBLE_CHILDREN_COUNT = 5;
const OPTION_HEIGHT = 32;
const SELECT_DESELECT_ALL_HEIGHT = 32;

const CustomListbox = forwardRef<HTMLUListElement, CustomListboxProps>(({
    children,
    onSelectDeselectAll,
    ...props
}, ref) => {
    const [visibleChildren, setVisibleChildren] = useState<React.ReactNode[]>([]);
    const [loadedCount, setLoadedCount] = useState(0);
    const observerRef = useRef<IntersectionObserver | null>(null);
    const loadMoreRef = useRef<HTMLDivElement | null>(null);
    const { t } = useCustomTranslation();
    const classes = useClasses(styles, { selectDeselectAllHeight: SELECT_DESELECT_ALL_HEIGHT });

    useEffect(() => {
        setVisibleChildren(React.Children.toArray(children).slice(0, loadedCount) || []);
    }, [children, loadedCount]);

    useEffect(() => {
        if (observerRef.current) observerRef.current.disconnect();
        observerRef.current = new IntersectionObserver((entries) => {
            if (entries[0]?.isIntersecting && loadedCount < React.Children.count(children)) {
                setLoadedCount((prevCount) => prevCount + VISIBLE_CHILDREN_COUNT);
            }
        }, {
            threshold: 0.1,
        });

        if (loadMoreRef.current) {
            observerRef.current.observe(loadMoreRef.current);
        }

        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };
    }, [children, loadedCount]);

    return (
        <List
            ref={ref}
            role="listbox"
            sx={{
                maxHeight: `${onSelectDeselectAll
                    ? VISIBLE_CHILDREN_COUNT * OPTION_HEIGHT + SELECT_DESELECT_ALL_HEIGHT
                    : VISIBLE_CHILDREN_COUNT * OPTION_HEIGHT}px !important`,
                overflowY: 'auto',
                padding: '0 !important',
            }}
            {...props}
        >
            {
                onSelectDeselectAll && (
                    <ListSubheader className={classes.selectDeselectAll} onClick={onSelectDeselectAll}>
                        {t('COMMON.SELECT_DESELECT_ALL')}
                    </ListSubheader>
                )
            }
            <div />
            {visibleChildren}
            <div
                ref={loadMoreRef}
                style={{ height: React.Children.count(children) > VISIBLE_CHILDREN_COUNT ? 1 : 0 }}
            />
        </List>
    );
});

CustomListbox.displayName = 'CustomListbox';

export default CustomListbox;

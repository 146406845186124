import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import {
    CHART_GRAPH_COLORS,
} from 'shared-components/constants';
import { TFunction } from 'i18next';
import { hasExcursion, profileCell } from '../Packaging/tableColumns';
import { SimulationDataHolder } from '../../TemperatureSimulation';

const tableColumns: (
    profileData?: SimulationDataHolder, type?: string, t?:TFunction
) => ColumnsType[] = (profileData, type = 'winter', t) => {
    return [
        {
            accessor: (row, index) => {
                return (
                    <div
                        style={{
                            background: '#61C6E940 0% 0% no-repeat padding-box',
                            borderLeft: `6px solid ${CHART_GRAPH_COLORS[index]}`,
                            borderRadius: '5px',
                            borderRight: `6px solid ${CHART_GRAPH_COLORS[index]}`,
                            display: 'inline-flex',
                            marginLeft: '10px',
                            padding: '5px',
                        }}
                    >
                        <div>{row.temperatureSpecificName}</div>
                    </div>
                );
            },
            dataType: 'custom',
            Header: t('COMMON.PACKAGING'),
        },
        {
            accessor: (row) => {
                const seasonalData = profileData?.[row?.type]?.[type];

                return seasonalData ? profileCell(
                    hasExcursion(seasonalData),
                ) : 'None';
            },
            dataType: 'custom',
            Header: t('TEMPERATURE_STATUS.EXCURSION'),
        },
    ];
};

export default tableColumns;

import { useEffect } from 'react';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';

export const useGetSeasonalData = ({
    packagingType,
    setProfileData,
    simulationData,
}) => {
    const {
        FlexibleRequest: getTemperatureSimulationRequest,
    } = useSkymindBackendEndpoints('').requests;

    useEffect(() => {
        if (!simulationData || !packagingType) return;

        (async () => {
            try {
                let summerResult = null;
                let winterResult = null;

                if (simulationData.summer.simulationSteps) {
                    summerResult = await getTemperatureSimulationRequest(
                        'POST',
                    `/packaging-types/${packagingType.type}/temperature-simulation`,
                    simulationData.summer,
                    );
                }
                if (simulationData.winter.simulationSteps) {
                    winterResult = await getTemperatureSimulationRequest(
                        'POST',
                        `/packaging-types/${packagingType.type}/temperature-simulation`,
                        simulationData.winter,
                    );
                }

                setProfileData((prev) => ({
                    ...prev,
                    [packagingType.type]: {
                        summer: summerResult?.data || [],
                        winter: winterResult?.data || [],
                    },
                }));
            } catch (error) {
                setProfileData({});
            }
        })();
    }, [simulationData, packagingType]);
    return {

    };
};

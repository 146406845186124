import React, {
    Dispatch, SetStateAction, useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import PreviewTable from 'TrackAndTrace/commonComponents/PreviewTable';
import useGetCommonData from 'hooks/useGetCommonData';
import { AssetsDTO } from 'dataTypes/SecureBackend/apiResponse';
import CommonSearch from 'shared-components/CommonSearch';
import { getQuickHelpAsset } from 'shared-components/icons';
import { ExtractedAssetData, extractAssetData } from 'TrackAndTrace/Assets/lib';
import tableColumns from './tableColumns';

type Props = {
    assetsFromPreviewTable: ExtractedAssetData[],
    isTableView: boolean,
    query?: string,
    setAssetsFromPreviewTable: Dispatch<SetStateAction<ExtractedAssetData[]>>,
    setQuery?: Dispatch<SetStateAction<string>>
}

const SearchPreview = ({
    assetsFromPreviewTable,
    isTableView,
    query,
    setAssetsFromPreviewTable,
    setQuery,
} : Props) => {
    const { t } = useCustomTranslation();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<ExtractedAssetData[]>([]);
    const [queryCleared, setQueryCleared] = useState(false);

    const {
        data: rawAssets,
    } = useGetCommonData<AssetsDTO>('assets/search',
        {
            enabled: !isTableView && open,
            query: {
                page: 0,
                pageSize: 10,
                q: query,
            },
        });

    useEffect(() => {
        const toggle = () => setOpen(false);

        document.addEventListener('click', toggle);
        return () => {
            document.removeEventListener('click', toggle);
        };
    }, []);

    const handleFocus = () => {
        setOpen(prev => prev || query.length > 0 || assetsFromPreviewTable.length > 0);
    };
    const handleClick = useCallback((e) => e.stopPropagation(), []);

    const searchStringChanged = (keyword) => {
        setQuery(keyword);
        setQueryCleared(false);

        if (keyword.length > 0) {
            setOpen(true);

            if (isTableView) {
                setQuery(keyword);
            }
        } else {
            setOpen(false);

            if (isTableView) {
                setAssetsFromPreviewTable([]);
            }
        }
    };

    const handleClickShowResults = useCallback(() => {
        setOpen(false);
    }, []);
    const handleClickClearSearch = useCallback(() => {
        setAssetsFromPreviewTable([]);
        setOpen(false);
        setQuery('');
        setQueryCleared(true);
    }, []);

    useEffect(() => {
        if (rawAssets?.resultList) {
            const extractedAssetData = extractAssetData(rawAssets.resultList.slice(0, 50));

            setData(extractedAssetData);
            if (isTableView) {
                setAssetsFromPreviewTable(extractedAssetData);
            }
        }
    }, [rawAssets]);

    const columns = useMemo(() => tableColumns(t), [t]);

    return (
        <div>
            <QuickHelpTooltip
                tooltipInfo={{
                    image: getQuickHelpAsset('asset_search.gif'),
                    order: 1,
                    position: 'right',
                    text: t('ONBOARDING.ASSET_MANAGEMENT.SEARCH_DESC'),
                    title: t('ONBOARDING.ASSET_MANAGEMENT.SEARCH'),
                    uid: 'searchBySerial',
                }}
            >
                <CommonSearch
                    externallyCleared={queryCleared}
                    handleClickInput={handleClick}
                    handleFocusInput={handleFocus}
                    query={query}
                    setQuery={searchStringChanged}
                />
            </QuickHelpTooltip>
            {
                !isTableView && (
                    <PreviewTable
                        columns={columns}
                        data={data}
                        isCardOpen={open}
                        maskForHighlight={query}
                        rowLinkTemplate="/asset-management/assets/location/:assetNumber"
                        onCardClick={handleClick}
                        onClickButtonClearSearch={handleClickClearSearch}
                        onClickButtonShowResults={handleClickShowResults}
                    />
                )
            }
        </div>
    );
};

export default SearchPreview;

import { Collapse } from '@mui/material';
import { OverlayView } from '@react-google-maps/api';
import useCachedQueryRequest from 'hooks/useCachedQueryRequest';
import useClasses from 'hooks/useClasses';
import { wrapStyles } from 'hooks/useClasses/useClasses';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import moment from 'moment';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCoordinates, getPolylinePath } from 'shared-components/ApexTemperatureChart';
import { SENSOR_DATA_REQUEST_PARAMS } from 'shared-components/constants';
import LastConnect from 'shared-components/LastConnect';
import { ExtractedLoggerData } from 'TrackAndTrace/Loggers/lib';

type Props = {
    core?: string,
    loggerGroup: ExtractedLoggerData[],
    onMouseLeave: MouseEventHandler<HTMLDivElement>,
    setPolylinePath: Function
}
const getClusterPositionOffset = () => ({
    x: 0,
    y: 0,
});

const styles = wrapStyles((theme) => ({
    body: {
    },
    division: {
        borderTop: '1px solid #dbdbdb',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        gap: '8px',
        justifyContent: 'center',
        padding: '8px 14px',
        width: '100%',
    },
    headerText: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: '32px',
        fontWeight: 'normal',
        letterSpacing: '0.01em',
        padding: '8px 14px',
        textAlign: 'left',
        textDecoration: 'underline',
        width: 'max-content',
    },
    loggerWrapper: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: 'max-content',
        zIndex: 100,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        paddingLeft: '40px',
        width: 'max-content',
    },

}));

const LoggerTooltip = ({
    core = 'track-and-trace',
    loggerGroup = [],
    onMouseLeave,
    setPolylinePath,
}: Props) => {
    const classes = useClasses(styles);
    const [hoveredLoggerNumber, setHoveredLoggerNumber] = useState<string | null>(null);
    const {
        Get: getLoggerSensorData,
    } = useSkymindBackendEndpoints('loggers').requests;
    const {
        data: sensorData,
    } = useCachedQueryRequest({
        key: `${hoveredLoggerNumber}_sensor_data`,
        options: {
            enabled: !!hoveredLoggerNumber,
        },
        request: getLoggerSensorData.bind(null, `${
            hoveredLoggerNumber
        }/sensor-data?dataTypes=LOCATION_LATITUDE&dataTypes=LOCATION_LONGITUDE&from=${
            moment().utc().subtract(7, 'day').format('YYYY-MM-DDTHH:mm')
        }&to=${moment().utc().format('YYYY-MM-DDTHH:mm')}`),
    });
    const [firstEntry] = loggerGroup;
    const {
        lastMeasuredLatitude: lat, lastMeasuredLongitude: lng,
    } = firstEntry || {};

    const { t } = useCustomTranslation();

    useEffect(() => {
        if (hoveredLoggerNumber) {
            if (sensorData) {
                const coordinates = getCoordinates(sensorData);

                const polyline = getPolylinePath(coordinates, { max: null, min: null });

                setPolylinePath(polyline);
            }
        } else {
            setPolylinePath([]);
        }
    }, [sensorData, hoveredLoggerNumber]);

    return (
        <OverlayView
            getPixelPositionOffset={getClusterPositionOffset}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={{
                lat,
                lng,
            }}
        >

            <div
                className={classes.root}
                onMouseLeave={onMouseLeave}
            >
                {
                    loggerGroup.map((logger) => {
                        const link = `/${core}/loggers/${logger.loggerNumber}?${SENSOR_DATA_REQUEST_PARAMS}`;
                        const {
                            loggerNumber,
                        } = logger || {};

                        return (
                            <div
                                key={loggerNumber}
                                className={classes.loggerWrapper}
                            >
                                <Link to={link}>
                                    <div
                                        className={classes.headerText}
                                        onMouseEnter={() => setHoveredLoggerNumber(loggerNumber)}
                                    >
                                        {loggerNumber}
                                    </div>
                                </Link>
                                <Collapse className={classes.body} in={hoveredLoggerNumber === loggerNumber}>
                                    <div className={classes.division}>
                                        <span>{logger.loggerFamilyLabel}</span>
                                        <span>
                                            {t('COMMON.LATEST_DATA')}
                                            {': '}
                                            {logger?.lastMeasuredTempStr}
                                            {' '}
                                            <LastConnect timestampUnit={logger?.lastMeasuredTimestampUnit} />
                                        </span>
                                        <span>
                                            {t('TRACK_AND_TRACE.PATH_ON_MAP')}
                                            {' 7 Days'}
                                        </span>
                                        <span>
                                            {t('TRACK_AND_TRACE.PAIRING_STATUS')}
                                            {t(` ${logger?.pairingStatus}`)}
                                        </span>

                                    </div>
                                </Collapse>
                            </div>
                        );
                    })
                }
            </div>
        </OverlayView>

    );
};

export default LoggerTooltip;

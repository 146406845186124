import { Checkbox } from '@mui/material';
import { TFunction } from 'i18next';
import { AccessRequestDTO } from 'MyCompany/AccessRequests/lib';
import React, { Dispatch, SetStateAction } from 'react';
import CommonButton from 'shared-components/CommonButton';
import ShrinkedTextWithTooltip from 'shared-components/ShrinkedTextWithTooltip';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import StatusChip from 'shared-components/StatusChip';

const tableColumns:
    (
        t:TFunction,
        requestType: 'outgoing' | 'incoming',
        setChecked: Dispatch<SetStateAction<number[]>>,
        checked: number[],
        onButtonPress: (id: number) => void,
        theme: SkycellThemeInterface,
    ) => ColumnsType<AccessRequestDTO>[] = (
        t,
        requestType,
        setChecked,
        checked,
        onButtonPress,
        theme,
    ) => [
        ...(requestType === 'incoming' ? [{
            accessor: ({ id }) => (
                <Checkbox
                    checked={checked.includes(id)} sx={{
                        '&.Mui-checked': {
                            color: theme.palette.primary.deepBlue,
                        },
                    }}
                    onChange={(event, checked) => {
                        setChecked(
                            prev => (checked ? [...prev, id] : prev.filter(item => item !== id)),
                        );
                    }}
                />
            ),
            customWidth: '50px',
            dataType: 'custom',
            Header: ' ',
            noDivider: true,
        }] : []),
        requestType === 'outgoing' ? {
            accessor: 'dataOwnerCompanyName',
            dataType: 'text',
            Header: t('TRACK_AND_TRACE.TITLE_SHIPMENT_DATA_OWNER'),
            id: 'dataOwnerCompanyName',
        } : {
            accessor: 'requestingCompanyName',
            dataType: 'text',
            Header: t('MY_COMPANY.REQUESTING_COMPANY'),
            id: 'requestingCompanyName',
        },
        {
            accessor: 'siteName',
            dataType: 'highlightedText',
            Header: t('COMMON.SITE_NAME'),
            id: 'siteName',
        },
        {
            accessor: 'geofenceName',
            dataType: 'highlightedText',
            Header: t('COMMON.GEOFENCE_NAME'),
            id: 'geofenceName',
        },
        {
            accessor: ({ status }) => <StatusChip status={status} t={t} />,
            dataType: 'custom',
            disableSortBy: true,
            Header: t('COMMON.STATUS'),
            id: 'status',
        },
        {
            accessor: ({ id, updateComment }) => (
                <ShrinkedTextWithTooltip
                    key={`${id}_${updateComment}`}
                    maxWidth={270}
                >
                    {updateComment}
                </ShrinkedTextWithTooltip>
            ),
            customWidth: '300px',
            dataType: 'custom',
            disableSortBy: true,
            Header: t('COMMON.COMMENT'),
            id: 'comment',
            noDivider: requestType === 'incoming',
        },
        ...(requestType === 'incoming' ? [{
            accessor: ({ id }) => (
                <CommonButton onClick={() => onButtonPress(id)}>
                    Check & Decide
                </CommonButton>
            ),
            customWidth: '50px',
            dataType: 'custom',
            disableSortBy: true,
            Header: ' ',
            id: 'actions',
        }] : []),

    ];

export default tableColumns;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    a: {
        textDecoration: 'none',
    },
    linkedTableRow: {
        borderBottom: `1px solid ${theme.palette.secondary[100]}`,
        height: '41px',
        // minHeight: '30px',
        minHeight: '100%',
        width: '100%',
    },
    linkedTableRowWithPointer: {
        '&:hover': {
            cursor: 'pointer',
        },
        borderBottom: `1px solid ${theme.palette.secondary[100]}`,
        minHeight: '30px',
        verticalAlign: 'middle',
        width: '100%',
    },
}));

export default styles;

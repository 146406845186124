import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme) => ({
    action: {
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
    },
    actions: {
        display: 'flex',
        gap: '15px',
    },
    badge: {
        '&:active': {
            background: theme.palette.primary[300],
        },
        '&:hover': {
            background: theme.palette.primary[400],
        },
        background: theme.palette.primary[500],
        borderRadius: '10px',
        boxShadow: '0px 1px 3px #00000033',
        color: theme.palette.primary[30],
        cursor: 'pointer',
        padding: '6px 10px',
        transition: '200ms ease',
        userSelect: 'none',
    },
    button: {
        cursor: 'pointer',
    },
    buttonBox: {

    },
    colon: {

    },
    contentControl: {
        alignItems: 'center',
        display: 'flex',
        gap: '5px',
    },
    contentEditPane: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    },
    example: {
        background: theme.palette.primary[200],
        borderRadius: '10px',
        boxShadow: '0px 1px 3px #00000033',
        color: theme.palette.common.white,
        cursor: 'pointer',
        padding: '10px',
    },
    examples: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    key: {
        fontWeight: 'bold',
    },
    page: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        padding: '15px',
    },
    parsedMessage: {
        borderRadius: '6px',
        boxShadow: '0px 1px 3px #00000033',
        minHeight: '200px',
        padding: '15px',
        width: '100%',
    },
    shortcuts: {
        display: 'flex',
        fontSize: '15px',
        gap: '10px',
        padding: '8px',
    },
    textArea: {
        minHeight: '200px',
        width: '100%',
    },
    textAreaHighlight: {
        background: 'transparent',
        height: '100%',
        left: '0',
        padding: '19px 14px',
        pointerEvents: 'none',
        position: 'absolute',
        top: '0',
        whiteSpace: 'pre',
        width: '100%',
    },
    textAreaWrapper: {
        position: 'relative',
    },
    value: {

    },

}));

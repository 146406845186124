const skycellPrimaryPalette = {
    30: '#E0F7FF',
    50: '#C3E9F7',
    100: '#192E36',
    200: '#4790A8',
    300: '#6FCAE8',
    400: '#2C5969',
    500: '#2C5969',
    600: '#2C5969',
    700: '#4790A8',
    800: '#2C5969',
    contrastText: '#393939',
    dark: '#2C5969',
    deepBlue: '#009CCF',
    light: '#57B1CF',
    main: '#2C5969',
};

const skycellSecondaryPalette = {
    50: '#191919',
    100: '#393939',
    200: '#6B6B6B',
    300: '#747474',
    400: '#939393',
    500: '#B7B7B7',
    600: '#D7D7D7',
    700: '#DFDFDF',
    800: '#EFEFEF',
    900: '#F7F7F7',
    dark: '#B7B7B7',
    light: '#393939',
    main: '#939393',
};

const skyCellDark = {
    overrides: {
        MuiAutocomplete: {
            input: {
                background: '#393939',
                borderRadius: 4,
            },
        },
        MuiCheckbox: {
            colorPrimary: {
                '&$checked': {
                    color: '#4790A8',
                },
                color: '#6B6B6B',
            },
        },
        MuiDrawer: {
            paper: {
                zIndex: 30,
            },
        },
        MuiFormLabel: {
            root: {
                color: '#6B6B6B',
            },
        },
        MuiInput: {
            underline: {
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '2px solid #747474',
                },
                '&:not($disabled):after': {
                    backgroundColor: 'rgba(116,116,116,0.5)',
                    height: 2,
                },
                '&:not($disabled):before': {
                    backgroundColor: 'rgba(116,116,116,0.5)',
                    height: 2,
                },
                color: 'white',
            },
        },
        MuiInputBase: {
            input: {
                '&::placeholder': {
                    color: 'gray',
                },
                borderRadius: 4,
                padding: '4px 0 7px',
            },
            multiline: {
                padding: '6px 0 3px',
            },
        },
        MuiInputLabel: {
            root: {
                color: skycellSecondaryPalette[500],
            },
        },
        MuiMenuItem: {
            root: {
                '&$selected': { color: skycellSecondaryPalette[50] },
            },
        },
        MuiSlider: {
            rail: {
                color: skycellSecondaryPalette[800],
            },
        },
    },
    palette: {
        action: {
            selected: '#6FCAE8',
        },
        common: {
            black: '#FFFFFF',
            constants: {
                gray900: '#191919',
                white: '#FFF',
            },
            contrastBlue: '#AADBEC',
            contrastGray600: '#939393',
            contrastWhite: '#393939',
            contrastWhite50: '#393939',
            dividerColor: '#D7D7D7',
            green: '#17921F',
            greenText: '#c6d9c8',
            lightYellow: '#EEE172',
            lightYellowText: '#e3e1d2',
            menuTextWhite: '#DFDFDF',
            primaryHighlight30: '#6B6B6B',
            purple: '#CF3B8A',
            red: '#D44848',
            redText: '#dccbcb',
            titleBlackText: '#DFDFDF',
            white: '#000000',
            yellow: '#EDAE49',
            yellowText: '#e8e4de',
        },
        error: {
            main: '#E22C2C',
        },
        info: {
            main: '#61C6E9',
        },
        mode: 'dark',
        primary: skycellPrimaryPalette,
        secondary: skycellSecondaryPalette,
        success: {
            main: '#43A047',
        },
    },
    shape: {
        tabHeight: 41,
    },
    typography: {
        body1: {
            color: skycellSecondaryPalette[600],
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '19px',
        },
        button: {
            fontSize: '14px',
            fontWeight: 400,
            letterSpacing: 1,
            lineHeight: '14px',
        },
        caption: {
            color: skycellSecondaryPalette[500],
            fontSize: '12px',
            fontWeight: 400,
            letterSpacing: 0.4,
            lineHeight: '14px',
        },
        fontFamily: 'Roboto',
        h1: {
            color: skycellSecondaryPalette[900],
            fontSize: '36px',
            fontWeight: 200,
            lineHeight: '43px',
        },
        h2: {
            fontSize: '24px',
            fontWeight: 400,
            letterSpacing: 0.26,
            lineHeight: '36px',
        },
        h3: {
            color: skycellSecondaryPalette[900],
            fontSize: '20px',
            fontWeight: 400,
            letterSpacing: 0.26,
            lineHeight: '24px',
        },
        h4: {
            color: skycellSecondaryPalette[600],
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '19px',
        },
        h5: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: 'none',
        },
        subtitle1: {
            fontSize: '16px',
            fontWeight: 300,
        },
        title: {
            fontSize: '72px',
        },
    },

};

export default skyCellDark;

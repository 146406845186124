import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import useClasses from 'hooks/useClasses';
import { wrapStyles } from 'hooks/useClasses/useClasses';
import React, { ChangeEvent, useEffect, useState } from 'react';

const styles = wrapStyles((theme) => ({
    buttonLabel: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    checked: {},
    label: {
        marginRight: '6px!important',
    },
    radio: {
        '& .MuiSvgIcon-root': {
            height: '18px',
            width: '18px',
        },
        '&$checked': {
            backgroundColor: 'white',
            color: '#61C6E9',
            marginLeft: '12px',
            width: '10px',
        },
    },
    radioItem: {
        padding: '0 9px!important',
    },
}));

type Props = {
    availableAnswerOptions: string[],
    defaultValue?: string,
    disabled?: boolean,
    flexDirection?: 'row' | 'column',
    labels?: { [key: string]: string },
    name?: string,
    row?: boolean,
    setAnswerUpdate: (answerUpdate: string) => void,
}

const RadioButtonGroup = ({
    availableAnswerOptions = [],
    defaultValue = null,
    disabled = false,
    flexDirection = 'column',
    labels = {},
    name = 'defaultName',
    row = false,
    setAnswerUpdate = null,
} : Props) => {
    const classes = useClasses(styles);
    const [value, setValue] = useState(defaultValue);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        if (value) {
            setTimeout(() => {
                setAnswerUpdate(value);
            }, 100);
        }
    }, [value]);

    return (
        <RadioGroup
            aria-labelledby={`${name}-group`}
            defaultValue={defaultValue}
            name={name}
            row={row}
            style={
                flexDirection === 'row' ? {
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0 0 0 0',
                    marginRight: '10px',
                }
                    : {}
            }
            value={value}
            onChange={handleChange}
        >
            {
                availableAnswerOptions.map((option) => {
                    return (
                        <FormControlLabel
                            key={`${name}-${option}`}
                            className={classes.label}
                            control={(
                                <Radio
                                    className={classes.radioItem}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.radio,

                                    }}
                                    color="default"
                                    disableRipple
                                    style={{
                                        backgroundColor: 'white',
                                        color: '#61C6E9',
                                        marginLeft: '12px',
                                        marginRight: '10px!important',
                                        width: '10px',
                                    }}
                                />
                            )}
                            disabled={disabled}
                            label={(
                                <div className={classes.buttonLabel}>
                                    {labels[option] || option}
                                </div>
                            )}
                            value={option}
                        />
                    );
                })
            }
        </RadioGroup>
    );
};

export default RadioButtonGroup;

const attributeParseRegexp = /(\w+)=["']([^'"]*)['"]/g;

export const attributeParser:(text: string) =>{ [key: string]: string } = (message) => {
    const parsedAttributesArray = message.match(attributeParseRegexp);

    if (!parsedAttributesArray) return { };

    const attributesObject:{ [key: string]: string } = parsedAttributesArray
        .reduce((a, b) => {
            const [attribute] = b.matchAll(attributeParseRegexp);

            return {
                ...a,
                [attribute?.[1]]: attribute?.[2],
            };
        }, {});

    return attributesObject;
};

import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme) => ({
    location: {
        marginBottom: '4px',
        marginLeft: '14px',
        marginTop: '4px',

    },
    locationDivider: {
        '&:after': {
            background: `linear-gradient(${theme.palette.secondary[600]} 50%, ${theme.palette.common.white} 50%)`,
            backgroundRepeatY: 'repeat',
            backgroundSize: '10px 9px',
            content: '""',
            display: 'block',
            height: '100%',
            marginLeft: '24px',
            width: '1px',
        },
        flex: 1,
    },
}));

import Button from '@mui/material/Button';
import useClasses from 'hooks/useClasses';
import { wrapStyles } from 'hooks/useClasses/useClasses';
import React, { CSSProperties, forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';

const styles = wrapStyles((theme) => ({
    a: {
        '&:not(:last-child)': {
            marginRight: '18px',
        },
        alignItems: 'center',
        display: 'flex',
        textDecoration: 'none',
    },
    activeAppBarButton: {
        '&&': {
            '&:hover': {
                background: '#F7F7F7 0% 0% no-repeat padding-box',
            },
            background: '#F7F7F7 0% 0% no-repeat padding-box',
            color: theme.palette.primary.light,
        },
    },
    appBarButton: {
        background: '#FFFFFF4D 0% 0% no-repeat padding-box',
        borderRadius: '5px 5px 0px 0px',
        color: theme.palette.common['menuTextWhite'],
        font: 'normal normal medium 14px/19px Roboto',
        fontWeight: 500,
        height: '36px',
        letterSpacing: '0.84px',
        opacity: 1,
        padding: '0 22px',
        textTransform: 'capitalize',
    },
    appBarOneButton: {
        alignSelf: 'center',
        background: 'none',
        borderRadius: '0!important',
        color: theme.palette.common['menuTextWhite'],
        font: 'normal normal 300 14px/17px Roboto',
        letterSpacing: '1.25px',
        padding: '0 22px',
        textTransform: 'uppercase',
    },
    buttonTitle: {
        marginTop: '1px',
    },
    contentContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    functionalButton: {
        background: '#FFFFFF4D 0% 0% no-repeat padding-box',
        borderRadius: '5px 5px 0px 0px',
        color: theme.palette.common['menuTextWhite'],
        font: 'normal normal medium 14px/19px Roboto',
        height: '36px',
        letterSpacing: '0.84px',
        padding: '0 22px',
        textTransform: 'capitalize',
    },
    icon: {
        height: '15px',
        marginRight: '8px',
        width: '15px',
    },
}));

type Props = {
    active: boolean,
    buttonClass?: string,
    iconSrc?: string,
    iconStyle?: CSSProperties,
    isOneButton?: boolean,
    link: string,
    onClick?: Function,
    title: string
}

const AppBarButton = forwardRef<HTMLButtonElement, Props>(({
    active,
    buttonClass = '',
    iconSrc = null,
    iconStyle = {},
    isOneButton = false,
    link,
    onClick = null,
    title,
} : Props, ref) => {
    const classes = useClasses(styles);

    const ButtonComponent = useMemo(() => {
        return (
            <Button
                ref={ref}
                className={[
                    !isOneButton ? (onClick ? classes.functionalButton : classes.appBarButton) : '',
                    isOneButton ? classes.appBarOneButton : '',
                    !isOneButton && active ? classes.activeAppBarButton : '',
                    buttonClass].join(' ')}
                onClick={onClick ? () => onClick() : null}
            >
                <div className={classes.contentContainer}>
                    {
                        iconSrc && (
                            <img
                                alt={title}
                                className={classes.icon}
                                src={iconSrc}
                                style={iconStyle}
                            />
                        )
                    }
                    <div className={classes.buttonTitle}>{title}</div>
                </div>
            </Button>
        );
    }, [classes, active, iconSrc, title, isOneButton, onClick]);

    return link ? (
        <Link className={classes.a} to={link}>{ButtonComponent}</Link>
    ) : ButtonComponent;
});

AppBarButton.displayName = 'AppBarButton';

export default AppBarButton;

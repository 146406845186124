import { useCallback, useMemo, useState } from 'react';
import useGetCommonData from 'hooks/useGetCommonData';
import { User } from 'dataTypes/SecureBackend/apiResponse';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useCachedQueryRequest from 'hooks/useCachedQueryRequest';
import useEditableTableColumns,
{ FieldDefinition, ValidationCheck } from 'shared-components/useEditableTableColumns/useEditableTableColumns';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import moment, { Moment } from 'moment';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import useCustomTranslation from 'hooks/useCustomTranslation';

type Props = {
    assetId: number,
    isAdmin?: boolean,
}

export interface PairingDTO {
    changeReason?: string;
    id?: number;
    installedBy?: number;
    loggerNumber?: string;
    pairingPeriod?: {
        installedOn: string;
        removedOn: string | null;
    };
    removalReason?: string;
    removedBy?: number;
    updatedBy?: number;
    updatedOn?: string;
}
export interface Pairing {
    changeReason?: string;
    id?: number | string;
    installedBy?: number;
    installedOn?: Moment;
    loggerNumber?: string;
    removalReason?: string;
    removedBy?: number;
    removedOn?: Moment;
    updatedBy?: number;
    updatedOn?: Moment;
}

const formatDate = (timestamp: string) => moment(timestamp);
const bakePairings = (pairings: PairingDTO[]): Pairing[] => (pairings || []).map((pairing) => {
    const {
        pairingPeriod,
        updatedOn,
        ...rest
    } = pairing;

    return {
        ...rest,
        installedOn: pairingPeriod.installedOn ? formatDate(pairingPeriod.installedOn) : null,
        removedOn: pairingPeriod.removedOn ? formatDate(pairingPeriod.removedOn) : null,
        updatedOn: updatedOn ? formatDate(updatedOn) : null,
    };
}).sort((a, b) => b.installedOn?.valueOf() - a.installedOn?.valueOf());
const unbakePairing = (pairing: Pairing): PairingDTO => {
    const {
        changeReason,
        installedOn,
        loggerNumber,
        removalReason,
        removedOn,
    } = pairing;

    return {
        changeReason,
        loggerNumber,
        pairingPeriod: {
            installedOn: installedOn.utcOffset(0, true)
                .utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS'),
            removedOn: removedOn ? removedOn.utcOffset(0, true)
                .utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS') : null,
        },
        removalReason: removalReason || null,
    };
};

const removalReasons = (t) => [
    {
        label: t('LOGGER_PAIRINGS.ASSET_REMOVAL_ULD_NOT_IN_USE'),
        value: 'ASSET_REMOVAL_ULD_NOT_IN_USE',
    },
    {
        label: t('LOGGER_PAIRINGS.ASSET_REMOVAL_ULD_NEEDS_REPAIR'),
        value: 'ASSET_REMOVAL_ULD_NEEDS_REPAIR',
    }, {
        label: t('LOGGER_PAIRINGS.ASSET_REMOVAL_TAG_DAMAGED'),
        value: 'ASSET_REMOVAL_LOGGER_DAMAGED',
    },
    {
        label: t('LOGGER_PAIRINGS.ASSET_REMOVAL_TAG_BATTERY_LOW'),
        value: 'ASSET_REMOVAL_LOGGER_BATTERY_LOW',
    },
    {
        label: t('LOGGER_PAIRINGS.ASSET_REMOVAL_TAG_FALLOUT'),
        value: 'ASSET_REMOVAL_LOGGER_FALLOUT',
    },
    {
        label: t('LOGGER_PAIRINGS.ASSET_REMOVAL_LOGGER_NUMBER_INCORRECT'),
        value: 'ASSET_REMOVAL_LOGGER_NUMBER_INCORRECT',
    },
];
const fields: (...args: [
    User[],
    Pairing[],
    (key: string) => string,
]) => FieldDefinition[] = (
    users,
    assetPairings,
    t,
) => [
    {
        accessor: (original: Pairing) => original.loggerNumber,
        editable: (editedItem: Pairing) => editedItem?.id === '+',
        flushable: true,
        Header: t('COMMON.LOGGER_NUMBER'),
        id: 'loggerNumber',
        mandatory: true,
        minWidth: '200px',
        placeholder: 'Logger Number',
        type: 'loggerNumber',
        validation: (editedItem: Pairing) => {
            if (!editedItem?.loggerNumber) return t('LOGGER_PAIRINGS.THIS_FIELD_CANNOT_BE_EMPTY');
        },
    },
    {
        accessor: (original: Pairing) => original.installedOn?.format('DD.MM.YYYY HH:mm'),
        editable: editedItem => editedItem?.id === '+',
        flushable: true,
        Header: t('LOGGER_PAIRINGS.INSTALLED_ON'),
        id: 'installedOn',
        mandatory: editedItem => editedItem?.id === '+',
        minWidth: '155px',
        type: 'datetime',
        validation: (editedItem: Pairing) => {
            if (!editedItem?.installedOn) return t('LOGGER_PAIRINGS.THIS_FIELD_CANNOT_BE_EMPTY');
        },
    },
    {
        accessor: (original: Pairing) => original.removedOn?.format('DD.MM.YYYY HH:mm'),
        flushable: true,
        Header: t('LOGGER_PAIRINGS.REMOVED_ON'),
        id: 'removedOn',
        minWidth: '155px',
        type: 'datetime',
        validation: (editedItem: Pairing) => {
            if (editedItem?.removedOn && editedItem?.installedOn) {
                if (editedItem?.removedOn.valueOf() < editedItem?.installedOn.valueOf()) {
                    return t('LOGGER_PAIRINGS.REMOVAL_INSTALLATION_DATE');
                }
            }
        },
    },
    {
        accessor: (original: Pairing) => original.changeReason,
        Header: t('LOGGER_PAIRINGS.CHANGE_REASON'),
        id: 'changeReason',
        type: 'text',
    },
    {
        accessor: (original: Pairing) => removalReasons(t)
            .find(it => it?.value === original?.removalReason)?.label || '',
        Header: t('LOGGER_PAIRINGS.REMOVAL_REASON'),
        id: 'removalReason',
        mandatory: (editedItem) => {
            return !!editedItem?.removedOn;
        },
        options: removalReasons(t),
        placeholder: 'Select',
        type: 'select',
        validation: (editedItem: Pairing) => {
            if (!editedItem?.removedOn) return null;
            const original = assetPairings?.find(it => it.id === editedItem?.id);

            if (original?.removalReason) return null;
            return editedItem?.removalReason ? null : t('LOGGER_PAIRINGS.REMOVAL_REASON_IS_REQUIRED');
        },
    },
    {
        accessor: (original: Pairing) => {
            const user = users
                .find(it => Number(it.id) === original.updatedBy)?.fullName;

            return user ? `${user.firstName} ${user.lastName}` : '';
        },
        editable: false,
        Header: t('LOGGER_PAIRINGS.LAST_UPDATED_BY'),
        id: 'fullName',
        type: 'text',
    },
    {
        accessor: (original: Pairing) => original?.updatedOn?.format('DD.MM.YYYY HH:mm'),
        editable: false,
        flushable: true,
        Header: t('LOGGER_PAIRINGS.LAST_UPDATED_ON'),
        id: 'updatedOn',
        noBar: true,
        type: 'datetime',
    },
];

const useEditablePairingsColumns = ({
    assetId,
    isAdmin = false,
}: Props) => {
    const { t } = useCustomTranslation();
    const {
        FlexibleRequest: updateAssetPairing,
        GetAll: getAllAssetPairings,
    } = useSkymindBackendEndpoints(isAdmin ? `admin/assets/${assetId}/logger-pairings`
        : `assets/${assetId}/logger-pairings`).requests;
    const {
        setErrorStatus,
        setSuccessStatus,
    } = useStatusStateProcessOptions();
    const [updateFlag, setUpdateFlag] = useState(Date.now());

    const update = useCallback(() => setUpdateFlag(Date.now()), []);
    const {
        data: assetPairings = [],
    }: {
        data: Pairing[],
        isLoading: boolean
    } = useCachedQueryRequest({
        key: `assetPairings-${assetId}-${updateFlag}`,
        onCompleted: (response) => {
            return bakePairings(response.data || []);
        },
        options: {
            enabled: !!assetId,
        },
        request: getAllAssetPairings,
    });

    const {
        data: users,
    } = useGetCommonData<User[]>(isAdmin ? 'admin/users' : 'users', { postProcess: (data) => data?.resultList || [] });

    const predefinedColumns = useMemo(() => fields(
        users, assetPairings, t,
    ), [users, assetPairings]);
    const [editedItem, setEditedItem] = useState<Pairing | null>(null);
    const [hoveredItem, setHoveredItem] = useState<Pairing | null>(null);
    const validationCheck = useMemo<ValidationCheck[]>(() => {
        if (!predefinedColumns.find(it => it.validation) || !editedItem) return [];
        return predefinedColumns.filter(it => it.validation).reduce((acc:ValidationCheck[], col) => {
            const validationFn = col.validation;
            const result = validationFn(editedItem);

            if (result) {
                acc.push({
                    field: col.id,
                    message: `${col.Header}: ${result}`,
                });
            }
            return acc;
        }, []);
    }, [editedItem, predefinedColumns]);
    const onSave = useCallback(() => {
        const pairingDTO = unbakePairing(editedItem);

        (async () => {
            try {
                const response = editedItem?.id === '+' ? await updateAssetPairing('POST', '', pairingDTO)
                    : await updateAssetPairing('PATCH', `/${editedItem?.id}`, pairingDTO);

                if (response?.status === 200 || response?.status === 201) {
                    update();
                    setEditedItem(null);
                    setSuccessStatus(t('COMMON.PAIRING_SAVED'));
                }
            } catch (e) {
                setErrorStatus(e?.response?.data?.errorMessage || t('LOGGER_PAIRINGS.SOMETHING_WENT_WRONG'));
            }
        })();
    }, [editedItem]);

    // Element with id '+' is a new element
    const newField = useMemo<Pairing>(() => (editedItem?.id === '+' ? editedItem : null), [editedItem]);

    const columns: ColumnsType[] = useEditableTableColumns({
        editedItem,
        elementId: assetId,
        hoveredItem,
        isAdmin,
        onSave,
        predefinedColumns,
        setEditedItem,
        validationCheck,
    });

    return {
        assetPairings,
        columns,
        editedItem,
        newField,
        setEditedItem,
        setHoveredItem,
        users,
        validationCheck,
    };
};

export default useEditablePairingsColumns;

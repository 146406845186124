import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    energyLevel: {
        color: theme.palette.secondary[900],
        font: 'normal normal normal 16px/20px Roboto',
        letterSpacing: '0.2px',
        opacity: 1,
        textAlign: 'left',
    },
    lastConnectContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'start',
        marginBottom: 20,
    },
    tempValue: {
        color: theme.palette.secondary[900],
        font: 'normal normal normal 44px/60px Roboto',
        letterSpacing: '0.85px',
        marginBottom: 10,
        opacity: 1,
        textAlign: 'left',
    },
}));

export default styles;

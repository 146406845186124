import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    modalBody: {
        backgroundColor: theme.palette.common.contrastWhite50,
        boxShadow: '0px 1px 3px #00000033',
        left: '50%',
        outline: 'none',
        position: 'fixed',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    modalData: {
        display: 'grid',
        padding: '20px',
    },
    modalHeader: {
        alignItems: 'center',
        background: `${theme.palette.primary[200]} 0% 0% no-repeat padding-box`,
        height: '35px',
        opacity: 1,
    },
    modalHeaderText: {
        color: 'white',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        opacity: 1,
        padding: '8px',
        textAlign: 'left',
        textTransform: 'uppercase',
    },
}));

export default styles;

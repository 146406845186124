import useClasses from 'hooks/useClasses';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TitleLine from 'TrackAndTrace/GenericShipments/AddShipment/TitleLine';
import { AddCircle } from '@mui/icons-material';
import LaneCardsContext from 'Contexts/LaneCardsContext';
import { getAllowedButtons } from 'LaneManagement/LaneDetails/Cards/AddLocation/BUTTON_MAP';
import { compareLocations, hasTwoLocations } from 'LaneManagement/LaneDetails/objectDefaults';
import StepsWrapper from 'LaneManagement/LaneDetails/StepsWrapper';
import { useParams } from 'react-router-dom';
import CommonButton from 'shared-components/CommonButton';
import { LaneObject, Milestone } from 'shared-components/dataTypes';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import { getFlights } from 'LaneManagement/LaneDetails/tools';
import { defaultLaneInformationObject } from 'LaneManagement/LaneDetails/Cards/SummaryCard/fieldMapper';
import { EditingCardInfo } from 'Contexts/LaneCardsContext/LaneCardsContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import ErrorScreen from 'shared-components/ErrorScreen';
import rootStyles from 'LaneManagement/LaneDetails/LaneDetails.style';
import SummaryCard from './Cards/SummaryCard';
import Flights from './Cards/Flights';
import Products from './Cards/Products';
import AddLocation from './Cards/AddLocation';
import TransportCard from './Cards/TransportCard';
import InformationCard from './Cards/InformationCard';

type Props = {}

export type StepValidation = {
    deliveryLocation?: boolean,
    pickupLocation?: boolean
}
type PathParams = { laneId: string }

// eslint-disable-next-line no-empty-pattern
const LaneDetails = ({}: Props) => {
    const classes = useClasses(rootStyles);
    const [stepValidation, setStepValidation] = useState<StepValidation[]>([]);
    const { laneId } = useParams() as unknown as PathParams;
    const [laneData, setLaneData] = useState<LaneObject>(defaultLaneInformationObject);
    const [error, setError] = useState<{ code:string }>(null);
    const [editingCard, setEditingCard] = useState<EditingCardInfo>(null);
    const [shouldUpdateLane, setShouldUpdateLane] = useState(new Date().getTime());
    const [milestonesUpdating, setMilestonesUpdating] = useState(false);
    const [milestonesLoading, setMilestonesLoading] = useState(false);
    const {
        setErrorStatus,
    } = useStatusStateProcessOptions();
    const milestones = useMemo(() => laneData
        ?.milestones?.sort((a, b) => (a?.index > b?.index ? 1 : -1)) || [],
    [laneData]);

    const updateLane = useCallback(() => {
        setShouldUpdateLane(new Date().getTime());
    }, []);
    const setMilestones = useCallback((changer: Milestone[] | ((prevState: Milestone[]) => Milestone[])) => {
        setLaneData(prev => ({
            ...prev,
            milestones: Array.isArray(changer) ? changer : changer(prev.milestones),
        }));
    }, []);
    const { t } = useCustomTranslation();
    const currentLaneId = useMemo(() => (laneId ? Number(laneId) : null), [laneId]);
    const {
        Create: postMilestoneRequest,
        Delete: deleteMilestoneRequest,
        Patch: updateMilestoneRequest,
    } = useSkymindBackendEndpoints(`lanes/${currentLaneId}/milestones`).requests;

    useEffect(() => {
        setStepValidation(
            milestones?.map((step, index) => {
                const prevStep: Milestone = milestones?.[index - 1];
                const nextStep: Milestone = milestones?.[index + 1];

                return {
                    deliveryLocation: index === milestones.length - 1 || compareLocations(
                        step?.deliveryLocation,
                        nextStep?.pickupLocation,
                    ),
                    pickupLocation: index === 0 || compareLocations(
                        step?.pickupLocation,
                        hasTwoLocations[prevStep.type] ? prevStep?.deliveryLocation : prevStep?.pickupLocation,
                    ),
                };
            }),
        );
    }, [milestones]);

    const storeMilestones = useCallback((milestones) => {
        if (milestones) {
            setMilestones(milestones);
        }
    }, [setMilestones]);

    const createMilestone = useCallback((buttonData) => {
        (async () => {
            try {
                setMilestonesUpdating(true);
                const response = await postMilestoneRequest({
                    type: buttonData.milestoneType,
                });

                if (response.status === 201) {
                    updateLane();
                }
            } catch (error) {
                global.console.log(error);
                if (error?.response?.status === 403) {
                    setErrorStatus(error?.response?.data?.errorMessage || 'Access Denied');
                }
            } finally {
                setMilestonesUpdating(false);
            }
        })();
    }, [milestones, storeMilestones, postMilestoneRequest]);

    const deleteMilestone = useCallback((index) => {
        (async () => {
            try {
                setMilestonesUpdating(true);
                const response = await deleteMilestoneRequest(index);

                if (response.status === 204) {
                    setMilestonesUpdating(false);
                    updateLane();
                }
            } catch (error) {
                global.console.log(error);
                if (error?.response?.status === 403) {
                    setErrorStatus(error?.response?.data?.errorMessage || 'Access Denied');
                }
            } finally {
                setMilestonesUpdating(false);
            }
        })();
    }, [deleteMilestoneRequest, storeMilestones]);

    const updateMilestone = useCallback((id, milestoneData:Milestone, dataToStore) => {
        (async () => {
            try {
                setMilestonesUpdating(true);
                const response = await updateMilestoneRequest(id, { ...milestoneData });

                if (dataToStore && response.status === 200) {
                    updateLane();
                }
            } catch (error) {
                global.console.log(error);
                if (error?.response?.status === 403) {
                    setErrorStatus(error?.response?.data?.errorMessage || 'Access Denied');
                }
            } finally {
                setMilestonesUpdating(false);
            }
        })();
    }, [updateMilestoneRequest, storeMilestones]);

    const loadingLocation = useMemo(() => {
        return milestones?.length === 0
            ? null : milestones.find(it => it.type === 'LOADING')?.pickupLocation;
    }, [
        milestones,
    ]);
    const unloadingLocation = useMemo(() => {
        return milestones?.length === 0
            ? null : milestones.filter(it => it.type === 'UNLOADING')?.pop()?.pickupLocation;
    }, [
        milestones,
    ]);

    const flights = useMemo(() => {
        return getFlights(milestones);
    }, [milestones]);

    const allowedButtonsList = useMemo(() => {
        if (!milestones) return null;
        const lastMilestone = milestones?.[milestones.length - 1]?.type || null;

        return getAllowedButtons(lastMilestone, t);
    }, [milestones]);

    if (error) {
        return (
            <ErrorScreen
                text={t('TRACK_AND_TRACE.ADD_SHIPMENT.ERROR_HAPPENED')}
                onAction={() => {
                    setError(null);
                }}
            />
        );
    }
    return (
        <div style={{ padding: '12px' }}>
            <LaneCardsContext.Provider value={{
                deleteMilestone,
                editingCard,
                setEditingCard,
                setMilestonesLoading,
                setSteps: setMilestones,
                shouldUpdateLane,
                steps: milestones,
                stepValidation,
                updateLane,
                updateMilestone,
            }}
            >
                <TitleLine title={t('LANE_MANAGEMENT.LANE_INFORMATION')} />
                <div className={[
                    classes.cardRow,
                    classes.first,
                    laneData?.skyCoreInfo ? classes.cardRowWithFourColumns : '',
                ].join(' ')}
                >
                    <SummaryCard
                        laneData={laneData}
                        laneId={currentLaneId}
                        setError={setError}
                        setLaneData={setLaneData}
                    />
                    {
                        currentLaneId && (
                            <>
                                <Flights flightsData={flights} />
                                {
                                    false && (
                                        <Products
                                            laneId={currentLaneId}
                                        />
                                    )
                                }
                                {
                                    laneData?.skyCoreId && <InformationCard lane={laneData} />
                                }
                            </>
                        )
                    }
                </div>

                {
                    currentLaneId && (
                        <>
                            <TitleLine title={t('LANE_MANAGEMENT.MILESTONE_SUMMARY')} />
                            <div className={[classes.cardRow, classes.second].join(' ')}>
                                <AddLocation
                                    description={t('LANE_MANAGEMENT.ADD_LOADING_DESCRIPTION')}
                                    selectedLocation={loadingLocation}
                                    title={t('LANE_MANAGEMENT.LOADING_LOCATION')}
                                />
                                <TransportCard
                                    stepsRaw={milestones}
                                />
                                <AddLocation
                                    description={t('LANE_MANAGEMENT.ADD_UNLOADING_DESCRIPTION')}
                                    selectedLocation={unloadingLocation}
                                    title={t('LANE_MANAGEMENT.UNLOADING_LOCATION')}
                                />
                            </div>

                            <TitleLine title={t('LANE_MANAGEMENT.MILESTONE_DETAILS')} />
                            <StepsWrapper
                                laneId={laneId}
                                milestonesLoading={milestonesLoading}
                                milestonesUpdating={milestonesUpdating}
                            />
                            <div className={classes.buttonRow}>
                                {allowedButtonsList.map(buttonData => {
                                    return (
                                        <CommonButton
                                            key={`BUTTON_${buttonData.milestoneType}_${buttonData.label}`}
                                            className={[classes.button, classes.spaced].join(' ')}
                                            disabled={milestonesUpdating}
                                            onClick={() => createMilestone(buttonData)}
                                        >
                                            <AddCircle />
                                            <span className={classes.buttonText}>{buttonData.label}</span>
                                            <img
                                                alt="icon"
                                                height="24px"
                                                src={buttonData.icon}
                                                style={{ filter: 'brightness(10)' }}
                                                width="auto"
                                            />
                                        </CommonButton>
                                    );
                                })}
                            </div>
                        </>
                    )
                }
            </LaneCardsContext.Provider>
        </div>
    );
};

export default LaneDetails;

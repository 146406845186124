import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import TempRange from 'shared-components/TempRange';

const tableColumns: (t) => ColumnsType[] = (t) => [
    {
        accessor: 'customerReference',
        dataType: 'highlightedText',
        Header: t('ORDER_DETAILS.REFERENCE_PO_NUMBER'),
    },
    {
        accessor: 'mawbNumber',
        customWidth: 125,
        dataType: 'highlightedText',
        Header: t('TRACK_AND_TRACE.MAWB'),
    },
    {
        accessor: 'hawbNumber',
        customWidth: 125,
        dataType: 'highlightedText',
        Header: t('TRACK_AND_TRACE.HAWB'),
    },
    {
        accessor: (row) => <TempRange tempRange={row.temperatureRange} />,
        dataType: 'custom',
        Header: t('COMMON.TEMPERATURE'),
    },
    {
        accessor: (row) => row?.cargo.map(item => item.packaging.serialNumber)?.join(', '),
        customCellStyle: {
            maxWidth: '230px',
            whiteSpace: 'normal',
        },
        dataType: 'highlightedText',
        Header: t('COMMON.PACKAGINGS'),
    },
    {
        accessor: (row) => row?.cargo.map(item => item.packaging.jypId)?.join(', '),
        customCellStyle: {
            maxWidth: '230px',
            whiteSpace: 'normal',
        },
        dataType: 'highlightedText',
        Header: t('COMMON.JYPID'),
    },
    {
        accessor: 'originAirport',
        dataType: 'highlightedText',
        Header: t('COMMON.FROM'),
    },
    {
        accessor: 'destinationAirport',
        dataType: 'highlightedText',
        Header: t('COMMON.TO'),
    },
    {
        accessor: 'externalId',
        dataType: 'highlightedText',
        Header: t('TRACK_AND_TRACE.ORDER'),
    },
    {
        accessor: 'status',
        dataType: 'text',
        Header: t('TRACK_AND_TRACE.STATUS'),
    },
];

export default tableColumns;

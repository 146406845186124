import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme) => ({
    link: {
        '&:visited': {
            color: theme.palette.primary.deepBlue,
        },
        color: theme.palette.primary.deepBlue,
        textDecoration: 'none',
    },
}));

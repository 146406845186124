import React, {
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Switch from '@mui/material/Switch';
import { LatLng, TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import Card from 'shared-components/Card';
import PeriodSelectorWithDatePicker from 'shared-components/PeriodSelectorWithDatePicker';
import SwitchWithLabel from 'shared-components/SwitchWithLabel';
import { userRoles } from 'hooks/useHasAccess';
import useClasses from 'hooks/useClasses';
import {
    EntitySelectorItem,
    initialEntitySelectorItem,
} from '../../dataTypes';
import { Selected } from '../../lib';

import EntitySelector from '../EntitySelector/EntitySelector';
import styles from './Options.style';
import LocationHistoryMap from '../LocationHistoryMap';

const AMBIENT = 'AMBIENT';
const INTERNAL = 'INTERNAL';
const LOCATION = 'LOCATION';
const LOCATION_LATITUDE = 'LOCATION_LATITUDE';
const LOCATION_LONGITUDE = 'LOCATION_LONGITUDE';
const TEMPERATURE = 'TEMPERATURE';

type Props = {
    assetList?: EntitySelectorItem[],
    disabled?: boolean,
    entitySelectorItem?: EntitySelectorItem,
    isCurrentTime?: boolean,
    localTimezone?: boolean,
    loggerList?: EntitySelectorItem[],
    mapAvailable?: boolean,
    mapData?: {
        customFullScreenControl: () => void,
        height: string,
        mapMaximized?: boolean,
        polylines: LatLng[][],
        selected: Selected,
        widthPercent: number
    },
    requestBody?: SensorDataRequestBody,
    sampleForSearch: string,
    setEntitySelectorItem?: (entitySelectorItem: EntitySelectorItem) => void,
    setIsCurrentTime?: (isCurrentTime?: boolean) => void,
    setLocalTimezone?: (prev: SetStateAction<boolean>) => void,
    setRequestBody?: (requestBody: SensorDataRequestBody) => void,
    setSampleForSearch: (sampleForSearch: string) => void,
    setShowMap?: (showMap: boolean) => void,
    setTimeRange?: (timeRange: TimeRange) => void,
    shipmentsActivePackagingSerialNumber?: string,
    showMapInternally?: boolean,
    showTimeRange?: boolean,
    timeRange?: TimeRange
}

const Options = ({
    assetList = [],
    disabled = false,
    entitySelectorItem = initialEntitySelectorItem,
    isCurrentTime = false,
    localTimezone = false,
    loggerList = [],
    mapAvailable,
    mapData = null,
    requestBody = null,
    sampleForSearch,
    setEntitySelectorItem = null,
    setIsCurrentTime = null,
    setLocalTimezone = null,
    setRequestBody = null,
    setSampleForSearch,
    setShowMap = null,
    setTimeRange = null,
    shipmentsActivePackagingSerialNumber = null,
    showMapInternally = false,
    showTimeRange = true,
    timeRange = null,
} : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    const {
        additionalData,
        entityType,
    } = entitySelectorItem ?? {};
    const {
        lastMeasuredGeolocation = null,
        lastMeasuredTemp = null,
        lastMeasuredTempAmbient = null,
        lastMeasuredTempInternal = null,
        leaseEnd = null,
        leaseEndExpected = null,
        leaseStart = null,
        packagingsInThisShipment = [],
        shipmentEnd = null,
        shipmentStart = null,
    } = additionalData ?? {};

    const [shipmentsChosen, setChooseShipments] = useState(true);
    const [loggersChosen, setChooseLoggers] = useState(true);
    const [assetsChosen, setChooseAssets] = useState(true);

    const collectedItems = useMemo(() => {
        try {
            const loggers = loggersChosen && loggerList.length ? loggerList : [];
            const assets = assetsChosen && assetList.length ? assetList : [];

            return [
                ...loggers,
                ...assets,
            ];
        } catch (error) {
            return [];
        }
    }, [
        loggerList,
        assetList,
        shipmentsChosen,
        loggersChosen,
        assetsChosen,
    ]);

    const showInternalTemperature = useMemo(() => {
        return entityType === 'shipment' || entityType === 'packagings';
    }, [entityType, lastMeasuredTempInternal]);

    const showAmbientTemperature = useMemo(() => {
        return entityType === 'shipment' || entityType === 'packagings';
    }, [entityType, lastMeasuredTempAmbient]);

    const showTemperature = useMemo(() => {
        return entityType === 'assets' && lastMeasuredTemp !== null;
    }, [entityType, lastMeasuredTemp]);

    const showLocation = useMemo(() => {
        return entityType === 'assets';
    }, [entityType, lastMeasuredGeolocation]);

    const showGeolocation = useMemo(() => {
        if (entityType === 'assets' || entityType === 'shipment') {
            return mapAvailable && (
                lastMeasuredGeolocation !== null
                    && lastMeasuredGeolocation.latitude
                && lastMeasuredGeolocation.longitude);
        }
        if (entityType === 'loggers') {
            return mapAvailable;
        }
    }, [entityType, lastMeasuredGeolocation, mapAvailable]);

    const disabledGeolocation = useMemo(() => {
        if (entityType === 'packagings' || entityType === 'loggers' || packagingsInThisShipment.length === 0) {
            return false;
        }
        if (shipmentsActivePackagingSerialNumber === null) {
            return true;
        }

        const { lastMeasuredGeolocation = null } = packagingsInThisShipment
            .find(item => item.serialNumber === shipmentsActivePackagingSerialNumber) || {};

        return lastMeasuredGeolocation === null;
    }, [entityType, shipmentsActivePackagingSerialNumber]);

    const geolocationTitle = useMemo(() => {
        const baseTitle = entityType === 'loggers'
            ? t('COMMON.MAP')
            : t('SENSOR_DATA.GEOLOCATION');

        const noteMark = disabledGeolocation ? '¹' : '';

        return `${baseTitle}${noteMark}`;
    }, [entityType, disabledGeolocation]);

    const [temperature, setTemperature] = useState(true);
    const [internalTemperature, setInternalTemperature] = useState(true);
    const [ambientTemperature, setAmbientTemperature] = useState(true);
    const [geolocation, setGeolocation] = useState(false);
    const [location, setLocation] = useState(true);
    const [firstEntry, setFirstEntry] = useState(true);

    useEffect(() => {
        if (!firstEntry) {
            setRequestBody({
                dataTypes: [
                    ...(internalTemperature || ambientTemperature || temperature
                        ? [TEMPERATURE]
                        : []),
                    // ...(door ? [DOOR] : []),
                    ...(geolocation ? [LOCATION_LATITUDE, LOCATION_LONGITUDE] : []),
                    ...(location ? [LOCATION] : []),
                ],
                loggers: [],
                positions: [
                    ...(ambientTemperature ? [AMBIENT] : []),
                    ...(internalTemperature ? [INTERNAL] : []),
                ],
            });
            setShowMap(geolocation);
        }
    }, [
        ambientTemperature,
        geolocation,
        location,
        internalTemperature,
        temperature,
    ]);

    useEffect(() => {
        if (!mapAvailable) {
            setShowMap(false);
            setGeolocation(false);
        }
    }, [mapAvailable]);

    useEffect(() => {
        setLocalTimezone(true);
        if (firstEntry && entitySelectorItem?.entityNumber && requestBody.dataTypes.length > 0) {
            setTemperature(showTemperature || entityType === 'loggers');
            setInternalTemperature(showInternalTemperature && requestBody.positions.includes(INTERNAL));
            setAmbientTemperature(showAmbientTemperature && requestBody.positions.includes(AMBIENT));
            setGeolocation(
                requestBody.dataTypes.includes(LOCATION_LATITUDE)
                && requestBody.dataTypes.includes(LOCATION_LONGITUDE),
            );
            setLocation(requestBody.dataTypes.includes(LOCATION));
            setFirstEntry(false);
        } else if (!firstEntry || requestBody.dataTypes.length === 0) {
            if (entitySelectorItem?.entityNumber) {
                if (entityType === 'loggers' || entityType === 'assets') {
                    setTemperature(showTemperature || entityType === 'loggers');
                    setInternalTemperature(showInternalTemperature);
                    setAmbientTemperature(showAmbientTemperature);
                    setLocation(showLocation);
                } else {
                    setTemperature(false);
                    setInternalTemperature(true);
                    setAmbientTemperature(true);
                    setLocation(false);
                }
                setGeolocation(false);
                setFirstEntry(false);
            } else {
                setTemperature(false);
                setInternalTemperature(false);
                setAmbientTemperature(false);
                setGeolocation(false);
                setLocation(false);
            }
        }
    }, [entitySelectorItem]);

    const handleChangeLeaseEndExpected = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCurrentTime(event.target.checked);
    };

    const filteredOptions = useMemo(() => collectedItems, [collectedItems]);
    const handleChangeShowInMyLocalTime = useCallback(() => setLocalTimezone(prev => !prev), []);
    const handleChangeTemperature = useCallback(() => setTemperature(prev => !prev), []);
    const handleChangeInternalTemperature = useCallback(() => setInternalTemperature(prev => !prev), []);
    const handleChangeAmbientTemperature = useCallback(() => setAmbientTemperature(prev => !prev), []);
    const handleChangeGeolocation = useCallback(() => setGeolocation(prev => !prev), []);
    const handleChangeLocation = useCallback(() => setLocation(prev => !prev), []);

    return (
        <Card title={t('SENSOR_DATA.OPTIONS')}>
            <EntitySelector
                assetsChosen={assetsChosen}
                assetsCount={assetList.length}
                entitySelectorItem={entitySelectorItem}
                filteredOptions={filteredOptions}
                loggersChosen={loggersChosen}
                loggersCount={loggerList.length}
                sampleForSearch={sampleForSearch}
                setChooseAssets={setChooseAssets}
                setChooseLoggers={setChooseLoggers}
                setChooseShipments={setChooseShipments}
                setEntitySelectorItem={setEntitySelectorItem}
                setSampleForSearch={setSampleForSearch}
                setTimeRange={setTimeRange}
            />
            {
                showTimeRange
                && (<div className={classes.optionTitle}>{t('SENSOR_DATA.TIMEZONE')}</div>)
            }
            {
                showTimeRange
                    && (
                        <SwitchWithLabel
                            className={classes.timezoneSwitch}
                            disabled={disabled}
                            size="small"
                            switchFirst
                            title={t('SENSOR_DATA.SHOW_IN_UTC')}
                            value={!localTimezone}
                            onChange={handleChangeShowInMyLocalTime}
                        />
                    )
            }
            {
                entityType === 'shipment' && (
                    (shipmentStart || leaseStart) && (!shipmentEnd && !leaseEnd) && userRoles.SHIPMENT_MANAGEMENT
                ) && (
                    <div>
                        <div className={classes.optionTitle}>{t('SENSOR_DATA.SHOW_UP_TO')}</div>
                        <div className={classes.switchContainer}>
                            <div
                                className={
                                    `${classes.switchOption} ${isCurrentTime ? classes.disabledSwitchOption : ''}`
                                }
                            >
                                {t('COMMON.EXPECTED_LEASE_END')}
                            </div>
                            <Switch
                                checked={isCurrentTime}
                                color="primary"
                                disabled={leaseEndExpected === null}
                                size="medium"
                                onChange={handleChangeLeaseEndExpected}
                            />
                            <div
                                className={
                                    `${classes.switchOption} ${isCurrentTime ? '' : classes.disabledSwitchOption}`
                                }
                            >
                                {t('SENSOR_DATA.NOW')}
                            </div>
                        </div>
                    </div>
                )
            }
            {
                (entityType === 'packagings' || entityType === 'loggers' || entityType === 'assets')
                && showTimeRange && (
                    <QuickHelpTooltip
                        tooltipInfo={{
                            order: 2,
                            text: t('QUICK_HELP.SENSOR_DATA.PERIOD_SELECT'),
                            uid: 'sensorDataPeriodOptions',
                        }}
                    >
                        <div>
                            <div className={classes.optionTitle}>{t('SENSOR_DATA.PERIOD')}</div>
                            <PeriodSelectorWithDatePicker
                                maxDateRange={entityType === 'assets' ? 14 : null}
                                setTimeRange={setTimeRange}
                                timeRange={timeRange}
                            />
                        </div>
                    </QuickHelpTooltip>
                )
            }
            {
                (showInternalTemperature || showAmbientTemperature || showGeolocation
                    // || showDoor
                    || showTemperature) && (
                    <div className={classes.optionTitle}>{t('SENSOR_DATA.DATA_TYPES')}</div>
                )
            }
            {
                showTemperature && (
                    <SwitchWithLabel
                        disabled={disabled || (!ambientTemperature && !internalTemperature)}
                        size="medium"
                        switchFirst
                        title={t('COMMON.TEMPERATURE')}
                        value={temperature}
                        onChange={handleChangeTemperature}
                    />
                )
            }
            {
                showInternalTemperature && (
                    <SwitchWithLabel
                        disabled={disabled || (!ambientTemperature && !temperature)}
                        size="medium"
                        switchFirst
                        title={t('COMMON.INTERNAL_TEMPERATURE')}
                        value={internalTemperature}
                        onChange={handleChangeInternalTemperature}
                    />
                )
            }
            {
                showAmbientTemperature && (
                    <SwitchWithLabel
                        disabled={disabled || (!internalTemperature && !temperature)}
                        size="medium"
                        switchFirst
                        title={t('COMMON.AMBIENT_TEMPERATURE')}
                        value={ambientTemperature}
                        onChange={handleChangeAmbientTemperature}
                    />
                )
            }
            {
                showGeolocation && (
                    <SwitchWithLabel
                        disabled={disabled || disabledGeolocation}
                        size="medium"
                        switchFirst
                        title={geolocationTitle}
                        value={geolocation}
                        onChange={handleChangeGeolocation}
                    />
                )
            }
            {
                showLocation && entityType !== 'assets' && (
                    <SwitchWithLabel
                        disabled={disabled}
                        size="medium"
                        switchFirst
                        title={t('TRACK_AND_TRACE.LOCATION')}
                        value={location}
                        onChange={handleChangeLocation}
                    />
                )
            }
            <div
                className={classes.optionTitle}
            >
                {disabledGeolocation ? '¹Please maximize a packaging to also show geolocation.' : ''}
            </div>
            {
                showMapInternally && mapData && (
                    <>
                        <div className={classes.divider} />
                        <LocationHistoryMap
                            customFullScreenControl={mapData.customFullScreenControl}
                            height={mapData.height}
                            polylines={mapData.polylines}
                            selected={mapData.selected}
                            widthPercent={mapData.widthPercent}
                        />
                    </>
                )
            }
        </Card>
    );
};

export default Options;

import useClasses from 'hooks/useClasses';
import useHasAccess from 'hooks/useHasAccess';
import React, {
    Dispatch,
    SetStateAction,
    useMemo, useState, useEffect, useCallback, ChangeEvent,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { getFieldCheckboxData } from 'shared-components/common';
import {
    FacetDTO,
    FilterPanelCheckboxOptionsGroup,
} from 'shared-components/dataTypes';
import SideFilter, {
    FilterBlock,
    AccordionOptionsGroup,
    CheckboxOption, SwitchOptionItem, AirportOptions,
} from 'shared-components/SideFilter';
import { getTempCheckIcon } from 'shared-components/TemperatureCheckIcon/TemperatureCheckIcon';
import { Geofence } from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import styles from 'TrackAndTrace/commonComponents/SideFilterComponents.style';
import { ONBOARDING_TYPE } from 'Contexts/QuickHelpContext/QuickHelpContext';
import { getQuickHelpAsset } from 'shared-components/icons';
import { useTheme } from '@emotion/react';
import AutoCompleteSearch from 'shared-components/AutoCompleteSearch';
import SearchPreviewTable from './components/SearchPreviewTable';

type Props = {
    countsLoading: boolean,
    facets: FacetDTO[],
    filterOptions: { [optionsGroupKey: string]: string[] },
    geofences?: Geofence[],
    initialFacets: FacetDTO[],
    isTableView: boolean,
    query?: string,
    setFilterOptions: Dispatch<SetStateAction<{ [optionsGroupKey: string]: string[] }>>,
    setQuery: Dispatch<SetStateAction<string>>,
    setShowAirportsInfo: (showAirportsInfo: boolean) => void,
    showAirportsInfo: boolean
}

const isOldAirportsDestinationFilterShown = false;
const FILTERS_WITH_AUTOCOMPLETE = ['originAirports', 'destinationAirports'];

const ShipmentFilterPanel = ({
    countsLoading,
    facets,
    filterOptions,
    geofences,
    initialFacets,
    isTableView,
    query,
    setFilterOptions,
    setQuery,
    setShowAirportsInfo,
    showAirportsInfo = false,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const [loadedFacets, setLoadedFacets] = useState<FacetDTO[]>([]);
    const hasAccess = useHasAccess();
    const theme = useTheme();

    useEffect(() => {
        if (facets?.length > 0) {
            setLoadedFacets(facets);
        }
    }, [facets]);

    const renderTemperatureStatusTooltip = useCallback((status) => {
        const icon = getTempCheckIcon(status, theme, 19, true);

        return (

            <div style={{
                color: theme.palette.common.black,
            }}
            >
                <span style={{
                    alignItems: 'center',
                    display: 'flex',
                    fontSize: '16px',
                    gap: '6px',
                    marginTop: '-6px',
                }}
                >
                    <span style={{
                        display: 'inline-block',
                        height: '20px',
                        margin: '6px 3px',
                        width: '20px',
                    }}
                    >
                        {icon}
                    </span>

                    {t(`TEMPERATURE_STATUS.${status}`)}
                </span>
                {t(`TEMPERATURE_STATUS_TOOLTIP_DESCRIPTION.${status}`)}
            </div>
        );
    }, [theme]);

    const filterData: FilterPanelCheckboxOptionsGroup[] = useMemo<FilterPanelCheckboxOptionsGroup[]>(() => {
        return [
            {
                options: getFieldCheckboxData({
                    descriptionGroup: 'SHIPMENT_STATUS_TOOLTIP_DESCRIPTION',
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'statuses',
                    t,
                    tgroup: 'SHIPMENT_STATUS',
                }),
                optionsGroupKey: 'statuses',
                title: t('TRACK_AND_TRACE.TITLE_SHIPMENT_STATUS'),
            },
            // {
            //     title: t('COMMON.ENERGY'),
            //     options: getFieldCheckboxData(loadedFacets, 'energyLevelStatuses', t, 'COMMON'),
            //     optionsGroupKey: 'energyLevelStatuses',
            // },
            // {
            //     title: t('TRACK_AND_TRACE.PACKAGING_TYPE'),
            //     options: getFieldCheckboxData({
            //         facets: loadedFacets,
            //         initialFacets,
            //         pluralName: 'packagingTypeCodes',
            //         t,
            //         tgroup: 'PACKAGING_CODE_LABEL',
            //     }),
            //     optionsGroupKey: 'packagingTypeCodes',
            // },
            {
                options: getFieldCheckboxData(
                    {
                        facets: loadedFacets,
                        initialFacets,
                        pluralName: 'temperatureStatuses',
                        renderTooltip: renderTemperatureStatusTooltip,
                        t,
                        tgroup: 'TEMPERATURE_STATUS',
                    }),
                optionsGroupKey: 'temperatureStatuses',
                showFully: true,
                title: t('TRACK_AND_TRACE.TITLE_PACKAGING_STATUS'),
            },
            // {
            //     title: t('COMMON.TEMPERATURE_RANGE'),
            //     options: getFieldCheckboxData({
            //         facets: loadedFacets,
            //         initialFacets,
            //         pluralName: 'temperatureRanges',
            //     }),
            //     optionsGroupKey: 'temperatureRanges',
            // },
            {
                options: getFieldCheckboxData(
                    {
                        facets: loadedFacets,
                        initialFacets,
                        pluralName: 'originAirports',
                        t,
                    }).map((option) => {
                    if (!geofences) return option;

                    const geofence = geofences
                        .filter((it) => it.type === 'AIRPORT')
                        .find((it) => it.name.startsWith(option.value));

                    return {
                        ...option,
                        label: geofence?.name || option.label,
                    };
                }),
                optionsGroupKey: 'originAirports',
                placeholder: t('TRACK_AND_TRACE.ORIGIN_AIRPORT_FILTER_PLACEHOLDER'),
                title: t('COMMON.ORIGIN_AIRPORT'),
            },
            {
                options: getFieldCheckboxData(
                    {
                        facets: loadedFacets,
                        initialFacets,
                        pluralName: 'destinationAirports',
                        t,
                    }).map((option) => {
                    if (!geofences) return option;

                    const geofence = geofences
                        .filter((it) => it.type === 'AIRPORT')
                        .find((it) => it.name.startsWith(option.value));

                    return {
                        ...option,
                        label: geofence?.name || option.label,
                    };
                }),
                optionsGroupKey: 'destinationAirports',
                placeholder: t('TRACK_AND_TRACE.DESTINATION_AIRPORT_FILTER_PLACEHOLDER'),
                title: t('COMMON.DESTINATION_AIRPORT'),
            },
        ];
    }, [initialFacets, facets, hasAccess]);

    const handleChangeShowAirports = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowAirportsInfo(event.target.checked);
    }, []);

    const availableAirports = useMemo(() => {
        const avO = initialFacets?.find(({ filterName }) => filterName === 'originAirports')
            ?.counts?.map(it => it.filterValue);
        const avD = initialFacets?.find(({ filterName }) => filterName === 'destinationAirports')
            ?.counts?.map(it => it.filterValue);

        return {
            destinationAirports: avD?.filter(Boolean),
            originAirports: avO?.filter(Boolean),
        };
    }, [initialFacets]);

    return (

        <SideFilter
            id="CompanyPageFilter"
            tooltipInfo={{
                core: ONBOARDING_TYPE.SHIPMENT,
                image: getQuickHelpAsset('filters_shipments.gif'),
                isMain: true,
                order: 2,
                padding: 0,
                position: 'right',
                text: t('ONBOARDING.TRACK_AND_TRACE.FILTER_DESC'),
                title: t('ONBOARDING.TRACK_AND_TRACE.FILTER'),
                uid: 'shipmentClientSideFilterGeneric',
            }}
        >
            <FilterBlock>
                <div className={classes.title}>{t('TRACK_AND_TRACE.FILTERS')}</div>
                <SearchPreviewTable
                    isTableView={isTableView}
                    query={query}
                    setQuery={setQuery}
                    tooltipInfo={{
                        core: ONBOARDING_TYPE.SHIPMENT,
                        image: getQuickHelpAsset('shipment_filter.gif'),
                        order: 1,
                        position: 'right',
                        text: t('ONBOARDING.TRACK_AND_TRACE.SEARCH_DESC'),
                        title: t('ONBOARDING.TRACK_AND_TRACE.SEARCH'),
                        uid: 'getBySerialPreviewTooltip',
                    }}
                />
            </FilterBlock>
            {
                filterData.map((checkboxGroup) => {
                    const { optionsGroupKey } = checkboxGroup;
                    const handleSelectDeselect = () => {
                        setFilterOptions(prev => {
                            const { [optionsGroupKey]: selectedOptions = [] } = prev;

                            return selectedOptions?.length === 0
                                ? { ...prev,
                                    [optionsGroupKey]: facets.find(it => it.filterName === optionsGroupKey)
                                        ?.counts?.filter(it => it.filterValue !== null)
                                        ?.map(it => it.filterValue) }
                                : { ...prev, [optionsGroupKey]: [] };
                        });
                    };

                    if (FILTERS_WITH_AUTOCOMPLETE.includes(optionsGroupKey)) {
                        return (
                            <FilterBlock key={optionsGroupKey}>
                                <AccordionOptionsGroup
                                    dynamic={false}
                                    title={checkboxGroup.title}
                                >
                                    <AutoCompleteSearch
                                        className={classes.autoCompleteSearch}
                                        filterOptions={filterOptions}
                                        options={checkboxGroup.options}
                                        optionsGroupKey={optionsGroupKey}
                                        placeholder={checkboxGroup.placeholder}
                                        setFilterOptions={setFilterOptions}
                                    />
                                    <span />
                                </AccordionOptionsGroup>
                            </FilterBlock>
                        );
                    }

                    return (
                        <FilterBlock key={optionsGroupKey}>
                            <AccordionOptionsGroup
                                key={checkboxGroup.title}
                                title={checkboxGroup.title}
                                tooltipInfo={checkboxGroup.tooltipInfo}
                                onSelectDeselect={handleSelectDeselect}
                            >
                                {
                                    checkboxGroup.options
                                        ?.sort((a, b) => b.count - a.count || a.label.localeCompare(b.label))
                                        ?.map((option) => (
                                            <div
                                                key={`${checkboxGroup.title}_${option.label}`}
                                                style={{ marginBottom: '12px' }}
                                            >
                                                <CheckboxOption
                                                    key={option.value}
                                                    checked={filterOptions[optionsGroupKey]?.includes(option.value)}
                                                    count={option.count}
                                                    countsLoading={countsLoading}
                                                    customTooltip={option.customTooltip}
                                                    label={option.label}
                                                    optionKey={option.value}
                                                    optionsGroupKey={optionsGroupKey}
                                                    setCheckboxGroupOptions={setFilterOptions}
                                                    showTooltip={!!option.description || !!option.customTooltip}
                                                    shrinkNames={!checkboxGroup.showFully}
                                                    tooltipDescription={option.description}
                                                />
                                            </div>
                                        ))
                                }
                            </AccordionOptionsGroup>
                        </FilterBlock>
                    );
                })
            }
            {
                isOldAirportsDestinationFilterShown && (availableAirports?.destinationAirports?.length > 0
                    || availableAirports?.originAirports?.length > 0) && (
                    <FilterBlock>
                        <AccordionOptionsGroup title={t('LANE_MANAGEMENT.ADDRESS.AIRPORT')}>
                            {
                                !isTableView && (
                                    <SwitchOptionItem
                                        defaultChecked={showAirportsInfo}
                                        label={t('TRACK_AND_TRACE.SHOW_AIRPORTS')}
                                        showTooltip
                                        tooltipDescription={t('EXPLANATION_TOOLTIPS.SHOW_AIRPORTS')}
                                        onChange={handleChangeShowAirports}
                                    />
                                )
                            }
                            <AirportOptions
                                availableDestinationAirports={availableAirports.destinationAirports}
                                availableOriginAirports={availableAirports.originAirports}
                                selectedDestinationAirports={filterOptions['destinationAirports']}
                                selectedOriginAirports={filterOptions['originAirports']}
                                setDestinationAirports={(value) => setFilterOptions((prev) => ({
                                    ...prev,
                                    destinationAirports: value,
                                }))}
                                setOriginAirports={(value) => setFilterOptions((prev) => ({
                                    ...prev,
                                    originAirports: value,
                                }))}
                            />
                        </AccordionOptionsGroup>
                    </FilterBlock>
                )
            }
            { !isTableView
                && (
                    <FilterBlock>
                        <AccordionOptionsGroup
                            dynamic={false}
                            title={t('TRACK_AND_TRACE.DISPLAY_OPTIONS')}
                        >
                            <SwitchOptionItem
                                defaultChecked={showAirportsInfo}
                                label={t('TRACK_AND_TRACE.AIRPORTS')}
                                onChange={handleChangeShowAirports}
                            />
                        </AccordionOptionsGroup>
                    </FilterBlock>
                )}
        </SideFilter>
    );
};

export default ShipmentFilterPanel;

import useClasses from 'hooks/useClasses';
import React, { useMemo, useRef, CSSProperties, MouseEventHandler } from 'react';
import TableCell from '@mui/material/TableCell';
import { useDrag, useDrop, DropTargetMonitor, DragSourceMonitor } from 'react-dnd';
import {
    ClientSideFilterType,
    ColumnFilterType,
    ColumnFilterOptions,
} from '../../dataTypes';
import styles from './HeaderCell.style';

import SortingIcon from './SortingIcon';
import ClientSideFilter from '../ClientSideFilter';

type DragItem = {
    id: string,
    index: number,
    type: string,
}

type Props = {
    className?: string,
    clientSideFilter: ClientSideFilterType,
    columnFilterOptions?: ColumnFilterOptions,
    columnWidth?: string | number,
    customHeaderStyle?: CSSProperties,
    filterKey: string | null,
    filterType?: ColumnFilterType,
    headerData: string | number,
    headerProps: any,
    index: number,
    isSorted?: boolean,
    isSortedDesc?: boolean,
    moveColumn?: (dragIndex: number, hoverIndex: number) => void,
    noDivider?: boolean,
    onClick?: MouseEventHandler<HTMLDivElement>,
    setClientSideFilter: (clientSideFilter: ClientSideFilterType) => void,
    style?: CSSProperties
}

const HeaderCell = ({
    className = '',
    clientSideFilter,
    columnFilterOptions,
    columnWidth = null,
    customHeaderStyle = {},
    filterKey,
    filterType = null,
    headerData,
    headerProps = {},
    index,
    isSorted = false,
    isSortedDesc = false,
    moveColumn,
    noDivider,
    onClick,
    setClientSideFilter,
}: Props) => {
    const classes = useClasses(styles, { noDivider });

    const ref = useRef<HTMLDivElement>(null);
    const { key, ...rest } = headerProps || {};

    const [, drop] = useDrop({
        accept: 'column',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
                return;
            }
            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [, drag] = useDrag({
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: {
            index,
        },
        type: 'column',
    });

    drag(drop(ref));

    const style = useMemo(() => {
        const styleObject = {};

        if (columnWidth) {
            styleObject['width'] = columnWidth;
        }
        return styleObject;
    }, [columnWidth]);

    return (
        <TableCell
            key={key}
            ref={ref}
            className={[classes.headerCell, className].join(' ')}
            style={style}
            {...rest}
        >
            <div
                key={index}
                className={classes.cellContainer}
                style={customHeaderStyle}
                onClick={onClick || null}
            >
                <div
                    key={index}
                    className={classes.headerData}
                    {...rest}
                >
                    {headerData}
                </div>
                {
                    filterType && filterKey && (
                        <ClientSideFilter
                            clientSideFilter={clientSideFilter}
                            columnFilterOptions={columnFilterOptions}
                            filterKey={filterKey}
                            filterType={filterType}
                            setClientSideFilter={setClientSideFilter}
                        />
                    )
                }
                <SortingIcon
                    isSorted={isSorted}
                    isSortedDesc={isSortedDesc}
                />
            </div>
        </TableCell>
    );
};

export default HeaderCell;

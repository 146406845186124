import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    large: {
        height: '350px',
        marginLeft: '5%',
        maxWidth: 'calc(100vw - 200px)',
    },
    largeInfoField: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        marginLeft: '10%',
        marginRight: '15%',
        opacity: 1,
        textAlign: 'left',
    },
    largeRow: {
        alignItems: 'center',
        display: 'flex',
        padding: '30px 0',
    },
    medium: {
        height: '220px',
        marginLeft: '5%',
        [theme.breakpoints.down('md')]: {
            height: '200px',
        },
        [theme.breakpoints.down('lg')]: {
            height: '220px',
        },
    },
    mediumInfoField: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/1.2 Roboto',
        fontSize: 'calc(12 * (100vw - 320px) / 880)',
        letterSpacing: '0.49px',
        marginLeft: '5%',
        marginRight: '5%',
        opacity: 1,
        textAlign: 'left',
    },
    mediumRow: {
        alignItems: 'center',
        display: 'flex',
        padding: '25px 0',
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
            height: '100%',
        },
    },
    small: {
        width: '100px',
    },
    smallInfoField: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        marginLeft: '20px',
        maxWidth: '250px',
        opacity: 1,
        textAlign: 'left',
    },
    smallRow: {
        alignItems: 'center',
        display: 'flex',
        margin: '15px 20px 10px',
    },
}));

export default styles;

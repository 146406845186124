import { ColumnsType } from 'shared-components/Table/dataTypes';
import { GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import RegularSelect from 'shared-components/RegularSelect';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { TFunction } from 'i18next';
import { Save } from '@mui/icons-material';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { CircularProgress } from '@mui/material';
import { Classes } from 'jss';

type Props = {
    classes: Classes,
    gatewayObjects: {
        gatewayId: number,
        uniqueId: string,
    }[],
    gateways: GatewayInfoDTO[],
    onChange: (uniqueId: string, gatewayId: number) => void,
    onRemove: (uniqueId: string, gatewayId: number) => void,
    onSave: (uniqueId: string, gatewayId: number) => void,
    t: TFunction,
    theme: SkycellThemeInterface,
    workInProgress: {
        [key: string]: boolean,
    },
}

export const tableColumns = ({
    classes,
    gatewayObjects,
    gateways,
    onChange,
    onRemove,
    onSave,
    t,
    theme,
    workInProgress,
}: Props): ColumnsType[] => {
    const gatewaysInUse = gatewayObjects.map(({ gatewayId }) => gatewayId);

    return [{
        accessor: ({ gatewayId, oldGatewayId, uniqueId }) => {
            // const currentGateway = gateways.find(g => g.id === gatewayId);

            return (
                <div
                    style={{
                        display: 'inline-grid',
                        gridTemplateColumns: '1fr auto',
                        width: '100%',
                    }}
                >
                    <RegularSelect
                        borderless
                        className={(gatewayId === null || oldGatewayId !== gatewayId) && classes.filledSelect}
                        customClasses={{
                            caretIcon: gatewayId === null && classes.caret,
                        }}
                        disabled={workInProgress[uniqueId] || gatewayId === oldGatewayId}
                        id={uniqueId}
                        options={gateways.filter(g => gatewaysInUse.indexOf(g.id) === -1)
                            .map(({ area, iataCode, id, locationName }) => ({
                                active: id === oldGatewayId,
                                label: [iataCode,
                                    locationName,
                                    area].filter(it => it).join(' | '),
                                value: id,
                            }))}
                        placeholder="Select a gateway"
                        renderValue={value => {
                            const selectedGateway = gateways.find(g => g.id === value);

                            return [selectedGateway?.iataCode,
                                selectedGateway?.locationName,
                                selectedGateway?.area].filter(it => it).join(' | ');
                        }}
                        value={gatewayId || null}
                        onChange={it => onChange(uniqueId, it)}
                    />
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '5px',
                        paddingLeft: '5px',
                    }}
                    >
                        {
                            !workInProgress[uniqueId] && gatewayId !== null && (
                                <DeleteIcon
                                    style={{
                                        color: theme.palette.secondary[600],
                                    }}
                                    onClick={() => onRemove(uniqueId, gatewayId)}
                                />
                            )
                        }
                        {
                            !workInProgress[uniqueId] && gatewayId !== oldGatewayId && gatewayId && (
                                <Save
                                    style={{
                                        color: theme.palette.secondary[600],
                                    }}
                                    onClick={() => onSave(uniqueId, gatewayId)}
                                />
                            )
                        }
                        {
                            workInProgress[uniqueId] && (
                                <CircularProgress
                                    size={20}
                                    style={{
                                        color: theme.palette.secondary[600],
                                    }}
                                />
                            )
                        }
                    </div>
                </div>
            );
        },
        dataType: 'custom',
        Header: t('TRACK_AND_TRACE.LOCATION'),
    },
    ];
};
export const resolveSequential = async (tasks) => {
    return tasks.reduce(async (
        accumulator, currentPromise,
    ) => {
        const results = await accumulator;

        return [...results, await currentPromise()];
    }, Promise.resolve([]));
};

import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme) => ({
    filteredTable: {
        margin: '0px 10px 0px 24px',
        [theme.breakpoints.down('sm')]: {
            margin: '0px 10px',
        },
    },
}));

import React, {
    useState,
    useEffect, useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import {
    ContactInfo as ContactInfoType,
    OperatingHours,
    servicesArr,
    commoditiesArr,
} from 'dataTypes/SecureBackend/apiResponse';
import {
    AddServiceProviderSiteRequestBody,
    Address,
} from 'dataTypes/SecureBackend/processedData';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import useCustomTitle from 'hooks/useCustomTitle';

import AddressCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/AddressCard';
// eslint-disable-next-line max-len
import ServiceProviderSiteCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ServiceProviderSiteCard';
// eslint-disable-next-line max-len
import ContactInformationCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard';
import OperatingHoursCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/OperatingHoursCard';
import OfferingsCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/OfferingsCard';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import useClasses from 'hooks/useClasses';
import BackToLink from 'shared-components/BackToLink';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import { Site } from 'dataTypes/SecureBackend/apiResponse/Site';
import styles from '../../ServiceProviderSites.style';

type PathParams = {
    serviceProviderSiteId: string,
}

const EditServiceProviderSite = () => {
    const classes = useClasses(styles);
    const { company } = useCurrentUserContext();
    const { serviceProviderSiteId } = useParams() as unknown as PathParams;
    const { Get: getSite } = useSkymindBackendEndpoints('admin/companies/sites').requests;
    const hasAccess = useHasAccess();
    const isAdmin = useMemo(() => hasAccess(userRoles.SKYCELL_ADMIN), [hasAccess]);
    const {
        FlexibleRequest: editSite,
    } = useSkymindBackendEndpoints(
        isAdmin ? `admin/companies/${company.id}/sites` : `companies/${company.id}/sites`,
    ).statusNotificationRequests;
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { setTitle } = useCustomTitle();
    const { t } = useCustomTranslation();
    const [address, setAddress] = useState<Address>(null);
    const [availableData, setAvailableData] = useState<Site>(null);
    const [contactInfo, setContactInfo] = useState<ContactInfoType>(null);
    const [currentEditedCard, setCurrentEditedCard] = useState<string>(null);
    const [editRequestBody, setEditRequestBody] = useState<AddServiceProviderSiteRequestBody>(null);
    const [offerings, setOfferings] = useState<string[]>([]);
    const [operatingHours, setOperatingHours] = useState<OperatingHours[]>([]);
    const [serviceProviderSiteInfo, setServiceProviderSiteInfo] = useState(null);
    const [requiredUpdateData, setRequiredUpdateData] = useState(true);

    const handleClickEdit = async () => {
        try {
            const {
                status = null,
            } = await editSite('PATCH', `/${serviceProviderSiteId}/overview-info`, editRequestBody);

            if (status === 200) {
                setRequiredUpdateData(true);
            }
        } catch (error) {
            global.console.log(error);
        }
    };

    const updateOfferings = async () => {
        try {
            await editSite('PATCH', `/${serviceProviderSiteId}/offer`, offerings);
        } catch (error) {
            global.console.log(error);
        }
    };

    const updateOperatingHours = async () => {
        try {
            const {
                status = null,
            } = await editSite('PATCH', `/${serviceProviderSiteId}/operating-hours`, operatingHours);

            if (status === 200) {
                setRequiredUpdateData(true);
            }
        } catch (error) {
            global.console.log(error);
        }
    };

    useEffect(() => {
        if (address && contactInfo && serviceProviderSiteInfo) {
            setEditRequestBody({
                ...serviceProviderSiteInfo,
                address,
                contactInfo,
            });
        }
    }, [
        address,
        contactInfo,
        serviceProviderSiteInfo,
    ]);

    useEffect(() => {
        if (requiredUpdateData) {
            (async () => {
                try {
                    const {
                        data: response = null,
                    } : { data: Site } = await getSite(serviceProviderSiteId);

                    if (response) {
                        setAvailableData(response);
                        setContactInfo(response.contactInfo);
                        setServiceProviderSiteInfo({
                            siteName: response.siteName,
                        });
                        setAddress({
                            addressLine1: response.address.addressLine1,
                            addressLine2: response.address.addressLine2,
                            addressLine3: response.address.addressLine3,
                            city: response.address.city,
                            countryCode: response.address.country.code,
                            geolocation: response.address.geolocation,
                            zipCode: response.address.zipCode,
                        });
                        setOfferings(response?.offers || []);
                        setBreadcrumbData('customBreadcrumb', `${response.companyName} / ${response.siteName}`);
                        setTitle(`Service Provider Site Details: ${response.siteName}`);
                    }
                } catch (error) {
                    global.console.log(error);
                } finally {
                    setRequiredUpdateData(false);
                }
            })();
        }
    }, [requiredUpdateData]);

    return (
        availableData
            ? (
                <div>
                    <BackToLink
                        marginBottom={0}
                        marginRight={0}
                        title={t('MENU_ITEMS.SERVICE_PROVIDER_SITES')}
                        to="/administration/service-provider-sites"
                    />
                    <div className={classes.editCompanyCardContentData}>
                        <ServiceProviderSiteCard
                            currentCompanyLogo={availableData.logoImagePath}
                            currentEditedCard={currentEditedCard}
                            currentSiteName={availableData?.siteName}
                            saveInfo={handleClickEdit}
                            setCurrentEditedCard={setCurrentEditedCard}
                            setFilter={setServiceProviderSiteInfo}
                            type="editable"
                        />
                        <AddressCard
                            currentAddressLine1={availableData?.address?.addressLine1}
                            currentAddressLine2={availableData?.address?.addressLine2}
                            currentAddressLine3={availableData?.address?.addressLine3}
                            currentCity={availableData?.address?.city}
                            currentCountry={availableData?.address?.country.name}
                            currentCountryCode={availableData?.address?.country.code}
                            currentEditedCard={currentEditedCard}
                            currentGeolocation={availableData?.address?.geolocation}
                            currentZipCode={availableData?.address?.zipCode}
                            saveInfo={handleClickEdit}
                            setCurrentEditedCard={setCurrentEditedCard}
                            setFilter={setAddress}
                            type="editable"
                        />
                        <ContactInformationCard
                            currentContactPerson={availableData?.contactInfo?.contactPerson}
                            currentEditedCard={currentEditedCard}
                            currentEmail={availableData?.contactInfo?.email}
                            currentPhoneNumber={availableData?.contactInfo?.phoneNumber}
                            saveInfo={handleClickEdit}
                            setCurrentEditedCard={setCurrentEditedCard}
                            setFilter={setContactInfo}
                            type="editable"
                        />
                        <OperatingHoursCard
                            currentEditedCard={currentEditedCard}
                            currentOperatingHours={availableData?.operatingHours}
                            saveInfo={updateOperatingHours}
                            setCurrentEditedCard={setCurrentEditedCard}
                            setData={setOperatingHours}
                            type="editable"
                        />
                        <OfferingsCard
                            currentCommodities={offerings.filter(offer => commoditiesArr.includes(offer))}
                            currentEditedCard={currentEditedCard}
                            currentServices={offerings.filter(offer => servicesArr.includes(offer))}
                            saveInfo={updateOfferings}
                            setCurrentEditedCard={setCurrentEditedCard}
                            setOfferings={setOfferings}
                            type="editable"
                        />
                    </div>
                </div>
            )
            : null
    );
};

export default EditServiceProviderSite;

import { uniqueId } from 'utils/stringTool';
import { AddressDTO } from 'shared-components/dataTypes';
import { nonNullKey } from 'shared-components/common';
import { getDistanceFromLatLonInKm } from './tools';

export const hasTwoLocations = {
    AIR: true,
    CUSTOMS: false,
    GROUND_HANDLING: false,
    LOADING: false,
    POSITIONING: true,
    ROAD: true,
    SEA: true,
    UNLOADING: false,
    WAREHOUSE: false,
};

export const compareLocations = (a:AddressDTO, b:AddressDTO) => {
    if (!a || !b) return false;

    const aType = nonNullKey(a);
    const bType = nonNullKey(b);

    if (!aType || !bType) return false;

    const [aAddress, bAddress] = [
        a[aType]?.geolocation || a[aType]?.address?.geolocation,
        b[bType]?.geolocation || b[bType]?.address?.geolocation,
    ];

    if (aType !== bType) {
        return false;
    } else {
        const { latitude: aLat, longitude: aLong } = aAddress;
        const { latitude: bLat, longitude: bLong } = bAddress;

        return getDistanceFromLatLonInKm(aLat, aLong, bLat, bLong) <= 1;
    }
};

export const generateObject = ({ deliveryLocation, id = null, milestoneType, pickupLocation }) => (
    {
        deliveryLocation,
        id: id || uniqueId(),
        milestones: [],
        milestoneType,
        pickupLocation,
    }
);

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import { styled } from '@mui/material/styles';
import useClasses from 'hooks/useClasses';
import { wrapStyles } from 'hooks/useClasses/useClasses';
import React, { useState } from 'react';

const styles = wrapStyles((theme) => ({
    image: {
        '@media (max-width: 600px)': {
            height: 'auto',
            width: '70vw',
        },
        backgroundColor: theme.palette.secondary.light,
        height: '776px',
        maxHeight: '80vh',
        maxWidth: '90vw',
        objectFit: 'contain',
    },
    sliderWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '54px 0 22px 0',
    },
}));

const ImageWithButtons = styled(Box)({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
});

const ArrowButton = styled(Button)(({ theme }) => ({
    '&.Mui-disabled': {
        svg: {
            fill: theme.palette.secondary[200],
        },
    },
    '&:active': {
        svg: {
            fill: theme.palette.primary.dark,
        },
    },
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '@media (max-width: 600px)': {
        svg: {
            height: '42px',
            width: '30px',
        },
    },
    svg: {
        fill: theme.palette.secondary[600],
        height: '52px',
        width: '40px',
    },
}));

const CustomMobileStepper = styled(MobileStepper)(({ theme }) => ({
    '.MuiMobileStepper-dot': {
        backgroundColor: '#707070',
        height: '7px',
        width: '7px',
    },
    '.MuiMobileStepper-dotActive': {
        backgroundColor: theme.palette.primary.dark,
        height: '10px',
        width: '10px',
    },
    '.MuiMobileStepper-dots': {
        alignItems: 'center',
        gap: '7px',
    },
    justifyContent: 'center',
    marginTop: '14px',
}));

interface Props {
    className?: string;
    imageUrls?: string[];
}

const ImageSlider = ({
    className,
    imageUrls = [],
    ...props
}: Props) => {
    const [activeStep, setActiveStep] = useState(0);
    const classes = useClasses(styles);

    const handleStepNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % imageUrls.length);
    };

    const handleStepBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep - 1 + imageUrls.length) % imageUrls.length);
    };

    return (
        <div
            className={[classes.sliderWrapper, className].join(' ')}
            {...props}
        >
            <ImageWithButtons>
                <ArrowButton disableRipple disabled={imageUrls.length <= 1} onClick={handleStepBack}>
                    <ArrowBackIosNewIcon />
                </ArrowButton>

                {imageUrls.length > 0 && (
                    <img
                        alt="preview"
                        className={classes.image}
                        src={imageUrls[activeStep]}
                    />
                )}

                <ArrowButton disableRipple disabled={imageUrls.length <= 1} onClick={handleStepNext}>
                    <ArrowForwardIosIcon />
                </ArrowButton>
            </ImageWithButtons>

            <CustomMobileStepper
                activeStep={activeStep}
                backButton={null}
                nextButton={null}
                position="static"
                steps={imageUrls.length}
            />
        </div>
    );
};

export default ImageSlider;

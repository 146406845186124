import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import LastConnect from 'shared-components/LastConnect';
import { LyingDifference } from 'shared-components/LyingDiff';
import EnergyLevel from 'shared-components/EnergyLevel';
import { calculateDiffMinutes } from 'TrackAndTrace/Assets/lib';

const tableColumns: (t: (key: string) => string) => ColumnsType[] = (t) => [
    {
        accessor: 'assetNumber',
        dataType: 'highlightedText',
        Header: t('TRACK_AND_TRACE.ASSET'),
    },
    {
        accessor: ({ assetTypeCode }) => t(`ASSET_TYPE_LABEL.${assetTypeCode}`),
        dataType: 'text',
        Header: t('TRACK_AND_TRACE.ASSET_TYPE'),
    },
    {
        accessor: 'uldType',
        dataType: 'highlightedText',
        Header: t('TRACK_AND_TRACE.ULD_TYPE'),
        id: 'uldType',
    },
    {
        accessor: 'iataCode',
        dataType: 'text',
        Header: t('LANE_MANAGEMENT.ADDRESS.AIRPORT'),
    },
    {
        accessor: ((row) => (row.countryCode ? t(`COUNTRY_NAMES.${row.countryCode}`) : 'N/A')),
        dataType: 'text',
        Header: t('COMMON.COUNTRY'),
    },
    {
        accessor: 'city',
        dataType: 'text',
        Header: t('COMMON.CITY'),
    },
    {
        accessor: 'locationName',
        dataType: 'text',
        Header: t('TRACK_AND_TRACE.LOCATION_NAME'),
    },
    {
        accessor: ((row) => {
            if (!row.locationLyingSince) {
                return ' ';
            } else if (!row.locationLyingSinceTimestampUnit) {
                return ' ';
            }

            const diffMinute = calculateDiffMinutes(
                row.locationLyingSince,
                row.locationLyingSinceTimestampUnit,
            );

            return <LyingDifference minutes={diffMinute} />;
        }),
        dataType: 'custom',
        Header: t('TRACK_AND_TRACE.LOCATION_LYING_TIME'),
        id: 'locationLyingSince',
        sortType: (rowA, rowB) => rowB.original.locationLyingSince - rowA.original.locationLyingSince,
    },
    {
        accessor: 'area',
        dataType: 'text',
        Header: t('TRACK_AND_TRACE.AREA'),
    },
    {
        accessor: (row) => (row.temperature ? `${Number(row?.temperature?.toFixed(1))}°C` : ''),
        dataType: 'text',
        Header: t('COMMON.TEMPERATURE'),
    },
    {
        accessor: (row) => (!row.lastMeasuredTimestamp ? ' '
            : <LastConnect timestamp={row.lastMeasuredTimestamp} />),
        dataType: 'custom',
        Header: t('TRACK_AND_TRACE.LAST_UPDATED'),
        sortType: (rowA, rowB) => rowB.original.lastMeasuredTimestamp - rowA.original.lastMeasuredTimestamp,
    },
    {
        accessor: 'loggerNumber',
        dataType: 'highlightedText',
        Header: t('COMMON.LOGGER_NUMBER'),
    },
    {
        accessor: (row) => (
            (
                row.batteryLevel >= 0 && row.batteryLevel <= 100 && row.batteryLevel !== null ? (
                    <EnergyLevel
                        energyLevel={Number(((row.batteryLevel) * 100).toFixed(0))}
                        threshold
                    />
                ) : ''
            )),
        customCellStyle: {
            alignItems: 'center',
            maxWidth: '230px',
            whiteSpace: 'pre-wrap',
        },
        dataType: 'custom',
        Header: t('COMMON.LOGGER_BATTERY'),
        id: 'batteryLevel',
    },
];

export default tableColumns;

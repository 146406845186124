import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import { RequestStatus } from 'dataTypes/common';
import useClasses from 'hooks/useClasses';
import { wrapStyles } from 'hooks/useClasses/useClasses';
import React from 'react';

const styles = wrapStyles((theme) => ({
    spinner: {
        color: theme.palette.primary[400],
    },
    wrapper: {
        alignItems: 'center',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
    },
}));

type Props = {
    error?: string,
    isAuthLoading?: boolean,
    status: RequestStatus
}

const StatusInfo = ({
    error = null,
    isAuthLoading = false,
    status,
} : Props) => {
    const classes = useClasses(styles);

    if (isAuthLoading || status === 'PENDING') {
        return (
            <div className={classes.wrapper}>
                <CircularProgress classes={{
                    colorPrimary: classes.spinner,
                }}
                />
            </div>
        );
    }
    return (
        <>
            <Alert severity="error">
                {error || 'Some error happened'}
            </Alert>
        </>
    );
};

export default StatusInfo;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    badge: {
        borderRadius: '3px',
        padding: '0 2px',
        position: 'absolute',
    },
    clusterCount: {
        alignItems: 'center',
        color: theme.palette.common.constants.gray900,
        display: 'flex',
        justifyContent: 'center',
        left: '0',
        position: 'absolute',
        top: '0',
    },
    itemTypeBadge: {
        background: theme.palette.primary[800],
        color: theme.palette.primary[50],
        whiteSpace: 'nowrap',
    },
    polygonMarker: {
        cursor: 'unset',
        position: 'relative',
    },
}));

export default styles;

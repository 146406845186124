import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme) => ({
    itemText: {
        '& label': {
            color: theme.palette.common.black,
        },
        color: theme.palette.common.black,
        fontSize: '14px',
    },
}));

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    error: {
        background: '#FFE5E5 0% 0% no-repeat padding-box',
        borderLeft: '6px solid #D44848',
        borderRadius: '3px',
        opacity: 1,
    },
    fieldTitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        margin: '12px 0 2px 0',
        opacity: 1,
        textAlign: 'left',
    },
    filedInfo: {
        color: '#747474',
        font: 'normal normal normal 16px/21px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    loading: {
        filter: 'blur(2px)',
        transition: '200ms ease',
    },
    mandatory: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderLeft: '6px solid #8BD1E8',
        borderRadius: '3px',
        opacity: 1,
    },
    textField: {
        marginTop: '12px',
        width: '100%',
    },
    textFieldText: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        height: '21px',
        letterSpacing: '0.49px',
        opacity: 1,
        paddingRight: '0!important',
        textAlign: 'left',
    },
    textFieldWithMargin: {
        margin: '12px 0 2px 0',
    },
}));

export default styles;

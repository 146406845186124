import { LayoutContext } from 'Contexts/LayoutContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

type PathParams = {
    companyId: string,
}

const Sites = () => {
    const { companyId } = useParams() as unknown as PathParams;
    const { t } = useCustomTranslation();

    const {
        setCustomPageTitle,
        setMenuTabs,
    } = useContext(LayoutContext);

    useEffect(() => {
        setMenuTabs([
            {
                activePage: false,
                iconSrc: '',
                link: `/administration/companies/${companyId}`,
                title: t('COMMON.COMPANY'),
            },
            {
                activePage: true,
                iconSrc: '',
                link: `/administration/companies/${companyId}/sites`,
                title: t('COMMON.SITES'),
            },
        ]);
        setCustomPageTitle(t('COMMON.SITES'));
        return () => {
            setMenuTabs([]);
        };
    }, [companyId]);

    return (
        <div />
    );
};

export default Sites;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    airportInfoContainer: {
        display: 'flex',
        margin: '10px 0',
    },
    airportInfoElement: {
        margin: '0 10px',
    },
    airportInfoLeftElement: {
        marginRight: '10px',
    },
    arrowSvg: {
        color: `${theme.palette.primary.deepBlue}!important`,
        fontSize: '14px!important',
        marginLeft: '5px!important',
        rotate: '90deg',
    },
    arrowUpSvg: {
        color: '#009CCF!important',
        fontSize: '14px!important',
        marginLeft: '5px!important',
        rotate: '270deg',
    },
    blockContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    blockInfoFull: {
        flex: '0 0 100%',
    },
    card: {
        borderRadius: '6px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        color: theme.palette.secondary[600],
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.49px',
        maxWidth: '35%',
        minWidth: '35%',
        opacity: 1,
        textAlign: 'left',
    },
    cardHeader: {
        color: `${theme.palette.secondary[900]}!important`,
        font: 'normal normal normal 32px/38px Roboto!important',
        letterSpacing: '0.49px',
        textAlign: 'left',
    },
    collapseDivider: {
        borderRight: '0.5px solid #d0d0d0',
        height: '115px',
    },
    collectionPoint: {
        '& div': {
            // @ts-ignore
            color: theme.palette.common.contrastGray600,
        },
        gridArea: 'collectionPoint',
    },
    containerCount: {
        margin: '0 10px',
    },
    containerIcon: {
        height: '13px',
        margin: '2px',
        width: '12px',
    },
    containerIconsContainer: {
        display: 'flex',
    },
    details: {
        color: `${theme.palette.primary.deepBlue}!important`,
        fontFamily: 'Roboto!important',
        fontSize: '15px',
        fontWeight: 'normal',
    },
    fieldTitle: {
        color: theme.palette.common.black,
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        marginBottom: '7px',
        opacity: 1,
        textAlign: 'left',
    },
    handoverPoint: {
        '& div': {
            // @ts-ignore
            color: theme.palette.common.contrastGray600,
        },
        gridArea: 'handoverPoint',
    },
    headerDivider: {
        borderRight: '0.5px solid #d0d0d0',
        height: '60px',
    },
    linkMargin: {
        margin: 'auto',
    },
    panelElement: {
        margin: '0',
        maxWidth: 'unset!important',
        minWidth: 'unset!important',
    },
    rightArrowGrey: {
        height: '16px',
        marginLeft: '5px',
        paddingTop: '1px',
        width: '16px',
    },
    summaryGridBlockColumns: {
        display: 'flex',
    },
    summaryGridBlockRows: {
        display: 'flex',
    },
    temperatureRangeContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        margin: '0 5px',
    },
    temperatureRangeIcon: {
        marginRight: '10px',
        width: '12px',
    },
    temperatureRangeInfo: {
        alignItems: 'center',
        display: 'flex',
        margin: '20px 0',
    },
    warningContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '5px',
    },
    warningIcon: {
        marginRight: '19px',
        width: '22px',
    },
}));

export default styles;

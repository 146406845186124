import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    anim_opacityIn: {
        from: { opacity: 0 },
        to: { opacity: 1 },
    },
    appearingLi: ({ classes }) => ({
        animation: `${classes.anim_opacityIn} 200ms forwards ease-in-out`,
        opacity: 0,
    }),
    dummyHolder: {
        maxHeight: '0px',
        overflow: 'visible',
    },
    icon: {
        fontSize: '14px',
    },
    iconButton: {
        height: '20px',
        width: '20px',
    },
    itemsList: {
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.secondary[100],
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.common.white,
            borderRadius: '10px',
        },
        background: theme.palette.common.white,
        boxShadow: `0px 1px 3px ${theme.palette.secondary[200]}`,
        listStyle: 'none',
        margin: '0px',
        maxHeight: '200px',
        opacity: 0,
        overflowY: 'auto',
        paddingInlineStart: '0px',
        position: 'absolute',
        transition: '200ms ease',
        visibility: 'hidden',
        zIndex: 100,
    },
    listItem: {
        '&:first-child': {
            marginTop: '10px',
        },
        '&:hover': {
            background: theme.palette.secondary[100],
        },
        color: theme.palette.common.black,
        cursor: 'pointer',
        padding: '6px',
        transition: 'background 200ms ease',
        userSelect: 'none',
    },
    listLoading: {
        filter: 'blur(2px)',
        pointerEvents: 'none',
        transition: '200ms ease',
    },
    mediumFont: {
        '& input': {
            fontSize: '14px',
        },
        fontSize: '14px',
    },
    searchContainer: {
        width: '100%',
    },
    smallFont: {
        '& input': {
            fontSize: '12px',
        },
        fontSize: '12px',
    },
    visible: {
        '&&': {
            opacity: 1,
            visibility: 'visible',
        },
    },
}));

export default styles;

import axios, { Method } from 'axios';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useJWTToken from 'hooks/useJWTToken';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';

import { useMemo } from 'react';
import {
    defaultGetAllQuery,
    getConfig,
    getQueries,
    getErrorReponseMessage,
    Query, getQueriesUnwrapped,
} from './lib';

const {
    REACT_APP_SKYMIND_API: apiUrl,
} = process.env;

type AdditionalParams = {
    axiosResponseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream',
    customSuccessMessage?: string,
    isSkymind?: boolean
}
/**
 * hook to use the Secure Backend.
 * Basic CRUD methods, FlexibleRequest for more specific requests
 * @param objectName name of the required object in Secure BE (shipment, user, company etc.)
 * @param additionalParams additional parameters for request object
 * @returns 2 groups of methods: without (requests) and with status notification (statusNotificationRequests)
 */
const useSkymindBackendEndpoints = (objectName: string, additionalParams?: AdditionalParams) => {
    const token = useJWTToken();
    const { t } = useCustomTranslation();
    const {
        setErrorStatus,
        setProgressStatus,
        setSuccessStatus,
    } = useStatusStateProcessOptions();

    const {
        axiosResponseType,
        customSuccessMessage = null,
        isSkymind,
    } = additionalParams || {};
    const instance = axios.create({
        baseURL: `${isSkymind ? 'https://skymind.dev.skycell.ch/secure' : apiUrl}/${objectName}`,
        responseType: axiosResponseType,
    });
    const config = useMemo(() => getConfig(token, {}), [token]);

    const requests = {
        Create: (params: object | string = {}, queryObj: object = null, headers = {}) => {
            return instance.post(getQueries(queryObj), params, {
                ...config,
                headers: {
                    ...config.headers,
                    ...headers,
                },
            });
        },
        /* Update: (id: string | number, params: object = {}, queryObj: object = null, headers = {}) => {
            const customConfig = getConfig(token, headers);

            return instance.put(`/${id}${getQueries(queryObj)}`, params, customConfig);
        },
        PutBody: (data: any, queryObj: object = null, headers = {}) => {
            const customConfig = getConfig(token, headers);
            const isNumeric = typeof data === 'number';

            return instance.put(`/${getQueries(queryObj)}`, isNumeric ? data?.toString() : data, customConfig);
        }, */
        Delete: (id: string | number, queryObj: object = null) => {
            return instance.delete(`/${id}${getQueries(queryObj)}`, config);
        },
        FlexibleRequest: (
            type: Method,
            url: string = '',
            data = null,
            headers: object = {},
            additionalConfig: object = {},
        ) => {
            const customConfig = getConfig(token, headers);

            return instance({
                data,
                method: type,
                url,
                ...customConfig,
                ...additionalConfig,
            });
        },
        Get: (id: string | number | unknown, queryObj: object = null) => {
            return instance.get(`/${id}${getQueries(queryObj)}`, config);
        },
        GetAll: (queryObj: Query = defaultGetAllQuery, queryWrap = true) => {
            return instance.get(queryWrap ? getQueries(queryObj) : getQueriesUnwrapped(queryObj), config);
        },
        Patch: (id: string | number, data: object = {}, queryObj: object = null) => {
            return instance.patch(`/${id}${getQueries(queryObj)}`, data, config);
        },
    };

    const statusNotificationRequests = {
        Create: async (params: object = {}, queryObj: object = null) => {
            let time: number = null;

            try {
                time = setProgressStatus(`We're saving your updates in ${objectName}. This might take a bit.`);
                const response = await instance.post(getQueries(queryObj), params, config);
                const message = `We have saved your updates in ${objectName}.`;

                setSuccessStatus(message, [time]);
                return response;
            } catch (error) {
                setErrorStatus(getErrorReponseMessage(error), [time]);
                return null;
            }
        }, /*
        Update: async (id: string | number, params: any = {}, queryObj: object = null, headers = {}) => {
            let time: number = null;
            const customConfig = getConfig(token, headers);

            try {
                time = setProgressStatus(`We're saving your updates in ${objectName}. This might take a bit.`);

                const response = await instance.put(
                    `${id ? `/${id}` : ''}${getQueries(queryObj)}`, params, customConfig,);
                const message = `We have saved your updates in ${objectName}.`;

                setSuccessStatus(message, [time]);
                return response;
            } catch (error) {
                setErrorStatus(getErrorResponseMessage(error), [time]);
                return null;
            }
        }, */
        Delete: async (id: string | number, queryObj: object = null) => {
            let time: number = null;

            try {
                time = setProgressStatus(`We're deleting ${objectName}. This might take a bit.`);

                const response = await instance.delete(`/${id}${getQueries(queryObj)}`, config);

                setSuccessStatus(`${objectName} with id ${id} successfully deleted`, [time]);
                return response;
            } catch (error) {
                setErrorStatus(getErrorReponseMessage(error), [time]);
                return null;
            }
        },
        FlexibleRequest: async (
            type: Method,
            url: string,
            data = null,
            headers: object = {},
            additionalConfig: object = {},
        ) => {
            let time: number = null;
            const customConfig = getConfig(token, headers);

            try {
                time = setProgressStatus('Request sending. This might take a bit.');

                const response = await instance({
                    data,
                    method: type,
                    url,
                    ...customConfig,
                    ...additionalConfig,
                });

                setSuccessStatus(customSuccessMessage || `Success request for ${objectName}`, [time]);
                return response;
            } catch (error) {
                setErrorStatus(getErrorReponseMessage(error, t('TRACK_AND_TRACE.ADD_SHIPMENT.ERROR_HAPPENED')), [time]);
                return error;
            }
        },
        Get: async (id: string | number, queryObj: object = null) => {
            let time: number = null;

            try {
                time = setProgressStatus(`Request to download ${objectName}...`);
                const response = await instance.get(`/${id}${getQueries(queryObj)}`, config);
                const message = `${objectName} with id ${id} successfully downloaded`;

                setSuccessStatus(message, [time]);
                return response;
            } catch (error) {
                setErrorStatus(getErrorReponseMessage(error), [time]);
                return null;
            }
        },
        GetAll: async (queryObj: Query = defaultGetAllQuery) => {
            let time: number = null;

            try {
                time = setProgressStatus(`Request to download all records of ${objectName}...`);
                const response = await instance.get(getQueries(queryObj), config);
                const message = `${objectName}: All records successfully downloaded`;

                setSuccessStatus(message, [time]);
                return response;
            } catch (error) {
                setErrorStatus(getErrorReponseMessage(error), [time]);
                return null;
            }
        },
    };

    return {
        requests,
        statusNotificationRequests,
    };
};

export default useSkymindBackendEndpoints;

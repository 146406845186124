import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    bodyCell: {
        '&:hover': {
            cursor: 'pointer',
        },
        alignItems: 'center!important',
        color: `${theme.palette.common.black}!important`,
        fontSize: '14px!important',
        letterSpacing: '0.01em!important',
        // minHeight: '30px',
        minHeight: '100%!important',
        opacity: 1,
        padding: '16px 13px 16px 13px',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            padding: '16px 8px 16px 8px!important',
        },
        [theme.breakpoints.down('lg')]: {
            padding: '16px 13px 16px 13px!important',
        },
    },
    bodyCellBottom: {
        verticalAlign: 'text-bottom',
    },
    bodyCellMiddle: {
        verticalAlign: 'middle',
    },
    bodyCellTop: {
        verticalAlign: 'text-top',
    },
}));

export default styles;

import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Polyline } from '@react-google-maps/api';
import BaseMap from 'TrackAndTrace/commonComponents/BaseMap';
import LoggerMarker from 'TrackAndTrace/Loggers/components/LoggerMarker';
import useConfig from 'hooks/useConfig';
import { PolylinePath } from 'SensorDataCommon/lib';
import { ExtractedLoggerData } from 'TrackAndTrace/Loggers/lib';
import { ZoomInMap, ZoomOutMap } from '@mui/icons-material';

type Props = {
    customFullScreenControl?: () => void,
    height: string,
    mapFullscreen?: boolean,
    polylines: PolylinePath[],
    selected?: ExtractedLoggerData,
    widthPercent: number
}

const LocationHistoryMap = ({
    customFullScreenControl = null,
    height,
    mapFullscreen = false,
    polylines = [],
    selected = null,
    widthPercent,
}: Props) => {
    const theme = useTheme();
    const config = useConfig();
    const [gMap, setGMap] = useState(null);
    const [, setBounds] = useState<number[]>([]);
    const mapId = config?.locationHistory?.mapId ?? 'c5af2a2e4049bce5';
    const tilt = config?.locationHistory?.mapId !== undefined ? 100 : 0;
    const heading = config?.locationHistory?.mapId !== undefined ? 60 : 0;

    return (
        <div style={{
            height,
            width: `${widthPercent}%`,
        }}
        >
            <BaseMap
                gMap={gMap}
                heading={heading}
                mapContainerStyle={{
                    height,
                    width: '100%',
                    zIndex: 1,
                }}
                mapId={mapId}
                options={{
                    fullscreenControl: false,
                }}
                setBounds={setBounds}
                setGMap={setGMap}
                tilt={tilt}
            >
                {
                    polylines.map((line, ind) => {
                        return line[0].lng && line[0].lat && (
                            <Polyline
                                key={`polyline${ind}_${line[0].lng}_${line[0].lat}`}
                                options={{
                                    strokeColor: theme.palette.primary['deepBlue'],
                                }}
                                path={line}
                            />
                        );
                    })
                }
                {selected && (
                    <LoggerMarker
                        key={selected.loggerNumber}
                        isSelected={false}
                        loggerGroup={[selected]}
                        showLastConnection={false}
                        showLoggerTypes={false}
                        onHover={() => {}}
                    />
                )}
                {customFullScreenControl && (
                    <div
                        style={{
                            alignItems: 'center',
                            backgroundColor: theme.palette.common.white,
                            borderRadius: '6px',
                            boxShadow: '0px 1px 3px #00000033',
                            color: 'rgba(0, 0, 0, 0.54)',
                            cursor: 'pointer',
                            display: 'flex',
                            height: '26px',
                            justifyContent: 'center',
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                            width: '28px',
                        }}
                        onClick={customFullScreenControl}
                    >
                        {
                            mapFullscreen ? <ZoomInMap style={{ fontSize: '20px' }} />
                                : <ZoomOutMap style={{ fontSize: '20px' }} />
                        }
                    </div>
                )}
            </BaseMap>
        </div>
    );
};

export default LocationHistoryMap;

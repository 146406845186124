import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import React, { useEffect, useState } from 'react';
import Card from 'shared-components/Card';
import CommonButton from 'shared-components/CommonButton';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import { PathParams, STATUS } from 'Administration/Shipments/lib';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import DataCompleteCheckModal from './DataCompleteCheckModal';

const styles = () => ({
    content: {
        paddingBottom: '15.6px !important',
        paddingLeft: '12px',
        paddingTop: '18px',
    },
    reasonText: {
        color: '#707070',
        fontSize: 12,
    },
    reasonTitle: {
        fontSize: 12,
        marginBottom: 8,
    },
    title: {
        marginBottom: '18.5px',
    },
});

const DataCompleteCheck = ({ externalId, isNotClosed }: { externalId: string, isNotClosed: boolean}) => {
    const { id } = useParams<PathParams>();
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const { userInfo } = useCurrentUserContext();
    const { FlexibleRequest: getDataCompleteCheckResult } = useSkymindBackendEndpoints('admin/v2/shipments').requests;
    const {
        FlexibleRequest: retriggerDataCompleteCheckResult,
    } = useSkymindBackendEndpoints('admin/v2/shipments').requests;
    const {
        FlexibleRequest: overrideDataCompleteCheckResult,
    } = useSkymindBackendEndpoints('admin/v2/shipments').requests;
    const [dataCompleteCheckResult, setDataCompleteCheckResult] = useState(null);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {
        setErrorStatus,
        setSuccessStatus,
    } = useStatusStateProcessOptions();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const retriggerDataCheck = async (isShowNotifications = true) => {
        setIsLoading(true);
        try {
            await retriggerDataCompleteCheckResult(
                'POST',
                `/${id}/action/data-complete-check`,
                {},
            );

            const response = (await getDataCompleteCheckResult(
                'GET',
                `/${id}/access/data-complete-check`,
            )).data;

            setDataCompleteCheckResult(response);
            if (isShowNotifications) {
                setSuccessStatus(t('DATA_COMPLETE_CHECK.SUCCESSFULLY_RE_TRIGGERED', { externalId }));
            }
        } catch (error) {
            if (isShowNotifications) {
                setErrorStatus(t('DATA_COMPLETE_CHECK.ERROR_RE_TRIGGER', { externalId }));
            }
        }
        setIsLoading(false);
    };

    const overrideDataCheck = async (reason) => {
        setIsLoading(true);
        try {
            const response = (await overrideDataCompleteCheckResult(
                'PATCH',
                `/${id}/action/data-completeness`,
                {
                    dataCompleteCheckOverride: true,
                    dataCompleteCheckOverrideBy: userInfo?.email,
                    dataCompleteCheckOverrideReason: reason,
                },
            ));

            if (response.status === 200) {
                setDataCompleteCheckResult({
                    ...dataCompleteCheckResult,
                    overrideOptions: response.data,
                });
                setSuccessStatus(t('DATA_COMPLETE_CHECK.SUCCESSFULLY_OVERRIDDEN', { externalId }));
            }
        } catch (error) {
            setErrorStatus(t('DATA_COMPLETE_CHECK.ERROR_OVERRIDE', { externalId }));
        }
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                const response = (await getDataCompleteCheckResult(
                    'GET',
                `/${id}/access/data-complete-check`,
                )).data;

                setDataCompleteCheckResult(response);
            } catch (error) {
                global.console.error(error);
            }
            setIsLoading(false);
        })();
    }, []);

    const isOverridden = dataCompleteCheckResult?.overrideOptions?.override;

    const isComplete = dataCompleteCheckResult?.cargoResults?.every(
        cargo => cargo.ambientDataCompleteStatus === STATUS.COMPLETE
            && cargo.internalDataCompleteStatus === STATUS.COMPLETE,
    );

    const areButtonsDisabled = !dataCompleteCheckResult || isNotClosed || isComplete;

    return (
        <Card
            contentClass={classes.content}
            title={t('DATA_COMPLETE_CHECK.TITLE')}
            titleClass={classes.title}
        >
            {isLoading && <CircularProgress />}

            {!isLoading && (
                isOverridden ? (
                    <>
                        <div className={classes.reasonTitle}>{t('DATA_COMPLETE_CHECK.OVERRIDE_REASON')}</div>
                        <div className={classes.reasonText}>
                            {dataCompleteCheckResult?.overrideOptions?.overrideReason}
                        </div>
                    </>
                ) : (
                    <div style={{ display: 'flex', gap: '14px', justifyContent: 'space-between' }}>
                        <CommonButton
                            disabled={areButtonsDisabled}
                            onClick={() => retriggerDataCheck()}
                        >
                            {t('DATA_COMPLETE_CHECK.RETRIGGER_DATA_CHECK')}
                        </CommonButton>

                        <CommonButton
                            disabled={areButtonsDisabled}
                            onClick={handleOpen}
                        >
                            {t('DATA_COMPLETE_CHECK.OVERRIDE')}
                        </CommonButton>

                        <DataCompleteCheckModal
                            handleClose={handleClose}
                            open={open}
                            onOverride={overrideDataCheck}
                        />
                    </div>
                )
            )}
        </Card>
    );
};

export default DataCompleteCheck;

import React, { useState } from 'react';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import EditMode from './EditMode';
import ReadMode from './ReadMode';
import styles from './PharmaSiteCard.style';

type Props = {
    currentCompanyLogo?: string,
    currentCompanyType?: string,
    currentEditedCard?: string,
    currentSiteName?: string,
    onNameChange?: (name: string) => void,
    saveInfo?: () => void,
    setCurrentEditedCard?: (currentEditedCard: string) => void,
    setFilter?: (filter) => void,
    type?: 'new' | 'editable' | 'readOnly' | 'newMyCompany'
};

const cardName = 'PharmaSiteCard';

const PharmaSiteCard = ({
    currentCompanyLogo = '',
    currentCompanyType = '',
    currentEditedCard = null,
    currentSiteName = '',
    onNameChange = () => {},
    saveInfo = () => {},
    setCurrentEditedCard = () => {},
    setFilter = () => {},
    type = 'readOnly',
}: Props) => {
    const classes = useClasses(styles);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkInProgress, setCheckInProgress] = useState(false);
    const [siteName, setSiteName] = useState(currentSiteName);
    const { t } = useCustomTranslation();

    const handleClickEditButton = () => {
        setCurrentEditedCard(cardName);
        setEditMode(true);
    };

    const handleClickSaveButton = async () => {
        setLoading(true);
        await saveInfo();
        setEditMode(false);
        setLoading(false);
        setCurrentEditedCard(null);
    };

    const handleClickUndoButton = () => {
        setCurrentEditedCard(null);
        setFilter({
            name: currentSiteName,
        });
        setEditMode(false);
    };

    const handleNameChange = (name: string) => {
        setSiteName(name);
        onNameChange(name);
    };

    return (
        <CardWithEditableContent
            className={loading ? classes.loading : ''}
            disabledEditButton={currentEditedCard !== null && currentEditedCard !== cardName}
            loadingIcon={checkInProgress}
            showEditButton={type === 'editable' && !editMode}
            showSaveButton={type === 'editable' && editMode && !checkInProgress && siteName.trim() !== ''}
            showUndoButton={type === 'editable' && editMode}
            title={t('COMMON.SITE_INFORMATION')}
            onClickEditButton={handleClickEditButton}
            onClickSaveButton={handleClickSaveButton}
            onClickUndoButton={handleClickUndoButton}
        >
            {
                type === 'new' || type === 'newMyCompany' || (type === 'editable' && editMode)
                    ? (
                        <EditMode
                            companyLogo={currentCompanyLogo}
                            currentCompanyType={currentCompanyType}
                            setCheckInProgress={setCheckInProgress}
                            setFilter={setFilter}
                            siteName={currentSiteName}
                            type={type}
                            onNameChange={handleNameChange}
                        />
                    )
                    : (
                        <ReadMode
                            companyLogo={currentCompanyLogo}
                            siteName={currentSiteName}
                        />
                    )
            }
        </CardWithEditableContent>
    );
};

export default PharmaSiteCard;

import { Theme } from '@emotion/react';
import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme: Theme) => ({
    backdrop: {
        background: '#0000001F',
        height: '100vh',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100vw',
        zIndex: 100,
    },
    buttons: {

        alignItems: 'flex-end',
        display: 'flex',
        flex: 1,
        gap: '30px',
        justifyContent: 'center',
        margin: '31.5px 0px 12px 0px',
    },
    closeIcon: {

        color: theme.palette.secondary[600],
        cursor: 'pointer',
        fontSize: '23px',
        position: 'absolute',
        right: '8px',
        top: '10px',
    },
    error: {
        color: theme.palette.error.main,
        fontSize: '14px',
        marginBottom: '5px',
        marginTop: '5px',
    },
    field: {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
    },
    fieldInput: {
        '& textarea': {
            '&::-webkit-scrollbar': {
                backgroundColor: theme.palette.secondary[100],
                width: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
                '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
                backgroundColor: 'rgba(0,0,0,0.2)',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-track': {
                '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
                backgroundColor: theme.palette.common.white,
                borderRadius: '10px',
            },
            color: theme.palette.common.black,
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
            letterSpacing: 'letter-spacing: 0.01em',
            textAlign: 'left',
        },

    },
    fieldSingle: {
        display: 'flex',
        flexDirection: 'column',
        gap: '11px',
    },
    fieldTitle: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 'normal',
        letterSpacing: '0.15px',
        textAlign: 'left',
    },
    fieldValue: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'normal',
        letterSpacing: '0.18px',
        textAlign: 'left',
    },
    firstDivision: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
    },
    modalTitle: {
        color: theme.palette.secondary[800],
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 'bold',
        letterSpacing: '0.21px',
        marginBottom: '24px',
        opacity: 1,
        textAlign: 'left',
    },
    modalWindow: {
        '.MuiSelect-iconStandard': {
            color: '#4B4B4B',
        },
        background: theme.palette.common.white,
        borderRadius: '4px',
        boxShadow: '0px 1px 3px #00000033',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '200px',
        minWidth: '485px',
        padding: '28px 35px 19px 35px',
        position: 'relative',
        zIndex: 101,

    },
    modalWrapper: {
        alignItems: 'center',
        color: theme.palette.common.black,
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        position: 'relative',
        width: '100vw',
    },
    thirdDivision: {
        display: 'flex',
        flexDirection: 'column',
        gap: '36px',
    },
    threeDivisions: {
        display: 'grid',
        gap: '22px',
        gridTemplateColumns: '1fr 2fr 1fr',
    },
}));

import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme) => ({
    filteredTable: {
        margin: '31px 24px 34px 24px',
        [theme.breakpoints.down('sm')]: {
            margin: '0px 10px',
        },
    },
}));

import React, { ReactNode } from 'react';
import { ColumnDataType } from 'shared-components/Table/dataTypes';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { highlight } from 'utils/stringTool';

type Props = {
    data?: string | number | ReactNode,
    dataType: ColumnDataType,
    maskForHighlight?: string,
}

const DataTypes = ({
    data = null,
    dataType,
    maskForHighlight = '',
} : Props) => {
    const { t } = useCustomTranslation();

    switch (dataType) {
    case 'number':
        return <span key={`number_${data}`}>{typeof data === 'number' ? data : parseFloat(String(data))}</span>;
    case 'label':
        return <span key={`label_${data}`}>{typeof data === 'string' ? t(data) : null}</span>;
    case 'custom':
    case 'stretchedCell':
    case 'date':
    case 'text':
        return Array.isArray(data) ? (
            <>
                {data.map((item, index) => <span key={`text_${index}`}>{item}</span>)}
            </>
        ) : (
            <span key={`text_${data}`}>{data}</span>
        );
    case 'highlightedText':
        return Array.isArray(data) ? (
            <>
                {data.map((item, index) => (
                    <span key={`highlightedText_${index}`}>
                        {(item && maskForHighlight) ? highlight(item, maskForHighlight) : item}
                    </span>
                ))}
            </>
        ) : (
            <span key={`highlightedText_${data}`}>
                {typeof data === 'string' && maskForHighlight ? highlight(data, maskForHighlight) : data}
            </span>
        );
    default:
        return null;
    }
};

export default DataTypes;

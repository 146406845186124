import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Typography } from '@mui/material';
import Card from 'shared-components/Card';
import {
    CompositePolyline,
    LatLngTimeTimestamp,
    TimeRange,
} from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import ApexTemperatureChart, {
    ZoomedDataLimits,
    getPolylinePath,
    defaultPolylineOptions,
} from 'shared-components/ApexTemperatureChart';
import icons from 'shared-components/icons';
import SwitchWithLabel from 'shared-components/SwitchWithLabel';
import NoSensorInfo from 'shared-components/NoSensorInfo';
import Map from 'shared-components/Map';
import useCacheContext from 'hooks/useCacheContext';
import RectangleContentLoader from 'shared-components/RectangleContentLoader';

import useCustomTranslation from 'hooks/useCustomTranslation';
import BackToLink from 'shared-components/BackToLink';
import moment from 'moment';
import useClasses from 'hooks/useClasses';
import { initialRequestBody } from 'SensorDataCommon/dataTypes';

import { SensorDataItem } from 'dataTypes/SecureBackend/apiResponse';
import TemperatureRangeSelector from 'SensorDataCommon/components/TemperatureRangeSelector';
import styles from './LoggerUnit.style';

type TempRangeType = { tempRangeMax: number, tempRangeMin: number }
const defaultTempRange = { tempRangeMax: null, tempRangeMin: null };

type Props = {
    core?: string,
    getSensorData?: (serialNumber: string, data: SensorDataItem[], labels: {
        dataTypes: string[],
        loggerTypes: string[],
        positions: string[],
    }) => void,
    localTimezone?: boolean,
    loggerId: number,
    loggerNumber: string,
    options: SensorDataRequestBody,
    showMap?: boolean,
    timeRange: TimeRange
}

const LoggerUnit = ({
    core = 'track-and-trace',
    getSensorData,
    localTimezone = false,
    loggerId,
    loggerNumber = '',
    options = initialRequestBody,
    showMap = false,
    timeRange,
}: Props) => {
    const classes = useClasses(styles);
    const [showGeolocationContentLoader, setShowGeolocationContentLoader] = useState(false);
    const [showMarkers, setShowMarkers] = useState(false);
    const [zoomedDataLimits, setZoomedDataLimits] = useState<ZoomedDataLimits>({ max: null, min: null });
    const [tempRange, setTempRange] = useState<TempRangeType>(defaultTempRange);
    const [coordinates, setCoordinates] = useState<LatLngTimeTimestamp[]>([]);
    const [gMap, setGMap] = useState(null);
    const [mouseMoveDataIndex, setMouseMoveDataIndex] = useState(0);
    const { getCacheValue } = useCacheContext();

    const { t } = useCustomTranslation();

    useEffect(() => {
        setZoomedDataLimits({ max: null, min: null });
    }, [coordinates]);

    const polylines = useMemo((): CompositePolyline => {
        if (!coordinates || coordinates.length === 0) {
            return {
                options: null,
                path: [],
            };
        }

        const path = getPolylinePath(coordinates, zoomedDataLimits);

        return {
            options: defaultPolylineOptions,
            path,
        };
    }, [coordinates, zoomedDataLimits]);

    const markerData = useMemo(() => {
        if (!coordinates || coordinates.length === 0 || mouseMoveDataIndex < 0 || !coordinates[mouseMoveDataIndex]) {
            return {
                icon: null,
                position: null,
            };
        }
        return {
            icon: icons.hex_with_cross,
            position: coordinates[mouseMoveDataIndex].location,
        };
    }, [
        coordinates,
        mouseMoveDataIndex,
    ]);

    const tooltipData = useMemo(() => {
        if (!coordinates || coordinates.length === 0 || !coordinates[mouseMoveDataIndex]) {
            return {
                location: null,
                time: '',
            };
        }
        return coordinates[mouseMoveDataIndex];
    }, [
        coordinates,
        mouseMoveDataIndex,
    ]);

    const bounds = useMemo(() => {
        const lats = polylines.path.map((item) => item?.lat);
        const lngs = polylines.path.map((item) => item?.lng);

        return {
            east: lngs.length ? Math.max(...lngs) : null,
            north: lats.length ? Math.max(...lats) : null,
            south: lats.length ? Math.min(...lats) : null,
            west: lngs.length ? Math.min(...lngs) : null,
        };
    }, [polylines]);

    const mapConfig = useMemo(() => {
        return {
            backgroundColor: 'unset',
            containerStyle: {
                height: '45vh',
                width: '100%',
            },
            zoom: 7,
        };
    }, []);

    useEffect(() => {
        if (gMap && polylines.path.length > 0 && bounds.east && bounds.north && bounds.south && bounds.west) {
            gMap.fitBounds(bounds);
            gMap.setZoom(gMap.getZoom() - 1);
            setTimeout(() => {
                gMap.setZoom(gMap.getZoom() + 1);
            }, 0);
        }
    }, [bounds, gMap]);

    useEffect(() => {
        if (showMap && polylines.path.length === 0) {
            setShowGeolocationContentLoader(true);
            setTimeout(() => setShowGeolocationContentLoader(false), 7000);
        }
    }, [showMap, polylines]);

    const handleLoad = (map) => {
        setGMap(map);
    };

    const handleChangeMarkers = useCallback(() => setShowMarkers(prev => !prev), []);

    return (
        <div>
            <BackToLink
                marginBottom={10}
                marginLeft={0}
                marginRight={12}
                marginTop={10}
                title="Loggers List"
                to={`/${core}/loggers?view=${getCacheValue('VIEW_TYPE') || 'table'}`}
            />
            <Card>
                <Typography className={classes.loggerTitle} variant="h3">
                    <div>
                        {
                            localTimezone
                                ? `Logger ${loggerNumber} | ${t('SENSOR_DATA.TEMPERATURE_IN_C')}`
                                : `Logger ${loggerNumber} | ${t('SENSOR_DATA.TEMPERATURE_UTC_TIME')}`
                        }
                    </div>
                    <div className={classes.loggerTitle}>
                        <TemperatureRangeSelector
                            setValue={setTempRange}
                            tooltipInfo={{
                                text: t('QUICK_HELP.SENSOR_DATA.SHOW_TEMPERATURE_RANGE'),
                                uid: loggerNumber,
                            }}
                        />
                        <SwitchWithLabel
                            title={t('SENSOR_DATA.MARKERS')}
                            value={showMarkers}
                            onChange={handleChangeMarkers}
                            // setValue={setShowMarkers}
                        />
                    </div>
                </Typography>
                <ApexTemperatureChart
                    dateTimeFrom={timeRange.from}
                    dateTimeTo={localTimezone ? moment(timeRange.to).local().format('YYYY-MM-DDTHH:mm') : timeRange.to}
                    errorPictureSize="large"
                    getSensorDataBySerialNumber={getSensorData}
                    height={showMap ? 300 : 600}
                    inLocalTimeZone={localTimezone}
                    isLogger
                    loggerId={loggerId}
                    requestOptions={options}
                    requestType="loggers"
                    serialNumber={loggerNumber}
                    setCoordinates={setCoordinates}
                    setMouseMoveDataIndex={showMap ? setMouseMoveDataIndex : () => {}}
                    setZoomedDataLimits={showMap ? setZoomedDataLimits : null}
                    showMarkers={showMarkers}
                    showTempRange
                    temperatureRangeMax={tempRange.tempRangeMax}
                    temperatureRangeMin={tempRange.tempRangeMin}
                />
                {
                    showMap
                        ? (
                            polylines.path.length
                                ? (
                                    <Map
                                        handleLoad={handleLoad}
                                        inLocalTimeZone={localTimezone}
                                        mapConfig={mapConfig}
                                        separateMarker={markerData.position}
                                        separateMarkerIcon={markerData.icon}
                                        separatePolyline={polylines}
                                        separateTooltip={tooltipData}
                                        showCenterToPosition
                                    />
                                )
                                : (
                                    showGeolocationContentLoader
                                        ? <RectangleContentLoader height="45vh" />
                                        : (
                                            <NoSensorInfo
                                                picture="noDataFound"
                                                text={t('SENSOR_DATA.NO_GEOLOCATION')}
                                            />
                                        )
                                )
                        )
                        : null
                }
            </Card>
        </div>

    );
};

export default LoggerUnit;

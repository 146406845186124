import useClasses from 'hooks/useClasses';
import { wrapStyles } from 'hooks/useClasses/useClasses';
import React from 'react';
import icons from 'shared-components/icons';

const styles = wrapStyles((theme) => ({
    icon: {
        marginRight: '7px',
        maxHeight: '31px',
        maxWidth: '31px',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '15px',
        },
    },
    iconsContainer: {
        display: 'flex',
        marginRight: '14px',
    },
}));

type Props = {
    iconList: string[],
}

const IconList = ({ iconList = [] } : Props) => {
    const classes = useClasses(styles);

    return (
        <div style={{ display: 'flex', marginRight: '14px' }}>
            {
                iconList.map((icon) => {
                    return (
                        <img
                            key={icon}
                            alt="icon"
                            className={classes.icon}
                            src={icons[icon.toLowerCase()]}
                        />
                    );
                })
            }
        </div>
    );
};

export default IconList;

import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme) => ({
    bottomText: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 12px/14px Roboto',
        whiteSpace: 'nowrap',
    },
    button: {
        textTransform: 'uppercase',
    },
    left: {
        display: 'flex',
        flexDirection: 'column',
    },
    proposedToolTip: {
        background: theme.palette.primary[50],
        maxWidth: '600px',
        padding: '5px 0 5px 0',
    },
    proposedToolTipArrow: {
        color: theme.palette.primary[50],
    },
    right: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    tooltipContents: {
        background: theme.palette.secondary[50],
        display: 'grid',
        gridGap: '20px',
        gridTemplateColumns: '1fr 65px',
        padding: '8px',

    },
    tooltipPopper: {
        pointerEvents: 'auto',
    },
    topText: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        whiteSpace: 'nowrap',
    },
}));

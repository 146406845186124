import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => {
    return ({
        checkboxStyle: {
            margin: '0 9px',
            padding: '0',
        },
        container: {
            display: 'flex',
        },
        formControlLabelStyle: {
            whiteSpace: 'nowrap',
        },
        icon: {
            marginRight: '5px',
        },
        textFieldLabel: {
            color: theme.palette.secondary[400],
            font: 'normal normal normal 16px/19px Roboto',
            letterSpacing: '0.49px',
            opacity: 0.5,
            textAlign: 'left',
        },
        title: {
            color: theme.palette.secondary[500],
            font: 'normal normal normal 12px/14px Roboto',
            letterSpacing: '0.4px',
            opacity: 1,
            textAlign: 'left',
        },
    });
});

export default styles;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    customInfoSeverity: {
        backgroundColor: '#61C6E9!important',
        color: theme.palette.common.white,
    },
    iconWrapper: {
        alignItems: 'center',
        display: 'flex',
    },
    infoStatic: {
        backgroundColor: theme.palette.primary.deepBlue,
        color: theme.palette.common.white,
    },
    message: {
        color: theme.palette.common.white,
        margin: '0 auto',
    },
    progressIcon: {
        color: theme.palette.common.white,
        position: 'relative',
    },
    progressIconWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        left: '0px',
        position: 'relative',
        top: '0px',
        width: '100%',
    },
    statusMessageItemContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
        width: '100%',
        zIndex: 1600,
    },
    statusMessagesContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    statusMessagesInnerContainer: {
        bottom: '40px',
        position: 'fixed',
        zIndex: 1500,
    },
}));

export default styles;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    body: {
        background: 'white',
        boxShadow: '0px 2px 4px #00000033',
        height: '0px',
        maxWidth: '80vw',
        opacity: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
        transition: 'opacity 300ms ease-in-out',
        width: 'auto',
        zIndex: 41,
    },
    centered: {
        '& > &tooltipBody': {
            left: 'unset!important',
        },
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        left: '0px',
        pointerEvents: 'none',
        position: 'fixed',
        top: '0px',
        width: '100vw',
        zIndex: 41,
    },
    contents: {
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.primary[600],
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
            backgroundColor: theme.palette.primary[200],
            borderRadius: '10px',
        },

        '&::-webkit-scrollbar-track': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
            backgroundColor: theme.palette.primary[600],
            borderRadius: '10px',
        },
        '@media (max-width: 700px)': {
            padding: '4px',
        },
        maxHeight: 'calc(90vh - 38px)',
        overflow: 'auto',
        padding: '8px 24px',

    },
    hideLabel: {
        visibility: 'hidden',
    },
    modalBody: {
    // @ts-ignore
        backgroundColor: theme.palette.common.contrastWhite50,
    },
    skyTooltip: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '12px',
        userSelect: 'none',
        width: 'auto',
    },
    toggled: {
        height: 'auto',
        opacity: 1,
        pointerEvents: 'all',
    },
    tooltipBackground: {
        background: 'black',
        cursor: 'pointer',
        height: '100vh',
        left: '0px',
        opacity: 0,
        pointerEvents: 'none',
        position: 'fixed',
        top: '0px',
        width: '100vw',
        zIndex: 40,
    },
    tooltipBackgroundOn: {
        opacity: 0.5,
        pointerEvents: 'all',
    },
    tooltipBody: {
        '@media (max-width: 700px)': {
            left: '0px',
        },
        cursor: 'pointer',
        position: 'fixed',
    },
    tooltipIcon: {
        alignItems: 'center',
        backgroundColor: theme.palette.primary[200],
        borderRadius: '50%',
        color: 'white',
        display: 'flex',
        height: '18px',
        justifyContent: 'center',
        marginRight: '7px',
        width: '18px ',
    },
    tooltipTop: {
        background: theme.palette.primary[200],
        color: 'white!important',
        fontFamily: 'Roboto',
        fontSize: '14px',
        letterSpacing: '1.25px',
        padding: '9px 10px',
        textTransform: 'uppercase',
    },
    wrapper: {},
}));

export default styles;

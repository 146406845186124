import React, { useMemo } from 'react';
import Table from 'shared-components/Table/Table';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import { LocationHistoryRow } from 'SensorDataCommon/lib';
import tableColumns from './tableColumns';
import styles from './LocationHistoryTable.style';

type Props = {
    height: string;
    onRowClick: (_, i) => void;
    rows: LocationHistoryRow[];
    selectedIndex: number;
    widthPercent: number;
}

const LocationHistoryTable = ({ height, onRowClick, rows = [], selectedIndex, widthPercent }: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    const columns = useMemo(() => tableColumns(t), []);

    return (
        <div style={{
            height,
            marginBottom: '20px',
            minHeight: height,
            overflow: 'auto',
            width: `${widthPercent}%`,
        }}
        >
            <Table
                classNames={{
                    bodyCell: classes.noBorder,
                    clickedRowExternalClassName: classes.clickedRowExternalClassName,
                    headerCell: classes.noBorder,
                    linkedTableRowClassName: classes.noBorder,
                    rowExtensionClassName: classes.noBorder,
                    tableHeaderClassName: classes.tableHeader,
                    tableRowClassName: classes.noBorder,
                }}
                clickedRowIndexExternal={selectedIndex}
                clientSideFilter={{}}
                columns={columns}
                data={rows}
                embed
                setClientSideFilter={() => {}}
                tableMaxHeight={height}
                tableMinHeight="100%"
                onRowClick={onRowClick}
            />
        </div>
    );
};

export default LocationHistoryTable;

import { wrapStyles } from 'hooks/useClasses/useClasses';
import { filterFullWidth } from 'shared-components/SideFilter/SideFilter.style';

const styles = wrapStyles((theme) => ({
    chipLabel: {
        color: theme.palette.secondary[900],
        fontSize: '12px',
        letterSpacing: '0.02em',
    },
    chipRoot: {
        backgroundColor: '#F4F4F4!important',
        maxWidth: '100%',
        overflow: 'hidden',
        width: 'min-content',
    },
    chips: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        margin: '16px 1px 8px 1px',
    },
    deleteIcon: {
        color: `${theme.palette.secondary[600]}!important`,
    },
    multipleSelectionInput: {
        '& ::placeholder': {
            color: theme.palette.secondary[900],
            fontSize: '12px',
            overflow: 'visible',
        },
        '.MuiAutocomplete-inputRoot': {
            fontSize: '12px',
            lineHeight: '36px',
            padding: '0 4px',
            width: `${filterFullWidth}px`,

            [theme.breakpoints.up('md')]: {
                width: `${filterFullWidth - 40}px`,
            },

        },
    },
    optionList: {
        '&.MuiListItem-root': {
            padding: '6px 10px',
        },
        display: 'block',
        font: 'normal normal normal 12px Roboto',
    },
}));

export default styles;

import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme) => ({
    cardBody: {
        paddingTop: '14px',
    },
    cardBodyDivided: {
        '&>div': {
            display: 'grid',
            gridGap: '20px',
            gridTemplateColumns: '1fr',
        },
        display: 'grid',
        gridGap: '20px',
        gridTemplateColumns: '1fr 1fr',
    },
    icon: {
        '&:hover': {
            color: theme.palette.secondary[800],
        },
        color: theme.palette.secondary[600],
        cursor: 'pointer',
        fontSize: '23px',
        height: '23px',
        width: '23px',
    },
    informationCard: {
        gridColumn: 'span 3',
    },
    tooltipText: {
        '& img': {
            marginLeft: '-4px',
            marginRight: '6px',
            width: '30px',
        },
        '& span': {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '4px',
        },
        display: 'flex',
        flexDirection: 'column',
    },

}));

import icons from 'shared-components/icons';
import {
    APPROVAL_STATUS,
    TEMPERATURE_STATUS,
} from 'shared-components/constants';

export const getIconByStatus = (status: string): string => {
    switch (status) {
    case APPROVAL_STATUS.APPROVED:
        return icons.success;
    case APPROVAL_STATUS.REJECTED:
        return icons.warning_round;
    case APPROVAL_STATUS.PREDICTED_EXCURSION:
        return icons.warning_triangle;
    case TEMPERATURE_STATUS.IN_RANGE:
        return icons.success;
    case TEMPERATURE_STATUS.EXCURSION:
        return icons.warning_round;
    default:
        return icons.dots_round_grey;
    }
};

import React, { useContext, useEffect } from 'react';
import { LayoutContext } from 'Contexts/LayoutContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { useParams } from 'react-router-dom';
import { PathParams } from 'Administration/Shipments/lib';
import GenericShipmentDetailsContainer from 'TrackAndTrace/GenericShipmentDetails';

const ShipmentDetails = () => {
    const { t } = useCustomTranslation();
    const { id } = useParams<PathParams>();

    const {
        setCustomPageTitle,
        setMenuTabs,
    } = useContext(LayoutContext);

    useEffect(() => {
        setMenuTabs([
            {
                activePage: true,
                iconSrc: '',
                link: `/administration/shipments/${id}`,
                title: t('MENU_ITEMS.DETAILS'),
            },
            {
                activePage: false,
                iconSrc: '',
                link: `/administration/shipments/${id}/admin`,
                title: t('MENU_ITEMS.ADMIN'),
            },
        ]);
        setCustomPageTitle(t('MENU_ITEMS.SHIPMENTS'));
        return () => {
            setCustomPageTitle('');
            setMenuTabs([]);
        };
    }, [id]);

    return <GenericShipmentDetailsContainer core="administration" />;
};

export default ShipmentDetails;

import styles from 'Administration/PharmaSites/PharmaSites.style';
import React, {
    useState,
    useEffect, useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { ContactInfo as ContactInfoType } from 'dataTypes/SecureBackend/apiResponse';
import { Site } from 'dataTypes/SecureBackend/apiResponse/Site';
import { AddPharmaSiteRequestBody, Address } from 'dataTypes/SecureBackend/processedData';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import useCustomTitle from 'hooks/useCustomTitle';

import AddressCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/AddressCard';
import PharmaSiteCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/PharmaSiteCard';
// eslint-disable-next-line max-len
import ContactInformationCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import useClasses from 'hooks/useClasses';
import BackToLink from 'shared-components/BackToLink';
import useCustomTranslation from 'hooks/useCustomTranslation';

type PathParams = {
    pharmaSiteId: string,
}

const EditPharmaSite = () => {
    const classes = useClasses(styles);
    const { pharmaSiteId } = useParams() as unknown as PathParams;
    const hasAccess = useHasAccess();
    const isAdmin = useMemo(() => hasAccess(userRoles.SKYCELL_ADMIN), [hasAccess]);

    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { setTitle } = useCustomTitle();
    const { t } = useCustomTranslation();
    const [pharmaSiteInfo, setPharmaSiteInfo] = useState(null);
    const [currentEditedCard, setCurrentEditedCard] = useState<string>(null);
    const [address, setAddress] = useState<Address>(null);
    const [contactInfo, setContactInfo] = useState<ContactInfoType>(null);
    const [addCompanyRequestBody, setAddCompanyRequestBody] = useState<AddPharmaSiteRequestBody>(null);
    const [availableData, setAvailableData] = useState<Site>(null);
    const [requiredUpdateData, setRequiredUpdateData] = useState(true);

    const { Get: getAllSites } = useSkymindBackendEndpoints('admin/companies/sites').requests;
    const { FlexibleRequest: editPharmaSite } = useSkymindBackendEndpoints(
        isAdmin ? `admin/companies/${availableData?.companyId}/sites` : `companies/${availableData?.companyId}/sites`,
    ).statusNotificationRequests;

    const handleClickEdit = async () => {
        try {
            const {
                status = null,
            } = await editPharmaSite('PATCH', `/${pharmaSiteId}/overview-info`, addCompanyRequestBody);

            if (status === 200) {
                setRequiredUpdateData(true);
            }
        } catch (error) {
            global.console.log(error);
        }
    };

    useEffect(() => {
        if (address && contactInfo && pharmaSiteInfo) {
            setAddCompanyRequestBody({
                ...pharmaSiteInfo,
                address,
                contactInfo,
            });
        }
    }, [
        address,
        contactInfo,
        pharmaSiteInfo,
    ]);

    useEffect(() => {
        if (requiredUpdateData) {
            (async () => {
                try {
                    const rawResponse = await getAllSites(pharmaSiteId);

                    const response: Site = rawResponse?.data || null;

                    if (response) {
                        setAvailableData(response);
                        setContactInfo(response.contactInfo);
                        setPharmaSiteInfo({
                            name: response.siteName,
                        });
                        setAddress({
                            addressLine1: response.address.addressLine1,
                            addressLine2: response.address.addressLine2,
                            addressLine3: response.address.addressLine3,
                            city: response.address.city,
                            countryCode: response.address.country.code,
                            geolocation: response.address.geolocation,
                            zipCode: response.address.zipCode,
                        });
                        setBreadcrumbData('customBreadcrumb', `${response.companyName} / ${response.siteName}`);
                        setTitle(`Pharma Site Details: ${response.siteName}`);
                    }
                } catch (error) {
                    global.console.log(error);
                } finally {
                    setRequiredUpdateData(false);
                }
            })();
        }
    }, [requiredUpdateData]);

    return (
        availableData
            ? (
                <div>
                    <BackToLink
                        marginBottom={0}
                        marginRight={0}
                        title={t('COMMON.PHARMA_SITES')}
                        to="/administration/pharma-sites"
                    />
                    <div className={classes.editCompanyPageContent}>
                        <div className={classes.addEditCompanyCardContentData}>
                            <PharmaSiteCard
                                currentCompanyLogo={availableData.logoImagePath}
                                currentCompanyType="PHARMA"
                                currentEditedCard={currentEditedCard}
                                currentSiteName={availableData?.siteName}
                                saveInfo={handleClickEdit}
                                setCurrentEditedCard={setCurrentEditedCard}
                                setFilter={setPharmaSiteInfo}
                                type="editable"
                            />
                            <AddressCard
                                currentAddressLine1={availableData.address.addressLine1}
                                currentAddressLine2={availableData.address.addressLine2}
                                currentAddressLine3={availableData.address.addressLine3}
                                currentCity={availableData.address.city}
                                currentCountry={availableData.address.country.name}
                                currentCountryCode={availableData.address.country.code}
                                currentEditedCard={currentEditedCard}
                                currentGeolocation={availableData.address.geolocation}
                                currentZipCode={availableData.address.zipCode}
                                saveInfo={handleClickEdit}
                                setCurrentEditedCard={setCurrentEditedCard}
                                setFilter={setAddress}
                                type="editable"
                            />
                            <ContactInformationCard
                                currentContactPerson={availableData.contactInfo.contactPerson}
                                currentEditedCard={currentEditedCard}
                                currentEmail={availableData.contactInfo.email}
                                currentPhoneNumber={availableData.contactInfo.phoneNumber}
                                saveInfo={handleClickEdit}
                                setCurrentEditedCard={setCurrentEditedCard}
                                setFilter={setContactInfo}
                                type="editable"
                            />
                        </div>
                    </div>
                </div>
            )
            : null
    );
};

export default EditPharmaSite;

import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { AxiosResponse } from 'axios';
import { IncludeFilters } from 'shared-components/dataTypes';
import {
    ColumnsType,
    TableMaxHeight,
} from 'shared-components/Table/dataTypes';
import Table from 'shared-components/Table';

type Props = {
    columns: ColumnsType[],
    dataRequest: (params?: object, queryObj?: object) => Promise<AxiosResponse<any> | any>,
    defaultIncludeFilters?: IncludeFilters,
    itemsOnPage?: number,
    onRowClick?: (...args: any) => void,
    rowExtension?: React.ReactNode,
    rowLinkTemplate?: string,
    tableMaxHeight?: TableMaxHeight
}

const DynamicTable = ({
    columns,
    dataRequest,
    defaultIncludeFilters = {},
    itemsOnPage = 20,
    onRowClick,
    rowExtension = null,
    rowLinkTemplate = null,
    tableMaxHeight = '80vh',
}: Props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);

    // Ref to hold the observer instance
    const observer = useRef<IntersectionObserver | null>(null);

    const lastRowRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && data.length > itemsOnPage) {
                setPageNumber(prevPageNumber => prevPageNumber + 1);
            }
        });

        if (node) observer.current.observe(node);
    }, [loading, hasMore, data.length, itemsOnPage]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await dataRequest({
                rows: pageNumber === 1 ? itemsOnPage * 2 : itemsOnPage,
                start: (pageNumber - 1) * itemsOnPage,
                ...defaultIncludeFilters,
            });

            if (response?.data?.items) {
                setData(response.data.items);
                setHasMore(response.data.items.length > 0);
            }
            setLoading(false);
        };

        fetchData();
    }, [dataRequest, pageNumber, itemsOnPage, defaultIncludeFilters]);

    return (
        <Table
            columns={columns}
            data={data}
            lastRowRef={lastRowRef}
            rowExtension={rowExtension}
            rowLinkTemplate={rowLinkTemplate}
            tableMaxHeight={tableMaxHeight}
            onRowClick={onRowClick}
        />
    );
};

export default DynamicTable;

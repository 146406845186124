import { wrapStyles } from 'hooks/useClasses/useClasses';

export const styles = wrapStyles((theme) => ({
    button: {
        margin: '9px',
        textTransform: 'uppercase',
    },
    card: {
        boxShadow: '0px 1px 3px #00000033',
    },
    cardContents: {
        display: 'grid',
        gridGap: '18px',
        gridTemplateColumns: '1fr 1fr',
    },
    cardField: {
        // display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        // padding: '10px 35px 10px 0px',
    },
    cardFieldFlexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    datePicker: {
        '& .css-1t8wyba-MuiButtonBase-root-MuiIconButton-root': {
            '& .MuiTypography-caption': {
                color: '#FFF!important',
            },
            background: '#61C6E9',
        },
        '& .css-2ujp1m': {
            background: '#61C6E9',
            border: '16px solid #61C6E9',
        },
        '& .css-7lip4c': {
            background: '#61C6E9!important',
        },
        '& .css-12ha4i7': {
            background: '#61C6E9!important',
        },
        '& .css-13go44a-MuiButtonBase-root-MuiIconButton-root': {
            '& .MuiTypography-caption': {
                color: '#FFF!important',
            },
            background: '#61C6E9',
        },
        '& .css-118whkv': {
            background: '#61C6E9',
            border: '16px solid #61C6E9',
        },
        '& .css-a1rc6s': {
            background: '#61C6E9!important',
        },
        '& .Mui-selected': {
            background: '#61C6E9!important',
            color: '#FFF!important',
        },
        '& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium': {
            '& .MuiTypography-caption': {
                color: '#000!important',
            },
            background: 'rgba(0, 0, 0, 0.04)',
        },
        '& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-dgcnuc': {
            '& .MuiTypography-caption': {
                color: '#FFF!important',
            },
            background: '#61C6E9!important',
        },
        '& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-dgcnuc:hover': {
            '& .MuiTypography-caption': {
                color: '#FFF!important',
            },
            background: '#61C6E9!important',
        },
        '& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-ocommy': {
            '& .MuiTypography-caption': {
                color: '#FFF!important',
            },
            background: '#61C6E9!important',
        },
        '& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-ocommy:hover': {
            '& .MuiTypography-caption': {
                color: '#FFF!important',
            },
            background: '#61C6E9!important',
        },
        '& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium:focus': {
            '& .MuiTypography-caption': {
                color: '#FFF!important',
            },
            background: '#61C6E9!important',
        },
        '& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium:hover': {
            '& .MuiTypography-caption': {
                color: '#FFF!important',
            },
            background: '#61C6E9!important',
        },
        '& label': {
            paddingLeft: '10px',
        },
    },
    fieldTitle: {
        color: theme.palette.secondary.main,
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        // margin: '12px 0 2px 0',
        marginBottom: '2px',
        opacity: 1,
        textAlign: 'left',
    },
    inputField: {
        width: '100%',
    },
    loading: {
        filter: 'blur(1px)',
        pointerEvents: 'none',
    },
    loadingPicker: {
        '&:after': {
            background: '#0000000f',
            content: '""',
            display: 'block',
            height: '100%',
            left: '0',
            position: 'absolute',
            top: '0',
            width: '100%',
        },
        filter: 'blur(1px)',
    },
    mandatory: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderLeft: `6px solid ${theme.palette.primary[200]}`,
        borderRadius: '3px',
        opacity: 1,
        paddingLeft: '3px',
        transition: '200ms ease',
    },
    shift: {
        '& label': {
            paddingLeft: '10px',
            zIndex: 10,
        },
    },
}));

import { wrapStyles } from 'hooks/useClasses/useClasses';

const TABLE_PADDING = 20;

const styles = wrapStyles((theme) => ({
    boxShadow: {
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    },
    dateFilterContainer: {
        alignItems: 'center',
        border: '2px solid #61C6E9',
        borderRadius: 4,
        display: 'flex',
        margin: '5px',
        padding: '10px',
    },

    dateFilterContainerText: {
        color: '#747474',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    datePicker: {
        '& .css-7lip4c': {
            backgroundColor: '#61C6E9!important',
        },
        '& .css-12ha4i7': {
            backgroundColor: '#61C6E9!important',
        },
        '& .css-118whkv': {
            backgroundColor: '#61C6E9!important',
            border: '16px solid #61C6E9',
        },
        '& .Mui-selected': {
            background: '#61C6E9!important',
            color: '#FFF!important',
        },
        '& .MuiButton-textPrimary': {
            color: '#61C6E9!important',
        },
    },

    datePickerTextField: {
        width: '120px',
    },
    noBar: {
        '&::after': {
            background: 'transparent!important',
        },
    },
    noData: {
        alignItems: 'center',
        color: theme.palette.secondary[600],
        display: 'flex',
        height: '50px',
        justifyContent: 'center',
        width: '100%',
    },
    reactTableTableRow: {
        borderBottom: `1px solid ${theme.palette.secondary[100]}`,
        minHeight: '30px',
        width: '100%',
    },
    rowWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    tableComponent: ({ title }) => ({
        backgroundColor: 'transparent !important',
        border: 'none',
        color: theme.palette.secondary[600],
        font: 'normal normal 500 1rem Roboto',
        margin: 'auto',
        textDecoration: 'none',
        width: title ? `calc(100% - ${TABLE_PADDING * 2}px)` : '100%',
        wordBreak: 'break-word',
        [theme.breakpoints.down('sm')]: {
            wordBreak: 'unset',
        },
    }),
    tableContainer: {
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.secondary[100],
            height: '6px',
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-track': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
            backgroundColor: theme.palette.common.white,
            borderRadius: '10px',
        },
        background: theme.palette.common.white,
        borderRadius: 6,
        overflow: 'auto',
        paddingBottom: '20px',
        scrollSnapType: 'y mandatory',
    },

    tableHeader: {
        background: theme.palette.common.white,
        boxShadow: '0 0.6px rgba(0, 0, 0, 0.1)',
        paddingLeft: '12px',
        position: 'sticky',
        top: '0',
        zIndex: 29,
    },
    tableTitle: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        fontSize: '32px',
        justifyContent: 'space-between',
        left: 0,
        margin: '12px 0px',
        padding: '12px',
        position: 'sticky',
        top: 0,
        width: '100%',
        zIndex: 10,
    },
}));

export default styles;

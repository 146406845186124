import React, { SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import LogoutIcon from '@mui/icons-material/Logout';
import useCustomTranslation from 'hooks/useCustomTranslation';
import PersonIcon from '@mui/icons-material/Person';
import useClasses from 'hooks/useClasses';
import styles from './MainUserInfo.style';

type Props = {
    buttonRect?: DOMRect,
    logout: (arg: React.MouseEvent<HTMLButtonElement>) => void,
    notificationCount: number,
    open: boolean,
    setOpen: (prev : SetStateAction<boolean>) => void
}

const MainUserInfoMenuEntries = ({
    buttonRect,
    logout,
    open = false,
    setOpen,
} : Props) => {
    const classes = useClasses(styles, {
        collapseWidth: buttonRect?.width || 162,
        left: buttonRect?.left,
        right: buttonRect?.left ? null : 15,
    });
    const { t } = useCustomTranslation();

    const handleClickToCollapseItem = () => setOpen(false);

    return (
        <Collapse
            className={classes.collapse}
            in={open}
            timeout="auto"
            unmountOnExit
        >
            <div style={{ display: 'grid' }}>
                <Link className={classes.a} to="/my-profile">
                    <Button
                        key="my-profile"
                        className={classes.userInfoMenuEntry}
                        onClick={handleClickToCollapseItem}
                    >
                        <PersonIcon className={classes.userInfoMenuEntryIcon} fontSize="small" />
                        <div className={classes.notificationLabel}>My Profile</div>
                    </Button>
                </Link>
                <Button
                    key="logout"
                    className={classes.userInfoMenuEntry}
                    onClick={logout}
                >
                    <LogoutIcon className={classes.userInfoMenuEntryIconLogout} fontSize="small" />
                    {t('COMMON.LOG_OUT')}
                </Button>
            </div>
        </Collapse>
    );
};

export default MainUserInfoMenuEntries;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    dashWrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
    },
    dot: {
        backgroundColor: theme.palette.primary.deepBlue,
        borderRadius: '50%',
        height: '8px',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        width: '8px',
        zIndex: 10,
    },
    dotFirst: {
        height: '16px',
        width: '16px',
    },
    line: {
        left: '50%',
        position: 'absolute',
        top: 1,
        transform: 'translateX(-50%)',
        zIndex: 9,
    },
}));

export default styles;

import { Asset } from 'dataTypes/SecureBackend/apiResponse';
import { getUtcMoment } from 'utils/timeUtils';

export interface ExtractedAssetData {
    area: string,
    areaLyingSince?: number,
    areaLyingSinceTimestampUnit?: {
        unit: string,
        value: number,
    }
    assetNumber: string,
    assetTypeCode: string,
    batteryLevel?: number,
    batteryLevelThreshold?: number,
    city: string,
    countryCode: string,
    iataCode: string,
    id: number,
    lastMeasuredLatitude: number,
    lastMeasuredLongitude: number,
    lastMeasuredTimestamp?: number,
    lastMeasuredTimestampUnit?: {
        unit: string,
        value: number,
    }
    locationLyingSince?: number,
    locationLyingSinceTimestampUnit?: {
        unit: string,
        value: number,
    }
    locationName: string,
    loggerFamily?: string,
    loggerFamilyLabel?: string,
    loggerNumber?: string,
    ownedByCompanyId?: number,
    ownedByCompanyName?: string,
    pairingStatus?: 'PAIRED' | 'NOT_PAIRED',
    temperature: number,
    uldOwnerCode: string,
    uldType?: string,
}

export const extractAssetData = (rawData: Asset[]): ExtractedAssetData[] => {
    return rawData
        .map((item) => {
            const {
                areaLyingSince,
                areaLyingSinceTimestampUnit,
                assetNumber = '',
                assetTypeCode,
                id,
                lastMeasuredData = null,
                locationLyingSince,
                locationLyingSinceTimestampUnit,
                loggerNumber,
                ownedByCompanyId,
                ownedByCompanyName,
                pairingStatus,
                uldOwnerCode,
                uldType,
            } = item;

            const {
                area,
                batteryLevel,
                city,
                countryCode,
                geolocation = null,
                iataCode,
                locationName = null,
                temperature,
                temperatureGeolocationTimestamp,
            } = lastMeasuredData || {};

            const {
                latitude = null,
                longitude = null,
            } = geolocation || {};

            return {
                area,
                areaLyingSince: getUtcMoment(areaLyingSince).valueOf(),
                areaLyingSinceTimestampUnit,
                assetNumber,
                assetTypeCode,
                batteryLevel,
                city,
                countryCode,
                iataCode,
                id,
                lastMeasuredLatitude: latitude ? (Math.trunc(latitude * 10000) / 10000) : null,
                lastMeasuredLongitude: longitude ? (Math.trunc(longitude * 10000) / 10000) : null,
                lastMeasuredTimestamp: temperatureGeolocationTimestamp
                    ? getUtcMoment(temperatureGeolocationTimestamp).valueOf()
                    : null,
                locationLyingSince: getUtcMoment(locationLyingSince).valueOf(),
                locationLyingSinceTimestampUnit,
                locationName,
                loggerNumber,
                ownedByCompanyId,
                ownedByCompanyName,
                pairingStatus,
                temperature,
                uldOwnerCode,
                uldType,
            };
        });
};
export const initialAssetFilterOptions: { [optionsGroupKey: string]: string[] } = {
    areaLyingSince: [],
    areas: [],
    assetTypeCodes: [],
    cities: [],
    countries: [],
    iataCodes: [],
    ownedByCompanyIds: [],
    pairingStatuses: [],
    uldOwnerCodes: [],
    uldTypes: [],
};

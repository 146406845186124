import { wrapStyles } from 'hooks/useClasses/useClasses';
import { TITLE_HEIGHT } from './ClusterBodyItem';

export const styles = wrapStyles((theme) => ({
    body: {
        background: theme.palette.common.white,
    },
    containerExpand: {

    },
    containerIcon: {
        borderRadius: '8px',
        height: '24px',
        margin: 'auto 2px',
        marginTop: '2px',
        width: '24px',
    },
    containerLabel: {
        color: theme.palette.common.black,
        fontSize: '10px',
        letterSpacing: '0.01em',
    },
    containerName: {

    },
    containerNumber: {
        color: theme.palette.common.black,
        fontSize: '16px',
        letterSpacing: '0.01em',
    },
    containerStatus: {
        alignItems: 'flex-end',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginRight: '5px',
    },
    containerStatusDescription: {
        color: theme.palette.common.black,
        display: 'flex',
        float: 'right',
        fontSize: '10px',
        letterSpacing: '0.01em',
        whiteSpace: 'nowrap',
    },
    containerStatusTitle: {
        alignItems: 'flex-end',
        color: theme.palette.common.black,
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        gap: '6px',
        letterSpacing: '0.01em',
    },
    customAirports: {
        margin: '4px 6px',
    },
    description: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        padding: '0px 10px',
        width: '100%',
    },
    descriptionInfo: {
        alignItems: 'center',
        margin: '0 3px',
    },
    division: {
        alignItems: 'center',
        borderTop: '1px solid #dbdbdb',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        justifyContent: 'center',
        padding: '15px',
        width: '100%',
    },
    header: {
        alignItems: 'center',
        background: 'white',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        height: TITLE_HEIGHT,
        minWidth: '365px',
    },
    headerInfoGroup: {
        '& *': {
            color: theme.palette.common.black,
        },
        margin: 0,
    },
    icon: {
        height: 14,
        marginLeft: 3,
    },
    packagingBottom: {
        display: 'flex',
        marginTop: '10px',
    },
    packagings: {
        color: theme.palette.common.black,
        fontSize: '14px',
        letterSpacing: '0.01em',
        whiteSpace: 'pre',
    },
    packagingsText: {
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    packagingTop: {
        display: 'flex',
        width: '100%',
    },
    packagingTopLeft: {
        '&>img': {
            transform: 'translateX(-4px)',
        },
        width: '26px',
    },
    packagingTopMiddle: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    packagingTopRight: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '30px',
    },
    planned: {
        fontSize: '14px',
        letterSpacing: '0.01em',
    },
    predicted: {
        color: 'rgb(237, 174, 73)',
    },
    redTemperatureInfo: {
        color: theme.palette.common.red,
    },
    rotate: {
        transform: 'scaleY(-1)',
    },
    shortInfo: {
        display: 'flex',
        gap: '20px',
        width: '100%',
    },
    slash: {
        margin: '0 5px',
    },
    status: {
        alignItems: 'center',
        color: theme.palette.common.red,
        display: 'flex',
        fontFamily: 'Roboto',
        fontSize: '10px',
        fontWeight: 'normal',
        letterSpacing: '0.01em',
        whiteSpace: 'nowrap',
    },
    temperatureInfoLabel: {
        color: theme.palette.common.black,
    },
    temperatureStatus: {
        '& img': {
            height: '16px',
            marginRight: '3px',
            width: '16px',
        },
        alignItems: 'center',
        display: 'flex',

    },
    title: {
        alignItems: 'flex-end',
        color: theme.palette.common.black,
        display: 'flex',
        flex: 1,
        fontFamily: 'Roboto',
        fontSize: '32px',
        fontWeight: 'normal',
        letterSpacing: '0.01em',
        textAlign: 'left',
        textDecoration: 'underline',
    },
    warning: {
        color: theme.palette.common.red,
    },
    wrapper: {
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        zIndex: 100,
    },
}));

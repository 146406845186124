import useClasses from 'hooks/useClasses';
import React, { useContext } from 'react';
import rootStyles from 'LaneManagement/LaneDetails/LaneDetails.style';
import { AddressDTO } from 'shared-components/dataTypes';
import LaneCardsContext from 'Contexts/LaneCardsContext';
import Card from 'shared-components/Card';
import LocationInfo from './LocationInfo';

type Props = {
    description?: string,
    selectedLocation: AddressDTO,
    title?: string
}

const AddLocation = ({
    description,
    selectedLocation,
    title = 'Loading Location',
}: Props) => {
    const classes = useClasses(rootStyles);
    const {
        editingCard,
    } = useContext(LaneCardsContext);

    return (
        <Card
            contentClass={classes.fullHeight}
            disabled={!!editingCard}
            fullHeight
            title={title}
        >
            <div className={[classes.cardBody, classes.centered].join(' ')}>
                {
                    !selectedLocation && (
                        <div className={classes.noLocation}>
                            {description}
                        </div>
                    )
                }
                {
                    selectedLocation && (
                        <LocationInfo
                            emptyText=""
                            font="medium"
                            location={selectedLocation}
                        />
                    )
                }
            </div>

        </Card>
    );
};

export default AddLocation;

import { AddressDTO, Milestone, MilestoneActivity } from 'shared-components/dataTypes';
import { nonNullKey } from 'shared-components/common';
import React from 'react';
import { compareLocations } from './objectDefaults';

export const getFlights = (steps: Milestone[]) => {
    const flightSteps = steps
        .map(step => [step?.pickupLocation, step?.deliveryLocation])
        .flat()
        .filter(address => nonNullKey(address) === 'airport');
    const normalizedSequence = [];

    flightSteps.forEach((flight, index) => {
        if (index === flightSteps.length - 1) {
            normalizedSequence.push(flight);
            return;
        }
        if (!compareLocations(flight, flightSteps[index + 1])) normalizedSequence.push(flight);
    });
    return normalizedSequence;
};
export const durationToSecondsHM = (durationString = '00:00', t) => {
    const [hours, minutes] = durationString
        .replace(t('TIME.M'), '')
        .replace(t('TIME.H'), '')
        .split(' ')
        .map(it => Number(it));

    return (hours * 60 * 60) + (minutes * 60);
};
export const secondsToDurationStringHHMM = (duration = 0, t) => {
    const durationMinutes = Math.floor(duration / 60);

    return `${(Math.min(Math.floor(durationMinutes / 60), 99)).toString()}${t('TIME.H')}\
 ${(durationMinutes % 60)}${t('TIME.M')}`;
};
export const dtoAddressToDTOObject: (arg: AddressDTO) => any = (addressDTO: AddressDTO) => {
    if (!addressDTO) return null;
    const type = nonNullKey(addressDTO);
    const typeMap = {
        airport: 'airportCode',
        pharmaSite: 'pharmaSiteId',
        seaport: 'seaportCode',
        serviceProviderSite: 'serviceProviderSiteId',
    };

    if (type === 'address') {
        return {
            address: {
                ...addressDTO.address,
                countryCode: addressDTO.address?.country?.code || addressDTO.address?.countryCode,
            },
        };
    }
    return {
        [typeMap[type]]: addressDTO[type]?.code || addressDTO[type]?.id,
    };
};

export const formTitle = (icon, label) => {
    return (
        <span
            style={{
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <img
                alt="road"
                src={icon}
                style={{
                    height: '25px',
                    marginRight: '5px',
                    width: '25px',
                }}
            />
            {' '}
            {label}
        </span>
    );
};

export const deg2rad = (deg: number) => deg * (Math.PI / 180);

export const getDistanceFromLatLonInKm = (
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number,
) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLng = deg2rad(lng2 - lng1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
        * Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distance in km
    return R * c;
};

export const unwrapEnum = (object: {
    [x: string]: any;
    activities?: MilestoneActivity[];
    certifiedOn?: string;
    deliveryLocation?: AddressDTO;
    documentUrl?: string;
    index?: number;
    isImmutable?: boolean;
    packagingPictureUrl?: string;
    pickupLocation?: AddressDTO;
    provider?: string;
    serviceProviderSiteId?: number;
    summerProfile?: boolean | { colSpan: number; data: string; };
    summerReserve?: string;
    supportedTemperatureRanges?: {
        temperatureRange: string;
        temperatureSpecificName: string;
    }[];
    technology?: string;
    temperatureRange?: string;
    temperatureSpecificName?: string;
    type?: string;
    uniqueId?: string;
    validationReport?: {
        certifiedOn: string;
        documentUrl: string;
    };
    winterProfile?: string;
    winterReserve?: string;
}) => Object.keys(object).map(key => ({
    [key]: (object[key]?.code && object[key]?.label) ? object[key]?.code : object[key],
})).reduce((a, b) => ({ ...a, ...b }), {});

export const reorderElement = (array: Milestone[], source: number, destination: number) => {
    const result = [...array];
    const [removed] = result.splice(source, 1);

    result.splice(destination, 0, removed);

    return result;
};

import useClasses from 'hooks/useClasses';
import { wrapStyles } from 'hooks/useClasses/useClasses';
import React from 'react';

const styles = wrapStyles((theme) => ({
    commodityContainer: {
        alignItems: 'center',
        color: theme.palette.common['contrastGray600'],
        display: 'flex',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    iconOnLeftSide: {
        marginRight: '11px',
        width: '20px',
    },
    iconOnRightSide: {
        marginLeft: '11px',
        right: '0',
        width: '20px',
    },
    marginBottom10px: {
        marginBottom: '10px',
    },
}));

type Props = {
    customIconClass?: string,
    icon: string,
    iconOnRightSide?: boolean,
    label: string,
    marginBottomDisabled?: boolean,
}

const RowWithIcon = ({
    customIconClass = null,
    icon,
    iconOnRightSide = false,
    label,
    marginBottomDisabled = false,
} : Props) => {
    const classes = useClasses(styles);

    return (
        <div
            key={label}
            className={`${classes.commodityContainer} ${!marginBottomDisabled ? classes.marginBottom10px : ''}`}
        >
            {iconOnRightSide && <div>{label}</div>}
            <div className={iconOnRightSide ? classes.iconOnRightSide : classes.iconOnLeftSide}>
                <img
                    alt={label}
                    className={customIconClass}
                    src={icon}
                />
            </div>
            {!iconOnRightSide && <div>{label}</div>}
        </div>
    );
};

export default RowWithIcon;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    button: {
        '& svg': {
            maxWidth: '36px',
        },
        '&:hover': {
            background: theme.palette.primary.deepBlue,
            opacity: 0.8,
        },
        background: theme.palette.primary.deepBlue,
        color: theme.palette.common.white,
        height: '35px',
        textTransform: 'uppercase',
    },
    packagingContainer: {
        '@media (max-width: 1600px)': {
            gridTemplateColumns: '1fr',
        },
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '1fr',
        marginBottom: '24px',
    },
    seasonInfoContainer: {
        '@media (max-width: 1600px)': {
            gridTemplateColumns: '1fr',
        },
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '1fr 5fr',
        gridTemplateRows: 'auto',
        marginBottom: '24px',
    },
    simulationSummaryTitle: {
        marginBottom: '16px',
        marginLeft: '8px',
    },
    transportEmbedded: {
        padding: 0,
        paddingBottom: '16px!important',
    },
}));

export default styles;

import { wrapStyles } from 'hooks/useClasses/useClasses';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = wrapStyles((theme: SkycellThemeInterface) => ({
    bigLogo: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    burger: {
        color: 'white',
        fontSize: '45px',
    },
    burgerHolder: {
        display: 'none',
        paddingLeft: '12px!important',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
        },
    },
    iconContainer: {
        [theme.breakpoints.down('xs')]: {

        },
    },
    logoContainer: {
        alignItems: 'end',
        background: theme.palette.primary.darkBlue,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        height: '156px',
        justifyContent: 'space-between',
        padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
        },
        [theme.breakpoints.down('xs')]: {
            height: '56px!important',
            padding: `${theme.spacing(0)} ${theme.spacing(0)}!important`,
        },

    },
    mainPage: {
        height: '205px',
    },
    smallLogo: {
        display: 'none',
        height: '47px',
        width: '48px',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
        },
    },
    titleHeader: {
        color: theme.palette.common.menuTextWhite,
        fontSize: theme.typography.title.fontSize,
        fontWeight: 100,
        [theme.breakpoints.down('xs')]: {
            fontSize: '28px',
            marginLeft: '10px',
        },
    },
    wrapper: {},
}));

export default styles;

import { LaneObject, Milestone } from 'shared-components/dataTypes';
import { ENTITY_TYPE } from 'hooks/useGetEntity/useGetEntity';
import { Classes } from 'jss';
import { InformationCardField } from 'LaneManagement/LaneDetails/Cards/SummaryCard/fieldMapper';
import { TEMPERATURE_RANGES, INCOTERMS } from 'shared-components/constants';
import React from 'react';
import TempRange from 'shared-components/TempRange';
import { TFunction } from 'i18next';

export const bakeLane = (original: LaneObject, replaceWithAddressDTO, getEntity, entityFromAddressId) => {
    const newLaneObject: LaneObject = { ...original };

    newLaneObject.fourthPartyLogistics = {
        serviceProviderSite:
            getEntity(original.fourthPartyLogisticsId, ENTITY_TYPE.SITE),
    };
    newLaneObject.milestones = original.milestones.map((milestone:Milestone) => ({
        ...milestone,
        deliveryLocation: entityFromAddressId(milestone.deliveryLocation),
        pickupLocation: entityFromAddressId(milestone.pickupLocation),
    }));

    return newLaneObject;
};

export const fieldsLeft: (classes: Classes, t: TFunction, laneId?: any, company?: any, laneData?: LaneObject)
    => InformationCardField[] = (classes, t, laneId = null, company, laneData) => [
        {
            id: 'temperatureRange',
            items: Object.keys(TEMPERATURE_RANGES)
                .map(key => ({
                    render: <TempRange
                        key={`option_${key}`}
                        tempRange={key}
                    />,
                    value: key,
                })),
            label: t('COMMON.TEMPERATURE_RANGE'),
            readOnly: laneId !== null,
            type: 'select',
        },
        ...(laneId ? [{
            id: 'laneNumber',
            label: t('LANE_MANAGEMENT.SUMMARY_CARD.LANE_NAME'),
            readOnly: true,
            type: 'text',
        }] : []),
        {
            disabled: Boolean(laneId),
            filter: company.companyType !== 'PHARMA' ? {
                companyType: 'PHARMA',
            } : {},
            id: 'pharmaCompanyId',
            label: t('COMMON.PHARMA_COMPANY'),
            mandatory: true,
            readOnly: Boolean(laneId) || Boolean(company.companyType === 'PHARMA'),
            request: 'companies',
            type: 'company',
        },
        {
            disabled: Boolean(laneId),
            filter: company?.companyType === 'PHARMA' ? (laneId ? {
                serviceProviderId: laneData?.fourthPartyLogisticsId,
            } : {}) : {
                serviceProviderId: company?.id,
            },
            id: 'fourthPartyLogistics',
            label: t('COMMON.4_PL'),
            mandatory: true,
            showOnly: ['serviceProviderSite'],
            type: 'location',
        },
        {
            hide: !laneData.primaryCarrierId,
            id: 'primaryCarrier',
            label: t('LANE_MANAGEMENT.SUMMARY_CARD.PRIMARY_CARRIER'),
            readOnly: true,
            type: 'readOnlyLocation',
        },
    ];

export const fieldsRight: (classes: Classes, t: TFunction) => InformationCardField[] = (classes, t) => [
    {
        id: 'comment',
        label: t('COMMON.COMMENT'),
        type: 'text',
    },
    {
        id: 'description',
        label: t('LANE_MANAGEMENT.SUMMARY_CARD.DESCRIPTION'),
        type: 'text',
    },
    {
        id: 'incoterms',
        items: Object.keys(INCOTERMS).map(key => ({
            render: INCOTERMS[key],
            value: key,
        })),
        label: t('LANE_MANAGEMENT.SUMMARY_CARD.INCOTERMS'),
        type: 'select',
    },
    {
        id: 'status',
        items: [
            {
                render: 'Active',
                value: 'ACTIVE',
            },
            {
                render: 'Inactive',
                value: 'INACTIVE',
            },
        ],
        label: t('LANE_MANAGEMENT.SUMMARY_CARD.LANE_STATUS'),
        type: 'radio',
    },
];

import AccessRequests from 'Administration/AccessRequests';
import Sites from 'Administration/Companies/tabs/Sites';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';

import LocationHistory from 'SensorDataCommon/LocationHistory';
import Companies from './Companies';
import AddCompany from './Companies/tabs/AddCompany';
import EditCompany from './Companies/tabs/EditCompany';
import ServiceProviderSites from './ServiceProviderSites';
import AddServiceProviderSite from './ServiceProviderSites/tabs/AddServiceProviderSite';
import EditServiceProviderSite from './ServiceProviderSites/tabs/EditServiceProviderSite';
import PharmaSites from './PharmaSites';
import AddPharmaSite from './PharmaSites/tabs/AddPharmaSite';
import EditPharmaSite from './PharmaSites/tabs/EditPharmaSite';
import Users from './Users';
import AddUser from './Users/tabs/AddUser';
import EditUser from './Users/tabs/EditUser';
import Shipments from './Shipments';
import ShipmentDetails from './Shipments/tabs/ShipmentDetails';
import ShipmentAdmin from './Shipments/tabs/ShipmentAdmin';
import Assets from './Assets';
import AssetSensorDataContainer from '../SensorDataCommon/AssetSensorDataContainer';
import AssetPairings from '../SensorDataCommon/AssetPairings';

const base = '/administration';

const AdministrationRouter: RouteItem[] = [
    {
        element: <Navigate to="/administration/companies" />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: base,
    },
    {
        element: <Assets />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/assets`,
    },
    {
        element: <AssetSensorDataContainer />,
        necessaryRoles: [
            userRoles.SKYCELL_ADMIN,
        ],
        path: [
            `${base}/assets/readout/:entityNumber`,
        ],
    },
    {
        element: <AssetPairings />,
        necessaryRoles: [
            userRoles.SKYCELL_ADMIN,
        ],
        path: [
            `${base}/assets/pairings/:entityNumber`,
        ],
    },
    {
        element: <LocationHistory />,
        necessaryRoles: [
            userRoles.SKYCELL_ADMIN,
        ],
        path: [
            `${base}/assets/location/:entityNumber`,
        ],
    },
    {
        element: <Companies />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/companies`,
    },
    {
        element: <AddCompany />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/companies/add`,
    },
    {
        element: <EditCompany />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/companies/:companyId`,
    },
    {
        element: <Sites />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/companies/:companyId/sites`,
    },
    {
        element: <ServiceProviderSites />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/service-provider-sites`,
    },
    {
        element: <AddServiceProviderSite />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/service-provider-sites/add`,
    },
    {
        element: <EditServiceProviderSite />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/service-provider-sites/:serviceProviderSiteId`,
    },
    {
        element: <PharmaSites />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/pharma-sites`,
    },
    {
        element: <AddPharmaSite />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/pharma-sites/add`,
    },
    {
        element: <EditPharmaSite />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/pharma-sites/:pharmaSiteId`,
    },
    {
        element: <Users />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/users`,
    },
    {
        element: <AddUser />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/users/add`,
    },
    {
        element: <EditUser admin />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/users/:userId`,
    },
    {
        element: <Shipments />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/shipments`,
    },
    {
        element: <ShipmentDetails />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/shipments/:id`,
    },
    {
        element: <ShipmentAdmin />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/shipments/:id/admin`,
    },
    {
        element: <AccessRequests />,
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        path: `${base}/access-requests`,
    },
];

export default AdministrationRouter;

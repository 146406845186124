import React from 'react';

export type CustomTheme = 'default' | 'dark';

interface CustomThemeContextInterface {
    setCustomTheme: (theme: object) => void,
    theme?: CustomTheme,
}

const initialState = {
    setCustomTheme: () => {},
    theme: 'default' as CustomTheme,
};

const CustomThemeContext = React.createContext<CustomThemeContextInterface>(initialState);

export default CustomThemeContext;

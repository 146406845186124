/* eslint-disable react/jsx-key */
import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import TempRange from 'shared-components/TempRange';
import EnergyLevel from 'shared-components/EnergyLevel';
import TemperatureCheckRelative from 'shared-components/TemperatureCheckRelative';
import { GenericShipmentData } from './lib';

const tableColumns: (
    t,
    minMaxTempArray: [number, number],
    hasAccessToEnergy: boolean
) => ColumnsType[] = (
    t,
    minMaxTempArray = [0, 0],
    hasAccessToEnergy = false,
) => {
    return [
        {
            accessor: 'customerReference',
            dataType: 'highlightedText',
            Header: t('ORDER_DETAILS.REFERENCE_PO_NUMBER'),
            id: 'customerReference',
        },
        {
            accessor: 'mawbNumber',
            customWidth: 140,
            dataType: 'highlightedText',
            Header: t('TRACK_AND_TRACE.MAWB'),
        },
        {
            accessor: 'hawbNumber',
            customWidth: 140,
            dataType: 'highlightedText',
            Header: t('TRACK_AND_TRACE.HAWB'),
        },
        {
            accessor: (row) => row?.cargo.map(item => item.packaging.serialNumber)?.join('\n\n'),
            customCellStyle: {
                maxWidth: '230px',
                whiteSpace: 'pre',
            },
            dataType: 'highlightedText',
            disableSortBy: true,
            Header: t('COMMON.PACKAGINGS'),
        },
        hasAccessToEnergy && {
            accessor: ({ cargo }: GenericShipmentData) => cargo.map((packaging) => {
                return (
                    <EnergyLevel
                        key={packaging.id}
                        energyLevel={
                            packaging?.energyLevelCheckResult?.remainingEnergyLevel
                            || packaging?.energyLevelCheckResult?.remainingEnergyLevel === 0
                                ? Number(
                                    ((packaging?.energyLevelCheckResult?.remainingEnergyLevel / 1) * 100)
                                        .toFixed(0),
                                ) : null
                        }
                        energyLevelStatus={packaging?.energyLevelCheckResult?.energyLevelStatus}
                    />
                );
            }),
            customCellStyle: {
                maxWidth: '230px',
            },
            dataType: 'text',
            disableSortBy: true,
            Header: t('COMMON.ENERGY'),
            id: 'remainingEnergyLevel',
        },
        {
            accessor: (row) => row?.cargo.map(item => item.packaging.jypId)?.join('\n\n'),
            customCellStyle: {
                maxWidth: '230px',
                whiteSpace: 'pre',
            },
            dataType: 'highlightedText',
            disableSortBy: true,
            Header: t('COMMON.JYPID'),
        },
        {
            accessor: (row) => <TempRange tempRange={row.temperatureRange} />,
            dataType: 'custom',
            Header: t('COMMON.RANGE_C'),
            id: 'temperatureRange',
            sortType: (rowA, rowB) => {
                const { original: originalA } = rowA || {};
                const { original: originalB } = rowB || {};
                const { temperatureRange: temperatureRangeA = '' } = originalA || {};
                const { temperatureRange: temperatureSpecifics = '' } = originalB || {};

                return temperatureRangeA.localeCompare(temperatureSpecifics);
            },
        },
        {
            accessor: ({ packagings }) => (
                <div
                    style={{
                        display: 'grid',
                        gridGap: '20px',
                    }}
                >
                    { packagings.map((packaging) => {
                        const {
                            temperatureMax,
                            temperatureMin,
                            temperatureRangeMax,
                            temperatureRangeMin,
                            temperatureStatus,
                        } = packaging?.temperatureCheckResult || {};

                        return (
                            <span
                                key={packaging.id}
                                style={{
                                    paddingRight: '10px',
                                    position: 'relative',
                                    top: '5px',
                                }}
                            >
                                <TemperatureCheckRelative
                                    bounds={minMaxTempArray || null}
                                    grid={false}
                                    temperatureCheckStatus={temperatureStatus || null}
                                    temperatureMax={temperatureMax || null}
                                    temperatureMin={temperatureMin || null}
                                    temperatureRangeMax={temperatureRangeMax || null}
                                    temperatureRangeMin={temperatureRangeMin || null}
                                />
                            </span>
                        );
                    })}
                </div>
            ),
            customWidth: '320px',
            dataType: 'custom',
            disableSortBy: true,
            Header: `${t('PRODUCT_RELEASE.TEMPERATURE_STATUS')}`,
        },
        {
            accessor: 'originAirport',
            dataType: 'text',
            Header: t('COMMON.FROM'),
        },
        {
            accessor: 'destinationAirport',
            dataType: 'text',
            Header: t('COMMON.TO'),
        },
        {
            accessor: 'externalId',
            dataType: 'highlightedText',
            Header: t('TRACK_AND_TRACE.ORDER'),
        },
        {
            accessor: 'status',
            dataType: 'text',
            Header: t('TRACK_AND_TRACE.SHIPMENT_STATUS'),
        },
    ].filter(Boolean); // This filters out any falsy values in the array
};

export default tableColumns;

import useClasses from 'hooks/useClasses';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import React, { useCallback, useContext } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import useHasAccess from 'hooks/useHasAccess';
import { checkPrivilege } from 'shared-components/common';
import icons from 'shared-components/icons';
import menuItems from 'shared-components/menuItems';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { ListItem } from '@mui/material';
import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import { openSupport } from 'utils/jiraHelpDesk';
import { isKnBaseShown } from 'shared-components/constants';
import Footer from './components/Footer';
import MenuItemCollapse from './components/MenuItemCollapse';
import MenuItemLink from './components/MenuItemLink';
import styles from './Sidebar.style';

import QuickHelpTooltip from '../Contexts/QuickHelpContext/QuickHelpTooltip';

type Props = {
    anchor?: 'bottom' | 'left' | 'right' | 'top',
    currentLink: string,
    setSidebarState: (sidebarState: boolean) => void,
    sidebarIsOpen: boolean,
}

const Sidebar = ({
    anchor = 'left',
    currentLink,
    setSidebarState,
    sidebarIsOpen,
} : Props) => {
    const classes = useClasses(styles);
    const hasAccess = useHasAccess();
    const isMobileDevice = anchor === 'top';
    const handleFooterOnClick = () => {
        setSidebarState(!sidebarIsOpen);
    };
    const { t } = useCustomTranslation();
    const {
        hasOnboardingTour,
        onBoardingRef,
        onboardingTooltipInfo = {},
        setEnableGuide,
        setEnableTooltips,
    } = useContext(QuickHelpContext);

    const {
        setErrorStatus,
    } = useStatusStateProcessOptions();

    const jiraHelpDeskCallback = useCallback(() => openSupport(() => {
        setErrorStatus('Jira extension failed to load.');
    }), []);

    return (
        <Drawer
            anchor={anchor}
            classes={{ paper: sidebarIsOpen ? classes.boxStyleOpened : classes.boxStyle }}
            variant="permanent"
        >
            {
                (sidebarIsOpen || !isMobileDevice) && (
                    <div>
                        <MenuItemLink
                            key="MenuItemLink_dashboard"
                            icon={icons.skycell}
                            isMobileDevice={isMobileDevice}
                            isTextHidden={!sidebarIsOpen}
                            link=""
                            setSidebarState={setSidebarState}
                            title="SkyMind"
                            type="home"
                        />
                    </div>
                )
            }
            <List className={classes.list}>
                {
                    (sidebarIsOpen || !isMobileDevice) && menuItems(t)
                        .filter((item) => {
                            return item.id !== 'start' && checkPrivilege(hasAccess, item);
                        })
                        .map((item) => {
                            if (item.children) {
                                return (
                                    <MenuItemCollapse
                                        key={`MenuItemCollapse_${item.id}`}
                                        currentLink={currentLink}
                                        icon={item.iconSrc}
                                        isMobileDevice={isMobileDevice}
                                        link={item.link}
                                        setSidebarState={setSidebarState}
                                        sidebarOpen={sidebarIsOpen}
                                        title={item.title}
                                    >
                                        {item.children}
                                    </MenuItemCollapse>
                                );
                            }
                            const isActive = item.link === currentLink;

                            return (
                                <MenuItemLink
                                    key={`MenuItemLink_${item.id}`}
                                    icon={item.iconSrc}
                                    isMobileDevice={isMobileDevice}
                                    isTextHidden={!sidebarIsOpen}
                                    link={item.link}
                                    setSidebarState={setSidebarState}
                                    title={item.title}
                                    type={isActive ? 'activeDefault' : 'default'}
                                />
                            );
                        })
                }
            </List>
            <div style={{ flex: 1 }} />
            <div style={{ width: '100%' }}>
                <List>
                    <QuickHelpTooltip tooltipInfo={onboardingTooltipInfo}>
                        <div
                            key="link_to_support"
                            className={classes.a}
                            onClick={jiraHelpDeskCallback}
                        >
                            <ListItem
                                key="support_button"
                                className={classes.buttons}
                                style={{ paddingLeft: '8px' }}
                            >
                                <img
                                    key="icon_support"
                                    alt="support"
                                    className={classes.icon}
                                    src={icons.support_icon}
                                />
                                <div
                                    className={classes.itemText}
                                    hidden={!sidebarIsOpen}
                                >
                                    Support
                                </div>
                            </ListItem>
                        </div>
                    </QuickHelpTooltip>
                    {
                        hasOnboardingTour && (
                            <div
                                key="link_to_quickHelp"
                                ref={onBoardingRef}
                                className={classes.a}
                                onClick={() => setEnableTooltips(prev => !prev)}
                            >
                                <ListItem
                                    key="quickHelp_button"
                                    className={classes.buttons}
                                    style={{ paddingLeft: '8px' }}
                                >
                                    <img
                                        key="icon_quickHelp"
                                        alt="quickHelp"
                                        className={classes.icon}
                                        src={icons.quick_help_icon}
                                    />
                                    <div
                                        className={classes.itemText}
                                        hidden={!sidebarIsOpen}
                                    >
                                        Onboarding tour
                                    </div>
                                </ListItem>
                            </div>
                        )
                    }
                    <div
                        key="userGuide_link"
                        className={classes.a}
                        onClick={() => setEnableGuide(prev => !prev)}
                    >
                        <ListItem
                            key="userGuide_button"
                            className={classes.buttons}
                            style={{ paddingLeft: '8px' }}
                        >
                            <img
                                key="icon_userGuide"
                                alt="userGuide"
                                className={classes.icon}
                                src={icons.user_guide_icon}
                            />
                            <div
                                className={classes.itemText}
                                hidden={!sidebarIsOpen}
                            >
                                {isKnBaseShown ? 'Knowledge Base' : 'User Guide'}
                            </div>
                        </ListItem>
                    </div>
                </List>
            </div>
            {
                !isMobileDevice && (
                    <Footer
                        handleFooterOnClick={handleFooterOnClick}
                        sidebarIsOpen={sidebarIsOpen}
                    />
                )
            }
        </Drawer>
    );
};

export default Sidebar;

import icons from 'shared-components/icons';
import { getQuestionnaireStat } from 'shared-components/CompanyInfoComponents/lib';
import { SERVICE_PROVIDER_FILTER_DATA } from 'shared-components/constants';

import {
    QuestionnaireProgressStatus,
    QuestionnaireStatus,
    CompanyRawData } from 'shared-components/dataTypes';

const getOperatingHours = (rawOperatingHours) => {
    const days = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
    ];
    const covertTime = (rawTime: string) => {
        const time = rawTime.replace('_', '');

        return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
    };

    return days.reduce((result, day) => {
        let data = '';

        if (rawOperatingHours[`${day}From`] && rawOperatingHours[`${day}To`]) {
            data = `${covertTime(rawOperatingHours[`${day}From`])} - ${covertTime(rawOperatingHours[`${day}To`])}`;
        } else if (rawOperatingHours[`${day}Closed`]) {
            data = 'Closed';
        } else if (rawOperatingHours[`${day}ContinuousOpen`]) {
            data = '24h Open';
        } else {
            data = 'Not yet defined';
        }

        return {
            ...result,
            [day]: data,
        };
    }, {});
};

export const getCapabilities = (rowCapabilities = []) => {
    const NOT_ANSWERED = 'NOT_ANSWERED';

    const getCapabilitiesStatistic = (capabilities = [], profileFilter = null) => {
        const targetCapabilities = profileFilter
            ? capabilities.filter((capability) => {
                return capability.questionnaireProfile === profileFilter;
            })
            : capabilities;

        if (targetCapabilities.length === 0) {
            return 0;
        }

        const answered = targetCapabilities.filter((capability) => {
            return capability.status !== NOT_ANSWERED;
        }).length;

        return Math.round((answered / targetCapabilities.length) * 100);
    };

    const capabilitiesByCategory = rowCapabilities.reduce((data, item) => {
        if (data[item?.category]) {
            return {
                ...data,
                [item.category]: [
                    ...data[item.category],
                    {
                        capability: item.capability,
                        questionnaireProfile: item.questionnaireProfile,
                        status: item.status,
                        subCategory: item.subCategory,
                    },
                ],
            };
        }

        return {
            ...data,
            [item.category]: [
                {
                    capability: item.capability,
                    questionnaireProfile: item.questionnaireProfile,
                    status: item.status,
                    subCategory: item.subCategory,
                },
            ],
        };
    }, {});

    const statisticsByCategory = Object.keys(capabilitiesByCategory)
        .reduce((statistic, category) => {
            return {
                ...statistic,
                [category]: {
                    all: getCapabilitiesStatistic(capabilitiesByCategory[category]),
                    core: getCapabilitiesStatistic(capabilitiesByCategory[category], 'CORE'),
                },
            };
        }, {});

    const byCategoryAndSubCategory = Object.keys(capabilitiesByCategory)
        .reduce((data, category) => {
            const subCategories = capabilitiesByCategory[category]
                .reduce((innerData, item) => {
                    if (innerData[item.subCategory]) {
                        return {
                            ...innerData,
                            [item.subCategory]: [
                                ...innerData[item.subCategory],
                                {
                                    capability: item.capability,
                                    questionnaireProfile: item.questionnaireProfile,
                                    status: item.status,
                                },
                            ],
                        };
                    }

                    return {
                        ...innerData,
                        [item.subCategory]: [
                            {
                                capability: item.capability,
                                questionnaireProfile: item.questionnaireProfile,
                                status: item.status,
                            },
                        ],
                    };
                }, {});

            return {
                ...data,
                [category]: subCategories,
            };
        }, {});

    return {
        byCategoryAndSubCategory,
        statisticsByCategory,
        totalStatistics: {
            all: getCapabilitiesStatistic(rowCapabilities),
            core: getCapabilitiesStatistic(rowCapabilities, 'CORE'),
        },
    };
};

export const getCertificates = (rawCertificates = [], labels) => {
    return rawCertificates.reduce((data, item) => {
        const { subCertificates: subCert = [] } = item;
        const subCertificates = subCert.map((sub) => `${labels[item.certificate]} | ${labels[sub]}`);

        const processedItem = {
            certificate: item.certificate,
            mainCertificate: labels[item.certificate],
            subCategory: item.subCategory,
            subCertificates,
        };

        if (data[item?.category]) {
            return {
                ...data,
                [item.category]: [...data[item.category], processedItem],
            };
        }

        return {
            ...data,
            [item.category]: [processedItem],
        };
    }, {});
};

export const getFacilities = (rawFacilities = [], labels) => {
    const getFacilityAmount = (facility) => {
        if (facility.amount) {
            switch (facility.unit) {
            case 'SQUARE_METERS':
                return `(${facility.amount}m\xB2)`;
            case 'NUMBER_OF_PLUGS':
                return `(${facility.amount} plug${facility.amount > 1 ? 's' : ''})`;
            default:
                return '';
            }
        }
        return '';
    };

    return rawFacilities.reduce((data, item) => {
        if (data[item?.category]) {
            return {
                ...data,
                [item.category]: [
                    ...data[item.category],
                    {
                        extendedLabel:
                            `${labels[item.facility] || item.facility} ${getFacilityAmount(item)}`,
                        icon: SERVICE_PROVIDER_FILTER_DATA.facilities[item.facility]?.icon || '',
                        label: item.facility,
                        subCategory: item.subCategory,
                    },
                ],
            };
        }

        return {
            ...data,
            [item.category]: [
                {
                    extendedLabel:
                        `${labels[item.facility] || item.facility} ${getFacilityAmount(item)}`,
                    icon: SERVICE_PROVIDER_FILTER_DATA.facilities[item.facility].icon,
                    label: item.facility,
                    subCategory: item.subCategory,
                },
            ],
        };
    }, {});
};

export const getQuestionnaire = (rawQuestionnaire = []) => {
    const byCategory = rawQuestionnaire.reduce((data, item) => {
        const { category, ...itemWithoutCategory } = item;

        if (data[category]) {
            return {
                ...data,
                [category]: [
                    ...data[category],
                    itemWithoutCategory,
                ],
            };
        }

        return {
            ...data,
            [category]: [itemWithoutCategory],
        };
    }, {});

    const byCategoryAndSubCategory = Object.keys(byCategory).reduce((mainData, category) => {
        const filteredCategory = byCategory[category].reduce((data, item) => {
            const { subCategory, ...itemWithoutSubCategory } = item;

            if (data[subCategory]) {
                return {
                    ...data,
                    [subCategory]: [
                        ...data[subCategory],
                        itemWithoutSubCategory,
                    ],
                };
            }

            return {
                ...data,
                [subCategory]: [
                    itemWithoutSubCategory,
                ],
            };
        }, {});

        return {
            ...mainData,
            [category]: filteredCategory,
        };
    }, {});

    const byCategoryAndSubCategoryLimited = Object.keys(byCategory).reduce((mainData, category) => {
        const filteredCategory = byCategory[category].reduce((data, item) => {
            const { subCategory } = item;
            const limitedItemInfo = {
                amount: item.amount,
                question: item.question,
                selectedAnswerOptions: item.selectedAnswerOptions,
            };

            if (data[subCategory]) {
                return {
                    ...data,
                    [subCategory]: [
                        ...data[subCategory],
                        limitedItemInfo,
                    ],
                };
            }

            return {
                ...data,
                [subCategory]: [
                    limitedItemInfo,
                ],
            };
        }, {});

        return {
            ...mainData,
            [category]: filteredCategory,
        };
    }, {});

    const statisticsByCategory = Object.keys(byCategory).reduce((data, category) => {
        return {
            ...data,
            [category]: getQuestionnaireStat(byCategory[category]),
        };
    }, {});

    const statisticsBySubCategory = Object.keys(byCategoryAndSubCategory).reduce((data, category) => {
        const subStat = Object.keys(byCategoryAndSubCategory[category]).reduce((subData, subCategory) => {
            return {
                ...subData,
                [subCategory]: getQuestionnaireStat(byCategoryAndSubCategory[category][subCategory]),
            };
        }, {});

        return {
            ...data,
            [category]: subStat,
        };
    }, {});

    return {
        byCategoryAndSubCategory,
        byCategoryAndSubCategoryLimited,
        statisticsByCategory,
        statisticsBySubCategory,
    };
};

export const getFullName = (personInfo) => {
    return `${personInfo?.firstName || ''} ${personInfo?.lastName || ''}`;
};

const getAuditStatusHistory = (rawAuditHistory = []) => {
    return rawAuditHistory.map((item) => {
        return {
            ...item,
            auditedBy: {
                ...item.auditedBy,
                fullName: getFullName(item.auditedBy),
            },
        };
    });
};

const getGalleryInfo = (rawGalleryData = []) => {
    return rawGalleryData.reduce((data, item) => {
        if (item.deleted) {
            return data;
        }

        return [
            ...data,
            {
                attachmentUrl: item.attachmentUrl,
                description: item.description,
                filename: item.filename,
                id: item.id,
            },
        ];
    }, []);
};

type QuestionnaireStatusHistoryItem = {
    completedOn: string,
    questionnaireStatus: QuestionnaireStatus,
    responsible: {
        contactName: string,
        email: string,
        id: number
    },
    status: QuestionnaireProgressStatus
}

const getQuestionnaireStatusHistoryMessage = (historyItem: QuestionnaireStatusHistoryItem) => {
    switch (historyItem.questionnaireStatus) {
    case 'ANSWER_QUESTIONS':
        if (historyItem.status === 'IN_PROGRESS') {
            return `Answer Questions (${historyItem.responsible.contactName || ''}, in progress)`;
        } if (historyItem.status === 'COMPLETED') {
            return `Answer Questions
                    (${historyItem.responsible.contactName} 
                    on ${new Date(historyItem.completedOn).toLocaleDateString()})`;
        }
        return '';
    case 'REVIEW_ANSWERS':
        if (historyItem.status === 'NOT_STARTED') {
            return `Review Answers (${historyItem.responsible.contactName || ''}`;
        } if (historyItem.status === 'IN_PROGRESS') {
            return `Review Answers (${historyItem.responsible.contactName || ''}, in progress)`;
        } if (historyItem.status === 'COMPLETED') {
            return `Review Answers
                (${historyItem.responsible.contactName} 
                on ${new Date(historyItem.completedOn).toLocaleDateString()})`;
        }
        return '';
    case 'PUBLISH_QUESTIONNAIRE':
        return 'Publish Questionnaire (Automated)';
    case 'REQUEST_ADJUSTMENT':
        return `Request Adjustment
                (${historyItem.responsible.contactName} 
                on ${new Date(historyItem.completedOn).toLocaleDateString()})`;
    default:
        return '';
    }
};

const getQuestionnaireStatusHistoryIcon = (status: QuestionnaireProgressStatus) => {
    switch (status) {
    case 'NOT_STARTED':
        return icons.dots_round_grey;
    case 'IN_PROGRESS':
        return icons.dots_round_blue;
    case 'COMPLETED':
        return icons.success;
    default:
        return icons.no_icon;
    }
};

const getQuestionnaireStatusHistory = (rawQuestionnaireStatusHistory = []) => {
    return rawQuestionnaireStatusHistory.reduce((data, item) => {
        return (
            [
                ...data,
                {
                    icon: getQuestionnaireStatusHistoryIcon(item.status),
                    message: getQuestionnaireStatusHistoryMessage(item),
                },
            ]
        );
    }, []);
};

export const fetchProcessedData = (rawData: CompanyRawData, labels = {}) => {
    return {
        address: {
            addressLine1: rawData?.addressLine1 || '',
            addressLine2: rawData?.addressLine2 || '',
            addressLine3: rawData?.addressLine3 || '',
            city: rawData?.city || '',
            countryName: rawData?.country?.countryName || '',
            zip: rawData?.zip || '',
        },
        audited: rawData.audited,
        auditedBy: {
            ...rawData.auditedBy,
            fullName: getFullName(rawData?.auditedBy),
        },
        auditedOn: rawData.auditedOn,
        auditStatusHistory: getAuditStatusHistory(rawData.auditStatusHistory),
        auditType: rawData.auditType,
        capabilities: getCapabilities(rawData?.capabilities),
        certificates: getCertificates(rawData?.certificates, labels),
        commodities: rawData.commodities || [],
        companyName: rawData.companyName || '',
        contactEmail: rawData.contactEmail || '',
        contactName: rawData.contactName || '',
        contactPhone: rawData.contactPhone || '',
        facilities: getFacilities(rawData.facilities, labels) || {},
        gallery: getGalleryInfo(rawData.gallery) || [],
        id: rawData.id,
        location: {
            lat: rawData.latitude,
            lng: rawData.longitude,
        },
        operatingHours: getOperatingHours(rawData?.operatingHours),
        parentCompany: {
            companyLogo: rawData?.parentCompany?.logo?.attachmentUrl || '',
            companyName: rawData?.parentCompany?.companyName || '',
            id: rawData?.parentCompany?.id || '',
        },
        questionnaire: getQuestionnaire(rawData.questionnaire),
        questionnaireAnsweredBy: {
            fullName: getFullName(rawData?.questionnaireAnsweredBy),
        },
        questionnaireProfile: rawData.questionnaireProfile || '',
        questionnairePublished: rawData.questionnairePublished || false,
        questionnaireReviewedBy: {
            fullName: getFullName(rawData?.questionnaireReviewedBy),
        },
        questionnaireStatus: rawData.questionnaireStatus || '',
        questionnaireStatusHistory: getQuestionnaireStatusHistory(rawData.questionnaireStatusHistory),
        services: rawData.services || [],
    };
};

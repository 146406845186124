import { wrapStyles } from 'hooks/useClasses/useClasses';

const PADDING = 32;
const WIDTH = 450;

export const styles = wrapStyles((theme) => ({
    boldTitle: {
        alignItems: 'flex-start',
        color: theme.palette.common.black,
        display: 'flex',
        fontSize: '24px',
        fontWeight: '700',
        justifyContent: 'center',
        marginBottom: '24px',
        textAlign: 'left',
    },
    buttonContainer: {
        display: 'flex',
        gap: '16px',
        justifyContent: 'flex-end',
        width: '100%',
    },
    centeredButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    closeButton: {
        alignItems: 'center',
        color: '#6c757d',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '24px',
        height: '16px',
        justifyContent: 'center',
        position: 'absolute',
        right: '16px',
        top: '16px',
        width: '16px',
    },
    icon: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '48px',
        justifyContent: 'center',
        marginBottom: '12px',
        width: '100%',
    },
    modalContents: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        justifyContent: 'center',
        position: 'relative',
        width: WIDTH - PADDING * 2,

    },
    modalContentsSuccess: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        justifyContent: 'center',
        position: 'relative',
        width: WIDTH - PADDING * 2,

    },
    root: ({ contentHeight, open, screenSize, shrinkFrom }) => ({
        '&:after': {
            backgroundColor: !open ? theme.palette.common.white : 'transparent',
            content: '""',
            height: '100%',
            left: '0',
            pointerEvents: 'none',
            position: 'absolute',
            top: '0',
            transition: 'background-color 0.5s ease',
            width: '100%',
            zIndex: '10001',
        },
        alignItems: 'flex-start',
        backgroundColor: '#ffffff',
        borderRadius: open ? '12px' : 0,
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        height: !open ? shrinkFrom?.height : contentHeight + PADDING * 2,
        left: !open ? shrinkFrom?.left : screenSize.width / 2,
        margin: 'auto',
        opacity: open ? 1 : 0,
        overflow: 'hidden',
        padding: !open ? 0 : `${PADDING}px ${PADDING}px ${PADDING}px ${PADDING + 6}px`,
        pointerEvents: open ? 'auto' : 'none',
        position: 'fixed',
        textAlign: 'center',
        top: !open ? shrinkFrom?.top : screenSize.height / 2,
        transform: open ? 'translate(-50%, -50%)' : 'translate(0, 0)',
        transition: !open ? 'all 0.5s ease 0.1s, opacity 0.9s linear 0.5s' : 'all 0.5s ease, opacity 0.2s linear',
        width: !open ? shrinkFrom?.width : WIDTH,
        zIndex: '10001',
    }),
    rootSuccess: ({ contentHeight, open, shrinkFrom }) => ({
        height: !open ? shrinkFrom?.height : contentHeight + PADDING * 2 - 20,
        paddingBottom: PADDING - 10,
        paddingTop: PADDING - 4,
    }),
    subTitle: {
        color: theme.palette.primary.deepBlue,
        display: 'flex',
        fontSize: '24px',
        fontWeight: '700',
        letterSpacing: '0.31px',
        marginBottom: '18px',
        textAlign: 'left',
    },
    successText: {
        '&&': {
            textAlign: 'center',
        },
    },
    text: {
        alignItems: 'flex-start',
        color: theme.palette.common.black,
        display: 'flex',
        fontSize: '1rem',
        justifyContent: 'center',
        marginBottom: '24px',
        textAlign: 'left',
        whiteSpace: 'pre-line',
    },
    title: {
        alignItems: 'flex-start',
        color: theme.palette.secondary[600],
        display: 'flex',
        fontSize: '16px',
        fontWeight: '400',
        justifyContent: 'center',
        letterSpacing: '0.49px',
        marginBottom: '8px',
        textTransform: 'uppercase',
    },
}));

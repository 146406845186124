import { SelectChangeEvent } from '@mui/material';
import Modal from '@mui/material/Modal';
import useClasses from 'hooks/useClasses';
import useCustomTitle from 'hooks/useCustomTitle';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import React, { useEffect, useContext, useState, useCallback } from 'react';
import { LayoutContext } from 'Contexts/LayoutContext';
import CommonButton from 'shared-components/CommonButton';
import icons from 'shared-components/icons';
import { ModalDialog } from 'react-bootstrap';
import {
    Close,
} from '@mui/icons-material';
import MultiSelect from 'shared-components/MultiSelect';
import styles from './AddAccessRequest.style';

const GAP = 33;
const AddAccessRequests = ({
    allCompanies,
    companiesLoading,
    companyGeofences,
    geofencesLoading,
    onUpdated,
}) => {
    const {
        setMenuTabs,
    } = useContext(LayoutContext);
    const { t } = useCustomTranslation();
    const [open, setOpen] = useState(false);
    const classes = useClasses(styles);
    const [geofences, setGeofences] = useState<number[]>([]);
    const [companies, setCompanies] = useState<number[]>([]);
    const [error, setError] = useState<string>('');
    const [requestInProgress, setRequestInProgress] = useState(false);
    const {
        setTitle,
    } = useCustomTitle();

    useEffect(() => {
        setTitle(t('MENU_ITEMS.ACCESS_REQUESTS'));
    }, []);

    const {
        setSuccessStatus,
    } = useStatusStateProcessOptions();
    const closeModal = useCallback(() => {
        if (requestInProgress) {
            return;
        }
        setOpen(false);
    }, [requestInProgress]);

    const {
        FlexibleRequest: accessRequestCreateRequest,
    } = useSkymindBackendEndpoints('access-requests').requests;

    useEffect(() => {
        setMenuTabs([
            {
                buttonClass: classes.addAccessRequestButton,
                iconSrc: icons.addCircle,
                iconStyle: {
                    height: '15px',
                    marginRight: '8px',
                    width: '15px',
                },
                isAction: true,
                onClick: () => setOpen(true),
                title: 'ADD ACCESS REQUESTS',
            },
        ]);

        return () => setMenuTabs([]);
    }, []);

    const onAddAccessRequests = useCallback(async () => {
        setRequestInProgress(true);
        setError('');
        try {
            await accessRequestCreateRequest('POST', '', {
                dataOwnerCompanyIds: companies,
                geofenceIds: geofences,
            });
            setOpen(false);
            setSuccessStatus((geofences.length === 1 && companies.length === 1)
                ? t('MY_COMPANY.ACCESS_REQUEST_CREATED') : t('MY_COMPANY.ACCESS_REQUESTS_CREATED'));
            onUpdated();
        } catch (e) {
            const errorMessage = e.response?.data?.errorMessage?.split(':')?.[0];

            global.console.log('Error', e.response?.data?.errorMessage);
            setError(errorMessage || t('COMMON.UNFORTUNATELY_SOMETHING_WRONG'));
        } finally {
            setRequestInProgress(false);
        }
    }, [companies, geofences]);
    const handleChangeGeofences = useCallback((event: SelectChangeEvent<number[]>) => {
        const {
            target: { value },
        } = event;

        setGeofences(value as number[]);
    }, []);
    const handleChangeCompanies = useCallback((event: SelectChangeEvent<number[]>) => {
        const {
            target: { value },
        } = event;

        setCompanies(value as number[]);
    }, []);
    const handleDeleteGeofence = useCallback((id) => {
        setGeofences(prev => prev.filter(it => it !== id));
    }, []);
    const handleDeleteCompany = useCallback((id) => {
        setCompanies(prev => prev.filter(it => it !== id));
    }, []);

    useEffect(() => {
        setGeofences([]);
        setCompanies([]);
        setError('');
    }, [open]);

    return (
        <Modal hideBackdrop open={open}>
            <ModalDialog className={classes.modalWrapper}>
                <div className={classes.backdrop} onClick={closeModal} />
                <div className={classes.modalWindow}>
                    <Close className={classes.closeIcon} onClick={closeModal} />
                    <div className={classes.modalTitle}>Add Access Requests</div>
                    <div>
                        <MultiSelect
                            data={companyGeofences}
                            handleDelete={handleDeleteGeofence}
                            loading={geofencesLoading}
                            selectedIds={geofences}
                            titlePlural={t('COMMON.GEOFENCES')}
                            onChange={handleChangeGeofences}
                        />
                    </div>
                    <div style={{
                        height: GAP,
                    }}
                    />
                    <div>
                        <MultiSelect
                            data={allCompanies}
                            handleDelete={handleDeleteCompany}
                            labelId="name"
                            loading={companiesLoading}
                            selectedIds={companies}
                            titlePlural={`${t('TRACK_AND_TRACE.TITLE_SHIPMENT_DATA_OWNER')}s`}
                            onChange={handleChangeCompanies}
                        />
                    </div>
                    <div style={{
                        minHeight: GAP,
                    }}
                    >
                        {
                            error && <div className={classes.error}>{error}</div>
                        }
                    </div>
                    <div className={classes.buttonHolder}>
                        <CommonButton
                            disabled={requestInProgress}
                            loading={requestInProgress}
                            onClick={onAddAccessRequests}
                        >
                            Add Access Requests
                        </CommonButton>
                    </div>
                </div>
            </ModalDialog>
        </Modal>
    );
};

export default AddAccessRequests;

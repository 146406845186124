import {
    getCapabilities,
    getCertificates,
    getFacilities,
    getQuestionnaire,
    getFullName,
} from 'hooks/useGetServiceProvider/lib';
import { Capabilities, CompanyRawData, QuestionnaireItem, QuestionnaireItemAnswer } from 'shared-components/dataTypes';

export interface ParentCompanyInfo {
    address: {
        addressLine1: string;
        addressLine2: string;
        addressLine3: string;
        city: string;
        countryName: string;
        zip: string;
    };
    audited: boolean;
    capabilities: Capabilities;
    certificates: {
        [key: string]: {
            mainCertificate: string,
            subCategory: string,
            subCertificates?: string[]
        }[] | null,
    },
    commodities: string[];
    companyLogo: string;
    companyName: string;
    contactEmail: string;
    contactName: string;
    contactPhone: string;
    facilities: { [key: string]: { extendedLabel: string; icon: string; label: string; subCategory: string }[] },
    id: string;
    location: {
        lat: number;
        lng: number;
    };
    questionnaire: {
        byCategoryAndSubCategory: { [key: string]: { [key: string]: QuestionnaireItem[] } },
        byCategoryAndSubCategoryLimited: { [key: string]: { [key: string]: QuestionnaireItemAnswer[] } },
        statisticsByCategory: { [key: string]: number },
        statisticsBySubCategory: { [key: string]: { [key: string]: number } },
    },
    questionnaireAnsweredBy: { fullName: string };
    questionnaireProfile: string;
    questionnairePublished: boolean;
    questionnaireReviewedBy: { fullName: string };
    questionnaireStatus: string;
    services: string[];
    website: string;
}

export const fetchProcessedData = (rawData: CompanyRawData, labels = {}): ParentCompanyInfo => {
    return {
        address: {
            addressLine1: rawData?.addressLine1 || '',
            addressLine2: rawData?.addressLine2 || '',
            addressLine3: rawData?.addressLine3 || '',
            city: rawData?.city || '',
            countryName: rawData?.country?.countryName || '',
            zip: rawData?.zip || '',
        },
        audited: rawData.audited,
        capabilities: getCapabilities(rawData?.capabilities),
        certificates: getCertificates(rawData?.certificates, labels),
        commodities: rawData.commodities || [],
        companyLogo: rawData.companyLogo || '',
        companyName: rawData.companyName || '',
        contactEmail: rawData.contactEmail || '',
        contactName: rawData.contactName || '',
        contactPhone: rawData.contactPhone || '',
        facilities: getFacilities(rawData.facilities, labels) || {},
        id: rawData.id,
        location: {
            lat: rawData.latitude,
            lng: rawData.longitude,
        },
        questionnaire: getQuestionnaire(rawData.questionnaire),
        questionnaireAnsweredBy: { fullName: getFullName(rawData?.questionnaireAnsweredBy) },
        questionnaireProfile: rawData.questionnaireProfile || '',
        questionnairePublished: rawData.questionnairePublished || false,
        questionnaireReviewedBy: { fullName: getFullName(rawData?.questionnaireReviewedBy) },
        questionnaireStatus: rawData.questionnaireStatus || '',
        services: rawData.services || [],
        website: rawData?.website || '',
    };
};

import React, {
    useEffect,
    useState,
} from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import useCacheContext from 'hooks/useCacheContext';
import useLoadSensorData from 'SensorDataCommon/hooks/useLoadSensorData';
import parseQueryParams from 'utils/parsePathQueries';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import {
    PathParams,
    EntitySelectorItem,
    initialEntitySelectorItem,
} from './dataTypes';
import {
    initializeTimeRange,
    initializeRequestBody, fetchEntitiesFromLoggers,
} from './lib';
import LoggerSensorData from './LoggerSensorData';
import useGetCommonData from '../hooks/useGetCommonData';
import { Logger } from '../dataTypes/SecureBackend/apiResponse';

const LoggerSensorDataContainer = ({ core = 'track-and-trace' }) => {
    const navigate = useNavigate();
    const history = useLocation();
    const { getCacheValue } = useCacheContext();
    const loggerTypeLabels = useGetTranslationGroup('LOGGER_TYPE_LABEL');
    const {
        entityNumber: entityNumberInPath = '',
    } = useParams() as unknown as PathParams;
    const queryParams = parseQueryParams(history.search);
    const [isCurrentTime, setIsCurrentTime] = useState(true);
    const [firstEntry, setFirstEntry] = useState(true);
    const [requestBody, setRequestBody] = useState<SensorDataRequestBody>(initializeRequestBody(queryParams));
    const [timeRange, setTimeRange] = useState<TimeRange>(initializeTimeRange(true, queryParams));
    const [sampleForSearch, setSampleForSearch] = useState('');

    const [entitySelectorItem, setEntitySelectorItem] = useState<EntitySelectorItem>(
        (getCacheValue('entitySelectorItem') || initialEntitySelectorItem),
    );
    const [loggerListAll, setLoggerListAll] = useState<EntitySelectorItem[]>([]);

    const {
        isFetchComplete,
        loggerList,
        loggerRequestStatus,
    } = useLoadSensorData(entitySelectorItem, 'loggers', entityNumberInPath);

    useEffect(() => {
        if (
            entityNumberInPath
            && entitySelectorItem.entityNumber !== entityNumberInPath
        ) {
            if (loggerList.length > 0) {
                const logger = loggerList
                    .find(item => item.entityNumber.toLowerCase() === entityNumberInPath.toLowerCase())
                    || null;

                if (logger) {
                    setEntitySelectorItem(logger);
                }
            }
        }
    }, [
        loggerList,
    ]);

    useEffect(() => {
        if (!firstEntry && isFetchComplete) {
            navigate({
                pathname: `/${core}/loggers/${entitySelectorItem?.entityNumber || ''}`,
            });
        }
    }, [
        entitySelectorItem,
        requestBody,
        timeRange,
        isFetchComplete,
    ]);

    const {
        data: rawLoggersAll,
    } = useGetCommonData<Logger[]>('loggers/search', {
        enabled: true,
        postProcess: it => it.resultList,
        query: {
            page: 0,
            pageSize: 500,
            q: sampleForSearch,
        },
    });

    useEffect(() => {
        if (rawLoggersAll) {
            setLoggerListAll(fetchEntitiesFromLoggers(rawLoggersAll, loggerTypeLabels));
        }
    }, [rawLoggersAll]);

    useEffect(() => {
        if (entitySelectorItem?.entityNumber) {
            if (firstEntry) {
                setFirstEntry(false);
            }
            setTimeRange(initializeTimeRange(true, queryParams, true));
        } else {
            setTimeRange({ from: null, to: null });
        }
    }, [entitySelectorItem, isCurrentTime]);

    return (
        <LoggerSensorData
            core={core}
            entitySelectorItem={entitySelectorItem}
            isCurrentTime={isCurrentTime}
            loggerList={loggerListAll}
            loggersFetched={loggerRequestStatus === 'SUCCESS'}
            requestBody={requestBody}
            sampleForSearch={sampleForSearch}
            setEntitySelectorItem={setEntitySelectorItem}
            setIsCurrentTime={setIsCurrentTime}
            setRequestBody={setRequestBody}
            setSampleForSearch={setSampleForSearch}
            setTimeRange={setTimeRange}
            timeRange={timeRange}
        />
    );
};

export default LoggerSensorDataContainer;

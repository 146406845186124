import DataPaginationContext from 'Contexts/DataPaginationContext';
import useClasses from 'hooks/useClasses';
import { wrapStyles } from 'hooks/useClasses/useClasses';
import useCustomTranslation from 'hooks/useCustomTranslation';
import React, { useContext, useMemo } from 'react';

const styles = wrapStyles((theme) => ({
    label: {
        color: 'black',
        font: '14px / 17px Roboto',
        height: '100%',
        letterSpacing: '0.43px',
        opacity: 1,
    },
    pagination: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        padding: '0 9px',
        width: '220px',
        [theme.breakpoints.down('sm')]: {
            flex: '1',
            flexWrap: 'wrap',
            height: 'auto',
            justifyContent: 'center',
            padding: '5px',
        },
    },
    shrink: {
        width: 'min-content',
    },
    wrap: {
        '& $label': {
            whiteSpace: 'nowrap',
        },
        '& ul': {
            flexWrap: 'nowrap',
        },
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.down('lg')]: {
            width: 'min-content',
        },
    },
    wrapper: {
        alignItems: 'flex-end',
        borderRadius: 8,
        display: 'flex',
        flex: '1',
        justifyContent: 'flex-end',
        padding: '9px',
    },
}));

type Props = {
    detached?: boolean,
    entity?: string,
    shrink?: boolean,
    withoutGeoLocation?: number,
    wrap?: boolean
}

const EntityView = ({
    detached = false,
    entity = 'Assets',
    shrink = false,
    withoutGeoLocation = 0,
    wrap = false,
}: Props) => {
    const { t } = useCustomTranslation();
    const { totalElements } = useContext(DataPaginationContext);
    const classes = useClasses(styles);

    const total = useMemo(() => {
        return totalElements - withoutGeoLocation;
    }, [totalElements, withoutGeoLocation]);

    return (
        <div className={`${detached ? classes.wrapper : ''} ${shrink && classes.shrink}`} style={{ height: '100%' }}>
            <div
                aria-disabled className={[
                    classes.pagination,
                    wrap && classes.wrap,
                    classes.label,
                ].join(' ')}
            >
                {
                    total > 0
                        ? `Showing ${total} ${entity}`
                        : t('COMMON.NO_OPTIONS')
                }
            </div>
        </div>

    );
};

export default EntityView;

import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import LastConnect from 'shared-components/LastConnect';
import { calculateDiffMinutes, ExtractedAssetData } from 'TrackAndTrace/Assets/lib';
import EnergyLevel from 'shared-components/EnergyLevel';
import CommonTooltip, { TOOLTIP_TYPE } from 'shared-components/CommonTooltip/CommonTooltip';
import { LyingDifference } from 'shared-components/LyingDiff';

const tableColumns: (t) => ColumnsType<ExtractedAssetData>[] = (t) => [
    {
        accessor: 'assetNumber',
        dataType: 'highlightedText',
        Header: t('TRACK_AND_TRACE.ASSET'),
    },
    {
        accessor: ({ assetTypeCode }) => (assetTypeCode ? t(`ASSET_TYPE_LABEL.${assetTypeCode}`) : ''),
        dataType: 'text',
        Header: t('TRACK_AND_TRACE.ASSET_TYPE'),
        id: 'assetTypeCode',
    },
    {
        accessor: 'uldType',
        dataType: 'highlightedText',
        Header: t('TRACK_AND_TRACE.ULD_TYPE'),
        id: 'uldType',
    },
    {
        accessor: 'uldOwnerCode',
        dataType: 'text',
        Header: t('TRACK_AND_TRACE.ULD_OWNER_CODE'),
        id: 'uldOwnerCode',
    },
    {
        accessor: (row) => (!row.countryCode ? 'N/A'
            : t(`COUNTRY_NAMES.${row.countryCode}`)),
        dataType: 'text',
        Header: t('COMMON.COUNTRY'),
        id: 'country',
    },
    {
        accessor: (row) => (!row.city ? 'N/A'
            : row.city),
        dataType: 'text',
        Header: t('COMMON.CITY'),
        id: 'city',
    },
    {
        accessor: (row) => (
            <CommonTooltip
                description={row.locationName}
                enabled={row.locationName?.length >= 21}
                placement="right"
                type={TOOLTIP_TYPE.SHRINK}
            >
                <div style={{
                    color: '#000',
                    font: 'normal normal normal 14px/16px Roboto',
                    letterSpacing: '0.43px',
                    maxWidth: '100%',
                    opacity: 1,
                    overflow: 'hidden',
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
                >
                    {row.locationName?.length > 21 ? `${row.locationName.slice(0, 21)}...` : row.locationName}
                </div>
            </CommonTooltip>
        ),
        dataType: 'text',
        Header: t('TRACK_AND_TRACE.LOCATION_NAME'),
        id: 'locationName',
    },
    {
        accessor: ((row) => {
            if (!row.locationLyingSince) {
                return ' ';
            } else if (!row.locationLyingSinceTimestampUnit) {
                return ' ';
            }
            const diffMinutes = calculateDiffMinutes(
                row.locationLyingSince,
                row.locationLyingSinceTimestampUnit,
            );

            return <LyingDifference minutes={diffMinutes} />;
        }),
        customWidth: 80,
        dataType: 'custom',
        Header: t('TRACK_AND_TRACE.LOCATION_LYING_TIME'),
        id: 'locationLyingSince',
    },
    {
        accessor: (row) => (
            <CommonTooltip
                description={row.area}
                enabled={row.area?.length >= 21}
                placement="right"
                type={TOOLTIP_TYPE.SHRINK}
            >
                <div style={{
                    color: '#000',
                    font: 'normal normal normal 14px/16px Roboto',
                    letterSpacing: '0.43px',
                    maxWidth: '100%',
                    opacity: 1,
                    overflow: 'hidden',
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
                >
                    {row.area?.length > 21 ? `${row.area.slice(0, 21)}...` : row.area}
                </div>
            </CommonTooltip>
        ),
        dataType: 'custom',
        Header: t('TRACK_AND_TRACE.AREA'),
        id: 'area',
    },
    {
        accessor: ((row) => {
            if (!row.areaLyingSince) {
                return ' ';
            } else if (!row.areaLyingSinceTimestampUnit) {
                return ' ';
            }
            const diffMinutes = calculateDiffMinutes(
                row.areaLyingSince,
                row.areaLyingSinceTimestampUnit,
            );

            return <LyingDifference minutes={diffMinutes} />;
        }),
        customWidth: 80,
        dataType: 'custom',
        Header: t('TRACK_AND_TRACE.AREA_LYING_TIME'),
        id: 'areaLyingSince',
    },
    {
        accessor: (row) => (row.temperature ? `${Number(row?.temperature?.toFixed(1))}°C`
            : row.temperature === 0 ? '0°C' : ''),
        dataType: 'text',
        Header: t('COMMON.TEMPERATURE'),
        id: 'temperature',
    },
    {
        accessor: (row) => (!row.lastMeasuredTimestamp ? ' '
            : row.lastMeasuredTimestampUnit ? <LastConnect timestampUnit={row.lastMeasuredTimestampUnit} />
                : <LastConnect timestamp={row.lastMeasuredTimestamp} />),
        customWidth: 80,
        dataType: 'custom',
        Header: t('TRACK_AND_TRACE.LAST_UPDATED'),
        id: 'temperatureGeolocation',
    },
    {
        accessor: 'ownedByCompanyName',
        dataType: 'highlightedText',
        Header: t('TRACK_AND_TRACE.OWNED_BY'),
        id: 'ownedByCompanyName',
    },
    {
        accessor: (row) => t(`PAIRING_STATUS.${row.pairingStatus}`),
        dataType: 'highlightedText',
        disableSortBy: true,
        Header: t('COMMON.PAIRING_STATUS'),
        id: 'paringStatuses',
    },
    {
        accessor: 'loggerNumber',
        dataType: 'highlightedText',
        Header: t('COMMON.CURRENT_LOGGER'),
    },
    {
        accessor: (row) => (
            (
                row.batteryLevel >= 0 && row.batteryLevel <= 100 && row.batteryLevel !== null ? (
                    <EnergyLevel
                        energyLevel={Number(((row.batteryLevel) * 100).toFixed(0))}
                        threshold
                    />
                ) : ''
            )),
        customCellStyle: {
            alignItems: 'center',
            maxWidth: '230px',
            whiteSpace: 'pre-wrap',
        },
        dataType: 'custom',
        Header: t('COMMON.LOGGER_BATTERY'),
        id: 'batteryLevel',
    },
];

export default tableColumns;

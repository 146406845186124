import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    pageWithFilter: ({ availableHeight }) => ({
        display: 'flex',
        height: '100%',
        maxHeight: availableHeight,
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            overflow: 'unset',
        },
    }),
}));

export default styles;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    addressBody: {
        display: 'flex',
        flexDirection: 'column',
    },

    addressCard: {
        boxShadow: 'none',
    },

    addressCardRoot: {
        maxWidth: '600px',
        padding: '0px',
    },

    autocomplete: {},
    body: {
        display: 'grid',
        gridTemplateColumns: '260px 1fr',
        minWidth: '30vw',
    },
    bodyLeft: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonLabel: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },

    checked: {},
    radio: ({ classes }) => ({
        [`&.${classes.checked}`]: {
            color: theme.palette.primary[200],
        },
        '& .MuiSvgIcon-root': {
            height: '18px',
            width: '18px',
        },
    }),

    radioItem: {
        padding: '0 4px !important',
    },

    textField: {
        '& > div': {
            paddingRight: '1px!important',
        },
    },

    topTitle: {
        color: theme.palette.secondary[500],
        fontSize: '12px',
        letterSpacing: '0.4px',
    },
}));

export default styles;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const BORDER_SIZE = '2px';

const styles = wrapStyles(theme => ({
    anim_shake: {
        '0%': { transform: 'rotate(0deg)' },
        '25%': { transform: 'rotate(-4deg)' },
        '50%': { transform: 'rotate(4deg)' },
        '75%': { transform: 'rotate(-4deg)' },
        '100%': { transform: 'rotate(0deg)' },
    },
    anim_spin: {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(-360deg)' },
    },
    backdrop: {
        background: 'rgba(147, 147, 147, 0.63)',
        height: '100vh',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100vw',
        zIndex: 100,
    },
    browseLink: {
        '&:hover': {
            textDecoration: 'underline',
        },
        color: theme.palette.primary.deepBlue,
        cursor: 'pointer',
    },
    buttonHolder: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    closeIcon: {
        color: theme.palette.secondary[600],
        fontSize: '20px',
        position: 'absolute',
        right: '10px',
        top: '10px',
    },
    dropZone: ({ backgroundColor, borderColor }) => ({
        alignItems: 'center',
        background: `
            linear-gradient(to right, ${borderColor || theme.palette.secondary[200]}\
             40%, rgba(255,255,255,0) 0%) top left / 8px ${BORDER_SIZE} repeat-x, 
            linear-gradient(to right, ${borderColor || theme.palette.secondary[200]}\
             40%, rgba(255,255,255,0) 0%) bottom left / 8px ${BORDER_SIZE} repeat-x, 
            linear-gradient(to bottom, ${borderColor || theme.palette.secondary[200]}\
             40%, rgba(255,255,255,0) 0%) top right / ${BORDER_SIZE} 8px repeat-y, 
            linear-gradient(to bottom, ${borderColor || theme.palette.secondary[200]}\
             40%, rgba(255,255,255,0) 0%) top left / ${BORDER_SIZE} 8px repeat-y, 
            ${backgroundColor || theme.palette.common.white}`,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'center',
        marginBottom: '15px',
        minHeight: '150px',
        padding: '22px',
    }),
    dropZoneIcon: {
        '& svg': {
            fontSize: '55px',
        },
        color: theme.palette.primary.deepBlue,
        pointerEvents: 'none',
    },
    dropZonePlaceholder: {
        color: theme.palette.secondary[700],
        fontSize: '16px',
    },
    dropZoneTitle: {
        color: theme.palette.secondary[700],
        fontSize: '16px',
    },

    errorTitle: {
        color: theme.palette.error.main, // Assuming a similar path for error color
        fontWeight: 500,
        letterSpacing: '0.43px',
    },
    failDetails: {
        alignItems: 'center',
        color: theme.palette.error.main, // Assuming a similar path for error color
        cursor: 'pointer',
        display: 'flex',
        fontSize: '14px',
    },
    failTitle: {
        alignItems: 'center',
        color: theme.palette.error.main, // Assuming a similar path for error color
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        gap: '14px',
        letterSpacing: '0.43px',
        textAlign: 'center',
    },
    infoTitle: {
        '& svg': {
            fontSize: '23px',
        },
        alignItems: 'center',
        display: 'flex',
        fontSize: '16px',
        gap: '5px',
        letterSpacing: '0.43px',
    },
    link: {
        alignItems: 'center',
        color: theme.palette.primary[500],
        cursor: 'pointer',
        display: 'flex',
        marginLeft: '5px',
        textDecoration: 'underline',
    },
    loadingIcon: ({ classes }) => ({
        animation: `${classes.anim_spin} 1s linear infinite`,
    }),
    modalTitle: {
        fontSize: '32px',
        marginBottom: '17px',
    },
    modalWindow: {
        background: theme.palette.common.white,
        borderRadius: '4px',
        filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
        minHeight: '200px',
        padding: '23px',
        position: 'relative',
        width: '410px',
        zIndex: 101,
    },

    modalWrapper: {
        alignItems: 'center',
        color: theme.palette.common.black,
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        position: 'relative',
        width: '100vw',
    },
    shakeAnimation: ({ classes }) => ({
        animation: `${classes.anim_shake} 0.3s ease infinite`,
    }),
    successIcon: {
        color: theme.palette.success.main, // Assuming a similar path for success color
    },
    successTitle: {
        '& svg': {
            filter: `drop-shadow(1px 1px 0px ${theme.palette.success.main}) \
            drop-shadow(-1px -1px 0px ${theme.palette.success.main})`,
        },
        color: theme.palette.success.main, // Assuming a similar path for success color
        letterSpacing: '0.43px',
    },
}));

export default styles;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    active: {
        color: theme.palette.secondary[400],
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        opacity: 1,
        textAlign: 'left',
    },
    container: {
        display: 'flex',
    },
    inactive: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        opacity: 1,
        textAlign: 'left',
    },
    switch_base: {
        color: theme.palette.primary[400],
    },
    switch_track: {
        backgroundColor: theme.palette.primary[400],
        opacity: 0.4,
    },
    switchContainer: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: 'auto',
        right: '0',
    },
}));

export default styles;

import { css } from '@emotion/css';
import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Route, Routes,
} from 'react-router-dom';
import { docsPath } from 'shared-components/constants';
import { GuideRouteMapping } from 'UserGuides/GuideRouteMapping';
import NoUserGuide from 'UserGuides/NoUserGuide';

import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const readerClass = css({
    '& .react-pdf__Document': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    label: 'readerClass',
    width: '100%',
});

const PDFViewer = ({ docpath, fallbackElement }) => {
    const [numPages, setNumPages] = useState(0);
    const [divWidth, setDivWidth] = useState(700);
    const divRef = useRef(null);
    const timeoutRef = useRef(null);
    const { enableGuide } = useContext(QuickHelpContext);

    useEffect(() => {
        const updateWidth = () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => {
                if (divRef.current) {
                    setDivWidth(divRef.current.offsetWidth);
                }
            }, 200);
        };

        if (enableGuide) {
            updateWidth();
        }

        window.addEventListener('resize', updateWidth);

        return () => {
            window.removeEventListener('resize', updateWidth);
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [enableGuide]);

    return (
        docpath ? (
            <div ref={divRef} className={readerClass}>
                <Document
                    file={docpath}
                    onLoadSuccess={({ numPages: numPagesInPdf }) => {
                        setNumPages(numPagesInPdf);
                    }}
                >
                    {
                        Array.from(
                            new Array(numPages),
                            (el, pageIndex) => (
                                <Page
                                    key={`page_${pageIndex + 1}`}
                                    pageNumber={pageIndex + 1}
                                    renderAnnotationLayer={false}
                                    renderMode="canvas"
                                    renderTextLayer={false}
                                    width={divWidth}
                                />
                            ),
                        )
                    }
                </Document>
            </div>
        ) : (
            fallbackElement
        )
    );
};

const UserGuide = () => {
    const { i18n } = useTranslation();

    return (
        <Routes>
            {
                GuideRouteMapping.map((item, index) => (
                    <Route
                        key={`GuideRouteMapping-${index}`}
                        element={(
                            <PDFViewer
                                docpath={`${docsPath}${i18n.language}/${item.docPath}`}
                                fallbackElement={item.element}
                            />
                        )}
                        path={item.path as string}
                    />
                ))
            }
            <Route element={<NoUserGuide />} path="*" />
        </Routes>
    );
};

export default UserGuide;

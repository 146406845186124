import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import icons from 'shared-components/icons';
import ParentCompany from 'shared-components/CompanyInfoComponents/ParentCompany';
import { TFunction } from 'i18next';
import { Site } from 'dataTypes/SecureBackend/apiResponse/Site';

const tableColumns: (t: TFunction) => ColumnsType[] = (t) => [
    {
        accessor: (row: Site) => {
            return (
                <ParentCompany
                    companyName={row.companyName}
                    srcImage={row.logoImagePath || icons.placeholder_company_logo}
                />
            );
        },
        dataType: 'custom',
        Header: t('ADMINISTRATION.PARENT_COMPANY'),
        sideFilterKey: 'companyName',
        sideFilterType: 'enum',
    },
    {
        accessor: 'siteName',
        dataType: 'highlightedText',
        Header: t('COMMON.PHARMA_SITE'),
        sideFilterKey: 'siteName',
        sideFilterType: 'text',
    },
    {
        accessor: 'city',
        dataType: 'highlightedText',
        Header: t('COMMON.CITY'),
        sideFilterKey: 'city',
        sideFilterType: 'text',
    },
    {
        accessor: 'countryName',
        dataType: 'text',
        Header: t('COMMON.COUNTRY'),
        sideFilterKey: 'countryName',
        sideFilterType: 'enum',
    },
];

export default tableColumns;

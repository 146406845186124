import React, { useCallback, useEffect, useState } from 'react';
import { DateTimePicker, LocalizationProvider, PickersActionBarProps } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Box from '@mui/material/Box';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import Card from 'shared-components/Card';
import { wrapStyles } from 'hooks/useClasses/useClasses';
import moment, { Moment, utc } from 'moment';
import CommonButton from 'shared-components/CommonButton';
import { getUtcOffset } from 'utils/timeUtils';
import { ShipmentDetailsInterface } from 'TrackAndTrace/GenericShipmentDetails/lib';

const styles = wrapStyles((theme) => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        margin: '28px 0 12px 0',
        width: '100%',
    },
    content: {
        paddingBottom: '15.6px !important',
        paddingLeft: '12px',
        paddingTop: '18px',
    },
    dateText: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
                borderLeft: 'none',
                borderRadius: '0',
                borderRight: 'none',
                borderTop: 'none',
                width: '100%',
            },
            padding: '2px',
        },
        '.MuiInputBase-input': {
            fontSize: '14px',
            fontSpacing: '12.9px',
            padding: 0,
        },
        '.MuiSvgIcon-root': {
            fill: theme.palette.common.black,
        },
        width: '100%',
    },
    dateTimePickerWrapper: {
        '&:not(:last-child)': {
            marginBottom: '16px',
        },
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    },
    label: {
        font: 'normal normal 14px/14px Roboto',
    },
    title: {
        marginBottom: '18.5px',
    },
}));

const MAX_DATE = '2090-01-01T00:00:00';
const MIN_DATE = '2020-01-01T00:00:00';

interface Props {
    shipment: ShipmentDetailsInterface
}

const CustomActionBar = (props: PickersActionBarProps) => {
    const { onAccept, onClear } = props;

    return (
        <Box
            sx={{
                alignItems: 'center',
                borderTop: '1px solid #e0e0e0',
                display: 'flex',
                gap: '8px',
                gridColumn: 2,
                gridRow: 3,
                justifyContent: 'flex-end',
                padding: '8px',
            }}
        >
            <CommonButton variant="outlined" onClick={onClear}>CLEAR</CommonButton>
            <CommonButton onClick={onAccept}>OK</CommonButton>
        </Box>
    );
};

const ShipmentTimestamps = ({
    shipment,
}: Props) => {
    const [loadingDateTime, setLoadingDateTime] = useState<Moment>(null);
    const [unloadingDateAndTime, setUnloadingDateAndTime] = useState<Moment>(null);

    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const {
        setErrorStatus,
        setSuccessStatus,
    } = useStatusStateProcessOptions();
    const { FlexibleRequest: ShipmentTimestampsRequest } = useSkymindBackendEndpoints(
        `admin/v2/shipments/${shipment.id}/timestamps`,
    ).requests;

    useEffect(() => {
        if (shipment?.loadingTimestamp) {
            setLoadingDateTime(moment(utc(shipment.loadingTimestamp)));
        }

        if (shipment?.unloadingTimestamp) {
            setUnloadingDateAndTime(moment(utc(shipment.unloadingTimestamp)));
        }
    }, [shipment]);

    const handleLoadingDateTimeChange = useCallback((value: Moment) => {
        setLoadingDateTime(value);
    }, []);

    const handleUnloadingDateTimeChange = useCallback((value: Moment) => {
        setUnloadingDateAndTime(value);
    }, []);

    const handleClickSetTimestamps = useCallback(async () => {
        setRequestLoading(true);
        try {
            const utcLoadingTimestamp = loadingDateTime?.utc().format() || '';
            const utcUnloadingTimestamp = unloadingDateAndTime?.utc().format() || '';

            const response = await ShipmentTimestampsRequest(
                'PATCH',
                '',
                {
                    loadingTimestamp: utcLoadingTimestamp,
                    unloadingTimestamp: utcUnloadingTimestamp,
                },
            );

            if (response.status === 200) {
                setSuccessStatus(t('SHIPMENT_TIMESTAMPS.SET_REQUEST_SUCCEEDED'));
            } else {
                throw new Error();
            }
        } catch (error) {
            setErrorStatus(t('SHIPMENT_TIMESTAMPS.SET_REQUEST_FAILED'));
        }
        setRequestLoading(false);
    }, [loadingDateTime, unloadingDateAndTime]);

    return (
        <Card
            contentClass={classes.content}
            title={t('SHIPMENT_TIMESTAMPS.TITLE')}
            titleClass={classes.title}
        >
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={classes.dateTimePickerWrapper}>
                    <div className={classes.label}>{t('SHIPMENT_TIMESTAMPS.LOADING_DATE_AND_TIME')}</div>
                    <DateTimePicker
                        ampm={false}
                        format={`DD.MM.YYYY HH:mm ${getUtcOffset()}`}
                        maxDateTime={moment(MAX_DATE)}
                        minDateTime={moment(MIN_DATE)}
                        slotProps={{
                            actionBar: {
                                actions: ['clear', 'accept'],
                            },
                            textField: { className: classes.dateText },
                        }}
                        slots={{
                            actionBar: CustomActionBar,
                        }}
                        timezone="UTC"
                        value={loadingDateTime}
                        onChange={handleLoadingDateTimeChange}
                    />
                </div>

                <div className={classes.dateTimePickerWrapper}>
                    <div className={classes.label}>{t('SHIPMENT_TIMESTAMPS.UNLOADING_DATE_AND_TIME')}</div>
                    <DateTimePicker
                        ampm={false}
                        format={`DD.MM.YYYY HH:mm ${getUtcOffset()}`}
                        maxDateTime={moment(MAX_DATE)}
                        minDateTime={moment(MIN_DATE)}
                        slotProps={{
                            actionBar: {
                                actions: ['clear', 'accept'],
                            },
                            textField: { className: classes.dateText },
                        }}
                        slots={{
                            actionBar: CustomActionBar,
                        }}
                        timezone="UTC"
                        value={unloadingDateAndTime}
                        onChange={handleUnloadingDateTimeChange}
                    />
                </div>
            </LocalizationProvider>

            <div className={classes.buttonWrapper}>
                <CommonButton
                    loading={requestLoading}
                    onClick={handleClickSetTimestamps}
                >
                    {t('SHIPMENT_TIMESTAMPS.SET_TIMESTAMPS')}
                </CommonButton>

            </div>
        </Card>
    );
};

export default ShipmentTimestamps;

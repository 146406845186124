import { linearProgressClasses } from '@mui/material/LinearProgress';
import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    categoryInfoTitle: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '32px',
    },
    progressBar: {
        '-webkit-transform': 'rotate(-90deg)', /* Chrome, Safari, Opera */
        display: 'inline-block',
        height: '17px!important',
        transform: 'rotate(-90deg)',
        width: '27px',
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: theme.palette.primary[400],
        },
        [`& .${linearProgressClasses.dashed}`]: {
            backgroundColor: 'red',
            color: 'pink',
        },
    },
    progressFinishIcon: {
        height: '27px',
        marginLeft: '8px',
        width: '27px',
    },
    questionGroupContainer: {
        marginTop: '8px',
        padding: '12px',
    },
    questionGroupGridContainer: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '1fr 1fr',
    },
    titleWithProgressBar: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
}));

export default styles;

import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    container: {
        display: 'grid',
        gridGap: theme.spacing(10),
        gridTemplateColumns: '1fr 1fr 1fr',
        transition: 'padding 100ms ease-in-out',
        [theme.breakpoints.down('xs')]: {
            gridGap: `${theme.spacing(3)}`,
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr',
        },
        [theme.breakpoints.down('lg')]: {
            padding: `${theme.spacing(0)}px ${theme.spacing(0)}px`,
        },
    },
}));

export default styles;

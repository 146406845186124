import { wrapStyles } from 'hooks/useClasses/useClasses';
import loading from 'LaneManagement/LaneDetails/StepsWrapper/steps/Loading';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = wrapStyles((theme: SkycellThemeInterface) => ({
    anim_loading: {
        '0%': {
            backgroundPositionX: '-1000px',
        },
        '100%': {
            backgroundPositionX: '100px',
        },
    },
    button: {
        textTransform: 'capitalize',
    },
    caret: {
        color: theme.palette.primary[700],
        fontSize: '30px',
        marginRight: '5px',
    },
    cell: {
        '& .MuiFormControl-root': {
            width: '100%',
        },
        padding: '5px 5px 5px 12px!important',
    },
    filledSelect: {
        background: theme.palette.primary[50],
    },
    hcell: {
        '& div': {
            paddingLeft: '0!important',
        },
        '&::after': {
            display: 'none',
        },

        color: `${theme.palette.common.black} !important`,
        display: 'none',
    },
    icon: {
        '&:hover': {
            color: theme.palette.secondary[800],
        },
        color: theme.palette.secondary[600],
    },
    loadingSkeleton: {
        animation: `${loading} 10s ease infinite alternate`,
        background: `linear-gradient(90deg, ${theme.palette.secondary[200]} 0%, ${theme.palette.secondary[400]} 25%,\
        ${theme.palette.secondary[200]} 50%, ${theme.palette.secondary[400]} 75%,\
        ${theme.palette.secondary[200]} 100%)`,
        borderRadius: '4px',
        height: '35px',
        width: '100%',
    },
    noLocations: {
        alignItems: 'center',
        color: theme.palette.secondary[600],
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'center',
    },
    table: {
        margin: '0px',
        paddingBottom: '4px',
    },
    tableDescription: {
        alignItems: 'center',
        color: theme.palette.secondary[600],
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        gap: '20px',
        marginBottom: '18px',
        marginLeft: '12px',
        marginTop: '8px',
    },
}));

export default styles;

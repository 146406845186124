import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    icon: {
        height: '21px',
        width: '21px',
    },
    iconWithRightMargin: {
        height: '21px',
        marginRight: '10px',
        width: '21px',
    },
    title: {
        color: theme.palette.secondary[900],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0px',
    },
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    tooltipBody: {
        fontSize: '14px',
        fontWeight: 'normal',
        letterSpacing: '0.18px',
    },
    tooltipContainer: {
        color: theme.palette.secondary[600],
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        opacity: 1,
        textAlign: 'left',
    },
}));

export default styles;

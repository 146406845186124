import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    contentContainer: {
        display: 'grid',
        gridGap: theme.spacing(1.5),
        gridTemplateColumns: '8fr minmax(325px, 1fr)',
        justifyContent: 'center',
        padding: '0 12px 12px 12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
        },
    },
    contentRightColumn: {
        alignContent: 'flex-start',
        display: 'grid',
        gridGap: theme.spacing(1.5),
        // gridAutoRows: 'auto',
        marginTop: 38,
    },
}));

export default styles;

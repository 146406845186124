import { wrapStyles } from 'hooks/useClasses/useClasses';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const CELL_LINE_PADDING = 7;
const CELL_LINE_OFFSET = 11;

const styles = wrapStyles((theme: SkycellThemeInterface) => ({
    cellContainer: {
        '&:first-child': {
            paddingLeft: '8px',
        },
        alignItems: 'center',
        display: 'flex',
        paddingBottom: '8px!important',
        paddingRight: '20px!important',
        paddingTop: '8px!important',
    },
    headerCell: ({ noDivider }) => ({
        '&:hover': {
            cursor: 'pointer',
        },
        '&:not(:last-child)::after': (noDivider ? {} : {
            background: theme.palette.common.dividerColor,
            content: '""',
            height: `calc(100% - ${CELL_LINE_PADDING * 2}px)`,
            position: 'absolute',
            right: CELL_LINE_OFFSET,
            top: CELL_LINE_PADDING,
            width: '2px',
        }),
        color: `${theme.palette.common.black}!important`,
        font: 'normal normal bold 16px/19px Roboto!important',
        fontSize: '14px!important',
        letterSpacing: '0.01em!important',
        opacity: 1,
        padding: '0!important',
        position: 'relative',
    }),
    headerData: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        wordBreak: 'keep-all',
    },
}));

export default styles;

import {
    Cargo,
    Location,
    OrderStep,
    Shipment, TemperatureCheckResult,
} from 'dataTypes/SecureBackend/apiResponse/Shipment';
import {
    NotificationDto,
    NotificationCategory,
    Airport,
    Geolocation,
} from 'dataTypes/SecureBackend/apiResponse';
import moment from 'moment';

import {
    LatLng,
    PolylineAndStepStatus,
    TimeRange,
} from 'dataTypes/common';
import { CheckboxOption } from 'shared-components/dataTypes';
import icons from 'shared-components/icons';
import {
    APPROVAL_STATUS,
    ORDER_STEP_TYPE, TEMPERATURE_STATUS,
} from 'shared-components/constants';
import { ProcessedPackagingData, fetchPackaging } from 'TrackAndTrace/GenericShipments/lib';

export interface ProcessedNotification {
    body: string,
    createdOn: number,
    createdOnDisplay: string,
    group?: string,
    hasBeenDeleted: boolean,
    hasBeenRead: boolean,
    id: number,
    isExpanded?: boolean,
    isSelected: boolean,
    name: NotificationCategory,
    subject: string
}

const getProcessedCreatedOn = (rawCreatedOn = ''): number => {
    if (!rawCreatedOn || !rawCreatedOn.includes('T')) {
        return 0;
    }
    return new Date(rawCreatedOn).getTime();
};
const getProcessedCreatedOnString = (today, rawCreatedOn = ''): string => {
    if (!rawCreatedOn || !rawCreatedOn.includes('T')) {
        return '';
    }
    const momentDate = moment(rawCreatedOn).add(-1 * (new Date().getTimezoneOffset()), 'minute');
    const [date] = rawCreatedOn.split('T');

    if (today === date) {
        return momentDate.format('HH:mm');
    }

    return momentDate.format('DD MMM').toUpperCase();
};

export const getProcessedNotifications = (rawData: NotificationDto[]): ProcessedNotification[] => {
    const today = moment().utc().format('yyyy-MM-DD');

    return rawData.map(item => {
        const {
            body,
            createdOn = '',
            group,
            id,
            movedToTrashOn = '',
            name,
            readOn,
            subject,
        } = item;

        return {
            body,
            createdOn: getProcessedCreatedOn(createdOn),
            createdOnDisplay: getProcessedCreatedOnString(today, createdOn),
            group,
            hasBeenDeleted: movedToTrashOn !== null,
            hasBeenRead: readOn !== null,
            id,
            isSelected: false,
            name,
            subject,
        };
    });
};

export const getUpdatedNotifications = (
    notifications: ProcessedNotification[],
    markedNotifications: number[],
): ProcessedNotification[] => {
    return notifications.map(item => {
        const { id } = item;

        return {
            ...item,
            isSelected: markedNotifications.includes(id),
        };
    });
};

export const getFilteredNotifications = (
    notifications: ProcessedNotification[],
    showOnlyUnreadNotifications: boolean,
    showOnlyDeletedNotifications: boolean,
): ProcessedNotification[] => {
    return notifications.filter(item => {
        return (
            !showOnlyDeletedNotifications
            || !showOnlyUnreadNotifications || (showOnlyUnreadNotifications && !item.hasBeenRead)
        );
    });
};

export const getApprovalStatuses = (cargo: Cargo[]): string[] => {
    return cargo.map(item => {
        const { skyCoreProductRelease } = item;
        const { approvalStatus = APPROVAL_STATUS.CHECK_NOT_PASSED } = skyCoreProductRelease || {};

        return approvalStatus;
    });
};
export const getTemperatureStatuses = (cargo: Cargo[]): string[] => {
    return cargo.map(item => {
        const { temperatureCheckResult } = item;
        const { temperatureStatus = TEMPERATURE_STATUS.EXCURSION } = temperatureCheckResult || {};

        return temperatureStatus;
    });
};

const getNumberOfStatus = (statuses: string[], requiredStatus: string): number => (
    statuses.filter((status) => status === requiredStatus).length
);

export const getPackagingIcons = (statuses: string[]): string[] => {
    if (statuses.length < 6) {
        return statuses.map((status) => {
            if (status === APPROVAL_STATUS.REJECTED
                || status === APPROVAL_STATUS.CHECK_NOT_PASSED) {
                return icons.rectangle_red;
            }
            if (status === APPROVAL_STATUS.NOT_CHECKED) {
                return icons.rectangle_grey;
            }
            return icons.rectangle_blue;
        });
    }
    const notApprovedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.REJECTED);

    /** Show ceiling(4 x count(ProductRelease | REJECTED) / count(ProductRelease))
     dots with color #D44848 and the other dots with #61C6E9 */
    const redIconsCount = Math.ceil((4 * notApprovedCount) / statuses.length);

    return [
        redIconsCount > 0 ? icons.rectangle_red : icons.rectangle_blue,
        redIconsCount > 1 ? icons.rectangle_red : icons.rectangle_blue,
        icons.dots_blue,
        redIconsCount > 2 ? icons.rectangle_red : icons.rectangle_blue,
        redIconsCount > 3 ? icons.rectangle_red : icons.rectangle_blue,
    ];
};

export const checkIsWarning = (statuses: string[]): boolean => (
    statuses.includes(APPROVAL_STATUS.REJECTED)
    || statuses.includes(APPROVAL_STATUS.CHECK_NOT_PASSED)
);

export const checkIsPending = (statuses: string[]): boolean => {
    const approvedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.APPROVED);

    const checkPassedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.CHECK_PASSED);

    return (statuses.length !== 0
        && (checkPassedCount === statuses.length
            || ((checkPassedCount + approvedCount) === statuses.length && checkPassedCount > 0)
        )
    );
};

export const checkIsAllApproved = (statuses: string[]): boolean => {
    const approvedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.APPROVED);

    return (statuses.length !== 0 && approvedCount === statuses.length);
};

export const getPackagingTypesCount = (cargo: Cargo[]): { [key: string]: number } => {
    return cargo.reduce((types, item) => {
        const { packaging = null } = item || {};
        const { packagingType = null } = packaging || {};
        const { type = null } = packagingType || {};

        return type
            ? { ...types, [type]: types[type] ? types[type] + 1 : 1 }
            : types;
    }, {});
};

export interface ShipmentWithSharedField extends Shipment {
    isShared: boolean,
}

export interface Packaging {
    serialNumber: string,
}

export interface ProcessedPackagingAndProductRelease {
    packagingInfo: ProcessedPackagingData,
    productRelease: {
        approvalStatus: string,
        containerStatus: string,
        palletStatus: string,
        palletStatus1: string,
        palletStatus2: string,
        rejectedStatusMessage: string,
        sealStatus: string,
        temperatureMax: number,
        temperatureMin: number,
        temperatureRangeMax: number,
        temperatureRangeMin: number,
        temperatureStatus: string
    },
    temperatureCheckResult: TemperatureCheckResult,
}

export interface ShipmentData {
    collectionDropoffPoint: string,
    currentPosition: LatLng,
    customerReference: string,
    destinationAirportCity: string,
    destinationAirportCode: string,
    handoverPoint: string,
    hawbNumber: string,
    isAllApproved: boolean,
    isPending: boolean,
    isWarning: boolean,
    leaseEnd: string,
    leaseStart: string,
    marker: LatLng,
    mawbNumber: string,
    originAirportCity: string,
    originAirportCode: string,
    packagings: ProcessedPackagingAndProductRelease[],
    packagingSquareIcons: string[],
    packagingsSerialNumberList: string[],
    packagingTypesCount: { [key: string]: number },
    pharmaCompanyName: string,
    polylines: PolylineAndStepStatus[],
    shipmentNumber: string,
    shipmentSensorDataTimeRange: TimeRange,
    status: string,
    statusLabel: string,
    temperatureRange: string
}

interface Statuses {
    containerStatus: string,
    palletStatus: string,
    palletStatus1: string,
    palletStatus2: string,
    sealStatus: string,
}

const getRejectedStatusMessage = (statuses: Statuses): string => {
    const rejectedStatuses = Object.keys(statuses).filter(status => statuses[status] === 'REJECTED');

    if (rejectedStatuses.length === 0) {
        return null;
    } else if (rejectedStatuses.length > 0) {
        return 'Several Damages Reported';
    }
    const [rejected] = rejectedStatuses;

    switch (rejected) {
    case 'containerStatus':
        return 'Container Damage Reported';
    case 'sealStatus':
        return 'Seal Issue Reported';
    default:
        return 'Pallet Damage Reported';
    }
};

const getProcessedPackagingAndProductRelease = (
    cargo: Cargo[], packagingCodeLabels = {},
): ProcessedPackagingAndProductRelease[] => {
    return cargo.map(item => {
        const { packaging, skyCoreProductRelease, temperatureCheckResult } = item;
        const {
            approvalStatus,
            containerStatus = '',
            palletStatus = '',
            palletStatus1 = '',
            palletStatus2 = '',
            sealStatus = '',
            temperatureMax = null,
            temperatureMin = null,
            temperatureRangeMax = null,
            temperatureRangeMin = null,
            temperatureStatus = '',
        } = skyCoreProductRelease || {};

        return {
            packagingInfo: fetchPackaging(packaging, packagingCodeLabels),
            productRelease: {
                approvalStatus,
                containerStatus,
                palletStatus,
                palletStatus1,
                palletStatus2,
                rejectedStatusMessage: getRejectedStatusMessage({
                    containerStatus,
                    palletStatus,
                    palletStatus1,
                    palletStatus2,
                    sealStatus,
                }),
                sealStatus,
                temperatureMax,
                temperatureMin,
                temperatureRangeMax,
                temperatureRangeMin,
                temperatureStatus,
            },
            temperatureCheckResult,
        };
    });
};

const isNumber = (value: string | number): boolean => {
    if (typeof value === 'number') {
        return !Number.isNaN(value);
    }

    return !Number.isNaN(Number.parseFloat(value));
};

const isPathCorrect = (startLocation: (Airport | Location), endLocation: (Airport | Location)): boolean => {
    const { geolocation: startGeolocation = null } = startLocation || {};
    const { geolocation: endGeolocation = null } = endLocation || {};

    return startGeolocation !== null && endGeolocation !== null
        && isNumber(startGeolocation.latitude) && isNumber(startGeolocation.longitude)
        && isNumber(endGeolocation.latitude) && isNumber(endGeolocation.longitude);
};

const getLocation = (location: Geolocation): LatLng => ({
    lat: (Math.trunc(location.latitude * 10000) / 10000),
    lng: (Math.trunc(location.longitude * 10000) / 10000),
});

const getPolyline = (startPoint: (Airport | Location), endPoint: (Airport | Location), stepStatus: string)
    : PolylineAndStepStatus => (
    {
        path: [getLocation(startPoint.geolocation), getLocation(endPoint.geolocation)],
        stepStatus,
    }
);

export const getMarker = (polylines: PolylineAndStepStatus[]): LatLng => {
    if (!polylines?.length) {
        return null;
    }

    if (polylines[0].stepStatus !== ORDER_STEP_TYPE.CLOSED) {
        return polylines[0].path[0];
    }

    for (let i = 1; i < polylines.length; i++) {
        if (polylines[i - 1].stepStatus === ORDER_STEP_TYPE.CLOSED
            && polylines[i].stepStatus !== ORDER_STEP_TYPE.CLOSED) {
            return polylines[i].path[0];
        }
    }

    return polylines[polylines.length - 1].path[1];
};

export const getPolylines = (orderSteps: OrderStep[] = []): PolylineAndStepStatus[] => {
    return orderSteps?.reduce((polylines, orderStep) => {
        const {
            deliveryLocation = null,
            destinationAirport = null,
            originAirport = null,
            pickupLocation = null,
            stepStatus = null,
        } = orderStep || {};

        if (isPathCorrect(pickupLocation, originAirport)
            && isPathCorrect(destinationAirport, deliveryLocation)) {
            return [
                ...polylines,
                getPolyline(pickupLocation, originAirport, stepStatus),
                getPolyline(originAirport, destinationAirport, stepStatus),
                getPolyline(destinationAirport, deliveryLocation, stepStatus),
            ];
        } else if (isPathCorrect(pickupLocation, deliveryLocation)) {
            return [
                ...polylines,
                getPolyline(pickupLocation, deliveryLocation, stepStatus),
            ];
        }
        return polylines;
    }, []);
};
export const getCurrentPosition = (orderSteps: OrderStep[] = []): LatLng => {
    let currentPosition = null;

    if (orderSteps.find(it => it.stepStatus === ORDER_STEP_TYPE.IN_PROGRESS)) {
        const intersectingStep = orderSteps.find(it => it.stepStatus === ORDER_STEP_TYPE.IN_PROGRESS);

        currentPosition = intersectingStep.pickupLocation || intersectingStep.location;
    } else if (orderSteps.find(it => it.stepStatus === ORDER_STEP_TYPE.CLOSED)) {
        const [intersectingStep] = orderSteps.filter(it => it.stepStatus === ORDER_STEP_TYPE.CLOSED).slice(-1);

        currentPosition = intersectingStep.deliveryLocation || intersectingStep.location;
    } else {
        const intersectingStep = orderSteps.find(it => it.stepStatus === ORDER_STEP_TYPE.NOT_STARTED);

        currentPosition = intersectingStep.pickupLocation || intersectingStep.location;
    }

    if (!currentPosition) return null;
    const {
        latitude: lat,
        longitude: lng,
    } = currentPosition?.geolocation || {};

    return lng && lat ? {
        lat,
        lng,
    } : null;
};

export const getShipmentTimeRange = (
    shipmentStart: string,
    shipmentEnd: string,
    leaseStart: string,
    leaseEnd: string,
    leaseEndExpected: string,
): TimeRange => {
    const from = shipmentStart
        ? moment(shipmentStart).utc().format('YYYY-MM-DDTHH:mm')
        : leaseStart
            ? moment(leaseStart).utc().format('YYYY-MM-DDTHH:mm')
            : null;

    const to = shipmentEnd !== null
        ? shipmentEnd
        : leaseEnd !== null
            ? leaseEnd
            : leaseEndExpected !== null
                ? leaseEndExpected
                : moment().utc().format('YYYY-MM-DDTHH:mm');

    return { from, to };
};

export const getShipmentData = (
    rawData: ShipmentWithSharedField[] = [],
    packagingCodeLabels = {},
    shipmentStatusLabels = {},
): ShipmentData[] => {
    const processedData = rawData.map(shipment => {
        const {
            cargo = [],
            customerReference = '',
            pharmaCompany = null,
            shipmentNumber,
            skyCoreInfo,
            status = '',
        } = shipment;

        const {
            name: pharmaCompanyName = '',
        } = pharmaCompany || {};

        const {
            collectionDropoffPoint = '',
            from: originAirportCode = '',
            handoverPoint = '',
            hawbNumber = '',
            leaseEnd = null,
            leaseEndExpected = null,
            leaseStart = null,
            mawbNumber = '',
            orderSteps = [],
            shipmentEnd = null,
            shipmentStart = null,
            temperatureRange = '',
            to: destinationAirportCode = '',
        } = skyCoreInfo || {};

        const approvalStatuses = getApprovalStatuses(cargo);
        const temperatureStatuses = getTemperatureStatuses(cargo);
        const polylines = getPolylines(orderSteps);
        const currentPosition = getCurrentPosition(orderSteps);
        const packagings = getProcessedPackagingAndProductRelease(cargo, packagingCodeLabels);
        const packagingsSerialNumberList = packagings
            .map(packaging => packaging.packagingInfo.serialNumber);

        return {
            collectionDropoffPoint,
            currentPosition,
            customerReference,
            destinationAirportCity: '',
            destinationAirportCode,
            handoverPoint,
            hawbNumber,
            isAllApproved: checkIsAllApproved(approvalStatuses),
            isPending: checkIsPending(approvalStatuses),
            isWarning: temperatureStatuses.includes(TEMPERATURE_STATUS.EXCURSION),
            leaseEnd: leaseEnd ? moment(leaseEnd).utc().format('DD.MM.YYYY') : '',
            leaseStart: leaseStart ? moment(leaseStart).utc().format('DD.MM.YYYY') : '',
            marker: getMarker(polylines),
            mawbNumber,
            originAirportCity: '',
            originAirportCode,
            packagings,
            packagingSquareIcons: getPackagingIcons(approvalStatuses),
            packagingsSerialNumberList,
            packagingTypesCount: getPackagingTypesCount(cargo),
            pharmaCompanyName,
            polylines,
            shipmentNumber,
            shipmentSensorDataTimeRange: getShipmentTimeRange(
                shipmentStart,
                shipmentEnd,
                leaseStart,
                leaseEnd,
                leaseEndExpected,
            ),
            status,
            statusLabel: shipmentStatusLabels[status] || status,
            temperatureRange,
        };
    });

    return processedData.filter(shipment => {
        const {
            destinationAirportCode,
            originAirportCode,
            packagings = [],
            status = null,
        } = shipment;

        const correctTemperatureStatuses = packagings.length === 0
            || packagings.every(packaging => {
                const { temperatureStatus } = packaging?.temperatureCheckResult || {};

                return temperatureStatus?.length > 0;
            });

        return status && originAirportCode && destinationAirportCode && correctTemperatureStatuses;
    });
};

export interface AvailableFilterOptions {
    allPackagingCount: number,
    allShipmentCount: number,
    destinationAirports: string[],
    originAirports: string[],
    shipmentStatus: CheckboxOption[],
    temperatureStatus: CheckboxOption[]
}

export const initialAvailableFilterOptions: AvailableFilterOptions = {
    allPackagingCount: 0,
    allShipmentCount: 0,
    destinationAirports: [],
    originAirports: [],
    shipmentStatus: [],
    temperatureStatus: [],
};

const getFilterOptions = (shipments: ShipmentData[] = []): { [key: string]: string[] } => {
    return shipments.reduce((data, shipment) => {
        const {
            destinationAirportsArr = [],
            originAirportsArr = [],
            shipmentStatusArr = [],
            temperatureStatusArr = [],
        } = data;
        const {
            destinationAirportCode,
            originAirportCode,
            packagings = [],
            status = null,
        } = shipment;

        const temperatureStatuses = packagings.reduce((data, packaging) => {
            const { temperatureStatus = null } = packaging?.temperatureCheckResult || {};

            return temperatureStatus
            && !data.includes(temperatureStatus)
            && !temperatureStatusArr.includes(temperatureStatus)
                ? [...data, temperatureStatus]
                : data;
        }, []);

        return {
            destinationAirportsArr: destinationAirportCode && !destinationAirportsArr.includes(destinationAirportCode)
                ? [...destinationAirportsArr, destinationAirportCode]
                : destinationAirportsArr,
            originAirportsArr: originAirportCode && !originAirportsArr.includes(originAirportCode)
                ? [...originAirportsArr, originAirportCode]
                : originAirportsArr,
            shipmentStatusArr: status && !shipmentStatusArr.includes(status)
                ? [...shipmentStatusArr, status]
                : shipmentStatusArr,
            temperatureStatusArr: temperatureStatusArr.concat(temperatureStatuses),
        };
    }, {
        destinationAirportsArr: [],
        originAirportsArr: [],
        shipmentStatusArr: [],
        temperatureStatusArr: [],
    });
};

const fetchShipmentStatusOptions = (
    shipments: ShipmentData[], availableStatuses: string[], labels = {}, descriptions = {},
): CheckboxOption[] => {
    return availableStatuses.map(option => ({
        count: shipments.filter(item => item.status === option).length,
        description: descriptions[option] || '',
        label: labels[option] || option,
        value: option,
    }));
};

const getPackagingCountByTemperatureStatus = (shipments: ShipmentData[], status: string): number => {
    return shipments.reduce((count, shipment) => {
        const { packagings = [] } = shipment;

        return packagings.length === 0
            ? count
            : count + packagings.filter(packaging => {
                const { temperatureStatus = null } = packaging?.temperatureCheckResult || {};

                return temperatureStatus === status;
            }).length;
    }, 0);
};

const fetchTemperatureStatusOptions = (
    shipments: ShipmentData[], availableStatuses: string[], labels = {}, descriptions = {},
): CheckboxOption[] => {
    return availableStatuses.map(option => ({
        count: getPackagingCountByTemperatureStatus(shipments, option),
        description: descriptions[option] || '',
        label: labels[option] || option,
        value: option,
    }));
};

export const getAvailableFilterOptions = (
    shipments: ShipmentData[] = [],
    shipmentStatusLabels = {},
    temperatureStatusLabels = {},
    shipmentStatusDescriptions = {},
    temperatureStatusDescriptions = {},
): AvailableFilterOptions => {
    const options = getFilterOptions(shipments);

    const shipmentStatus = fetchShipmentStatusOptions(
        shipments, options.shipmentStatusArr, shipmentStatusLabels, shipmentStatusDescriptions,
    );
    const temperatureStatus = fetchTemperatureStatusOptions(
        shipments, options.temperatureStatusArr, temperatureStatusLabels, temperatureStatusDescriptions,
    );
    const allPackagingCount = temperatureStatus.reduce((sum, option) => {
        return sum + option.count;
    }, 0);

    const { destinationAirportsArr = [], originAirportsArr = [] } = options;

    return {
        allPackagingCount,
        allShipmentCount: shipments.length,
        destinationAirports: destinationAirportsArr.sort(),
        originAirports: originAirportsArr.sort(),
        shipmentStatus,
        temperatureStatus,
    };
};

export const getNotificationStatusCount = (notifications: ProcessedNotification[]): { [key: string]: number } => {
    const categories = notifications.map(({ name }) => name);
    const availableCategories = [...(new Set(categories))];

    return availableCategories.reduce((data, option) => {
        return {
            ...data,
            [option]: categories.filter(category => category === option).length,
        };
    }, {});
};

export interface ClientSideFilter {
    group: string[],
    notificationStatus: string,
    sample: string,
}

export const initialClientSideFilter: ClientSideFilter = {
    group: [],
    notificationStatus: '',
    sample: '',
};

export interface FilterOptionsCount {
    notificationStatusCount: { [key: string]: number },
}

export const getPackagingsCount = (shipments: ShipmentData[] = []) : { [key: string]: number } => {
    const options = getFilterOptions(shipments);

    const temperatureStatus = fetchTemperatureStatusOptions(shipments, options.temperatureStatusArr);

    return temperatureStatus.reduce((data, option) => {
        return {
            ...data,
            [option.value]: option.count,
        };
    }, {});
};

export const initialFilterOptionsCount: FilterOptionsCount = {
    notificationStatusCount: {},
};

const areCoordinatesEqual = (point1: LatLng, point2: LatLng): boolean => {
    return point1.lat === point2.lat && point1.lng === point2.lng;
};

export interface ClusterInfo {
    hasPending: boolean,
    hasWarning: boolean,
    id: number,
    position: LatLng,
    shipmentNumbers: string[],
}

export const getClustersInfo = (shipments: ShipmentData[] = []): ClusterInfo[] => shipments
    .reduce((data: ClusterInfo[], shipment) => {
        if (!shipment.marker) {
            return data;
        }

        const clusterIndexWithSameLocation = data
            .findIndex(cluster => areCoordinatesEqual(cluster.position, shipment.marker));

        return clusterIndexWithSameLocation === -1
            ? [
                ...data,
                {
                    hasPending: shipment.isPending,
                    hasWarning: shipment.isWarning,
                    id: data.length,
                    position: shipment.marker,
                    shipmentNumbers: [shipment.shipmentNumber],
                },
            ] : [
                ...data.slice(0, clusterIndexWithSameLocation),
                {
                    hasPending: data[clusterIndexWithSameLocation].hasPending || shipment.isPending,
                    hasWarning: data[clusterIndexWithSameLocation].hasWarning || shipment.isWarning,
                    id: data[clusterIndexWithSameLocation].id,
                    position: data[clusterIndexWithSameLocation].position,
                    shipmentNumbers: [
                        ...data[clusterIndexWithSameLocation].shipmentNumbers,
                        shipment.shipmentNumber,
                    ],
                },
                ...data.slice(clusterIndexWithSameLocation + 1),
            ];
    }, []);

export const getFilteredShipmentsByShipmentStatus = (
    data: ShipmentData[] = [],
    filters: ClientSideFilter,
) => {
    const { notificationStatus } = filters;

    return data.filter((shipment) => notificationStatus.includes(shipment.status));
};

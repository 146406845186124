import moment from 'moment';
import {
    Logger,
    Asset,
    GatewayInfoDTO,
} from 'dataTypes/SecureBackend/apiResponse';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import { ShipmentWithSharedField } from 'TrackAndTrace/GenericShipments/lib';
import {
    TIME_RANGE_PERIOD,
    assetsPath,
} from 'shared-components/constants';
import icons from 'shared-components/icons';
import dayPassedToRange from 'utils/dayPassedToRange';
import {
    TimeRange,
} from 'dataTypes/common';

import {
    EntitySelectorItem,
    EntityType,
    dataTypeOptions,
    positionOptions,
} from './dataTypes';

export const requiredStatuses = ['SUCCESS', 'FAILURE'];

export const initializeTimeRange = (
    isFullAccess = true,
    queryParams: { [key: string]: string },
    getOneDayRange = false,
    getTwoWeeksRange = false,
    getSevenDaysRange = false,
): TimeRange => {
    // TODO: refactor
    const { from = null, period = null, to = null } = queryParams;

    if (getOneDayRange) {
        return dayPassedToRange(1);
    }

    if (getSevenDaysRange) {
        return dayPassedToRange(7);
    }

    if (getTwoWeeksRange) {
        return dayPassedToRange(14);
    }

    const time = moment().format('HH:mm');

    if (isFullAccess && from && to) {
        return {
            from: moment(`${from}T${time}`).utc().format('YYYY-MM-DDTHH:mm'),
            to: moment(`${to}T${time}`).utc().format('YYYY-MM-DDTHH:mm'),
        };
    } else if (isFullAccess && period && TIME_RANGE_PERIOD[period]) {
        return dayPassedToRange(TIME_RANGE_PERIOD[period]);
    }

    return { from: null, to: null };
};

export const initializeRequestBody = (queryParams: { [key: string]: string }): SensorDataRequestBody => {
    const {
        dataTypes: dataTypesInQuery = '',
        positions: positionsInQuery = '',
    } = queryParams;
    const dataTypes = dataTypesInQuery.length === 0
        ? []
        : dataTypesInQuery.split(',').filter(type => dataTypeOptions.includes(type));

    const positions = positionsInQuery.length === 0
        ? []
        : positionsInQuery.split(',').filter(type => positionOptions.includes(type));

    return {
        dataTypes,
        loggers: [],
        positions,
    };
};

export const initializeEntityList = (entitySelectorItem: EntitySelectorItem, currentType: EntityType) => {
    const { entityType = null } = entitySelectorItem || {};

    return entityType === currentType
        ? [entitySelectorItem]
        : [];
};

export const getTitle = (entitySelectorItem: EntitySelectorItem, t = (word: string) => word): string => {
    const { additionalData, entityNumber, entityType } = entitySelectorItem ?? {};
    const { loggerTypeLabel = null, packagingTypeLabel = null } = additionalData ?? {};

    if (entityType === 'shipment') {
        return `${t('COMMON.SHIPMENT')} ${entityNumber} / MAWB ${additionalData.mawbNumber}`;
    } else if (entityType === 'packagings') {
        return `${t('SENSOR_DATA.DATA_READOUT_PACKAGING')} ${entityNumber}${
            packagingTypeLabel ? ` | ${packagingTypeLabel}` : ''
        }`;
    } else if (entityType === 'loggers') {
        return `${t('SENSOR_DATA.DATA_READOUT_LOGGER')} ${entityNumber}${
            loggerTypeLabel ? ` | ${loggerTypeLabel}` : ''
        }`;
    } else if (entityType === 'assets') {
        return `${t('SENSOR_DATA.DATA_READOUT_ASSET')} ${entityNumber}${
            loggerTypeLabel ? ` | ${loggerTypeLabel}` : ''
        }`;
    }

    return 'Sensor Data';
};

export const filterOptions = (collectedItems: EntitySelectorItem[], sampleForSearch: string) => {
    const sampleForSearchLowerCase = sampleForSearch.toLowerCase();

    return sampleForSearchLowerCase
        ? collectedItems.filter(option => (
            option.entityNumber.toLowerCase().includes(sampleForSearchLowerCase)
            || (option.entityType === 'shipment'
                && option.additionalData.packagingsInThisShipment
                    .some(packaging => packaging.serialNumber.toLowerCase().includes(sampleForSearchLowerCase)))
            || (option.entityType === 'shipment'
                && option.additionalData.mawbNumber !== null
                && option.additionalData.mawbNumber.toLowerCase().includes(sampleForSearchLowerCase)
                && option.additionalData.hawbNumber !== null
                && option.additionalData.hawbNumber.toLowerCase().includes(sampleForSearchLowerCase))
            || (option.entityType === 'assets'
                && option.additionalData.assetNumber.toLowerCase().includes(sampleForSearchLowerCase))
        ))
        : collectedItems.slice(0, 25);
};

export const collectItems = (
    shipmentsChosen: boolean,
    shipmentList: EntitySelectorItem[],
    loggersChosen: boolean,
    loggerList: EntitySelectorItem[],
    assetsChosen: boolean,
    assetList: EntitySelectorItem[],
) => {
    try {
        const shipments = shipmentsChosen && shipmentList.length ? shipmentList : [];
        const loggers = loggersChosen && loggerList.length ? loggerList : [];
        const assets = assetsChosen && assetList.length ? assetList : [];

        return [
            ...shipments,
            ...loggers,
            ...assets,
        ];
    } catch (error) {
        return [];
    }
};

export const fetchEntitiesFromShipments = (
    rawData: ShipmentWithSharedField[],
    packagingCodeLabels = {},
) :EntitySelectorItem[] => {
    return rawData.map(shipment => {
        const {
            cargo,
            externalId: shipmentNumber,
            isShared = false,
            skyCoreInfo,
            status = '',
        } = shipment;

        const {
            from = '',
            hawbNumber = '',
            leaseEnd = null,
            leaseEndExpected = null,
            leaseStart = null,
            mawbNumber = '',
            shipmentEnd = null,
            shipmentStart = null,
            temperatureRange = '',
            to = '',
        } = skyCoreInfo || {};

        const packagingsInThisShipment = cargo.map(({ lastMeasuredData, packaging, skyCoreProductRelease }) => {
            const {
                packagingPictureUrl,
                packagingTypeCode: type,
                serialNumber,
            } = packaging || {};

            const {
                geolocation: lastMeasuredGeolocation = null,
                temperatureInternal: lastMeasuredTempInternal = null,
                temperatureInternalTimestamp: lastMeasuredTempInternalTimestamp,
            } = lastMeasuredData || {};

            const { id } = skyCoreProductRelease || {};

            return {
                lastMeasuredGeolocation,
                lastMeasuredTempInternal,
                lastMeasuredTempInternalTimestamp: moment(lastMeasuredTempInternalTimestamp).utc(true).valueOf(),
                packagingPictureUrl: packagingPictureUrl
                    ? `${assetsPath}/assets${packagingPictureUrl}`
                    : icons.default_packaging_icon,
                packagingType: type,
                packagingTypeLabel: packagingCodeLabels[type] || type,
                productReleaseId: id,
                serialNumber,
                temperatureRange,
            };
        });

        return {
            additionalData: {
                hawbNumber,
                iataCodeFrom: from,
                iataCodeTo: to,
                isShared,
                leaseEnd,
                leaseEndExpected,
                leaseStart,
                mawbNumber,
                packagingCount: cargo.length,
                packagingsInThisShipment,
                shipmentEnd,
                shipmentStart,
                shipmentStatus: status,
                temperatureRange,
            },
            entityNumber: shipmentNumber.toUpperCase(),
            entityType: 'shipment',
        };
    });
};

export const fetchEntitiesFromLoggers = (rawData: Logger[], loggerTypeLabels = {}) :EntitySelectorItem[] => {
    return rawData
        .map((logger) => {
            const {
                id,
                lastMeasuredData,
                loggerNumber,
                loggerType,
            } = logger;
            const {
                batteryLevel,
                geolocation: lastMeasuredGeolocation = null,
                temperature: lastMeasuredTempInternal = null,
                temperatureGeolocationTimestamp = '',
            } = lastMeasuredData || {};

            const loggerFamily = loggerType || '';

            return {
                additionalData: {
                    batteryLevel,
                    lastMeasuredGeolocation,
                    // lastMeasuredTemp,
                    lastMeasuredTempAmbient: null,
                    lastMeasuredTempInternal,
                    lastMeasuredTempInternalTimestamp: moment(temperatureGeolocationTimestamp).utc(true).valueOf(),
                    loggerType: loggerFamily,
                    loggerTypeLabel: loggerTypeLabels[loggerType] || loggerType,
                },
                entityId: id,
                entityNumber: loggerNumber,
                entityType: 'loggers',
            };
        });
};

export const fetchAssets = (rawData: Asset[], loggerTypeLabels = {}) :EntitySelectorItem[] => {
    return rawData
        .filter(({ assetNumber }) => assetNumber !== null)
        .map((asset) => {
            const {
                assetNumber = '',
                assetTypeCode: code,
                id: entityId,
                loggerNumber,
                ownedByCompanyId,
                ownedByCompanyName,
            } = asset;

            return {
                additionalData: {
                    assetNumber,
                    assetType: code,
                    loggerNumber,
                    loggerTypeLabel: loggerTypeLabels[code] || code,
                    ownedByCompanyId,
                    ownedByCompanyName,
                },
                entityId,
                entityNumber: assetNumber,
                entityType: 'assets',
            };
        });
};

export const getRequestParametersString = (obj: object): string => {
    const parameters: string[] = Object.keys(obj).reduce((data, key) => {
        const val = obj[key];

        if (Array.isArray(val)) {
            return val.length === 0
                ? data
                : [...data, val.map(it => `${key}=${it}`).join('&')];
        } else if (val instanceof Object) {
            return [...data, getRequestParametersString(val)];
        }

        return [...data, `${key}=${val}`];
    }, []);

    return parameters.join('&');
};

export const getTimeDiffMinutes = ({ arr, ind, it, sensorData }) => {
    let areaTimeDiffMinutes: number;

    if (ind === 0) {
        const lastReceived = sensorData.data?.at(-1);

        areaTimeDiffMinutes = moment(lastReceived?.t).diff(moment(it.sensorData?.t), 'minutes');
    } else {
        const prevEntry = arr[ind - 1];

        areaTimeDiffMinutes = moment(prevEntry.sensorData?.t).diff(moment(it.sensorData?.t), 'minutes');
    }

    return areaTimeDiffMinutes;
};

export type LatLngLiteral = {
    lat: number;
    lng: number;
};

export type PolylinePath = LatLngLiteral[];

export interface LocationHistoryRow {
    area: string;
    areaTimeDiffMinutes?: number;
    arrLength: number;
    isHistorical: boolean;
    isNotShared: boolean;
    locationName: string;
    locationTimeDiffMinutes?: number;
    timeData: {
        gateway: GatewayInfoDTO;
        localTimezone: string;
        time?: string;
    }
}

export type Selected = {
    lastMeasuredLatitude: number,
    lastMeasuredLongitude: number,
    loggerFamily: string,
};

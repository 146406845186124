import {
    useEffect,
    useState,
} from 'react';
import {
    useParams,
} from 'react-router-dom';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import useCustomTitle from 'hooks/useCustomTitle';
import useCacheContext from 'hooks/useCacheContext';

import useCurrentUserContext from 'hooks/useCurrentUserContext';
import { fetchProcessedData } from './lib';

type PathParams = { providerId: string }

const useGetServiceProvider = (disablePageUpdate = false) => {
    const { providerId } = useParams() as unknown as PathParams;
    const id = Number(providerId);
    const { getCacheValue, setCacheValue } = useCacheContext();
    const { company } = useCurrentUserContext();

    const { Get: getSite } = useSkymindBackendEndpoints(
        `companies/${company.id}/sites`,
    ).requests;
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { setTitle } = useCustomTitle();

    const [serviceProvider, setServiceProvider] = useState(null);

    useEffect(() => {
        (async () => {
            const providerFromSession = getCacheValue<{
                id: number,
            }>('SERVICE_PROVIDER');

            if (providerFromSession?.id === id) {
                setServiceProvider(providerFromSession);
            } else {
                try {
                    const response = (await getSite(id)).data || null;

                    if (response) {
                        const processedData = fetchProcessedData(response);

                        setServiceProvider(processedData);
                        setCacheValue('SERVICE_PROVIDER', processedData);
                    }
                } catch (error) {
                    setServiceProvider(null);
                    global.console.log(error);
                }
            }
        })();
    }, [id]);

    useEffect(() => {
        if (serviceProvider && !disablePageUpdate) {
            setBreadcrumbData('customBreadcrumb', serviceProvider?.companyName);
            setTitle(serviceProvider?.companyName);
        }
    }, [serviceProvider]);

    return serviceProvider;
};

export default useGetServiceProvider;

import { css } from '@emotion/css';
import React, { MouseEventHandler, ReactNode } from 'react';
import { Button, ButtonProps, CircularProgress, Theme } from '@mui/material';
import { styled, SxProps } from '@mui/system';

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
    '&.MuiButton-contained': {
        '&:disabled': {
            background: `${theme.palette.secondary.medium} 0% 0% no-repeat padding-box`,
            color: theme.palette.common.white,
        },
        '&:hover': {
            background: '#0088B5 0% 0% no-repeat padding-box',
        },
        svg: {
            fill: theme.palette.common.white,
        },
    },
    '&.MuiButton-endIcon': {
        marginLeft: '4px',
    },
    '&.MuiButton-outlined': {
        '&:disabled': {
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.secondary.medium}`,
            color: theme.palette.secondary.medium,
            svg: {
                fill: theme.palette.secondary.medium,
            },
        },
        '&:hover': {
            backgroundColor: theme.palette.common.primaryHighlight30,
            border: `1px solid ${theme.palette.primary.deepBlue}`,
        },
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.deepBlue}`,
        color: theme.palette.primary.deepBlue,
        svg: {
            fill: theme.palette.primary.deepBlue,
        },
    },
    '&.MuiButton-sizeSmall': {
        padding: '7px 22px',
    },
    '&.MuiButton-startIcon': {
        marginRight: '4px',
    },
    '&.MuiButton-text': {
        '&:disabled': {
            backgroundColor: 'transparent',
            border: 'none',
            color: theme.palette.secondary.medium,
            svg: {
                fill: theme.palette.secondary.medium,
            },
        },
        '&:hover': {
            backgroundColor: theme.palette.common.primaryHighlight30,
            border: 'none',
        },
        backgroundColor: 'transparent',
        border: 'none',
        color: theme.palette.primary.deepBlue,
        svg: {
            fill: theme.palette.primary.deepBlue,
        },
    },
    '&:disabled': {
        opacity: 1,
    },
    '&:hover': {
        opacity: 1,
    },
    alignItems: 'center',
    backgroundColor: theme.palette.primary.deepBlue,
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none !important',
    color: theme.palette.common.white,
    display: 'flex',
    fontSize: '14px',
    height: 'fit-content',
    justifyContent: 'center',
    letterSpacing: '0.43px',
    padding: '12px 23px',
    textTransform: 'none',
    transition: '200ms ease',
    whiteSpace: 'nowrap',
    width: 'fit-content',
}));

const SpinnerWrapper = styled('div')({
    left: '11px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
});

type Props = {
    autoFocus?: boolean,
    children: ReactNode,
    className?: string,
    disabled?: boolean,
    endIcon?: ReactNode,
    loading?: boolean,
    onClick: MouseEventHandler<HTMLButtonElement>,
    size?: 'small' | 'medium',
    startIcon?: ReactNode,
    sx?: SxProps<Theme>,
    variant?: 'contained' | 'outlined' | 'text',
}

const eventsEnabled = css({
    opacity: 1,
    pointerEvents: 'auto',
});
const disable = css({
    opacity: 0.5,
    pointerEvents: 'none',
});
const CommonButton = ({
    children,
    disabled = false,
    loading = false,
    variant = 'contained',
    ...props
}: Props) => {
    return (
        <StyledButton
            className={loading ? disable : eventsEnabled}
            disabled={disabled}
            variant={variant}
            {...props}
        >
            {loading && (
                <SpinnerWrapper>
                    <CircularProgress size={14} />
                </SpinnerWrapper>
            )}
            {children}
        </StyledButton>
    );
};

export default CommonButton;

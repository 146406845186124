import { Theme } from '@emotion/react';
import useClasses from 'hooks/useClasses';
import React, { useEffect, useContext, useState, useCallback, useMemo, useRef } from 'react';
import { LayoutContext } from 'Contexts/LayoutContext';
import icons from 'shared-components/icons';
import { Modal, useTheme, CircularProgress } from '@mui/material';
import { ModalDialog } from 'react-bootstrap';
import {
    Close,
    RotateLeft,
    InsertDriveFileOutlined,
    ErrorOutline,
    CheckOutlined,
    UploadFile,
} from '@mui/icons-material';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useCustomTranslation from 'hooks/useCustomTranslation';
import CommonButton from 'shared-components/CommonButton';
import { DownloadComponentIcon } from 'shared-components/ApexTemperatureChart/icons';
import styles from './AddAssets.style';

type ErrorType = 'size' | 'type' | 'misc' | null;
const AddAssets = () => {
    const {
        setMenuTabs,
    } = useContext(LayoutContext);
    const { t } = useCustomTranslation();
    const [open, setOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const theme = useTheme<Theme>();
    const [errorType, setErrorType] = useState<ErrorType>(null);
    const [errorReportId, setErrorReportId] = useState<string>(null);
    const [reportLoading, setReportLoading] = useState<boolean>(false);
    const [hasSuccess, setHasSuccess] = useState<boolean>(false);
    const [borderColor, setBorderColor] = useState(theme.palette.primary.deepBlue);
    const [fileDropHover, setFileDropHover] = useState(false);
    const classes = useClasses(styles, {
        backgroundColor: '#F3F3F3',
        borderColor,
    });

    useEffect(() => {
        setFile(null);
        setErrorType(null);
        setHasSuccess(false);
        setIsLoading(false);
        setErrorReportId(null);
        setBorderColor(theme.palette.primary.deepBlue);
    }, [open]);
    const {
        FlexibleRequest: importRequest,
    } = useSkymindBackendEndpoints('assets/import').requests;

    const closeModal = useCallback(() => {
        setOpen(false);
    }, []);

    const importCallback = useCallback(() => {
        if (file) {
            const formData = new FormData();

            formData.append('file', file);

            setIsLoading(true);
            importRequest('POST', '', formData, {
                'Content-Type': 'multipart/form-data',
            }).then((res) => {
                if (res.status === 200) {
                    setHasSuccess(true);
                    setBorderColor(theme.palette.common.green);
                    setIsLoading(false);
                } else {
                    setBorderColor(theme.palette.common.red);
                    setErrorType('misc');
                }
            }).catch((e) => {
                const reportId = e?.response?.data?.errorReportId;

                if (reportId) {
                    setErrorReportId(reportId);
                }
                setErrorType('misc');
                setBorderColor(theme.palette.common.red);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [file]);

    const handleFileChange = useCallback((e) => {
        const f:File = e.target.files?.[0];

        if (f) {
            const sizeMb = f.size / 1024 / 1024;
            const fileExtension = f.name.split('.').pop().toLowerCase();

            if (fileExtension !== 'xlsx' && fileExtension !== 'xls') {
                setErrorType('type');
                setBorderColor(theme.palette.common.red);
                setFile(null);
            } else if (sizeMb > 5) {
                setErrorType('size');
                setBorderColor(theme.palette.common.red);
                setFile(null);
            } else {
                setFile(f);
                setErrorType(null);
            }
        }
    }, []);

    useEffect(() => {
        setMenuTabs([
            {
                activePage: false,
                iconSrc: icons.addCircle,
                iconStyle: {
                    height: '15px',
                    marginRight: '8px',
                    width: '15px',
                },
                onClick: () => setOpen(true),
                title: t('ASSET_IMPORT.ADD_ASSETS'),
            },
        ]);

        return () => setMenuTabs([]);
    }, []);

    const dragEvents = useMemo(() => ({
        onDragEnter: (e) => {
            e.stopPropagation();
            e.preventDefault();
            setBorderColor(theme.palette.primary[400]);
            setFileDropHover(true);
        },
        onDragLeave: (e) => {
            e.stopPropagation();
            e.preventDefault();
            setBorderColor(theme.palette.primary.deepBlue);
            setFileDropHover(false);
        },
        onDragOver: (e) => {
            e.stopPropagation();
            e.preventDefault();
        },
        onDrop: (e) => {
            e.stopPropagation();
            e.preventDefault();
            setFileDropHover(false);
            setErrorType(null);
            setErrorReportId(null);
            setHasSuccess(false);
            handleFileChange({
                target: {
                    files: e.dataTransfer.files,
                },
            });
            setBorderColor(theme.palette.primary.deepBlue);
        },
    }), []);
    const downloadReport = useCallback(() => {
        setReportLoading(true);
        importRequest('GET', `error-reports/${errorReportId}`, {}, {}, {
            responseType: 'blob',
        }).then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');

            link.href = href;
            link.setAttribute('download', 'ErrorReport.xlsx');
            link.click();
            link.remove();

            URL.revokeObjectURL(href);
        }).finally(() => {
            setReportLoading(false);
        });
    }, [errorReportId]);

    return (
        <Modal hideBackdrop open={open}>
            <ModalDialog className={classes.modalWrapper}>
                <div className={classes.backdrop} onClick={closeModal} />
                <div className={classes.modalWindow}>
                    <Close className={classes.closeIcon} onClick={closeModal} />
                    <div className={classes.modalTitle}>{t('ASSET_IMPORT.IMPORT_ASSETS')}</div>
                    <input
                        ref={fileInputRef}
                        accept=".xls,.xlsx"
                        id="xlsUpload"
                        multiple={false}
                        style={{ display: 'none' }}
                        type="file"
                        onChange={handleFileChange}
                    />
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <label
                        {...dragEvents}
                        className={classes.dropZone}
                        htmlFor="xlsUpload"
                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                    >
                        {
                            !errorType && (
                                <div className={classes.dropZoneIcon}>
                                    {
                                        !isLoading && !file && (
                                            <UploadFile className={fileDropHover ? classes.shakeAnimation : ''} />
                                        )
                                    }
                                    {
                                        isLoading && (
                                            <RotateLeft
                                                className={classes.loadingIcon}
                                            />
                                        )
                                    }
                                    {
                                        hasSuccess && (
                                            <InsertDriveFileOutlined
                                                className={classes.successIcon}
                                            />
                                        )
                                    }
                                </div>
                            )
                        }

                        {
                            file && !isLoading && !errorType && !hasSuccess && (
                                <div className={classes.dropZoneTitle}>
                                    {
                                `${file.name} (${(file.size / 1024 / 1024).toFixed(2)}MB)`
                                    }
                                </div>
                            )
                        }
                        {
                            !file && !isLoading
                            && (
                                <div className={classes.dropZonePlaceholder}>
                                    {
                                        !fileDropHover && (
                                            <>
                                                <span style={{ pointerEvents: 'none' }}>
                                                    {t('ASSET_IMPORT.DRAG_N_DROP_OR')}
                                                </span>
                                                {' '}
                                                <span
                                                    className={classes.browseLink}
                                                    onClick={() => { fileInputRef.current?.click(); }}
                                                >
                                                    {t('ASSET_IMPORT.BROWSE')}
                                                </span>
                                            </>
                                        )
                                    }
                                    {
                                        fileDropHover && (
                                            <>{t('ASSET_IMPORT.DROP_TO_PROCEED')}</>
                                        )
                                    }
                                    <div style={{
                                        color: theme.palette.secondary[400],
                                        fontSize: '12px',
                                        marginTop: '8px',
                                        textAlign: 'center',
                                    }}
                                    >
                                        {t('ASSET_IMPORT.SUPPORT_XLSX')}
                                    </div>

                                </div>
                            )
                        }
                        {
                            hasSuccess && (
                                <div className={[classes.infoTitle, classes.successTitle].join(' ')}>
                                    <CheckOutlined />
                                    {t('ASSET_IMPORT.FILES_UPLOADED_SUCCESSFULLY')}
                                </div>
                            )
                        }
                        {
                            errorType === 'type' && (
                                <div className={[classes.infoTitle, classes.errorTitle].join(' ')}>
                                    <ErrorOutline />
                                    {t('ASSET_IMPORT.INCORRECT_FORMAT')}
                                </div>
                            )
                        }
                        {
                            errorType === 'size' && (
                                <div className={[classes.infoTitle, classes.errorTitle].join(' ')}>
                                    <ErrorOutline />
                                    {t('ASSET_IMPORT.FILE_EXCEEDS_SIZE')}
                                </div>
                            )
                        }
                        {
                            errorType === 'misc' && errorReportId && (
                                <div className={classes.failTitle}>
                                    <div>
                                        {t('ASSET_IMPORT.UPLOAD_FAILED_DOWNLOAD_REPORT')}
                                    </div>
                                    <div
                                        className={classes.failDetails}
                                        onClick={reportLoading ? null : downloadReport}
                                    >
                                        <DownloadComponentIcon color={theme.palette.primary[500]} size={20} />
                                        <div
                                            className={classes.link}
                                        >
                                            ErrorReport.xlsx
                                            {
                                                reportLoading && (
                                                    <div style={{ overflow: 'visible', width: 0 }}>
                                                        <CircularProgress size={14} style={{ marginLeft: '4px' }} />
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            errorType === 'misc' && !errorReportId && (
                                <div className={classes.failTitle}>
                                    <div>
                                        {t('ASSET_IMPORT.UPLOAD_FAILED_UNKNOWN')}
                                    </div>
                                </div>

                            )
                        }

                    </label>
                    <div className={classes.buttonHolder}>
                        {
                            !errorReportId && !errorType && !hasSuccess && (
                                <CommonButton
                                    disabled={isLoading || !file}
                                    onClick={importCallback}
                                >
                                    {t('ASSET_IMPORT.IMPORT')}
                                </CommonButton>
                            )
                        }
                    </div>
                </div>
            </ModalDialog>
        </Modal>
    );
};

export default AddAssets;

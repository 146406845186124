import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useLoadScript } from '@react-google-maps/api';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';
import {
    LIBRARIES,
    GOOGLE_LOCATION_INFO,
} from 'shared-components/constants';
import useGetCountries from 'hooks/useGetCountries';
import { ManualAddress } from 'shared-components/dataTypes';
import GoogleAddress from './GoogleAddress';

const {
    REACT_APP_GOOGLE_MAP_KEY: googleMapKey,
} = process.env;

type Country = {
    code: string,
    name: string,
}

type Props = {
    currentAddressLine1?: string,
    currentAddressLine2?: string,
    currentAddressLine3?: string,
    currentCity?: string,
    currentCountry?: string,
    currentCountryCode?: string,
    currentGeolocation?: {
        latitude?: number,
        longitude?: number,
    },
    currentZipCode?: string,
    geofenceEnabled?: boolean,
    geofenceSide?: number,
    initialData?: ManualAddress,
    setFilter: (filter) => void,
    setGeofenceEnabled?: (geofenceEnabled: boolean) => void,
    setGeofenceSide?: (geofenceSide: number) => void,
    type?: 'new' | 'editable' | 'readOnly',
}

const GoogleAddressContainer = ({
    currentAddressLine1 = '',
    currentAddressLine2 = '',
    currentAddressLine3 = '',
    currentCity = '',
    currentCountry = '',
    currentCountryCode = '',
    currentGeolocation = {},
    currentZipCode = '',
    geofenceEnabled,
    geofenceSide,
    initialData,
    setFilter,
    setGeofenceEnabled,
    setGeofenceSide = () => {},
    type = 'editable',
} : Props) => {
    useLoadScript({
        googleMapsApiKey: googleMapKey,
        libraries: LIBRARIES,
    });

    const countries:Country[] = useGetCountries();

    const [placeId, setPlaceId] = useState(null);
    const [autocompleteGoogle, setAutocompleteGoogle] = useState('');
    const [addressLine1, setAddressLine1] = useState(initialData?.addressLine1 || currentAddressLine1);
    const [addressLine1FormValue, setAddressLine1FormValue] = useState({
        description: initialData?.addressLine1 || currentAddressLine1,
        place_id: '',
        structured_formatting: {
            main_text: '',
            main_text_matched_substrings: [],
            secondary_text: '',
        },
    } as google.maps.places.AutocompletePrediction);
    const [addressLine2, setAddressLine2] = useState(currentAddressLine2);
    const [addressLine3, setAddressLine3] = useState(currentAddressLine3);
    const [city, setCity] = useState(currentCity);
    const [zipCode, setZipCode] = useState(currentZipCode);
    const [country, setCountry] = useState(currentCountry);
    const [countryCode, setCountryCode] = useState(currentCountryCode);
    const [geolocation, setGeolocation] = useState(currentGeolocation);

    const {
        ready,
        setValue,
        suggestions: { data },
    } = usePlacesAutocomplete();

    const resetData = () => {
        setCountry('');
        setCountryCode('');
        setCity('');
        setZipCode('');
        setGeolocation({ latitude: null, longitude: null });
    };

    const customData = useMemo(() => {
        if (data.length === 0 && !autocompleteGoogle && currentAddressLine1) {
            return [
                {
                    description: currentAddressLine1,
                    id: null,
                    matched_substrings: [],
                    place_id: '',
                    structured_formatting: {
                        main_text: '',
                        main_text_matched_substrings: [],
                        secondary_text: '',
                    },
                    terms: [],
                    types: [],
                } as google.maps.places.AutocompletePrediction,
            ];
        }

        return data.concat([
            {
                description: autocompleteGoogle,
                matched_substrings: [],
                place_id: '',
                structured_formatting: {
                    main_text: '',
                    main_text_matched_substrings: [],
                    secondary_text: '',
                },
                terms: [],
                types: [],
            },
        ]);
    }, [autocompleteGoogle, data]);

    useEffect(() => {
        if (!addressLine1) {
            resetData();
        }
    }, [addressLine1]);

    useEffect(() => {
        setFilter(previous => (
            {
                ...previous,
                addressLine1,
                addressLine2,
                addressLine3,
                city,
                country,
                countryCode,
                geolocation,
                zipCode,
            }
        ));
    }, [
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        zipCode,
        countryCode,
        country,
        geolocation,
    ]);

    useEffect(() => {
        if (initialData) {
            setAddressLine1(initialData?.addressLine1 || null);
            setAddressLine2(initialData?.addressLine2 || null);
            setAddressLine3(initialData?.addressLine3 || null);
            setCity(initialData?.city || null);
            setZipCode(initialData?.zipCode || null);
            setCountryCode(initialData?.country?.code || null);
            setGeolocation(initialData?.geolocation || null);
            setCountry(initialData?.country?.name || null);
        }
    }, [initialData]);

    useEffect(() => {
        if (placeId) {
            (async () => {
                try {
                    const response = await getGeocode({ placeId }) || [];
                    const [place = null] = response;

                    if (place) {
                        const { lat: latitude = null, lng: longitude = null } = await getLatLng(place) || {};

                        setGeolocation({ latitude, longitude });

                        const { address_components: addressComponents = [] } = place;
                        const countryComponent = addressComponents.find((item) => (
                            item.types.includes(GOOGLE_LOCATION_INFO.country)
                        )) || null;

                        if (countryComponent) {
                            setCountry(countryComponent.long_name);
                            setCountryCode(countryComponent.short_name);
                        }

                        const localityComponent = addressComponents.find((item) => (
                            item.types.includes(GOOGLE_LOCATION_INFO.locality)
                        )) || null;

                        if (localityComponent) {
                            setCity(localityComponent.long_name);
                        }

                        const zipCodeComponent = addressComponents.find((item) => (
                            item.types.includes(GOOGLE_LOCATION_INFO.postal_code)
                        )) || null;

                        if (zipCodeComponent) {
                            setZipCode(zipCodeComponent.long_name);
                        }
                    }
                } catch (error) {
                    global.console.log(error);
                    resetData();
                }
            })();
        } else if (placeId === '') {
            resetData();
        }
    }, [placeId]);

    return (
        <GoogleAddress
            addressLine1={addressLine1}
            addressLine1FormValue={addressLine1FormValue}
            addressLine2={addressLine2}
            addressLine3={addressLine3}
            addressOptions={customData}
            city={city}
            countries={countries}
            country={country}
            countryCode={countryCode}
            geofenceEnabled={geofenceEnabled}
            geofenceSide={geofenceSide}
            geolocation={geolocation}
            isGoogleServiceReady={ready}
            setAddressLine1={setAddressLine1}
            setAddressLine1FormValue={setAddressLine1FormValue}
            setAddressLine2={setAddressLine2}
            setAddressLine3={setAddressLine3}
            setAutocompleteGoogle={setAutocompleteGoogle}
            setCity={setCity}
            setCountry={setCountry}
            setCountryCode={setCountryCode}
            setGeofenceEnabled={setGeofenceEnabled}
            setGeofenceSide={setGeofenceSide}
            setGeolocation={setGeolocation}
            setPlaceId={setPlaceId}
            setValue={setValue}
            setZipCode={setZipCode}
            type={type}
            zipCode={zipCode}
        />
    );
};

export default GoogleAddressContainer;

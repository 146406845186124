import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles(theme => ({
    active: {
        '&&': {
            backgroundColor: theme.palette.primary.deepBlue,
            height: '10px',
            width: '10px',
        },
    },
    activeImg: {
        '& img': {
            borderRadius: '10px',
            height: 'auto',
            left: '0',
            position: 'fixed',
            top: '0',
            width: '100wh',
        },
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },
    anim_fadeIn: {
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
    },
    closeButton: {
        alignItems: 'center',
        color: '#6c757d',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '24px',
        height: '16px',
        justifyContent: 'center',
        position: 'absolute',
        right: '16px',
        top: '16px',
        width: '16px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        lineHeight: '20px',
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        height: '20px',
        justifyContent: 'space-between',
        marginTop: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '100%',
    },
    dot: {
        backgroundColor: theme.palette.secondary[600],
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'inline-block',
        height: '7px',
        transition: 'all 600ms ease 100ms',
        width: '7px',
    },
    dots: {
        alignItems: 'center',
        display: 'flex',
        gap: '5px',
    },
    imageHolder: {
        '& img': {
            borderRadius: '10px',
            height: '275px',
            maxWidth: '500px',
        },
        cursor: 'zoom-in',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        transition: '200ms ease',
    },
    line: {
        backgroundColor: theme.palette.primary[200],
        marginRight: '8px',
        width: '2px',
    },
    linkHolder: {
        marginTop: '20px',
        whiteSpace: 'nowrap',
    },
    next: ({ hasNext }) => ({
        '&:after': {
            content: '">"',
            paddingLeft: '20px',
            position: 'absolute',
            right: '-20px',
        },
        color: hasNext ? theme.palette.primary.deepBlue : theme.palette.secondary[400],
        cursor: 'pointer',
        position: 'relative',
    }),
    previous: ({ hasPrev }) => ({
        '&:after': {
            content: '"<"',
            left: '-20px',
            paddingRight: '20px',
            position: 'absolute',
        },
        color: hasPrev ? theme.palette.primary.deepBlue : theme.palette.secondary[400],
        cursor: 'pointer',
        position: 'relative',
    }),
    quickHelp: ({ classes }) => ({
        animation: `${classes.anim_fadeIn} 0.5s forwards`,
        color: theme.palette.secondary[600],
        display: 'flex',
        maxWidth: '600px',
        minWidth: '400px',
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 200ms ease',
        zIndex: 105,
    }),
    quickHelpRight: ({ classes }) => ({
        flexDirection: 'row-reverse',
        [`& .${classes.tooltipBodyWrapper}`]: {
            flexDirection: 'row-reverse',
        },
    }),
    title: {
        color: theme.palette.common.yellow,
        fontSize: '20px',
        fontWeight: 500,
    },
    tooltipBody: {
        backgroundColor: theme.palette.common.white,
        border: `3px solid ${theme.palette.common.yellow}`,
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'row',
        lineHeight: 'normal',
        minHeight: '50px',
        padding: '20px',
        width: '100%',

    },
    tooltipBodyWrapper: {
        alignItems: 'center',
        display: 'flex',
        filter: 'drop-shadow(2px 2px 3px #747474)',
        width: '100%',
    },
    tooltipVisible: {
        opacity: 1,
        pointerEvents: 'all',
    },
}));

export default styles;

import useClasses from 'hooks/useClasses';
import { Classes } from 'jss';
import React, { useContext, useMemo, useState } from 'react';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { TEMPERATURE_RANGES } from 'shared-components/constants';
import { LaneObject } from 'shared-components/dataTypes';
import LaneCardsContext from 'Contexts/LaneCardsContext';
import { useTheme } from '@mui/material/styles';
import useGetPackagingTypes from 'hooks/useGetPackagingTypes';
import useGetEntity from 'hooks/useGetEntity';
import { ENTITY_TYPE } from 'hooks/useGetEntity/useGetEntity';
import TempRange from 'shared-components/TempRange';
import rootStyles from 'LaneManagement/LaneDetails/LaneDetails.style';
import { styles } from './InformationCard.style';
import LockTooltip from './LockTooltip';
import { fieldMapper } from '../SummaryCard/fieldMapper';

type Props = {
    lane: LaneObject
}
const fieldsLeft = (classes: Classes, t) => [
    {
        id: 'laneNumber',
        label: t('LANE_MANAGEMENT.INFORMATION_CARD.LANE_IDENTIFIER'),
        type: 'text',
    },
    {
        id: 'tempRange',
        items: Object.keys(TEMPERATURE_RANGES)
            .map(key => ({
                render: <TempRange
                    key={`option_${key}`}
                    tempRange={key}
                />,
                value: key,
            })),
        label: t('COMMON.TEMPERATURE_RANGE'),
        type: 'select',
    },
    {
        id: 'allowedContainerTypes',
        label: t('LANE_MANAGEMENT.INFORMATION_CARD.ALLOWED_PACKAGING_TYPES'),
        type: 'packagingType',
    },
];
const fieldsRight = (classes: Classes, t) => [
    {
        id: 'handoverPoint',
        label: t('COMMON.HANDOVER_POINT'),
        type: 'location',
    },
    {
        id: 'collectionDropoffPoint',
        label: t('COMMON.COLLECTION_DROP_OFF_POINT'),
        type: 'location',
    },
    {
        id: 'consignee',
        label: t('LANE_MANAGEMENT.INFORMATION_CARD.CONSIGNEE'),
        type: 'location',
    },
    {
        id: 'originAirport',
        label: t('COMMON.ORIGIN_AIRPORT'),
        type: 'location',
    },
    {
        id: 'destinationAirport',
        label: t('COMMON.DESTINATION_AIRPORT'),
        type: 'location',
    },
];

const InformationCard = ({
    lane,
}:Props) => {
    const { skyCoreInfo } = lane;
    const [tooltipOpen, setTooltipOpen] = useState();

    const packagingTypes = useGetPackagingTypes();
    const theme = useTheme();

    const {
        dataLoaded,
        getEntity,
    } = useGetEntity();
    const {
        editingCard,
    } = useContext(LaneCardsContext);

    const skyMindObject = useMemo(() => {
        if (!packagingTypes || !dataLoaded) return { };
        return {
            ...skyCoreInfo,
            allowedContainerTypes: lane?.packagingTypes
                ?.filter(({ toCompare }) => toCompare === false)
                ?.map(({ code }) => packagingTypes.find(it => it.type === code)),
            collectionDropoffPoint: {
                address: skyCoreInfo.collectionDropoffPoint,
            },
            consignee: {
                address: skyCoreInfo.consignee,
            },
            destinationAirport: {
                airport: getEntity(lane.destinationAirportCode, ENTITY_TYPE.AIRPORT),
            },
            handoverPoint: {
                address: skyCoreInfo.handoverPoint,
            },
            originAirport: {
                airport: getEntity(lane.originAirportCode, ENTITY_TYPE.AIRPORT),
            },
            tempRange: lane.temperatureRange,

        };
    }, [lane, packagingTypes, dataLoaded, getEntity]);
    const classes = useClasses(styles);
    const rootClasses = useClasses(rootStyles);

    const { t } = useCustomTranslation();
    const customLockIcon = useMemo(() => {
        return (
            <LockTooltip
                classes={classes}
                open={tooltipOpen}
                setOpen={setTooltipOpen}
            />
        );
    }, [
        classes,
        tooltipOpen,
        setTooltipOpen,
    ]);

    return (
        <CardWithEditableContent
            className={classes.informationCard}
            customLockIcon={customLockIcon}
            disabled={!!editingCard}
            showLock
            title="SkyCell Information"

        >
            <div className={[classes.cardBody, classes.cardBodyDivided].join(' ')}>
                <div>
                    {
                        fieldsLeft(rootClasses, t).map(field => fieldMapper({
                            classes: rootClasses,
                            editable: false,
                            field,
                            onChange: () => {},
                            theme,
                            value: skyMindObject[field.id],
                        }))
                    }
                </div>
                <div>
                    {
                        fieldsRight(rootClasses, t).map(field => fieldMapper({
                            classes: rootClasses,
                            editable: false,
                            field,
                            onChange: () => {},
                            theme,
                            value: skyMindObject[field.id],
                        }))
                    }
                </div>
            </div>

        </CardWithEditableContent>
    );
};

export default InformationCard;

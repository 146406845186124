import { wrapStyles } from 'hooks/useClasses/useClasses';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { CIRCLE_GROW, CIRCLE_SIZE } from './TooltipCluster';

const styles = wrapStyles((theme: SkycellThemeInterface) => ({
    active: ({ classes }) => ({
        background: theme.palette.primary.deepBlue,
        color: 'white',
        height: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        minHeight: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        minWidth: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        transform: `translate(${-CIRCLE_GROW / 2}px,${-CIRCLE_GROW / 2}px)`,
        width: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        [`&.${classes.tooltipPredicted}`]: {
            backgroundColor: theme.palette.common.yellow,
            borderColor: theme.palette.common.yellow,
        },
        [`&.${classes.tooltipWarning}`]: {
            background: theme.palette.common.red,
        },
    }),

    activeWarning: ({ classes }) => ({
        backgroundColor: theme.palette.common.red,
        color: 'white',
        height: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        minHeight: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        minWidth: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        transform: `translate(${-CIRCLE_GROW / 2}px,${-CIRCLE_GROW / 2}px)`,
        width: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        [`&.${classes.tooltipPredicted}`]: {
            background: theme.palette.common.yellow,
        },
        [`&.${classes.tooltipWarning}`]: {
            background: theme.palette.common.red,
        },
    }),

    clusterBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginLeft: '-10px',
        paddingBottom: '150px',
        paddingLeft: '10px',
    },

    containerIcon: {
        height: '13px',
        margin: 'auto 2px',
        marginTop: '2px',
        width: '12px',
    },

    headerInfo: {
        alignItems: 'center',
        margin: '0 3px',
    },

    headerInfoGroup: {
        alignItems: 'center',
        display: 'flex',
        margin: '0',
    },

    headerInfoMessageText: {
        alignItems: 'center',
        color: theme.palette.secondary[600],
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        margin: '0 3px',
        opacity: 1,
    },

    headerInfoWithTextStyle: {
        alignItems: 'center',
        color: theme.palette.secondary[600],
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        margin: '2px 6px',
        opacity: 1,
        textAlign: 'left',
    },

    tooltip: {
        alignItems: 'center',
        background: 'white',
        border: `${CIRCLE_SIZE / 10}px solid ${theme.palette.primary.deepBlue}`,
        borderRadius: '50%',
        color: theme.palette.primary.deepBlue,
        cursor: 'unset',
        display: 'flex',
        fontSize: '14px',
        height: `${CIRCLE_SIZE}px`,
        justifyContent: 'center',
        minHeight: `${CIRCLE_SIZE}px`,
        minWidth: `${CIRCLE_SIZE}px`,
        transition: '200ms ease',
        width: `${CIRCLE_SIZE}px`,
    },

    tooltipHeader: {
        alignItems: 'center',
        display: 'flex',
        margin: '1px auto',
    },

    tooltipPredicted: {
        borderColor: theme.palette.common.yellow,
        color: theme.palette.common.yellow,
    },

    tooltipWarning: {
        borderColor: theme.palette.common.red,
        color: theme.palette.common.red,
    },

    warningIcon: {
        height: '19px',
        margin: 'auto 2px',
        marginTop: '2px',
        width: '22px',
    },

    wrapper: {
        cursor: 'unset',
        display: 'flex',
        flexDirection: 'row',
    },
}));

export default styles;

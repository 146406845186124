import { wrapStyles } from 'hooks/useClasses/useClasses';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const nthChildStyles = (theme: SkycellThemeInterface, lineClass: string) => Array.from({ length: 7 }, (_, i) => {
    const background = i === 0 ? theme.palette.primary.darkBlue : theme.palette.primary[(i + 1) * 100];

    return {
        [`:nth-child(7n+${i + 1})`]: {
            [`.${lineClass}`]: {
                background,
            },
        },
    };
}).reduce((acc, curr) => ({ ...acc, ...curr }), {});

const styles = wrapStyles(theme => ({
    anim_fadeInKeyframes: {
        '0%': {
            opacity: 0,
            transform: 'scale(0.5)',
        },
        '100%': {
            opacity: 1,
            transform: 'scale(1)',
        },
    },
    anim_fadeInOpacityKeyframes: {
        '0%': {
            opacity: 0.4,
        },
        '100%': {
            opacity: 1,
        },
    },
    divider: {
        background: `linear-gradient(90deg, ${theme.palette.secondary[600]} 4px, ${theme.palette.common.white} 4px)`,
        backgroundSize: '8px',
        height: '1px',
        marginLeft: '40px',
        marginRight: '40px',
        width: '75px',
    },
    icon: ({ classes }) => ({
        animation: `${classes.anim_fadeInKeyframes} 200ms forwards ease-in-out`,
        maxHeight: '30px',
        maxWidth: '100%',
        minWidth: '5%',
        width: 'auto',
    }),
    iconWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    line: ({ classes }) => ({
        animation: `${classes.anim_fadeInOpacityKeyframes} 300ms forwards ease-in-out`,
        height: '22px',
        transition: '200ms ease',
    }),
    lineTbd: {
        background: `${theme.palette.secondary[400]}!important`,
    },
    root: ({ classes }) => {
        const nthStyles = nthChildStyles(theme, classes.line);

        return {
            alignItems: 'center',
            display: 'grid',
            flex: 1,
            flexDirection: 'column',
            gridAutoRows: '20px 20px 31px',
            gridGap: '8px',
            transition: '200ms ease',
            ...nthStyles,
        };
    },
    shrinked: {
        '&:hover': {
            filter: 'grayscale(1)',
        },
        cursor: 'pointer',
        gridAutoRows: '20px 31px',
    },
    time: {
        color: theme.palette.secondary[600],
        textAlign: 'center',
    },
    tooltipBody: {
        maxWidth: 'unset!important',
        width: 'auto!important',
    },
    tooltipDescription: {
        alignItems: 'center',
        display: 'flex',
        whiteSpace: 'nowrap',
    },
}));

export default styles;

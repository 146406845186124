import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles(theme => ({
    active: {},
    anim_fadeIn: {
        '0%': { opacity: 0 },
        '100%': { opacity: 1 },
    },
    anim_fadeInOpacity: {
        '0%': { opacity: 0.4 },
        '100%': { opacity: 1 },
    },
    divider: {
        background: `linear-gradient(90deg, ${theme.palette.secondary[600]} 4px, ${theme.palette.common.white} 4px)`,
        backgroundSize: '8px',
        height: '1px',
        marginLeft: '40px',
        marginRight: '40px',
        width: '75px',
    },
    icon: ({ classes }) => ({
        animation: `${classes.anim_fadeIn} 200ms forwards ease-in-out`,
        height: '25px',
        maxWidth: '25px',
        minWidth: '5%',
        width: '100%',
    }),
    iconWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    line: ({ classes }) => ({
        animation: `${classes.anim_fadeInOpacity} 300ms forwards ease-in-out`,
        height: '22px',
        transition: '200ms ease',
    }),
    lineTbd: {
        background: `${theme.palette.secondary[400]} !important`,
    },
    root: ({ classes: { active, line } }) => ({
        alignItems: 'center',
        display: 'grid',
        flex: 1,
        flexDirection: 'column',
        gridAutoRows: '20px 20px 31px',
        gridGap: '8px',
        transition: '200ms ease',
        [`&:nth-child(4n+4) > .${line}`]: {
            background: theme.palette.common.thirdShadeOfGray,
        },
        [`&:nth-child(4n+4) > .${line}.${active}`]: {
            background: theme.palette.primary[400],
        },
        [`&:nth-child(4n+3) > .${line}`]: {
            background: theme.palette.common.fourthShadeOfGray,
        },
        [`&:nth-child(4n+3) > .${line}.${active}`]: {
            background: theme.palette.primary[700],
        },
        [`&:nth-child(4n+2) > .${line}`]: {
            background: theme.palette.common.thirdShadeOfGray,
        },
        [`&:nth-child(4n+2) > .${line}.${active}`]: {
            background: theme.palette.primary[400],
        },
        [`&:nth-child(4n+1) > .${line}`]: {
            background: theme.palette.common.secondShadeOfGray,
        },
        [`&:nth-child(4n+1) > .${line}.${active}`]: {
            background: theme.palette.primary[30],
        },
    }),
    shrinked: {
        '&:hover': {
            filter: 'grayscale(1)',
        },
        cursor: 'pointer',
        gridAutoRows: '20px 31px',
    },
    time: {
        color: theme.palette.secondary[600],
        textAlign: 'center',
    },
    tooltipBody: {
        maxWidth: 'unset !important',
        width: 'auto !important',
    },
    tooltipDescription: {
        alignItems: 'center',
        display: 'flex',
        whiteSpace: 'nowrap',
    },
}));

export default styles;

import React, { useState } from 'react';
import Card from 'shared-components/Card';
import CommonButton from 'shared-components/CommonButton';
import RadioButtonType from 'shared-components/CompanyInfoComponents/AnswerUnit/RadioButtonType';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import { AuditStatusHistoryItem } from 'MyCompany/Sites/lib';
import AddAudit from './AddAudit';
import AuditHistory from './AuditHistory';
import AuditInfoRow from './AuditInfoRow';
import styles from './Audits.style';

type Props = {
    audited?: boolean,
    auditedBy: string,
    auditedOn: string,
    auditStatusHistory: AuditStatusHistoryItem[],
    auditType: string,
    setQuestionnaireBasedOnAudit: (auditAnswer: string[]) => void,
}

const Audits = ({
    audited = false,
    auditedBy = '',
    auditedOn = '',
    auditStatusHistory,
    auditType,
    setQuestionnaireBasedOnAudit,
} : Props) => {
    const classes = useClasses(styles);
    const [openAuditHistory, setOpenAuditHistory] = useState(false);
    const [openAddAudit, setOpenAddAudit] = useState(false);
    const { t } = useCustomTranslation();
    const auditHistory = () => setOpenAuditHistory(!openAuditHistory);
    const addAudit = () => setOpenAddAudit(!openAddAudit);

    if (audited) {
        return (
            <Card title={t('MY_COMPANY.AUDITS')}>
                <AuditInfoRow
                    auditType={auditType}
                    auditedBy={auditedBy}
                    auditedOn={auditedOn}
                />
                <div className={classes.row}>
                    <div>{t('MY_COMPANY.ARE_YOUR_ANSWERS_BASED_ON_THIS_AUDIT')}</div>
                    <RadioButtonType
                        availableAnswerOptions={['YES', 'NO']}
                        row
                        setAnswerUpdate={setQuestionnaireBasedOnAudit}
                    />
                </div>
                <div className={classes.buttonContainer}>
                    <CommonButton
                        autoFocus
                        className={classes.auditButton}
                        onClick={auditHistory}
                    >
                        {t('MY_COMPANY.AUDIT_HISTORY')}
                    </CommonButton>
                    <CommonButton
                        className={classes.auditButton}
                        onClick={addAudit}
                    >
                        {t('MY_COMPANY.ADD_AUDIT')}
                    </CommonButton>
                </div>
                <AuditHistory
                    auditStatusHistory={auditStatusHistory}
                    handleClose={auditHistory}
                    open={openAuditHistory}
                />
                <AddAudit
                    handleClose={addAudit}
                    open={openAddAudit}
                />
            </Card>
        );
    }

    return (
        <Card title="Audits">
            <div className={classes.row}>
                {t('MY_COMPANY.NOT_YET_BEEN_AUDITED')}
            </div>
            <div className={classes.buttonContainer}>
                <CommonButton
                    className={classes.auditButton}
                    onClick={addAudit}
                >
                    {t('MY_COMPANY.ADD_AUDIT')}
                </CommonButton>
            </div>
            <AddAudit
                handleClose={addAudit}
                open={openAddAudit}
            />
        </Card>
    );
};

export default Audits;

import React, { ReactNode } from 'react';
import TableRow, { TableRowProps } from '@mui/material/TableRow';

import useClasses from 'hooks/useClasses';
import styles from './LinkedTableRow.style';

type Props = {
    children: ReactNode | ReactNode[],
    className?: string,
    isCursorPointer?: boolean,
    lastRowRef?: (node: HTMLTableRowElement | null) => void,
    linkedTableRowProps?: TableRowProps
    onClick?: React.MouseEventHandler<HTMLTableRowElement>,
}

const LinkedTableRow = ({
    children = null,
    className = '',
    isCursorPointer = false,
    lastRowRef = null,
    linkedTableRowProps = null,
    onClick = null,
}: Props) => {
    const classes = useClasses(styles);
    const { key, ...rest } = linkedTableRowProps || {};

    return (
        <TableRow
            {...rest}
            key={key}
            ref={lastRowRef}
            className={[
                isCursorPointer ? classes.linkedTableRowWithPointer : classes.linkedTableRow,
                className,
            ].join(' ')}
            hover
            onClick={onClick}
        >
            {children}
        </TableRow>
    );
};

export default LinkedTableRow;

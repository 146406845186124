import useClasses from 'hooks/useClasses';
import React, {
    useState,
    useMemo,
    ReactNode,
    CSSProperties,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';
import TableComponent from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CircularProgress } from '@mui/material';
import { getLinkPathByTemplate, getBodyCellValue } from './lib';
import {
    ClientSideFilterType,
    ColumnsType,
    ColumnFilterOptions,
    TableMaxHeight,
    ClassNames,
} from './dataTypes';

import BodyCell from './components/BodyCell';
import HeaderCell from './components/HeaderCell';
import LinkedTableRow from './components/LinkedTableRow';
import styles from './Table.style';
import CommonButton from '../CommonButton';
import { DownloadComponentIcon } from '../ApexTemperatureChart/icons';

type Props = {
    afterTable?: ReactNode,
    bodyCellVerticalAlign?: 'top' | 'middle' | 'bottom',
    classNames?: ClassNames,
    clickedRowIndexExternal?: number,
    clientSideFilter: ClientSideFilterType,
    columnFilterOptions?: ColumnFilterOptions,
    columns: ColumnsType[],
    currentSort?: {columnId: string, direction: 'asc' | 'desc'},
    data: {[key: string]: any}[],
    disableTableHeader?: boolean,
    downloadButtonLoading?: boolean,
    embed?: boolean,
    equalColumnWidths?: boolean,
    lastRowRef?: (node: HTMLTableRowElement | null) => void,
    maskForHighlight?: string,
    moveColumn?: (dragIndex: number, hoverIndex: number) => void,
    onDownloadExcel?: () => void,
    onRowClick?: (...args: any) => void,
    onRowHover?: (...args: any) => void,
    onSort?: (columnId: string, direction: 'asc' | 'desc') => void,
    prepareRowLinkTemplate?: (rowData: any) => string,
    rowExtension?: ReactNode,
    rowExtensionIndex?: number,
    rowLinkTemplate?: string,
    setClientSideFilter: (clientSideFilter: ClientSideFilterType) => void,
    style?: CSSProperties,
    tableId?: string,
    tableMaxHeight?: TableMaxHeight,
    tableMinHeight?: string,
    title?: string
}

const Table = ({
    afterTable = null,
    bodyCellVerticalAlign = 'top',
    classNames = null,
    clickedRowIndexExternal = null,
    clientSideFilter,
    columnFilterOptions,
    columns,
    currentSort = null,
    data,
    disableTableHeader,
    downloadButtonLoading = false,
    embed = false,
    equalColumnWidths = false,
    lastRowRef = null,
    maskForHighlight = '',
    moveColumn,
    onDownloadExcel = null,
    onRowClick = null,
    onRowHover = () => {},
    onSort = null,
    prepareRowLinkTemplate = null,
    rowExtension = null,
    rowExtensionIndex = null,
    rowLinkTemplate = null,
    setClientSideFilter,
    style = { },
    tableId = '',
    tableMaxHeight = '80vh',
    tableMinHeight = 'unset',
    title = null,
}: Props) => {
    const classes = useClasses(styles, {
        title,
    });
    const navigate = useNavigate();
    const [clickedRowIndex, setClickedRowIndex] = useState<number>(null);

    const {
        getTableBodyProps,
        getTableProps,
        headerGroups,
        prepareRow,
        rows,
    } = useTable({ columns, data }, useSortBy);

    const equalColumnWidth = useMemo(() => (`${100 / columns.length}%`), [columns.length]);

    const preparedRows = useMemo(() => {
        if (!rows) {
            return [];
        }

        return rows.map((row, rowIndex: number) => {
            prepareRow(row);
            const skippedCells = [0];
            const handleRowClick = () => {
                if (rowLinkTemplate) {
                    const linkPath = getLinkPathByTemplate(rowLinkTemplate, row.original);

                    navigate(linkPath, { replace: true });
                } else if (prepareRowLinkTemplate) {
                    const linkPath = prepareRowLinkTemplate(row.original);

                    navigate(linkPath);
                } else if (onRowClick) {
                    onRowClick(row?.original || {}, rowIndex);
                    setClickedRowIndex(rowIndex);
                }
            };
            const props = {
                onMouseEnter: () => onRowHover({
                    original: row?.original || {},
                    state: true,
                }),
                onMouseLeave: () => onRowHover({
                    original: row?.original || {},
                    state: false,
                }),
                ...row.getRowProps(),
            };

            return (
                <LinkedTableRow
                    key={`LinkedTableRow-${rowIndex}`}
                    className={[
                        classNames?.linkedTableRowClassName || '',
                        clickedRowIndexExternal === rowIndex ? classNames.clickedRowExternalClassName : '',
                    ].join(' ')}
                    isCursorPointer={rowLinkTemplate !== null}
                    lastRowRef={(rows.length === rowIndex + 1) ? lastRowRef : null}
                    linkedTableRowProps={props}
                    onClick={handleRowClick}
                >
                    {
                        row.cells.map((cell, cellIndex: number) => {
                            const colSpan = (cell.column.dataType === 'stretchedCell'
                                && cell.value.colSpan) || null;

                            const requiredSkip = cellIndex < skippedCells[skippedCells.length - 1];

                            if (requiredSkip) {
                                return null;
                            }

                            if (colSpan) {
                                skippedCells.push(cellIndex + cell.value.colSpan);
                            }

                            const linkPath = getLinkPathByTemplate(cell.column.bodyCellLinkTemplate, row.original);
                            const handleBodyCellClick = () => navigate(linkPath);

                            return (
                                <BodyCell
                                    key={`BodyCell-${cellIndex}`}
                                    bodyCellProps={cell.getCellProps()}
                                    bodyData={getBodyCellValue(cell.column.dataType, cell.value)}
                                    className={classNames.bodyCell}
                                    colSpan={colSpan}
                                    customCellStyle={cell.column.customCellStyle}
                                    dataType={cell.column.dataType}
                                    maskForHighlight={cell.column.overrideHighlight || maskForHighlight}
                                    verticalAlign={bodyCellVerticalAlign}
                                    onClick={linkPath ? handleBodyCellClick : null}
                                />
                            );
                        })
                    }
                </LinkedTableRow>
            );
        });
    }, [rows, clickedRowIndexExternal, maskForHighlight]);

    const rowsWithExtension = useMemo(() => {
        if (preparedRows.length === 0) {
            return [];
        }

        if (!rowExtension) {
            return preparedRows;
        }

        const rowIndex = rowExtensionIndex || clickedRowIndex;
        const { linkedTableRowClassName = '', rowExtensionClassName = '' } = classNames || {};

        return [
            ...preparedRows.slice(0, rowIndex + 1),
            <LinkedTableRow
                key={`LinkedTablerowExtension-${rowIndex}`}
                className={[linkedTableRowClassName, rowExtensionClassName].join(' ')}
            >
                <BodyCell
                    key={`BodyCellSpannedExtension-${rowIndex}`}
                    bodyData={rowExtension}
                    className={classNames.bodyCell}
                    colSpan={columns.length}
                    dataType="custom"
                />
            </LinkedTableRow>,
            ...preparedRows.slice(rowIndex + 1),
        ];
    }, [
        preparedRows,
        clickedRowIndex,
        rowExtension,
        rowExtensionIndex,
    ]);

    return (
        <div
            className={[
                classes.tableContainer,
                classNames?.tableContainerClassName || '',
                embed ? '' : classes.boxShadow].join(' ')}
            style={{ maxHeight: tableMaxHeight, minHeight: tableMinHeight, overflow: 'auto', ...style }}
        >
            {
                title && (
                    <div className={classes.tableTitle}>
                        {title}
                        { tableId === 'assetsTable'
                            ? downloadButtonLoading ? (
                                <CircularProgress
                                    color="primary"
                                    size={24}
                                    style={{ marginRight: 30 }}
                                />
                            ) : (
                                <CommonButton
                                    disabled={data.length === 0}
                                    startIcon={<DownloadComponentIcon color={null} distorted />}
                                    variant="text"
                                    onClick={onDownloadExcel}
                                >
                                    Excel
                                </CommonButton>
                            )
                            : null}
                    </div>
                )
            }
            <TableComponent
                className={[
                    classes.tableComponent,
                    classNames.tableClassName || '',
                ].join(' ')}
                {...getTableProps()}
            >
                {
                    !disableTableHeader && (
                        <TableHead className={[
                            classes.tableHeader,
                            classNames?.tableHeaderClassName || '',
                        ].join(' ')}
                        >
                            {
                                headerGroups.map((headerGroup) => {
                                    const { key, ...rest } = headerGroup.getHeaderGroupProps();

                                    return (
                                        <TableRow
                                            key={`TableHeadRow-${key}`}
                                            className={[
                                                classes.reactTableTableRow,
                                                classNames?.tableRowClassName || '',
                                            ].join(' ')}
                                            {...rest}
                                        >
                                            {
                                                headerGroup.headers.map((column, index) => {
                                                    const hasCustomSort = currentSort?.columnId === column.id;
                                                    const isCustomSorted = hasCustomSort
                                                        && currentSort?.direction !== null;
                                                    const customSortedDesc = hasCustomSort
                                                    && currentSort?.direction === 'desc';

                                                    return (
                                                        <HeaderCell
                                                            key={`HeaderCell-${index}`}
                                                            className={[
                                                                classNames.headerCell, column.noBar
                                                                    ? classes.noBar : '',
                                                            ].join(' ')}
                                                            clientSideFilter={clientSideFilter}
                                                            columnFilterOptions={columnFilterOptions}
                                                            columnWidth={
                                                                column.customWidth
                                                            || (equalColumnWidths && equalColumnWidth) || null
                                                            }
                                                            customHeaderStyle={column.customHeaderStyle}
                                                            filterKey={column.filterKey || column.id}
                                                            filterType={column.filterType}
                                                            headerData={column.render('Header')}
                                                            headerProps={
                                                                onSort
                                                                    ? null
                                                                    : column.getHeaderProps(
                                                                        column
                                                                            .getSortByToggleProps({ title: undefined }),
                                                                    )
                                                            }
                                                            index={index}
                                                            isSorted={isCustomSorted || column.isSorted}
                                                            isSortedDesc={customSortedDesc || column.isSortedDesc}
                                                            moveColumn={moveColumn}
                                                            noDivider={column.noDivider}
                                                            setClientSideFilter={setClientSideFilter}
                                                            onClick={onSort ? () => {
                                                                if (column.disableSortBy) return;
                                                                const direction = currentSort?.columnId !== column.id
                                                                    ? 'asc' : (currentSort?.direction === null
                                                                        ? 'asc' : (currentSort?.direction === 'asc'
                                                                            ? 'desc' : null));

                                                                onSort(column.id, direction);
                                                            } : null}
                                                        />
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    );
                                })
                            }
                        </TableHead>

                    )
                }
                <TableBody {...getTableBodyProps()}>
                    {
                        rowsWithExtension
                    }
                </TableBody>
            </TableComponent>
            {afterTable}
        </div>
    );
};

export default Table;

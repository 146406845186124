import useClasses from 'hooks/useClasses';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Delete, Edit, DragIndicator, Save } from '@mui/icons-material';
import Card from 'shared-components/Card';
import LaneCardsContext from 'Contexts/LaneCardsContext';
import { Draggable } from 'react-beautiful-dnd';
import LocationSelector from 'shared-components/LocationSelector';
import rootStyles from 'LaneManagement/LaneDetails/LaneDetails.style';
import Location from 'LaneManagement/LaneDetails/StepsWrapper/Location';
import { hasTwoLocations } from 'LaneManagement/LaneDetails/objectDefaults';
import { dtoAddressToDTOObject, unwrapEnum } from 'LaneManagement/LaneDetails/tools';
import { CardType } from 'Contexts/LaneCardsContext/LaneCardsContext';
import { uniqueId } from 'utils/stringTool';
import { Milestone } from 'shared-components/dataTypes';
import { styles } from './CardWrapper.style';

type Props = {
    children?: any[],
    className?: string,
    index?: number,
    onChange: Function,
    stepData: Milestone
}

const CardWrapper = ({
    children,
    className,
    index,
    onChange,
    stepData,
}: Props) => {
    const classes = useClasses(styles);
    const rootClasses = useClasses(rootStyles);
    const [validators, setValidators] = useState({
        activities: true,
    });

    const {
        steps,
    } = useContext(LaneCardsContext);

    const [cardHover, setCardHover] = useState(false);
    const { deleteMilestone, stepValidation, updateMilestone } = useContext(LaneCardsContext);
    const [startLocationSelector, setOriginLocation] = useState(false);
    const [endLocationSelector, setDestinationLocation] = useState(false);

    const {
        editingCard,
        setEditingCard,
    } = useContext(LaneCardsContext);

    const edit = useMemo(() => {
        return editingCard?.type === CardType.MILESTONE && editingCard.id === stepData?.index;
    }, [editingCard, stepData?.index]);

    const setEdit = useCallback((bool = false) => {
        if (editingCard
            && editingCard.id !== stepData?.index) return;

        setEditingCard(bool ? {
            id: stepData?.index,
            type: CardType.MILESTONE,
        } : null);
    }, [setEditingCard, editingCard, stepData?.index]);

    const handleAddressChange = useCallback((address, type:'pickupLocation' | 'deliveryLocation') => {
        const dtoObj = { ...stepData };
        const storeObj = { ...stepData };
        const addressTypes = ['pickupLocation', 'deliveryLocation'].filter(it => it !== type);

        dtoObj[type] = dtoAddressToDTOObject(address);
        storeObj[type] = address;
        addressTypes.forEach(restType => {
            if (dtoObj[restType]) {
                dtoObj[restType] = dtoAddressToDTOObject(dtoObj[restType]);
            }
        });
        updateMilestone(stepData.index, unwrapEnum(dtoObj), unwrapEnum(storeObj));
    }, [onChange, stepData, steps]);

    return (
        <Draggable key={`milestone_${stepData?.uniqueId}`} draggableId={stepData?.uniqueId || uniqueId()} index={index}>
            {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <div>
                        <Card
                            className={
                                [rootClasses.stepCard, snapshot.isDragging ? rootClasses.draggedCard : ''].join(' ')
                            }
                            contentClass={[
                                rootClasses.stepCardRoot,
                                `color${index % 4}`,
                                className,
                            ].join(' ')}
                            disabled={editingCard && !edit}
                            fullHeight
                            highlight={edit}
                        >
                            <div className={classes.location}>
                                <Location
                                    destinationLocation={stepData?.deliveryLocation}
                                    endLocationClicked={setDestinationLocation.bind(null, true)}
                                    milestoneId={stepData?.uniqueId}
                                    originLocation={stepData?.pickupLocation}
                                    startLocationClicked={setOriginLocation.bind(null, true)}
                                    twoLocations={hasTwoLocations[stepData.type]}
                                    wrongDestination={!stepValidation?.[index]?.deliveryLocation}
                                    wrongOrigin={!stepValidation?.[index]?.pickupLocation}
                                />
                            </div>
                            <div className={classes.transport}>
                                {children?.[0]
                                    && React.cloneElement(
                                        children?.[0],
                                        { edit, onChange, setValidators, stepData, validators },
                                    )}
                            </div>
                            <div
                                className={classes.control}
                                onMouseEnter={() => setCardHover(true)}
                                onMouseLeave={() => setCardHover(false)}
                            >
                                {children?.[1]}
                                <div className={classes.hoverIconsWrapper}>
                                    <div
                                        className={[classes.hoverIconsRow, (cardHover || edit) ? classes.visible : '']
                                            .join(' ')}
                                    >
                                        <div onClick={() => {
                                            deleteMilestone(stepData?.index);
                                        }}
                                        >
                                            <Delete className={classes.icon} />
                                        </div>
                                        <div
                                            {...provided.dragHandleProps}
                                        >
                                            <DragIndicator
                                                className={classes.icon}
                                            />
                                        </div>
                                        {
                                            !edit && (
                                                <div
                                                    onClick={() => setEdit(true)}
                                                >
                                                    <Edit className={classes.icon} />
                                                </div>
                                            )
                                        }
                                        {
                                            edit && (
                                                <div
                                                    onClick={setEdit.bind(null, !validators.activities)}
                                                >
                                                    <Save className={classes.icon} />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                        </Card>
                        <LocationSelector
                            addressData={stepData?.pickupLocation}
                            open={startLocationSelector}
                            onCloseAction={() => setOriginLocation(false)}
                            onDataChange={
                                (address) => handleAddressChange(
                                    address, 'pickupLocation',
                                )
                            }
                        />
                        {
                            hasTwoLocations[stepData.type] && (

                                <LocationSelector
                                    addressData={stepData?.deliveryLocation}
                                    open={endLocationSelector}
                                    onCloseAction={() => setDestinationLocation(false)}
                                    onDataChange={
                                        (address) => handleAddressChange(
                                            address, 'deliveryLocation',
                                        )
                                    }
                                />
                            )
                        }

                    </div>
                </div>
            )}
        </Draggable>

    );
};

export default CardWrapper;

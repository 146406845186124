import useClasses from 'hooks/useClasses';
import React, { forwardRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddCircle from '@mui/icons-material/AddCircle';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import { fetchTableData } from 'ServiceProviders/lib';
import Card from 'shared-components/Card';
import Table from 'shared-components/Table';
import useCustomTranslation from 'hooks/useCustomTranslation';
import styles from 'MyCompany/Sites/Sites.style';
import { Site } from 'dataTypes/SecureBackend/apiResponse/Site';
import tableColumns from './tableColumns';

type Props = {
    className?: string,
    serviceProviderId?: number | unknown,
}

const ServiceProviderSites = forwardRef<HTMLDivElement, Props>(({ className = '', serviceProviderId }: Props, ref) => {
    const { GetAll: getSites } = useSkymindBackendEndpoints(
        `companies/${serviceProviderId}/sites`)
        .requests;
    const [data, setData] = useState([]);
    const { t } = useCustomTranslation();

    const classes = useClasses(styles);

    useEffect(() => {
        (async () => {
            try {
                const response: Site[] = (await getSites()).data;

                setData(fetchTableData(response));
            } catch (error) {
                setData([]);
                global.console.log(error);
            }
        })();
    }, []);

    return (
        <Card
            ref={ref}
            className={className}
            title={t('COMMON.SITES')}
            zeroSidePadding
        >
            <Table
                classNames={{
                    bodyCell: classes.cell,
                    headerCell: classes.hcell,
                }}
                columns={tableColumns(t)}
                data={data}
                embed
                rowLinkTemplate="/my-company/sites/:id/overview"
                tableMaxHeight="300px"
            />
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '11px 13px 0',
                }}
            >
                <Link to="/my-company/sites/new">
                    <AddCircle color="action" fontSize="small" />
                </Link>
            </div>
        </Card>
    );
});

ServiceProviderSites.displayName = 'ServiceProviderSites';

export default ServiceProviderSites;

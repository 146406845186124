import { wrapStyles } from 'hooks/useClasses/useClasses';

const styles = wrapStyles((theme) => ({
    label: {
        color: 'black',
        display: 'inline-flex',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        marginRight: '19.5px',
        opacity: 1,
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            flex: '100%',
            textAlign: 'center',
        },
    },
    labelBlue: {
        color: '#009CCF',
        display: 'inline-flex',
        paddingLeft: '4px',
        paddingRight: '4px',
    },
    legendWrapper: {
        bottom: '25px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        justifyContent: 'space-between',
        left: '25px',
        position: 'absolute',
        right: '60px',
        zIndex: 2,
    },
    muiPagination: {
        margin: '3px 0',
    },
    pagination: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 1px 3px #00000033',
        boxSizing: 'border-box',
        display: 'flex',
        height: '70px',
        padding: '0 9px',
        [theme.breakpoints.down('sm')]: {
            flex: '1',
            flexWrap: 'wrap',
            height: 'auto',
            justifyContent: 'center',
            padding: '5px',
        },
    },
    root: {
        '& .Mui-selected.Mui-selected': {
            backgroundColor: '#009CCF!important',
            color: '#FFFFFF',
        },
        '& .MuiPaginationItem-root': {
            backgroundColor: 'transparent',
            border: '1px solid #009CCF',
            borderRadius: '0',
            color: '#009CCF',
            font: 'normal normal normal 14px/17px Roboto',
            height: '100%',
            margin: '3px 1.5px',
            padding: '0',
            width: '20px',
        },
    },
}));

export default styles;
